
import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import { Typography,Table, Option,Button } from '@mui/joy';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,InputLabel,MenuItem, FormControl, Select,Dialog,DialogTitle,DialogContent,DialogActions,Box,Tab,DialogContentText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import Pending from '../../../Components/CommissionSummary/Pending.js';
import Approved from '../../../Components/CommissionSummary/Approved.js';
import Saved from '../../../Components/CommissionSummary/Saved';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import Loader from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import swal from "sweetalert";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};
let summaryMonthYear =[];
let reportSubmitted = 0;


function CommissionSummary(props) {
    const dispatch = useDispatch();
    const {http, user} = AuthUser();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [labelMonth, setLabelMonth] = useState();
    const [labelYear, setLabelYear] = useState();
    const [monthValue, setMonthValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [saveToLocal, setSaveToLocal] = useState(false);
    const [freez, setFreez] = React.useState(true);
    const [currentDate, setCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const navigate = useNavigate();
    const handleFreezStop = () => {
        setIsLoading(false);
    };
    const handleFreezStart = () => {
        setIsLoading(true);
    };

    const backupData = (values) =>{
        summaryMonthYear = values;
        localStorage.setItem('summaryMonth', summaryMonthYear[0]);
        localStorage.setItem('summaryYear', summaryMonthYear[1]);
    }

    const handleClickOpen = () => {
      setOpen(true);
       
    };

    const setDefaultMonthYear = async page => {
        const currentDate = new Date();
        const lastTwoMonthsDate = new Date();
        lastTwoMonthsDate.setMonth(currentDate.getMonth() - 2);
        const lastTwoMonthsYear = lastTwoMonthsDate.getFullYear();
        const lastTwoMonth = lastTwoMonthsDate.getMonth();
        localStorage.getItem("summaryMonth") === null ? setMonth(lastTwoMonth) : setMonth(localStorage.getItem("summaryMonth"));
        localStorage.getItem("summaryYear") === null ? setYear(lastTwoMonthsDate) : setYear(localStorage.getItem("summaryYear"));
        // setMonth(lastTwoMonth)
        // setYear(lastTwoMonthsYear);
        if(reportSubmitted == 1) {
            setIsLoading(true);
            // setFreez(true);
            const apiUrl = '/commission-data';
            const args = {
                month: summaryMonthYear[0] ? summaryMonthYear[0] : lastTwoMonth,
                year: summaryMonthYear[1] ? new Date(summaryMonthYear[1]).getFullYear() : lastTwoMonthsYear,
                save_to_local : 0,
            };
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if(res.success === true){
                    setData(res)
                    setLabelMonth(res.dateData.month);                                         
                    setLabelYear(res.dateData.year);                                         
                    setMonthValue(res.dateData.monthvalue);  
                    setIsLoading(false);
                  
                    // handleFreezStop();                                   
                }else{
                    setData([]);
                    setIsLoading(false);   
                    // handleFreezStop();    
                }
            }
        }
    }

    useEffect(() => {
        if(user.roles_id < 3) {
            const myDate = new Date();
            myDate.setDate(myDate.getDate());
            var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
            setCurrentDate(cDate);
    
            const startDate = new Date('2005-01-01');
            startDate.setDate(startDate.getDate());
            var cDate = Moment(startDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
            setMinDate(cDate);

            setDefaultMonthYear();
        }else{
            navigate('/login');
        }
       
    }, []);

    const checkUserRole = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }else{
            return true;
        }
    };

    const submitForm =async (event) => {
        var check = checkUserRole();
        if(check === true) {
            var currentMonth = (new Date).getMonth();
            var currentYear = (new Date).getFullYear();
            if(month > currentMonth && (new Date(year).getFullYear() == currentYear || new Date(year).getFullYear() > currentYear)){
                swal({
                    title: "Failed",
                    text: "Please Select Correct Month and Year",
                    icon: "error",
                    button: "Ok",
                  })
                  return false;
            }
            if (typeof month === 'undefined' || typeof year === 'undefined') {          //check month & year is undefined
                swal({
                    title: "Failed",
                    text: "Please Select Month and Year",
                    icon: "error",
                    button: "Ok",
                  })
                
            }else {
                reportSubmitted = 1;
            
                // setFreez(true);
                setIsLoading(true);
                const sendYear = new Date(year).getFullYear();
                const apiUrl = '/check-fetch-month-year';
                const args = {
                    month : month,
                    year:sendYear, 
                };
                const res = await dispatch(postData({ apiUrl, args })).unwrap();
                if (res) {
                    if(res.success === true){
                        
                        if(res.exists == "Yes") {
                            const apiUrl = '/commission-data';
                            const args = {
                                month: month,
                                year: sendYear,
                                save_to_local : saveToLocal,
                            };
                            const res = await dispatch(postData({ apiUrl, args })).unwrap();
                            if (res) {
                                if(res.success === true){
                                    setData(res)
                                    setLabelMonth(res.dateData.month);                                         
                                    setLabelYear(res.dateData.year);                                         
                                    setMonthValue(res.dateData.monthvalue);  
                                   
                                    setIsLoading(false);   
                                    // handleFreezStop();                                        
                                }else{
                                    setData([]);
                                    setIsLoading(false);  
                                    // handleFreezStop();         
                                }
                            }
                        }else {
                            handleClickOpen();
                            
                        }
                        //setIsLoading(false);
                        // handleFreezStop();    
    
                    }else{
    
                        setIsLoading(false);
                        // handleFreezStop();    
                    }
                }
                
            }
        }
        
      
    };

    const handleClose1 = () => {
        setOpen(false);
        setIsLoading(false);
    }
  
    const handleClose =async () => {
        setOpen(false);
        setIsLoading(true);
        // setFreez(true);
        const sendYear = new Date(year).getFullYear();
        const apiUrl = '/commission-data';
        const args = {
            month: month,
            year: sendYear,
            save_to_local : 0,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
                if(res.success === true){
                    setData(res)  
                    setLabelMonth(res.dateData.month);                                         
                    setLabelYear(res.dateData.year);                                         
                    setMonthValue(res.dateData.monthvalue);  
                    setIsLoading(false);   
                    // handleFreezStop();                                     
                }else{
                    setData([]);
                    setIsLoading(false);       
                    // handleFreezStop(); 
                }
            }
           
    };

    const setFlag = async() => {
        // handleFreezStop(); 
        // setFreez(true);
        setOpen(false);
        setIsLoading(true);
        const sendYear = new Date(year).getFullYear();
        const apiUrl = '/commission-data';
        const args = {
            month: month,
            year: sendYear,
            save_to_local : 1,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if(res.success === true){
                setData(res) 
                setLabelMonth(res.dateData.month);                                         
                setLabelYear(res.dateData.year);                                         
                setMonthValue(res.dateData.monthvalue);  
                setIsLoading(false); 
                // handleFreezStop();                                      
            }else{
                setData([]);
                setIsLoading(false);  
                // handleFreezStop();     
            }
        }
    };
    const [value, setValue] = React.useState('1');

      const handleChange = (event, newValue) => {
          setValue(newValue);
      };
    
    function ContentLayout() {
    return (
        <>
         {isLoading?<Loader/>:<></>}
        <Box>
            <Grid container item xs={12} mb={2}>
                <Typography level="h4" color="primary">
                    Commission Summary Report
                </Typography>
               </Grid>
               {/* <Grid container item  lg={12} md={12} sm={12} xs={12} mt={2}>
                <FormControl sx={{width:200}} size='small' mt={4}>
                    <InputLabel id="month">Select Month</InputLabel>
                    <Select
                    labelId="demo-multiple-name-label"
                    name='month'
                    label="Select Month"
                    value={summaryMonthYear[0] ? summaryMonthYear[0] : month}
                    // onChange={(e)=>{setMonth(e.target.value)}}
                    MenuProps={MenuProps}
                    onChange={(e) => {setMonth(e.target.value); backupData([e.target.value,year])}}
                    >
                        <MenuItem value="0" >January</MenuItem>
                        <MenuItem value="1" >February</MenuItem>
                        <MenuItem value="2">March</MenuItem>
                        <MenuItem value="3" >April</MenuItem>
                        <MenuItem value="4" >May</MenuItem>
                        <MenuItem value="5" >June</MenuItem>
                        <MenuItem value="6" >July</MenuItem>
                        <MenuItem value="7" >August</MenuItem>
                        <MenuItem value="8" >September</MenuItem>
                        <MenuItem value="9" >October</MenuItem>
                        <MenuItem value="10" >November</MenuItem>
                        <MenuItem value="11" >December</MenuItem>
                    </Select>
                </FormControl>&nbsp;&nbsp;&nbsp;    
                
                <FormControl sx={{width:200}} size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} >
                            <DatePicker

                                id="year"
                                name="year"
                                label="Select Year *"
                                views={['year']}
                                value={summaryMonthYear[1] ? dayjs(summaryMonthYear[1]) : dayjs( year) }
                                onChange={(e) => {setYear(e); backupData([month,e])}}
                                MenuProps={MenuProps}
                                minDate={dayjs(minDate)}
                                maxDate={dayjs(currentDate)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </FormControl>&nbsp;&nbsp;&nbsp;
                <Button color='success' onClick={submitForm} sx={{minWidth:200}}>Submit</Button>
            </Grid>  */}

            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Grid >
                    <FormControl sx={{ width: 200 }} mt={4}>
                        <InputLabel id="month">Select Month</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            name='month'
                            label="Select Month"
                            value={summaryMonthYear[0] ? summaryMonthYear[0] : month}
                            // onChange={(e)=>{setMonth(e.target.value)}}
                            MenuProps={MenuProps}
                            onChange={(e) => { setMonth(e.target.value); backupData([e.target.value, year]) }}
                        >
                            <MenuItem value="0" >January</MenuItem>
                            <MenuItem value="1" >February</MenuItem>
                            <MenuItem value="2">March</MenuItem>
                            <MenuItem value="3" >April</MenuItem>
                            <MenuItem value="4" >May</MenuItem>
                            <MenuItem value="5" >June</MenuItem>
                            <MenuItem value="6" >July</MenuItem>
                            <MenuItem value="7" >August</MenuItem>
                            <MenuItem value="8" >September</MenuItem>
                            <MenuItem value="9" >October</MenuItem>
                            <MenuItem value="10" >November</MenuItem>
                            <MenuItem value="11" >December</MenuItem>
                        </Select>
                    </FormControl>&nbsp;&nbsp;&nbsp;  
                </Grid>
            <Grid >
                            <FormControl sx={{ width: 200 }} size='small' className='commission-year-selector'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']} >
                                        <DatePicker

                                            id="year"
                                            name="year"
                                            label="Select Year *"
                                            views={['year']}
                                            value={summaryMonthYear[1] ? dayjs(summaryMonthYear[1]) : dayjs(year)}
                                            onChange={(e) => { setYear(e); backupData([month, e]) }}
                                            MenuProps={MenuProps}
                                            minDate={dayjs(minDate)}
                                            maxDate={dayjs(currentDate)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>&nbsp;&nbsp;&nbsp; 
                        </Grid>
                        <Grid sx={{display: 'flex',alignItems: 'center'}}>
                            <Button color='success' onClick={submitForm} sx={{ minWidth: 200 }} >Submit</Button>
                        </Grid>
                    </Grid>
            <Grid  container item xs={12} mt={2} mb={1}>
                <Typography level="h6" color="black">
                    <b>{labelMonth} {labelYear} Commission Report</b>
                </Typography>
            </Grid>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Pending" value="1" />
                                    <Tab label="Approved" value="2" />
                                    <Tab label="Saved" value="3" />
                                </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="1"><Pending month={labelMonth} year={labelYear} monthvalue={monthValue} data={data ? data.pendingCommssion : ''} onValueChange={submitForm} handleFreezStop={handleFreezStop} handleFreezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="2"><Approved month={labelMonth} year={labelYear} monthvalue={monthValue} data={data ? data.approvedCommission : ''}/></TabPanel>
                    <TabPanel value="3"><Saved month={labelMonth} year={labelYear} monthvalue={monthValue} data={data ? data.savedCommission : ''}/></TabPanel>
                </TabContext>
                <BootstrapDialog
                  open={open}
                  aria-labelledby="responsive-dialog-title"
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose1}>
                      <Typography level="h3">Save data locally?</Typography>
                      </BootstrapDialogTitle>
                      <DialogContent>
                      <DialogContentText>
                      <Typography level="h6"><b>Save data locally?</b></Typography>
                      </DialogContentText>
                      <DialogContentText sx={{color:"black"}}>It is recommended to save data locally, only if you are ready to approve data. 
                        Update any commision rates that may have changed before approving. Do you confirm?</DialogContentText>
                      </DialogContent>
                    <DialogActions sx={{display:"flex",justifyContent:"center"}}>
                        <Button color="primary" sx={{minWidth: 150}} onClick={()=>{
                            setSaveToLocal(false);
                            handleClose()
                            }}>
                            No
                        </Button>
                        <Button color='success' sx={{minWidth: 150}} onClick={()=>{
                              setSaveToLocal(true);
                            //   handleClose();
                            setFlag()
                            
                        }
                            }>
                            Yes
                        </Button>
                    </DialogActions>
                    </BootstrapDialog>
        </Box>
        </>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
              onClick={handleFreezStop}
            >
              <Freez />
            </Backdrop>
          </div> */}
        </div>
    );
}
    
export default CommissionSummary;