import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from '../views/Home';
import Login from '../views/Auth/Login';
import ForgetPassword from '../views/Auth/ForgetPassword';
import ChangePassword from '../views/Auth/ChangePassword';
import Register from '../views/Auth/Register';
import RegisterToWin from '../views/Auth/RegisterToWin';
import ThanksToWin from '../views/Auth/ThanksToWin';
import Dashboard from '../views/Web/Dashboard/Dashboard';
import CompanyPanel from '../views/Web/CompanyPanel/CompanyPanel';
import CompanyDetails from '../views/Web/CompanyPanel/CompanyDetails';
import CompanyData from '../views/Web/CompanyPanel/CompanyData';
import CompanyDetailsnew from '../views/Web/CompanyPanel/CompanyDetails1';
import UserList from '../views/Web/UserPanel/UserList';
//blogs route
import CreditScore from '../views/Blogs/Category/CreditScores';
import CreditScoreChild from '../views/Blogs/Category/CreditScoreChild';
import CreditInfo from '../views/Blogs/Category/CreditInfo';
import CreditBureaus from '../views/Blogs/Category/CreditBureaus';
import CreditReport from '../views/Blogs/Category/CreditReport';
import CreditScoreLink from '../views/Blogs/Category/CreditScoreLink';
import CreditMonitoring from '../views/Blogs/Category/CreditMonitoring';
import IdentityTheft from '../views/Blogs/Category/IdentityTheft';
import CreditGlossary from '../views/Blogs/Category/CreditGlossary';
//blogs route

// import UserList1 from '../views/Web/UserPanel/UserList1';
import EditUser from '../views/Web/UserPanel/EditUser';
import EditPartnerUser from '../views/Web/PartnerAdmin/EditUser';
import CreateUser from '../views/Web/UserPanel/CreateUser';
import CobrandPanel from '../views/Web/CobrandPanel/CobrandPanel';
import Indexing from '../views/Web/CobrandPanel/Index';
import ReferenceAffiliateRequest from '../views/Web/ReferenceAffilate/ReferenceAffiliateRequest';
import AffilateBankdetails from '../views/Web/CompanyPanel/AffilateBankdetails';
import Thankyou from '../views/Auth/Thankyou';
import BankDetailsForm from '../views/Web/CompanyPanel/BankDetailsForm'
import BankDetailsFormAffiliate from '../views/Web/CompanyPanel/BankDetailsForm'
import SoftwareBankDetailsForm from '../views/Web/CompanyPanel/SoftwareBankDetailsForm'
import BankDetailsCreate from '../views/Web/CompanyPanel/BankDetailsCreate'
import BankDetailsPanel from '../views/Web/CompanyPanel/BankDetailsPanel'
import ReportIssueAudit from '../views/Web/CompanyPanel/ReportIssueAudit';
import CheckBankDetailsFollowup from '../views/Web/CompanyPanel/CheckBankDetailsFollowup';
// Manage Leads 
import Index from '../views/Web/ManageLeadsPanel/Index';
import CrcReport from '../views/Web/ManageLeadsPanel/CrcReport';
import ApprovedNow from '../views/Web/ManageLeadsPanel/ApprovedNow';
// Manage Leads endds
// Cobranding
import CobrandRequest from '../views/Web/CobrandPanel/CobrandRequest';
import CobrandVerify from '../views/Web/CobrandPanel/CobrandVerify';
import UpdatePid from '../views/Web/CobrandPanel/UpdatePid';
import CobrandDetails from '../views/Web/CobrandPanel/CobrandDetails';
import InitialCobrandSetup from '../views/Web/CobrandPanel/InitialCobrandSetup';
import CobrandReview from '../views/Web/CobrandPanel/CobrandReview';
// Cobranding ends
//Commission
import CommissionSummary from '../views/Web/Commission/CommissionSummary';
import CommissionViewDetails from '../views/Web/Commission/CommissionViewDetails';
import CommissionPayment from '../views/Web/Commission/CommissionPayment';
import PaymentDetails from '../views/Web/Commission/PaymentDetails';
import CommissionRates from '../views/Web/Commission/CommissionRates';

import SoftwareCommissionSummary from '../views/Web/Commission/SoftwareCommissionSummary';
import SoftwareCommissionViewDetails from '../views/Web/Commission/SoftwareCommissionViewDetails';
import SoftwareCommissions from '../views/Web/Commission/SoftwareCommissions';
import SoftwareCommissionPayments from '../views/Web/Commission/SoftwareCommissionPayments';
import SoftwarePaymentDetails from '../views/Web/Commission/SoftwarePaymentDetails'

import AffiliateUpdateBankDetailsIndex from '../views/Web/Commission/AffiliateUpdateBankDetailsIndex';
//Commission ends
import ContactLog from '../views/Web/Contact/ContactLog';
import ReportPanel from '../views/Web/Report/ReportPanel';
import ManageMembers from '../views/Web/ManageMembers/ManageMembers';
import MissingMemberFollowup from '../views/Web/ManageMembers/MissingMemberFollowup';
import MyLink from '../views/Web/MyLinks/MyLink';
import Credit from '../views/Web/CreditSnapshot/CreditIndex';
import CreditThanks from '../views/Web/CreditSnapshot/CreditThanks';
import CreditExpaired from '../views/Web/CreditSnapshot/CreditExpaired';
import CreditSnapFirst from '../views/Web/CreditSnapshot/CreditSnapFirst';
import LogoEditor from '../views/Web/CreditSnapshot/LogoEditor';
import MyProfile from '../views/Web/MyProfile/MyProfile';
import AffiliateCommissionSummary from '../views/Web/Commission/AffiliateCommissionSummary';
import AffiliateCommissionViewDetails from '../views/Web/Commission/AffiliateCommissionViewDetails';
//test route
import ThreeBReport from '../views/Web/3Breport/3breport';
import EpicProRender from '../views/Web/EpicProReportRender/EpicProReportRender';
import OneBReport from '../views/Web/1Breport/1breport';
import OneBReportView from '../views/Web/1Breport/1BView';
import OperationalHours from '../views/Web/CreditSnapshot/OperationalHoursWithShift';
import ScoreMeterComponant from '../views/Web/SnapShotEnrollments/SpeedometerComponent';

//Contact Type Configuration Routes start here
import ContactTypeConfig from '../views/Web/ContactTypeConfiguration/ContactTypeConfig';
import ContactEdit from '../views/Web/ContactTypeConfiguration/ContactEdit';
import ContactAdd from '../Components/ContactTypeConfigure/ContactAdd';
import SuspendedMembers from '../views/Web/SuspendedMembers/SuspendedMembers';
import SuspendedAffiliate from '../views/Web/SuspendedMembers/SuspendedAffiliate';
//Referral program

// Pool Pid Route Start 
import PoolPid from '../views/Web/PoolPid/PoolPid';

//Pool Pid End
// Default PID Start
import SetDefaultPid from '../views/Web/DefaultPid/DefaultPid';
//End default PID
import TermsAndCondition from '../views/Web/ReferralProgram/TermsAndCondition';
import ReferralProgramAffiliate from '../views/Web/ReferralProgram/ReferralIndex';
// CRC Lead Simulation
import CRCLeadSimulation from '../views/Web/CRC/CRCLeadSimulation';
import CRCEmailLead from '../views/Web/CRC/CRCEmailLead';

import ReferralProgram from '../views/Web/ReferralProgram/ReferralProgram';
import ReferralReport from '../views/Web/ReferralProgram/ReferralReport';
import TotalCompanyPerformance from '../views/Web/ReferralProgram/TotalCompanyPerformance';
//Footer Pages 
import AboutUs from '../views/FooterPages/AboutUs';
import ContactUs from '../views/FooterPages/ContactUs';
import PrivacyPolicy from '../views/FooterPages/PrivacyPolicy';
import CancelPolicy from '../views/FooterPages/CancelPolicy';
import RefundPolicy from '../views/FooterPages/RefundPolicy';
import TermsAndConditions from '../views/FooterPages/TermsAndConditions';
import Faq from '../views/FooterPages/Faq';
import Contact from '../views/FooterPages/Contact';
//Epic Pro Report
import EpicProreport from '../views/Web/EpicProReport/EpicProreport';
import SideBar from '../views/Web/EpicProReport/Sidebar';
//logoedit popup in component
import LogoEdit from '../Components/Cobrand/LogoEdit';
//lead application
import LeadApplications from '../views/Web/LeadApplication/LeadApplications';
//snapshot Enrollments
import EnrollStep1 from '../views/Web/SnapShotEnrollments/EnrollStep1';
import EnrollStep2 from '../views/Web/SnapShotEnrollments/EnrollStep2';
import SnapshotScore from '../views/Web/SnapShotEnrollments/SnapshotScore';
// import SnapShotLogin from '../views/Web/SnapShotEnrollments/SnapShotLogin';
import SnapShotLogout from '../views/Web/SnapShotEnrollments/SnapShotLogout';
//main enrollments
// import EnrollIndex from '../views/Web/Enrollment/EnrollIndex';
import Enrollment1 from '../views/Web/Enrollment/Enrollment1';
import Enrollment2 from '../views/Web/Enrollment/Enrollment2';
import Enrollment3 from '../views/Web/Enrollment/Enrollment3';
import Enrollment4 from '../views/Web/Enrollment/Enrollment4';
import Enrollment5 from '../views/Web/Enrollment/Enrollment5';

import EnrollIndex from '../Components/CobrandCustomization/CustomizeEnrollIndex';

// import TestContact from '../views/Web/Contact/TestContact';


//test rout for contact modal

// import ContactModal from '../Components/ContactLog/ContactLog'
//software refrral
import SoftwareReferrals from '../views/Web/SoftwareReferrals/SoftwareReferrals';

//myleads 
import AffiliateMyLeadsIndex from '../views/Web/MyLeads/AffiliateIndex';
import AdminMyLeadsIndex from '../views/Web/MyLeads/AdminIndex';
//memberlist
import MemberListIndex from '../views/Web/MemberList/Index';
//pendingClose panel
import PendingIndex from '../views/Web/PendingClosePanel/PendingIndex';
import MemberActivity from '../views/Web/PendingClosePanel/MemberActivity';
import PendingOffer from '../views/Web/PendingClosePanel/PendingOffer';
import OfferThankYou from '../views/Web/PendingClosePanel/OfferThankYou';
//pendingClose panel
//Lead Report Panel
import LeadReportIndex from '../views/Web/LeadReportPanel/LeadReportIndex';
//Lead Report Panel
import ShowMassage from '../Components/ShowMessage';
import PrivateRoute from './PrivateRoute';

//partner admin panel
import PartnerAdminDashboard from '../views/Web/PartnerAdmin/AdminDashboard';
import CROLink from '../views/Web/PartnerAdmin/CROLink';
import Administration from '../views/Web/PartnerAdmin/Administration';
import AddUser from '../views/Web/PartnerAdmin/AddUser';
import EditingUser from '../views/Web/PartnerAdmin/EditingUser';
import CROEnrollment from '../views/Web/PartnerAdmin/CROEnrollment';
import CROLeads from '../views/Web/PartnerAdmin/CROLeads';
import AssignCRO from '../views/Web/PartnerAdmin/AssignCRO';
//Retention Dashboard
import RetentionDashboard from '../views/Web/Dashboard/RetentionDashboard';
//enrollment dashboard pages for email
import DashboardVerification from '../views/Web/Enrollment/DashboardVerification';
import CreditCardPage from '../views/Web/Enrollment/CreditCardPage';
import MembershipQuestion from '../views/Web/Enrollment/MembershipQuestion';
import AddBankDetailsForm from '../views/Web/CompanyPanel/AddBankDetailsForm';
import AbandonedMembers from '../views/Web/AbandonedMember/AbandonedMember';
// import AbandonedMembersDetails from '../views/Web/AbandonedMember/AbandonedMemberDetails';

//Agent Dashboard//
import AgentDashboard from '../views/Web/Dashboard/AgentDashboard';
//Blog Dashboard start here
import BlogDashboard from '../views/Web/Dashboard/BlogDashboard';
import BlogDashboardEdit from '../views/Web/Dashboard/BlogDashboardEdit';
//Blog dashbaord end here
import Repassword from '../views/Web/Password/RePassword';
//Pid Manager Start
import PidManager from '../views/Web/PidManager/PidManager';
import EditPidManager from '../views/Web/PidManager/EditPidManager';
import CompanyReferralReport from '../views/Web/ReferralProgram/CompanyReferralReport';

//Pid Manager End
import ApiTool from '../views/ApiTool/Apitool';

//tax 1099 information start here
import Tax1099 from '../views/Web/TaxInformation/Tax1099';
import Form1099 from '../views/Web/TaxInformation/Form1099';
import BounceEmail from '../views/Web/BounceEmail/BounceEmail';
import BounceEmailActivityLog from '../views/Web/BounceEmail/BounceEmailActivityLog';
import Indexs from '../views/Web/BounceEmail/Index';
//tax 1099 information end here

import AboutUsPage from '../views/Web/AboutUs';
import CancellationPolicy from '../views/Web/CancellationPolicy';
import RefundPolicyPage from '../views/Web/RefundPolicy';
import NotFound from '../Components/NotFound';



import CustomizationRequest from '../Components/CustomizationRequest/CustomizationRequest'
import AdvancedCobrandSetup from '../Components/CobrandCustomization/AdvancedCobrandSetup';
import CobrandIndex from '../Components/CobrandCustomization/CobrandIndex'
import EpicProReportLogin from '../views/Web/DemoEpicPro/EpicProReportLogin';
import SnapShotLogin1 from '../views/Web/SnapShotEnrollments/SnapShotLogin1';
import Features from '../views/Web/Features';
import CallHistoryDashboard from '../views/Web/Contact/CallHistoryDashboard';
import ContestUsers from '../views/Web/ContestUsers';

//api integration panel routes start here 
import AutomationsIndex from '../views/Web/Automations/Index';
//api integration panel routes end here 
import CommissionTargets from '../views/Web/Commission/CommissionTargets';  
import AdvancedCustomizationReviewIndex from '../views/Web/CobrandCustomizationReview/index'
import CustomizationDetails from '../views/Web/CobrandCustomizationReview/CustomizationDetails'
import View3BReport from '../views/Web/3Breport/View3BReport'
import LoginActivity from '../views/Web/LoginLogs/LoginActivities'
import AffiAgreementIndex from '../Components/AffiAgreement/AffiAgreementIndex'
import AffiAgreementThankyou from '../Components/AffiAgreement/AffiAgreementThankyou'
import AutoRedirects from '../views/AutoRedirects';
import AnnualGala from '../views/AnnualGala';

function Routing(props) {
    return (
        // <BrowserRouter>        
        <Routes>            
            {/* Public Routes */}
            <Route path="/ZXZlbnRz" element={<AutoRedirects />} />
            <Route path="/annualgala" element={<AnnualGala />} />
            {/* Register to win Registrant public path */}
            <Route path="/public/registertowin/users" element={<ContestUsers />} />
            {/* Epicpro Login demo path */}
            <Route path="/epicproreport/login" element={<EpicProReportLogin />} />
            {/* Epicpro Login demo path */}
            {/* test routs */}
            <Route path="*" element={<NotFound />} />
            <Route path="/3breport" element={<ThreeBReport />} />
            <Route path="/view3BReport/:email/:password" element={<View3BReport />} />
            <Route path="/Epicprorender" element={<EpicProRender />} />
            <Route path="/1breport" element={<OneBReport />} />
            <Route path="/1breportview" element={<OneBReportView />} />
            {/* test routes */}
            {/* Auth Router */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/reset-password/:token" element={<ChangePassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/features" element={<RegisterToWin/>} />
            <Route path="/registration-thankyou" element={<ThanksToWin/>} />
            <Route path="/referenceAffiliateRequest/:referencecode=" element={<Register />} />
            {/* software refereal route start */}
            <Route path="/:softwarename/:referencecode=" element={<Register />} />
            {/* software refereal route end */}
            <Route path='/thankyou' element={<Thankyou />} />
            <Route path="/my-profile" element={<MyProfile />} />
            {/* Auth Router */}
            {/* blogs route start here */}
            <Route path="/Blogs/Category/:name" element={<CreditScore/>}/>
            <Route path="/blog/:url" element={<CreditScoreChild/>} />
            <Route path="/credit-info" element={<CreditInfo/>}/>
            <Route path="/credit-bureaus" element={<CreditBureaus/>}/>
            <Route path="/credit-report" element={<CreditReport/>}/>
            <Route path="/credit-scores" element={<CreditScoreLink/>}/>
            <Route path="/credit-monitoring" element={<CreditMonitoring/>}/>
            <Route path="/identity-theft" element={<IdentityTheft/>}/>
            <Route path="/credit-glossary" element={<CreditGlossary/>}/>
            {/* blogs route end here */}
            {/* main Enrollments */}
            <Route path="/enroll" element={<EnrollIndex />} />
            <Route path="/enrollment1" element={<Enrollment1 />} />
            <Route path="/enrollment2" element={<Enrollment2 />} />
            <Route path="/enrollment3" element={<Enrollment3 />} />
            <Route path="/enrollment4" element={<Enrollment4 />} />
            <Route path="/enrollment5" element={<Enrollment5 />} />
            <Route path="/dashboardverification" element={<DashboardVerification />} />
            <Route path="/creditcardpage" element={<CreditCardPage />} />
            <Route path="/membershipquestion" element={<MembershipQuestion />} />



            <Route path="/ApiTool" element={<ApiTool/>}/>
            {/* Footer Pages */}
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/cancelpolicy" element={<CancelPolicy />} />
            <Route path="/refundpolicy" element={<RefundPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/emailLeadReferenceAffiliate/verify/:email" element={<CRCEmailLead />} />
            <Route path="/en/creditsnapshot/user/register/:id" element={<EnrollStep1 />} />
            <Route path="/en/creditsnapshot/user/register/:aid/enrollstep2" element={<EnrollStep2 />} />
            {/* Enrollments Snapshot */}
            <Route path="/enrollstep1" element={<EnrollStep1 />} />
            <Route path="/enrollstep2" element={<EnrollStep2 />} />
            <Route path="/score/:userId" element={<SnapshotScore />} />
            {/* SnapshotLogin & Logout Route */}
            <Route path="/snapshotlogin" element={<SnapShotLogin1 />} />
           
            
            <Route path="/snapshotlogout" element={<SnapShotLogout />} />

            <Route path="/about-us.html" element={<AboutUsPage/>}/>
            <Route path="/cancellation-policy.html" element={<CancellationPolicy/>}/>
            <Route path="/refund-policy.html" element={<RefundPolicyPage/>}/>
            <Route path="/pending/offer/:id" element={<PendingOffer />} />
            <Route path="/accept" element={<OfferThankYou />} />
            <Route path="/features" element={<Features />} />
            <Route path="/AffiliateAgreement/:id/:role/:status" element={<AffiAgreementIndex />} />
            <Route path='/AgreementThankyou' element={<AffiAgreementThankyou />} />
            {/* Protected Router */}
            <Route element={<PrivateRoute />}>
                {/* Dashboard Router */}
                <Route path="/dashboard" element={<Dashboard />} />
                {/* Dashboard Router */}

                {/* { Pool Pid route start } */}
                <Route path="/PoolPid" element={<PoolPid />} />
                {/* { Pool Pid route End } */}

                {/* {Default Pid route start } */}
                <Route path="/SetDefaultPid" element={<SetDefaultPid />} />
                {/* { Default Pid route End } */}
                {/* Company Panel Router */}
                <Route path="/companypanel" element={<CompanyPanel />} />
                <Route path="/companydata" element={<CompanyData />} />
                <Route path="/companydetails" element={<CompanyDetails />} />
                <Route path="/companydetail/:id" element={<CompanyDetailsnew />} />
                <Route path="/affiliate/companypanel/:id" element={<CompanyDetailsnew />} />
                <Route path="/BankDetails/:id" element={<BankDetailsForm />} />
                <Route path="/affilatebankdetails" element={<AffilateBankdetails />} />
                <Route path="/bankdetailsform" element={<BankDetailsForm />} />
                <Route path="/affiliate/bankdetails/:id" element={<BankDetailsFormAffiliate />} />
                <Route path="/software/bankdetails/:name" element={<SoftwareBankDetailsForm />} />
                <Route path="/software-payment-details/:id" element={<SoftwarePaymentDetails />} />
                <Route path="bankdetailspanel" element={<BankDetailsPanel />} />
                <Route path="/bankdetailscreate" element={<BankDetailsCreate />} />
                <Route path="/bankdetailsview" element={<BankDetailsPanel />} />
                <Route path="/referenceaffiliaterequest/:token" element={<ReferenceAffiliateRequest />} />
                <Route path="/reportissueaudit" element={<ReportIssueAudit />} />
                <Route path="/addbankdetails/:id" element={<AddBankDetailsForm />} />
                <Route path="/checkbankdetailsfollowup/:id" element={<CheckBankDetailsFollowup/>} />
                {/* Company Panel Router */}

                {/* Manage Leads Panel Router */}

                <Route exact name="manageleads" path="/manageleads/:tab" element={<Index />} />


                {/* <Route name="manageleads" path="/manageleads/:tab" element={<Index/>}/> */}
                <Route path="/index" element={<Index />} />
                <Route path="/crcreport/approve-now/:id" element={<ApprovedNow />} />
                <Route path="/crcreport/:id" element={<CrcReport />} />
                {/* Manage Leads Panel Router */}

                {/* User Panel Router */}
                <Route path="/userpanel" element={<UserList />} />
                <Route path="/userlist" element={<UserList />} />
                {/* <Route path="/edituser1/:id" element={<EditUser1/>}/> */}

                <Route path="/createuser" element={<CreateUser />} />
                <Route path="/edituser/:id" element={<EditUser />} />
                <Route path="/editpartneruser/:id" element={<EditPartnerUser />} />


                {/* User Panel Router */}

                {/* Cobrand Panel Router */}
                <Route path="/cobrandpanel" element={<CobrandPanel />} />
                <Route path="/cobrand-request/:id" element={<CobrandRequest />} />
                <Route path="/cobrand-verify/:id" element={<CobrandVerify />} />
                <Route path="/cobrand-review/:id" element={<CobrandReview />} />
                <Route path="/updatePid/:id" element={<UpdatePid />} />
                <Route path="/cobrand-details/show/:id" element={<CobrandDetails />} />
                <Route path="/affiliate/initialcobrandsetup" element={<InitialCobrandSetup />} />
                <Route exact name="cobranding" path="/cobrand/:tab" element={<Indexing />} />
                {/* Cobrand Panel Router */}

                {/* Commission Panel Router */}
                <Route path="commission-summary" element={<CommissionSummary />} />
                <Route path="commission-details" element={<CommissionViewDetails />} />
                <Route path="commission-payments" element={<CommissionPayment />} />
                <Route path="payment-details/:id" element={<PaymentDetails />} />
                <Route path="/commission-rates" element={<CommissionRates />} />

                <Route path="/affiliate-update-bank-details/:id" element={<AffiliateUpdateBankDetailsIndex />} />

                <Route path="software-commission-summary" element={<SoftwareCommissionSummary />} />
                <Route path="software-commission-details" element={<SoftwareCommissionViewDetails />} />
                <Route path="software-commissions" element={<SoftwareCommissions />} />
                <Route path="software-commission-payments" element={<SoftwareCommissionPayments />} />
                {/* Commission Panel Router */}

                {/* Contact Log Panel Router */}
                <Route path="/contactlog" element={<ContactLog />} />
                {/* Contact Log Panel Router */}

                {/* Report pages Route */}
                {/* Member Routes start here */}
                <Route path="/reportpanel" element={<ReportPanel />} />
                <Route path="/managemembers" element={<ManageMembers />} />
                <Route path="/missingmemberdetails/:id" element={<MissingMemberFollowup />} />
                {/* Member Routes end here */}

                {/* Suspended member Route start*/}
                <Route path="/suspendedMemberList" element={<SuspendedMembers />} />
                <Route path="/suspendedaffiliate" element={<SuspendedAffiliate />} />
                {/* Suspended member Route end*/}

                {/* Additional Company Admin Route  */}

                {/* Affiliate Route */}
                <Route path="/affiliate/dashboard" element={<Dashboard />} />
                <Route path="/affiliate/my-profile" element={<MyProfile />} />
                <Route path="/affiliate/userpanel" element={<UserList />} />
                <Route path="/affiliate/reportpanel" element={<ReportPanel />} />
                <Route path="/affiliate/mylink" element={<MyLink />} />
                <Route path="/affiliate/SuspendedAffiliate" element={<SuspendedAffiliate />} />

                {/* creadit Snapshot Route */}
                <Route path="affiliate/creditsnapshot/" element={<Credit />} />
                <Route path="/creditthanks" element={<CreditThanks />} />
                <Route path="/creditexpaired" element={<CreditExpaired />} />
                <Route path="/affiliate/creditsnapshotpanel" element={<CreditSnapFirst />} />

                {/* test routes */}
                <Route path="/logoeditor" element={<LogoEditor />} />

                <Route path="/operationalhours" element={<OperationalHours />} />
                <Route path="/scoremeter" element={<ScoreMeterComponant />} />
                {/* creadit Snapshot Route */}

                {/* contact type Configuration */}
                <Route path="/contactTypeConfig" element={<ContactTypeConfig />} />
                <Route path="/contactTypeConfig/edit/:id" element={<ContactEdit />} />

                <Route path="/affiliate/commission-summary-report" element={<AffiliateCommissionSummary />} />
                <Route path="/affiliate/commission-details" element={<AffiliateCommissionViewDetails />} />

                <Route path="/contactadd" element={<ContactAdd />} />
                {/* Referreal Program */}
                <Route path="/termsandcondition" element={<TermsAndCondition />} />
                <Route path="/referralprogram" element={<ReferralProgram />} />
                <Route path="/affiliate/referralprogram/" element={<ReferralProgramAffiliate />} />
                <Route path="/referralreports" element={<ReferralReport />} />
                <Route path="/company-referral-reports" element={<CompanyReferralReport />} />
                <Route path="/totalcompanyperformance" element={<TotalCompanyPerformance />} />
                {/* CRCLeadSimulation */}
                <Route path="/crc-leads" element={<CRCLeadSimulation />} />

                {/* <Route name="manageleads" path="/manageleads/:tab" element={<Index/>}/> */}
                <Route path="/index" element={<Index />} />
                <Route path="/crcreport/approve-now/:id" element={<ApprovedNow />} />
                <Route path="/crcreport/:id" element={<CrcReport />} />

                {/* Manage Leads Panel Router */}

                {/* Epic Pro Report */}
                <Route path="/epicproreport" element={<EpicProreport />} />
                <Route path="/sidebar" element={<SideBar />} />

                {/* Logo Edit Popup */}
                <Route path="/logoedit" element={<LogoEdit />} />
                {/* lead application */}
                <Route path="/leadapplications" element={<LeadApplications />} />
                <Route name="leadapplication" path="/leadapplication/:tab" element={<LeadApplications />} />
                {/* Enrollments Snapshot */}
                {/* <Route path="/enrollstep1" element={<EnrollStep1 />} />
                <Route path="/enrollstep2" element={<EnrollStep2 />} />
                <Route path="/score" element={<Score />} /> */}
                {/* test route for contact log modal */}

                {/* <Route path="/contactmodal" element={<ContactModal/>} /> */}
                {/* Contact Log Panel Router */}
                <Route path="/SoftwareReferrals" element={<SoftwareReferrals />} />
                {/* Contact Log Panel Router */}
                {/* My Leads route */}
                <Route name="affiliatemyleadsindex" path="/affiliate/myleads/:tab" element={<AffiliateMyLeadsIndex />} />
                <Route name="adminmyleadsindex" path="/myleads/adminmyleads/:tab" element={<AdminMyLeadsIndex />} />
                {/* My Leads route */}
                {/* MemberList route */}
                <Route name="memberlistindex" path="/memberlist/:tab" element={<MemberListIndex />} />
                {/* MemberList route */}
                {/* Pending close Panel Router */}
                <Route name="PendingIndex" path="/pendingclosepanel/:tab" element={<PendingIndex />} />
                <Route path="/pendingindex" element={<PendingIndex />} />
                <Route path="/member-activity/:memberId" element={<MemberActivity />} />
                
                {/* Pending close Panel Router */}
                <Route name="leadreportindex" path="/leadreports/:tab" element={<LeadReportIndex />} />
                {/* <Route path="/leadreportindex" element={<LeadReportIndex />}/> */}
                {/* Pending close Panel Router */}
                <Route name="ShowMassage" path="/ShowMassage" element={<ShowMassage />} />
                {/* partner admin routing start here */}
                <Route name="PartnerAdminDashboard" path="/partner-admin/dashboard" element={<PartnerAdminDashboard />} />
                <Route name="CROLink" path="/partner-admin/crolink" element={<CROLink />} />
                <Route name="Administration" path="/partner-admin/administration" element={<Administration />} />
                <Route name="AddUser" path="/partner-admin/adduser" element={<AddUser />} />
                <Route name="EditingUser" path="/partner-admin/editinguser" element={<EditingUser />} />
                <Route name="CROEnrollment" path="/partner-admin/croenrollment" element={<CROEnrollment />} />
                <Route name="CROLeads" path="/partner-admin/croleads" element={<CROLeads />} />
                <Route name="AssignCRO" path="/partner-admin/assigncro/:id" element={<AssignCRO />} />
                {/* Retention Dashboard Router */}
                <Route path="/retentiondashboard" element={<RetentionDashboard />} />
                {/* Retention Dashboard Router */}
                {/* Agent Dashboard  Router start*/}
                <Route path="/agentdashboard" element={<AgentDashboard />} />
                {/* Agent Dashboard  Router end */}
                {/* Blog dashboard Router start */}
                <Route path="/blogdashboard" element={<BlogDashboard />} />
                <Route path="/blogdashboardedit/:id" element={<BlogDashboardEdit />} />
                {/* Blog Dashboard Router end */}
                {/*AbandonedMembers Route Start */}
                <Route path="/AbandonedMembers" element={<AbandonedMembers />} />
                {/*AbandonedMembers Route End */}
                <Route path="/Repassword" element={<Repassword/>}/>
                {/*Pid Manager Route Start*/}
                <Route path="/PidManager" element={<PidManager/>}/>
                <Route path="/EditPidManager/:id"  element={<EditPidManager/>}/>
                

                
                
                {/* Pid Manager Route End */}
                {/* tax 1099 information route start */}
                <Route path="/Tax1099" element={<Tax1099/>}/>
                <Route path="/Form1099" element={<Form1099/>}/>
                {/* tax 1099 information route end */}
                
                {/* BounceEmail Route End */}
                {<Route path="/bouncedemails" element={<BounceEmail/>}/>}
                {<Route path="/bounceemailactivitylog/:id" element={<BounceEmailActivityLog/>}/>}
                <Route exact name="bounceemail" path="/bounceemail/:tab" element={<Indexs />} />

                {/* cobrand customization */}
                <Route path="/CobrandIndex" element={<CobrandIndex />} />
                
                <Route exact name="customizationReview" path="/customizationReview/:tab" element={<AdvancedCustomizationReviewIndex />} />
                <Route path="/customization-details/show/:id/:name" element={<CustomizationDetails />} />
                
                <Route path="/CustomizationRequest" element={<CustomizationRequest />} />
                <Route path="/AdvancedCobrandSetup" element={<AdvancedCobrandSetup/>} />
                <Route path="/callhistorydashboard" element={<CallHistoryDashboard />} />
                


                {/* target commission */}
                <Route path="/commission-targets" element={<CommissionTargets />} />
                
                {/* automations start here*/}
                <Route exact name="automations" path="/automations/:tab" element={<AutomationsIndex />} />
                {/* automations end here*/}

                {/* login-activity logs */}
                <Route path="/login-activity" element={<LoginActivity />} />
                
            </Route>
        </Routes>
        // </BrowserRouter>
    );
}

export default Routing;