
import React from "react";
import { Grid,  Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement11({ highlightButton }) {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h6">15. Notices. Any notice required or permitted by this Agreement shall be in writing and shall be delivered as follows with notice deemed given as indicated: (a) by personal delivery; (b) by overnight courier upon written verification of receipt; (c) by telecopy or facsimile transmission upon acknowledgment of receipt or electronic transmission; or (d) by certified mail, return receipt requested, upon verification of receipt. MFSN (only) may also notify Referrer of changes via email. Notices shall be sent to the Parties at the addresses described in the first paragraph of this Agreement or such other address as either Party may designated for itself in writing. Each Party shall have the right from time to time and at any time to change its address and shall have the right to specify as its address any other address by giving at least ten (10) days' written notice to the other Party.<br /><br />
                            16. Agency. Nothing contained herein shall be construed as creating any agency, partnership, or other form of joint enterprise between the Parties<br/><br/>
                            17. Assignment. Either Party may assign this Agreement only upon prior written consent of the other, which consent shall not be unreasonably withheld.<br/><br/>
                            18. Force Majeure. Neither Party shall be liable hereunder by reason of any failure or delay in the performance of its obligations hereunder on account of strikes, shortages, riots, insurrection, fires, flood, storm, explosions, acts of God, war, governmental action, labor conditions, earthquakes or any other cause which is beyond the reasonable control of such Party.<br/><br/>
                            19.Jurisdiction. This Agreement shall be governed by California law. The exclusive jurisdiction for any actions brought in connection with this Agreement shall be in the state and federal courts located in the State of Washington, King County, without regard to conflict of laws.<br/><br/>
                            20. Attorney’s Fees. In the event of any legal or equitable proceeding or lawsuit is brought by either Party in connection with this Agreement, the prevailing Party shall be entitled to expert fees and costs, reasonable attorneys’ fees and costs and any other monetary award as the presiding Court deems appropriate.<br/><br/>
                            21. Counterparts. This may be executed in counterparts, which together shall constitute one and the same Agreement. The Parties may execute more than one copy of the Agreement, each of which shall constitute an original each of which alone and all of which together shall constitute one and the same instrument. This Agreement may be transmitted by facsimile and it is the intent of the Parties for the facsimile (or a photocopy thereof) of any autograph printed by a receiving facsimile machine to be an original signature and for the facsimile (or a photocopy thereof) and any complete photocopy of this Agreement to be deemed an original counterpart.<br/><br/>
                            22.Entire Agreement. This agreement, including all exhibits and/or attachments, hereto constitutes the complete and entire agreement of the Parties regarding the subject matter and supersedes all prior proposals, agreements or other communications between the Parties, whether written or oral. This Agreement shall not be modified except by written amendment, signed by both Parties.<br/><br/>
                            
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement11;