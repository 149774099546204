
import React,{useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box,Table ,TableBody ,TableCell ,Dialog,DialogActions ,DialogTitle,TextField,DialogContent,FormControl,FormHelperText,
  TableContainer,TableHead,TableRow,Paper,Button,Grid,Tooltip  } from '@mui/material';
  import TextareaAutosize from '@mui/base/TextareaAutosize';
import MaterialReactTable from 'material-react-table';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
//Date Picker Imports
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import config from '../../AppConfig';
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import { Typography } from '@mui/joy';
import dayjs from 'dayjs';
import {currencyFormat} from '../../Components/CommonFormula';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ];


const ReferralCommissionView = ({ bonusCommissionList, referralCommissionList }) => {
  const [requestItemList, setIRequestItemList] = useState(JSON.stringify(referralCommissionList));
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
      setOpen(false);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [statusMessage, setStatusMesage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [leadDetails, setleadDetails] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const [isLoading, setIsLoading] = useState(false);
  const q = search == '' ? 'null' : search;
  const [new_commission, setNewCommission] = useState();
  const [commission_note, setCommissionNote] = useState();
  const [rownumber, setRowNumber] = useState();

  const detailsSchema = Yup.object().shape({
    new_commission: Yup.string()
            .required('Please  Enter Commission'),
    commission_note: Yup.string()
                .required('Please Enter Note'),
  });

  const customSortReferralBonusFunction = (rowA, rowB) => {
    const a = rowA.original.commission;
    const b = rowB.original.commission;
    return a - b;
  };


  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.companyName}`,
        id:'companyName',
        header: 'Company Name',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.commission}`,
        id:'commission',
        header: 'commission ($)',
        sortingFn: customSortReferralBonusFunction, 
        size: 50,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.commission))),
      },
      {
        accessorFn: (row) => `${row.commission}`,
        id:'ReferralCommission',
        header: 'Referral Commission ($)',
        size: 50,
        sortingFn: customSortReferralBonusFunction, 
        Cell:({renderedCellValue,row}) => (
          <div>
              {currencyFormat(parseFloat(row.original.commission * 5/100))}
           </div>          
         ),
      },
      
    ]
  )

  const referralBonuscolumns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.companyName}`,
        id:'companyName',
        header: 'Company Name',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.commission}`,
        id:'commission',
        header: 'Referral Bonus ($)',
        size: 50,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.commission))),
      },
    ]
  )
//csv option start here
const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'Referral Commission Details',
  headers: ['Company Name','Commission','Referral Commission'],
};

const bonusCsvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'Referral Bonus Details',
  headers: ['Company Name','Referral Bonus'],
};

const csvExporter = new ExportToCsv(csvOptions);
const handleExportData = (referralCommissionList) => {
  const exportedData = referralCommissionList.map((row) => {
    return {
      companyName: `${row.original.companyName}` || 'N/A',
      commission: `${row.original.commission}` || 'N/A',
      referralCommission:  (row.original.commission * 5/100).toFixed(2) || 'N/A',
    };
  });

  const csvContent = convertToCSV(exportedData);
  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const handleExportBonusData = (bonusCommissionList) => {
  const exportedData = bonusCommissionList.map((row) => {
    return {
      companyName: `${row.original.companyName}` || 'N/A',
      commission: `${row.original.commission}` || 'N/A',
    };
  });

  const csvContent = convertToBonusCSV(exportedData);
  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = bonusCsvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      if (typeof value === 'string' && value.includes(',')) {
				return `"${value}"`;
				}

      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
};

const convertToBonusCSV = (data) => {
  const headers = bonusCsvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      if (typeof value === 'string' && value.includes(',')) {
				return `"${value}"`;
				}
      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
};
//csv end here

        return (
            <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography level="h3" mb={2} align="center">
                Referral Commission Details
              </Typography >
              <MaterialReactTable
                  columns={columns}
                  data={referralCommissionList}
                  filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                  }} 
                  globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                  enableColumnFilterModes
                  enableColumnFilters={config.DatatableColumnFilter}
                  enableRowSelection
                  initialState={{ showColumnFilters: false, density: 'compact' }}
                  positionToolbarAlertBanner="bottom"
                  enableDensityToggle={false}
                  muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        backgroundColor:"#F6FAFF",
                      },
                    }}
                //   renderDetailPanel={({ row }) => (
                //     <Box
                //       sx={{
                //           display: 'flex',
                //           justifyContent: 'space-around',
                //           alignItems: 'center',
                //       }}
                //     >
                //       <TableContainer component={Paper}>
                //         <Table sx={{ minWidth: 500 }} aria-label="simple table">
                //                 <TableHead>
                //                     <TableRow>
                //                       <TableCell sx={{fontWeight:"bold"}}>AID</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>First Name</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>Last Name</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>Effective Date</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>PID</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>Amount</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>Description</TableCell>
                //                       <TableCell align="center" sx={{fontWeight:"bold"}}>Commission</TableCell>
                //                        <TableCell align="center" sx={{fontWeight:"bold"}}>Approved Commission</TableCell>
                //                     </TableRow>
                //                 </TableHead>
                //                 <TableBody>
                //                         <TableRow
                //                           key={row.original.aid}
                //                           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                //                           >
                //                           <TableCell component="th" scope="row">
                //                               {row.original.aid}
                //                           </TableCell>
                //                           <TableCell align="center">{row.original.firstname}</TableCell>
                //                           <TableCell align="center">{row.original.lastname}</TableCell>
                //                           <TableCell align="center">{row.original.effectivedate}</TableCell>
                //                           <TableCell align="center">{row.original.pid}</TableCell>
                //                           <TableCell align="center">{row.original.amount}</TableCell>
                //                           <TableCell align="center">{row.original.description}</TableCell>
                //                           <TableCell align="center">{row.original.commission}</TableCell>
                //                           <TableCell align="center">{row.original.approvedcommission}</TableCell>
                //                         </TableRow>
                //                 </TableBody>
                //         </Table>
                //       </TableContainer>
                // </Box>
                // )}
                  //top csv option
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                    <Button
                        color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleExportData(table.getPrePaginationRowModel().rows)
                          }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                    variant='contained'
                        >
                        CSV
                        </Button>
                    </Box>
                )
                }
                  />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="h3" mb={2} align="center">
                Referral Bonus Details
              </Typography >
              <MaterialReactTable
                  columns={referralBonuscolumns}
                  data={bonusCommissionList}
                  enableColumnFilterModes
                  enableColumnFilters={config.DatatableColumnFilter}
                  enableRowSelection
                  initialState={{ showColumnFilters: false, density: 'compact' }}
                  positionToolbarAlertBanner="bottom"
                  enableDensityToggle={false}
                  muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        backgroundColor:"#F6FAFF",
                      },
                    }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                    <Button
                        color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleExportBonusData(table.getPrePaginationRowModel().rows)
                          }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportBonusData}
                        startIcon={<FileDownloadIcon />}
                    variant='contained'
                        >
                        CSV
                        </Button>
                    </Box>
                )
                }
                  />
            </Grid>
                
            </>
            );

    }


    export default ReferralCommissionView;