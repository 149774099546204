import React from 'react';
import { useState } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import { Box, Typography, Button, Backdrop, Card, Grid, Alert } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import Freez from '../../../Components/Loader/Loader';

function CRCEmailLead(props) {
    const { email } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [freez, setFreez] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const insertCRCLead = async () => {
        handleFreezStart();
        const apiUrl = "/insertCRCLead";
        const args = {
            email: atob(email)
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success) {
                navigate('/thankyou', { state: { name: res.name } });
            } else {
                handleFreezStop();
                setErrorMessage(res.message);
                setTimeout(function () {
                    setErrorMessage('');
                  }, 5000);

            }
        }
    }

    return (
        
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
            >
           
                <Freez />
            </Backdrop>
            <LoginHeader />
         
           <Box sx={{padding:"5%"}}>
                <Card sx={{padding:"2%"}} varient="outlined">
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center"}}>
                        <Typography variant="h4" color="primary">Thank you for verifying your email.</Typography >
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center"}} mt={2}>
                        <Typography variant="h6">Please click <b>“CONTINUE”</b> to learn about our affiliate membership levels and commissions, and to receive your personalized affiliate program document.</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center"}} mt={2}>
                        <Typography variant="h6" sx={{textAlign:"center"}}>Next Steps: Return your document and we’ll send you a Welcome Email containing your affiliate links and the instructions for loading your links into Credit Repair Cloud (CRC).</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center"}} mt={2}>
                        <Button variant="contained"  color="success"  onClick={() => { insertCRCLead() }} sx={{color:"white",backgroundColor:"#76C044",minWidth:200}}>Continue</Button>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default CRCEmailLead;