import React, { useState, useEffect, useMemo } from 'react'
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Box, Button,MenuItem,FormControl,InputLabel,Select,OutlinedInput,TableContainer,ListItemIcon,Checkbox, TableHead, TableRow, Paper,TableCell,Table,TableBody } from '@mui/material';
import { Typography } from '@mui/joy';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import AuthUser from '../../../Components/Auth/AuthUser';
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useNavigate } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import swal from "sweetalert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {DateRangePicker,createStaticRanges} from "react-date-range";
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import {openOrFocusWindow,calendarFilter,useDateSelectionRange,getInitialSelectionRange } from  '../../../Components/CommonFormula';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
let defaultRoleId;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function LoginActivities() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = AuthUser();

    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [freez, setFreez] = React.useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [userRoles, setuserRoles] = useState([]);
    const [roleType, setRoleType] = useState([]);
    const [roleId, setRoleId] = useState([]);
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } =useDateSelectionRange(getInitialSelectionRange('loginActivities'));
    const { selectionRange: selectionRange1, setSelectionRange: setSelectionRange1, startDate: startDate1, endDate: endDate1, clearFilter: clearFilter1 } = useDateSelectionRange(getInitialSelectionRange('loginActivities1'));
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const [toggleState, setToggleState] = React.useState("company");

    const handleToggleChange = (event) => {
        setToggleState(event.target.checked);
        // Add your logic to show/hide login_activity details here
    };

    const tablePreferencesArray = {
    }
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }

    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);

    const checkColumnVisibility = async () => {
        if (Object.values(columnVisibility).length > 0) {
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'loginLogs'
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
        }
    }

    useEffect(() => {
        checkColumnVisibility();
    }, [columnVisibility])

    const tablePreferences = async () => {
        const apiUrl = '/fetchTablePreferences/loginLogs';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (Object.values(res).length == 0) {
                setColumnVisibility(tablePreferencesArray);
            } else {
                setColumnVisibility((JSON.parse(res.preferences)));
            }
        }
    }

    useEffect(() => {
 
        if(toggleState==='company'){
            // alert(toggleState)
            const arr=[{"id":3,"role_name":"Affiliate login"},{"id":4,"role_name":"Affiliate User login"},{"id":5,"role_name":"Affiliate Staff login"},{"id": 11,"role_name": "API User"}]
            setuserRoles(arr);
            const defaultRoleNames = arr.map(role => role.role_name);
            const defaultRoleIds = arr.map(role => role.id);
            setRoleType(defaultRoleNames);
            defaultRoleId=defaultRoleIds
            setRoleId(defaultRoleIds);
            getLogsDetails();
        }
       else{
        fetchUserTypes()
       }
        
    }, [toggleState])

    const getLogsDetails = async () => {
         const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        handleFreezStart()
        const apiUrl = '/loginLog';
        const args = {
            roleId: defaultRoleId?defaultRoleId:roleId,
            fromDate1: startDate1,
            toDate1: endDate1,
            loginAsAffiliate: roleType.includes('Logged in as an affiliate') ? true : false,
            group_by: toggleState,
            ...(toggleState === 'company' && { fromDate: startDate, toDate: endDate }) 
        };
    
     
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.data) {
                setData(res.data)
                // if(toggleState!='company'){
                // fetchUserTypes()
                // }
                handleFreezStop()
                setIsLoading(false);
            }
            else {
                   setIsLoading(false);
                handleFreezStop()
            }
        }
    }
    const fetchUserTypes = async() => {
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            // Modify the role_name
            const updatedRoles = res.map(role => ({
                ...role,
                role_name: `${role.role_name} login`
            }));
            const maxId = Math.max(...updatedRoles.map(role => role.id));
            // Add the new role
            updatedRoles.unshift({
                id: 0,
                role_name: "Logged in as an affiliate",
            });
            setuserRoles(updatedRoles);
            const defaultRoleNames = updatedRoles.map(role => role.role_name);
            const defaultRoleIds = updatedRoles.map(role => role.id);
            setRoleType(defaultRoleNames);
            setRoleId(defaultRoleIds);
            defaultRoleId=defaultRoleIds
            getLogsDetails()
            // handleFreezStop();
            // setIsLoading(false);
        }
    }
    
    const customSortCompany = (rowA, rowB) => {
        const sortA = rowA.original.company_name;
		const sortB =rowB.original.company_name;
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
    };
    const customSortEnrollment = (rowA, rowB) => {
        const a = rowA.original.newEnrollements;
        const b = rowB.original.newEnrollements;
        return a - b;
    };
   
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.company_name ? row.company_name.trim() : ''}`,
                accessorKey: 'Company Name',
                header: 'Company Name',
                sortingFn: customSortCompany,
                Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : 'N/A'),

            },
            {
                accessorFn: (row) =>  `${row.company ? row.company.reference_affiliates ? row.company.reference_affiliates.approved_date ? Moment(row.company.reference_affiliates.approved_date).format('MM-DD-YYYY H:mm') : '':'':''}`,
                accessorKey: 'Approve Date',
                header: 'Approve Date',
                Cell: ({ renderedCellValue, row }) => (row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.approved_date ? Moment(row.original.company.reference_affiliates.approved_date).format('MM-DD-YYYY H:mm') : 'N/A': 'N/A': 'N/A'),
                
            },
            {
                accessorFn: (row) => row.user?row.user.login_activity?row.user.login_activity.length:'':'',
                accessorKey: 'Login Count',
                header: 'Login Count',
                Cell: ({ row }) => row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A' ,
            },
            {
                accessorFn: (row) => `${row.company ?row.company.reference_affiliates?row.company.reference_affiliates.email? row.company.reference_affiliates.email.trim() : '': '':''}`,
                accessorKey: 'Email',
                header: 'Email',
                Cell: ({ renderedCellValue, row }) => (row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.email ? row.original.company.reference_affiliates.email.trim() : 'N/A': 'N/A': 'N/A'),
            },
            {
                accessorFn: (row) =>`${row.phone_no?formatPhoneNumber(row.phone_no.trim()):''}`,
                accessorKey: 'Phone No',
                header: 'Phone No',
                Cell: ({ renderedCellValue, row }) => (row.original.phone_no?formatPhoneNumber(row.original.phone_no.trim()):'N/A'),

            },
            {
                accessorFn: (row) => `${row.newEnrollements ? row.newEnrollements : ''}`,
                accessorKey: 'Total Enrollment',
                header: 'Total Enrollment',
                filterVariant: 'range',
                sortingFn:customSortEnrollment,
                Cell: ({ renderedCellValue, row }) => (row.original.newEnrollements ? row.original.newEnrollements : 'N/A'),

            },
            {
                accessorFn: (row) => `${row.activeEnrollements ? row.activeEnrollements : ''}`,
                accessorKey: 'Total Active Enrollments',
                header: 'Total Active Enrollments',
                Cell: ({ renderedCellValue, row }) => (row.original.activeEnrollements ? row.original.activeEnrollements : 'N/A'),

            },
            {

                accessorFn: (row) => `${row.projectedCommission ? row.projectedCommission : ''}`,
                header: 'Projected Commission',
                accessorKey: 'Projected Commission',
                Cell: ({ renderedCellValue, row }) => (row.original.projectedCommission ? `$ ${row.original.projectedCommission}` : '$ 0.00'),
            },
            {
                accessorFn: (row) => `${row.lastApprovedCommission ? row.lastApprovedCommission : ''}`,
                accessorKey:  'Last Paid',
                header:  'Last Paid',
                // size: 30,
                Cell: ({ renderedCellValue, row }) => (
                    <Box className="datatable-activity">
						<Typography>{row.original.lastApprovedCommission ? `$ ${row.original.lastApprovedCommission}` : '$ 0.00'}</Typography>
					</Box>
                    ),

            }
        ]
    )
    const columns1 = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
                accessorKey: 'Email',
                header: 'Email',
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),

            },
            {
                accessorFn: (row) => row.user?row.user.login_activity?row.user.login_activity.length:'N/A':'N/A',
                accessorKey: 'Login Count',
                header: 'Login Count',
                Cell: ({ row }) => row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A' ,
            },
            {
                accessorFn: (row) => `${row.first_name ? row.first_name.trim() : ''}`,
                accessorKey: 'First Name',
                header: 'First Name',
                size: 60,
                Cell: ({ renderedCellValue, row }) => (row.original.first_name ? row.original.first_name.trim() : 'N/A'),
            },
            {
                accessorFn: (row) =>`${row.last_name ?row.last_name.trim() : ''}`,
                accessorKey: 'Last Name',
                header: 'Last Name',
                Cell: ({ renderedCellValue, row }) => (row.original.last_name ? row.original.last_name.trim() : 'N/A'),

            },
            {
                accessorFn: (row) => `${row.phone_no ? formatPhoneNumber(row.phone_no.trim()) : '' }`,
                accessorKey: 'Phone No',
                header: 'Phone No',
                Cell: ({ renderedCellValue, row }) => (row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : 'N/A'),

            },
          
        ]
    )
   
    const columnsToShow = toggleState == "company" ? columns : columns1;
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Log Activities',
        // headers: toggleState === "company" ? ['Company Name','Approve Date','Login Count','Email','Phone','Total Enrollments','Total Active Enrollments','Projected Commission','Last Paid']:['First Name', 'Last Name', 'Email', 'Phone', 'Login count'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        const exportedData = [];
        rows.forEach((row) => {
            // Add the main table headers before each row
            exportedData.push(toggleState === "company" ? 
                ['Company Name', 'Approve Date', 'Login Count', 'Email', 'Phone', 'Total Enrollments', 'Total Active Enrollments', 'Projected Commission', 'Last Paid'] : 
                ['First Name', 'Last Name', 'Email', 'Phone', 'Login count']
            );
            const mainTableRow1 = {
                company_name: row.original.company_name?row.original.company_name :'N/A',
               approve_date:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.approved_date ? Moment(row.original.company.reference_affiliates.approved_date).format('MM-DD-YYYY H:mm') : 'N/A': 'N/A': 'N/A',
               login_activity:row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A' ,
               email:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.email ? row.original.company.reference_affiliates.email.trim() : 'N/A': 'N/A': 'N/A',
               phone_no:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.phone_no ? row.original.company.reference_affiliates.phone_no.trim() : 'N/A': 'N/A': 'N/A',
               total_enrollment:row.original.newEnrollements ? row.original.newEnrollements : 'N/A',
               total_active_enrollment:row.original.activeEnrollements ? row.original.activeEnrollements : 'N/A',
               Projected_Commission:row.original.projectedCommission ? `$ ${row.original.projectedCommission}` : '$ 0.00',
               last_paid:row.original.lastApprovedCommission ? `$ ${row.original.lastApprovedCommission}` : '$ 0.00'
            };
            const mainTableRow2 = {
                first_name: row.original.first_name ? row.original.first_name : 'N/A',
                last_name:row.original.last_name ? row.original.last_name : 'N/A',
               email:row.original.email ? row.original.email : 'N/A',
               phone_no:row.original.phone_no ? row.original.phone_no : 'N/A',
               login_activity:row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A',
            };
            // Add header first
       
            exportedData.push(toggleState === "company" ? mainTableRow1 :mainTableRow2);
            exportedData.push({});
            const subheader = {
                  'Email': 'Email', 
                  'Login Time': 'Login Time',
                  'Ip Address': 'Ip Address',
                  'Event': 'Event',
              };
            exportedData.push(subheader);

            
            (row.original.user && row.original.user.login_activity) && 
            row.original.user.login_activity.forEach((child) => {
                const subRow = {
                    email: child.email ? child.email : 'N/A',
                    login_time: child.login_time ? child.login_time : 'N/A',
                    ip_address: child.ip_address ? child.ip_address : 'N/A',
                    ...((row.original.company_name)?{
                        event: child.event ? child.event : 'N/A'
                    }:{
                        event: row.original.event ? row.original.event : 'N/A'
                    })
                    
                };
                exportedData.push(subRow);
            });
        // Pushing empty row after the logic
                exportedData.push({});
        
          });  
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };
    const handleExportRowsSummary = (rows) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        const exportedData = [];
        exportedData.push(toggleState === "company" ? 
            ['Company Name', 'Approve Date', 'Login Count', 'Email', 'Phone', 'Total Enrollments', 'Total Active Enrollments', 'Projected Commission', 'Last Paid'] : 
            ['First Name', 'Last Name', 'Email', 'Phone', 'Login count']
        );
        rows.map((row) => {
            
            const mainTableRow1 = {
                company_name: row.original.company_name?row.original.company_name :'N/A',
               approve_date:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.approved_date ? Moment(row.original.company.reference_affiliates.approved_date).format('MM-DD-YYYY H:mm') : 'N/A': 'N/A': 'N/A',
               login_activity:row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A' ,
               email:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.email ? row.original.company.reference_affiliates.email.trim() : 'N/A': 'N/A': 'N/A',
               phone_no:row.original.company?row.original.company.reference_affiliates ? row.original.company.reference_affiliates.phone_no ? row.original.company.reference_affiliates.phone_no.trim() : 'N/A': 'N/A': 'N/A',
               total_enrollment:row.original.newEnrollements ? row.original.newEnrollements : 'N/A',
               total_active_enrollment:row.original.activeEnrollements ? row.original.activeEnrollements : 'N/A',
               Projected_Commission:row.original.projectedCommission ? `$ ${row.original.projectedCommission}` : '$ 0.00',
               last_paid:row.original.lastApprovedCommission ? `$ ${row.original.lastApprovedCommission}` : '$ 0.00'
            };
            const mainTableRow2 = {
                first_name: row.original.first_name ? row.original.first_name : 'N/A',
                last_name:row.original.last_name ? row.original.last_name : 'N/A',
               email:row.original.email ? row.original.email : 'N/A',
               phone_no:row.original.phone_no ? row.original.phone_no : 'N/A',
               login_activity:row.original.user?row.original.user.login_activity?row.original.user.login_activity.length :'N/A':'N/A',
            };
            exportedData.push(toggleState === "company" ? mainTableRow1 :mainTableRow2);
        
          });  
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const convertToCSV = (data) => {

        // const headers = csvOptions.headers.join(',') + '\n';

        const rows = data.map((row) => {
            return Object.values(row).map((value) => {
                // Handle any necessary formatting or escaping of values
                // For simplicity, we assume all values are already properly formatted

                // Wrap the value with double quotes if it contains a comma
                if (typeof value === 'string' && value.includes(',')) {
                    return `"${value}"`;
                }

                return value;
            }).join(',');
        }).join('\n');

        return rows;
    };

    //csv end here

    const changeRoleType=async(id,name)=>{
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
     handleFreezStart()
        const apiUrl = '/loginLog';
        const args = {
            roleId:id,
            fromDate: startDate,
            toDate: endDate,
            fromDate1:startDate1,
            toDate1: endDate1,
            loginAsAffiliate:name.includes('Logged in as an affiliate')? true:false,
            group_by:toggleState
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.data) {
                setData(res.data)
                handleFreezStop()
                setIsLoading(false)
            }
            else {
                handleFreezStop()
            }
        }
    }
    const sideBar = calendarFilter();

    const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar)
    ];
      
  
    const ContentLayout = <>
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
            <Typography level="h4" color="primary">Login Activities</Typography>
        </Grid>

        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-multiple-name-label">User Event</InputLabel>
                    <Select
                        labelId="multiple-select-label"
                        multiple
                        name="UserType"
                        input={<OutlinedInput label="User Type" />}
                        id="UserType"
                        MenuProps={MenuProps}
                        value={roleType} // Ensure this is an array
                        onChange={(event) => {
                            const { value } = event.target;
                            const updatedRoleType = typeof value === 'string' ? value.split(',') : value;
                            const selectedUserTypes = userRoles.filter((usertype) =>
                                updatedRoleType.includes(usertype.role_name)
                            );
                            if (selectedUserTypes.length > 0) {
                                const selectedIds = selectedUserTypes.map((usertype) => usertype.id);
                                setRoleId(selectedIds); // Ensure this can handle an array of IDs
                                defaultRoleId=selectedIds
                                setRoleType(updatedRoleType);
                            } else {
                                setRoleId([]);
                                defaultRoleId=[]
                                setRoleType(updatedRoleType);
                            }
                        }}
                        renderValue={(selected) => selected.join(', ')} // Display selected values without checkboxes
                    >
                        {userRoles.map((usertype, index) => (
                            <MenuItem key={index} value={usertype.role_name}>
                                <ListItemIcon>
                                    <Checkbox checked={roleType.indexOf(usertype.role_name) > -1} />
                                </ListItemIcon>
                                {usertype.role_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>                       
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} >
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                    <Grid  item lg={4} md={4} sm={6} xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-multiple-name-label">User Login Activity</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value="date"
                                input={<OutlinedInput label="User Login Activity" />}
                            >
                                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                                <DateRangePicker
                                    className='custom-date-picker'
                                    onChange={item => setSelectionRange([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectionRange}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                    staticRanges={staticRanges}
                                    />
                            </Select>
                        </FormControl>
                    </Grid>

                    {toggleState==='company' &&
                    <Grid  item lg={4} md={4} sm={6} xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-multiple-name-label">Company Joining Date</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value="date"
                                input={<OutlinedInput label="Company Joining Date" />}
                            >
                                <MenuItem value="date" >{startDate1 ? <>{startDate1} To {endDate1}</> : ''}</MenuItem>
                                <DateRangePicker
                                    className='custom-date-picker'
                                    onChange={item => setSelectionRange1([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectionRange1}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                    staticRanges={staticRanges}
                                    />
                            </Select>
                        </FormControl>
                    </Grid>
                    }

                    <Grid item lg={4} md={4} sm={6} xs={6}>
                        <Button color="primary" onClick={()=>changeRoleType(roleId,roleType)} variant="contained" sx={{ minWidth: 200 }}>
                        Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid  item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Box sx={{ display: "flex" }}>
                    <Box className="toggle-mask-box">
                        <Box
                        className="toggle-mask"
                        style={{
                            transform: `translateX(${toggleState === "company" ? 0 : "100px"})`
                        }}
                        />
                        <Button
                        disableRipple
                        variant="text"
                        sx={{ color: toggleState === "company" ? "#ffffff" : "#1976d2" }}
                        onClick={() => setToggleState("company")}
                        >
                        Company
                        </Button>
                        <Button
                        disableRipple
                        variant="text"
                        sx={{ color: toggleState === "user" ? "#ffffff" : "#1976d2" }}
                        onClick={() => setToggleState("user")}
                        >
                        User
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        <Box mt={2}>
            <MaterialReactTable
                columns={columnsToShow}
                data={data}
                enableColumnFilterModes
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                      row.getValue(id).toLowerCase().includes(filterValue.toLowerCase()),
                  }} 
                // globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnFilters={config.DatatableColumnFilter}
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        backgroundColor: "#F6FAFF",
                    },
                }}
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="login activity table">
                                <TableHead>
                                    <TableRow>
                                       <TableCell  sx={{ fontWeight: "bold" }}>Email</TableCell>
                                        <TableCell  sx={{ fontWeight: "bold" }}>Login Time</TableCell>
                                        <TableCell  sx={{ fontWeight: "bold" }}>IP Address</TableCell>
                                        <TableCell  sx={{ fontWeight: "bold" }}>Event</TableCell>
                                    </TableRow>
                                </TableHead>
            
                                <TableBody>
                                    {row.original.user && row.original.user.login_activity && row.original.user.login_activity.length > 0 ? (
                                        row.original.user.login_activity.map((activity, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell >{activity.email || 'N/A'}</TableCell>
                                                <TableCell >{activity.login_time || 'N/A'}</TableCell>
                                                <TableCell >{activity.ip_address || 'N/A'}</TableCell>
                                                <TableCell >{activity.event || row.original.event || 'N/A'}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">No login activity available</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All
                        </Button>
                        <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={() =>
                                handleExportRowsSummary(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Summary
                        </Button>

                    </Box>
                )
                }
            />

        </Box>
    </>

    return (
        <>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </>
    );
}


export default LoginActivities;