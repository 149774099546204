import React,{useMemo,useState,useEffect} from 'react';
import MaterialReactTable from 'material-react-table';
import config from '../../AppConfig';
import AuthUser from '../../Components/Auth/AuthUser';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Box, Button, } from '@mui/material';
import Moment from 'moment';
import formatPhoneNumber from '../../Components/Formats/PhoneNumberValidation';
import { useNavigate } from "react-router-dom";
function Registrants(props) {
    const { http, user } = AuthUser();
    const [data, setData] = useState(props.data);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const tablePreferencesArray = {
    ProductName:false,
    StreetAddress:false,
    IPAddress:false,
    Authenticated:false,
    AuthenticationCount:false,
    ADID:false,
    SID:false,
    TID:false,
    Phoneno:false,
    City:false,
    State:false,
    ZIP:false
  }
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////
  

  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      http.post('/saveTablePreferences', {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'registrants'
      }).then((result) => {
      })
    }
  }, [columnVisibility])
  const tablePreferences = () => {
    http.get('/fetchTablePreferences/registrants')
      .then((res) => {
        if (Object.values(res.data).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.data.preferences)));
        }
      });
  }

    const   columns = useMemo(
      //column definitions...
      () => [
        {
          accessorFn: (data) => `${data.leadId?data.leadId:'N/A'}`,
          id:'Lead Id',
          header: 'Lead Id',
          size: 50,
          enablePinning: true,
        },
        {
          accessorFn: (data) => `${data.dateRangeFilter? data.dateRangeFilter :''}`,
          id:'EnrollmentDate',
          header: 'Enrollment Date',
          size: 50,
          enableGlobalFilter: false,
          Cell:({renderedCellValue,row}) => (
            <div >
            {row.original.dateRangeFilter ? Moment(row.original.dateRangeFilter).format('MM-DD-YYYY H:mm') + " Hrs":"N/A"}
            </div>          
            ),
        },
        {
          accessorFn: (data) => `${data.productName?data.productName.trim():''}`,
          id:'ProductName',
          header: 'Product Name',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.productName ? row.original.productName.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.publisherId?data.publisherId:''}`,
          id:'Publisher Id',
          header: 'Publisher Id',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.publisherId ? row.original.publisherId : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.AID?data.AID.trim():''}`,
          id:'AID',
          header: 'AID',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.ADID?data.ADID.trim():''}`,
          id:'ADID',
          header: 'ADID',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.ADID ? row.original.ADID.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.SID?data.SID.trim():''}`,
          id:'SID',
          header: 'SID',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.SID ? row.original.SID.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.TID?data.TID.trim():''}`,
          id:'TID',
          header: 'TID',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.TID ? row.original.TID.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.First?data.First.trim():''}`,
          id:'First Name',
          header: 'First Name',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.First ? row.original.First.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.Last?data.Last.trim():''}`,
          id:'Last Name',
          header: 'Last Name',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.Last ? row.original.Last.trim() : 'N/A'),
        },
        {
          accessorFn: (row) => `${row.Phone ? formatPhoneNumber(row.Phone.trim()) : 'N/A'}`,
          // accessorFn: (data) => `${data.Phone?data.Phone:'N/A'}`,
          id:'Phoneno',
          header: 'Phone no.',
          size: 50,
          
        },
        {
          accessorFn: (data) => `${data['Street Address']?data['Street Address'].trim():''}`,
          id:'StreetAddress',
          header: 'Street Address',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original['Street Address'] ? row.original['Street Address'].trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.City?data.City.trim():''}`,
          id:'City',
          header: 'City',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.City ? row.original.City.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.State?data.State.trim():''}`,
          id:'State',
          header: 'State',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.State ? row.original.State.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.ZIP?data.ZIP.trim():''}`,
          id:'ZIP',
          header: 'ZIP',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.ZIP ? row.original.ZIP.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data['IP Addr']?data['IP Addr'].trim():''}`,
          id:'IPAddress',
          header: 'IP Address',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original['IP Addr'] ? row.original['IP Addr'].trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data['Email Address']?data['Email Address'].trim():''}`,
          id:'Email',
          header: 'Email',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original['Email Address'] ? row.original['Email Address'].trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.Authenticated?'YES':'NO'}`,
          id:'Authenticated',
          header: 'Authenticated',
          size: 50,
        },
        {
          accessorFn: (data) => `${data['Authentication Count']?data['Authentication Count'].trim():''}`,
          id:'AuthenticationCount',
          header: 'Authentication Count',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original['Authentication Count'] ? row.original['Authentication Count'].trim() : 'N/A'),
        }
      ]
    );
    const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: `Registrants Report_${currentDate}.csv`,
      headers: ['Lead Id', 'Enrollment Date', 'Product Name', 'Publisher Id', 'AID', 'ADID', 'SID', 'TID', 'First Name', 'Last Name', 'Phone no.', 'Street Address', 'City', 'State', 'ZIP', 'IP Address', 'Email', 'Authenticated', 'Authentication Count'],
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handleExportData = (rows) => {
      if(localStorage.getItem("admin") != null) {
        navigate('/login');
        return false;
      }
      const exportedData = rows.map((row) => {
       
        return {
          'leadId':`${row.original.leadId ? row.original.leadId:'N/A'}`,
          'Enrollment Date': row.original.dateRangeFilter ? Moment(row.original.dateRangeFilter).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') + " Hrs":"N/A",
          'Product Name': row.original.productName ? row.original.productName.trim() : 'N/A',
          'Publisher Id':row.original.publisherId ? row.original.publisherId : 'N/A',
          'AID':row.original.AID ? row.original.AID.trim() : 'N/A',
          'ADID':row.original.ADID ? row.original.ADID.trim() : 'N/A',
          'SID':row.original.SID ? row.original.SID.trim() : 'N/A',
          'TID':row.original.TID ? row.original.TID.trim() : 'N/A',
          'First Name':row.original.First ? row.original.First.trim() : 'N/A',
          'Last Name':row.original.Last ? row.original.Last.trim() : 'N/A',
          'Phone no.':row.original.Phone ? formatPhoneNumber(row.original.Phone.trim()) : 'N/A',
          'Street Address':row.original['Street Address'] ? row.original['Street Address'].trim() : 'N/A',
          'City':row.original.City ? row.original.City.trim() : 'N/A',
          'State':row.original.State ? row.original.State.trim() : 'N/A',
          'ZIP':row.original.ZIP ? row.original.ZIP.trim() : 'N/A',
          'IP Address':row.original['IP Addr'] ? row.original['IP Addr'].trim() : 'N/A',
          'Email':row.original['Email Address'] ? row.original['Email Address'].trim() : 'N/A',
          'Authenticated':row.original.Authenticated?'YES':'NO',
          'Authentication Count':row.original['Authentication Count'] ? row.original['Authentication Count'].trim() : 'N/A'
    
        };
      });
    
     // Convert data to CSV format
 const csvContent = convertToCSV(exportedData);

 // Create a Blob object from the CSV data
 const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

 // Create a download link element
 const link = document.createElement('a');
 link.href = URL.createObjectURL(blob);
 link.download = csvOptions.fileName + '.csv';

 // Append the link to the document body
 document.body.appendChild(link);

 // Programmatically click the link to trigger the file download
 link.click();

 // Clean up the temporary URL and remove the link from the document
 URL.revokeObjectURL(link.href);
 link.remove();
};

const convertToCSV = (data) => {
 const headers = csvOptions.headers.join(',') + '\n';

 const rows = data.map((row) => {
   return Object.values(row).map((value) => {
     // Handle any necessary formatting or escaping of values
     // For simplicity, we assume all values are already properly formatted

     // Wrap the value with double quotes if it contains a comma
     if (typeof value === 'string' && value.includes(',')) {
       return `"${value}"`;
     }

     return value;
   }).join(',');
 }).join('\n');

 return headers + rows;
};
        return (
            <>
                <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                enablePinning
                enableRowSelection={false}
                filterFns={{
                  customSearchFilterFn: (row, id, filterValue) =>
                  row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnFilters={config.DatatableColumnFilter}
                initialState={{ showColumnFilters: false, density: 'compact' ,
                  columnPinning: { left: ['mrt-row-select','Lead Id'] }
                }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection }} 
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                    backgroundColor:"#F6FAFF",
                    },
                }} 
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                    <Button
                      sx={{ bgColor: "#2A68B3" }}
                      disabled={table.getPrePaginationRowModel().rows.length === 0}
                      //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                          handleExportData(table.getPrePaginationRowModel().rows)
                        }
                     
                      variant="contained"
                      className='export'
                    >
                      CSV
                    </Button>
                  </Box>
                )}
                
                />
            </>
        );
    }
    
    
    export default Registrants;