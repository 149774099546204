
import React, { useState, useRef } from "react";
import { Grid, Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement8({ highlightButton }) {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h6">5. Termination. Either Party may terminate this Agreement pursuant to the terms provided herein upon three (3) business days written notice as follows:<br />
                            (a) If either Party materially breaches the Agreement and fails to remedy the breach within ten (10) days of receiving written notice of such breach (or such longer period as may deemed reasonable to cure the material breach, with said determination being in MFSN’s sole discretion); or (b) if it is determined that either Party knowingly or intentionally makes misrepresentations or conceals material information related to this Agreement or in documents provided under this Agreement; or
(c) if Referrer engages in Fraud or an otherwise illegal activity in which instance MFSN may terminate this Agreement without notice and may refer such illegal acts to the appropriate law enforcement agencies. Either Party may terminate this Agreement upon three (3) days written notice for any reason whatsoever. Upon termination by either Party, Referrer shall immediately take down all tracking links (URLs) provided to it by MFSN and will cease to promote or direct traffic or visitors to MFSN’s web site or Referrer’s tracking URLs; Referrer shall return all materials, equipment and supplies provided by MFSN. All licenses provided to Referrer by this Agreement, for use of MFSN’s trademarks, tradedress or otherwise, must immediately cease.
<br /><br />
6. Voided Acquisitions. MFSN may void any Acquisition that is later rescinded by the Visitor or corrected by MFSN for suspicion of fraud. MFSN may Void Acquisitions and/or product purchases that are fraudulently obtained, duplicated, or for any similar reason as determined by MFSN in its sole discretion. In the event of a Void, MFSN shall be entitled to recover from Referrer the corresponding Sales Commission previously credited to Referrer’s Account. Such Sales Commission will be immediately deducted from Referrer’s Account balance with MFSN. In the event that Referrer’s Account balance is insufficient to cover the amount of the credited Sales Commission, the sales Commission will be deducted against Referrer’s future earnings under this Agreement. In the event that MFSN ceases to receive Acquisitions from Referrer and the credited Sales Commission is greater than MFSN’s Account balance, MFSN will invoice Referrer for the difference and payment will be due immediately upon receipt of this invoice.<br /><br />
7. Reserve Account. MFSN reserves the right in its sole discretion to establish a Reserve Account with Referrer to recover fraudulently generated Sales Commissions, to pay fines or fees and protect itself in the event of Voids and excessive chargebacks attributable to the Referrer’s Performance Marketing activities. MFSN will give two (2) business days’ notice before establishing any new Reserve Account.
8. Website Content. Referrer represents and warrants that it does not feature any of the following types of products, services or content and acknowledges that no such products, services or content are permitted in conjunction with MFSN’s Services: (a) sexual paraphernalia; (b) drug paraphernalia; (c) adult films, recording or magazines; (d) materials which are defamatory, obscene, harmful to minors or pornographic, child or otherwise; (e) weapons; (f) other illicit or illegal activities; (g) computer viruses, so- called Trojan horses, time bombs, cancel-bots or other computer programming routines that have the effect (whether by intent or not) of damaging, detrimentally interfering with, surreptitiously intercepting or expropriating any system, data, or personal information; (h) or materials which are false, misleading or intentionally inaccurate.
<br />
<br />
</Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement8;