import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { styled, alpha } from '@mui/material/styles';
import { Box, Tab, Grid, FormControl, Button, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import MemberListData from '../../../Components/MemberList/MemberListData';
import { DateRangePicker } from 'react-date-range';
import { Typography } from '@mui/joy';
import { addDays, format, subDays } from 'date-fns';
function Index(props) {
    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
    useEffect(() => {
        setValue(tab);
    })
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //Date range select function start
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    //Date range select function start
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    useEffect(() => {
        setStartDate(format(selectionRange[0].startDate, 'dd-MM-yyyy'))
        setEndDate(format(selectionRange[0].endDate, 'dd-MM-yyyy'))
    }, [selectionRange]);
    //Clear date ranges start
    const clearFilter = () => {
        setStartDate('');
        setEndDate('');
    }
    //Clear date ranges start
    //Get referal info function start
    const ContentLayout = <>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography level="h4" color="primary">Member List</Typography>
        </Grid>
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                        <Grid item lg={12} md={12} xs={12} sm={12}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="Active" component={Link} to="/memberlist/active" value="active" sx={{ color: 'black' }} />

                                <Tab label="Paused" component={Link} to="/memberlist/paused" value="paused" sx={{ color: 'black' }} />
                            </TabList>

                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value="active"><MemberListData tab={tab} /></TabPanel>
                <TabPanel value="paused"><MemberListData tab={tab} /></TabPanel>
            </TabContext>
        </Box>
    </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}

export default Index;