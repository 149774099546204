import React, { useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import config from '../../AppConfig';
import AuthUser from '../../Components/Auth/AuthUser';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Box, Button,  } from '@mui/material';
import Moment from 'moment';
import formatPhoneNumber from '../../Components/Formats/PhoneNumberValidation';
import { useNavigate } from "react-router-dom";
function CustomerDetails(props) {
  const dispatch = useDispatch();
  const {  user } = AuthUser();
  const [data, setData] = useState(props.data);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const tablePreferencesArray = {
    CreditGrade: false,
    FirstName:false,
    City:false,
    State:false,
    ZIP:false,
    EnrollementDate:false,
    SubscriptionTime:false,
    ProductName:false,
    StreetAddress:false,
    PublisherID:false,
    ADID:false,
    SID:false,
    TID:false,
    IPAddress:false,
  }
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])
  
  const tablePreferences = async() => {
      const apiUrl = '/fetchTablePreferences/customerdetails';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        if (Object.values(res).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.preferences)));
        }
      }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
        const apiUrl = '/saveTablePreferences';
        const args = {
            columnArray: columnVisibility,
            userId: user.id,
            tableId: 'customerdetails'
        }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data['Member ID'] ? data['Member ID'] : 'N/A'}`,
        id: 'MemberID',
        header: 'Member ID',
        size: 50,
        enablePinning: true,
      },
      {
        accessorFn: (data) => `${data.accountStatus ? data.accountStatus : ''}`,
        id: 'AccountStatus',
        header: 'Account Status',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.accountStatus ? row.original.accountStatus : 'N/A'),
      },
      {
        accessorFn: (data) => `${data['Credit Grade'] ? data['Credit Grade'] : ''}`,
        id: 'CreditGrade',
        header: 'Credit Grade',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['Credit Grade'] ? row.original['Credit Grade'] : 'N/A'),
      },
      {
        accessorFn: (data) => `${data['First Name'] ? data['First Name'].trim() : ''}`,
        id: 'FirstName',
        header: 'First Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['First Name'] ? row.original['First Name'].trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data['Last Name'] ? data['Last Name'].trim() : ''}`,
        id: 'LastName',
        header: 'Last Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['Last Name'] ? row.original['Last Name'].trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.Email ? data.Email.trim() : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.Email ? row.original.Email.trim(): 'N/A'),
      },
      {
        accessorFn: (row) => `${row.Phone ? formatPhoneNumber(row.Phone.trim()) : 'N/A'}`,
        id: 'Phone',
        header: 'Phone',
        size: 50,
      },
      {
        accessorFn: (data) => `${data['Street Address'] ? data['Street Address'].trim() : ''}`,
        id: 'StreetAddress',
        header: 'Street Address',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['Street Address'] ? row.original['Street Address'].trim(): 'N/A'),
      },
      {
        accessorFn: (data) => `${data.City ? data.City.trim() : ''}`,
        id: 'City',
        header: 'City',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.City ? row.original.City.trim(): 'N/A'),
      },
      {
        accessorFn: (data) => `${data.State ? data.State.trim() : ''}`,
        id: 'State',
        header: 'State',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.State ? row.original.State.trim(): 'N/A'),
      },
      {
        accessorFn: (data) => `${data.ZIP ? data.ZIP : ''}`,
        id: 'ZIP',
        header: 'ZIP',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.ZIP ? row.original.ZIP: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.dateRangeFilter ? data.dateRangeFilter : ''}`,
        id: 'EnrollementDate',
        header: 'Enrollment Date',
        size: 50,
        Cell:({renderedCellValue,row}) => (
          <div>
              {row.original.dateRangeFilter ? Moment(row.original.dateRangeFilter).format('MM-DD-YYYY') :"N/A"}
           </div>          
         ),
      },
      {
        accessorFn: (data) => `${data['Subscription Time'] ? data['Subscription Time'] : ''}`,
        id: 'SubscriptionTime',
        header: 'Subscription Time',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['Subscription Time'] ? row.original['Subscription Time']: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.productName ? data.productName.trim() : ''}`,
        id: 'ProductName',
        header: 'Product Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.productName ? row.original.productName.trim(): 'N/A'),
      },
      {
        accessorFn: (data) => `${data.publisherId ? data.publisherId : ''}`,
        id: 'PublisherID',
        header: 'Publisher ID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.publisherId ? row.original.publisherId: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        id: 'AID',
        header: 'AID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.ADID ? data.ADID : ''}`,
        id: 'ADID',
        header: 'ADID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.ADID ? row.original.ADID: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.SID ? data.SID : ''}`,
        id: 'SID',
        header: 'SID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.SID ? row.original.SID: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.TID ? data.TID : ''}`,
        id: 'TID',
        header: 'TID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.TID ? row.original.TID: 'N/A'),
      },
      {
        accessorFn: (data) => `${data['IP Addr'] ? data['IP Addr'] : ''}`,
        id: 'IPAddress',
        header: 'IP Address',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original['IP Addr'] ? row.original['IP Addr']: 'N/A'),
      }
    ]
  );
  const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: `Customer Details Report_${currentDate}.csv`,
  headers: ['Member ID', 'Account Status', 'Credit Grade', 'First Name', 'Email', 'Phone', 'Street Address', 'City', 'State', 'ZIP', 'Enrollment Date', 'Subscription Time', 'Product Name', 'Publisher ID', 'AID', 'ADID', 'SID', 'TID', 'IP Address'],
};

const csvExporter = new ExportToCsv(csvOptions);

const handleExportData = (rows) => {
  if(localStorage.getItem("admin") != null) {
    navigate('/login');
    return false;
  }
  const exportedData = rows.map((row) => {
   
    return {
      'Member ID': row.original['Member ID'] || 'N/A',
      'Account Status': row?.original?.accountStatus || 'N/A',
    'Credit Grade': row.original['Credit Grade'] ? row.original['Credit Grade'] : 'N/A',
    'First Name': row.original['First Name'] ? row.original['First Name'].trim() : 'N/A',
    'Email': row.original['Email'].trim() || 'N/A',
    'Phone': row.original['Phone'] ? formatPhoneNumber(row.original['Phone'].trim()) : 'N/A',
    'Street Address': row.original['Street Address'] ? row.original['Street Address'].trim(): 'N/A',
    'City': row.original['City'] ? row.original['City'].trim() : 'N/A',
    'State': row.original['State'] ? row.original['State'].trim() : 'N/A',
    'ZIP': row.original['ZIP'] ? row.original['ZIP'] : 'N/A',
    'Enrollment Date': row.original.dateRangeFilter ? Moment(row.original.dateRangeFilter).format('MM-DD-YYYY') :"N/A",
    'Subscription Time': row.original['Subscription Time'] ? row.original['Subscription Time']: 'N/A',
    'Product Name': row.original.productName ? row.original.productName.trim(): 'N/A',
    'Publisher ID': row.original.publisherId ? row.original.publisherId: 'N/A',
    'AID': row.original['AID'] ? row.original['AID'].trim() : 'N/A',
    'ADID': row.original['ADID'] ? row.original['ADID'] : 'N/A',
    'SID': row.original['SID'] ? row.original['SID'] : 'N/A',
    'TID': row.original['TID'] ? row.original['TID'] : 'N/A',
    'IP Address': row.original['IP Addr'] ? row.original['IP Addr']: 'N/A',

    };
  });

 // Convert data to CSV format
 const csvContent = convertToCSV(exportedData);

 // Create a Blob object from the CSV data
 const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

 // Create a download link element
 const link = document.createElement('a');
 link.href = URL.createObjectURL(blob);
 link.download = csvOptions.fileName + '.csv';

 // Append the link to the document body
 document.body.appendChild(link);

 // Programmatically click the link to trigger the file download
 link.click();

 // Clean up the temporary URL and remove the link from the document
 URL.revokeObjectURL(link.href);
 link.remove();
};

const convertToCSV = (data) => {
 const headers = csvOptions.headers.join(',') + '\n';

 const rows = data.map((row) => {
   return Object.values(row).map((value) => {
     // Handle any necessary formatting or escaping of values
     // For simplicity, we assume all values are already properly formatted

     // Wrap the value with double quotes if it contains a comma
     if (typeof value === 'string' && value.includes(',')) {
       return `"${value}"`;
     }

     return value;
   }).join(',');
 }).join('\n');

 return headers + rows;
};

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilterModes
        enablePinning
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
         }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        enableRowSelection={false}
        enableColumnFilters={config.DatatableColumnFilter}
        initialState={{ showColumnFilters: false, density: 'compact',
          columnPinning: { left: ['mrt-row-expand','MemberID'] }
         }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection}}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            backgroundColor: "#F6FAFF",
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              sx={{ bgColor: "#2A68B3" }}
              disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
              
              variant="contained"
              className='export'
            >
              CSV
            </Button>
          </Box>
        )}
      />
    </>
  );
}


export default CustomerDetails;