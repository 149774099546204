
import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import {Box,Tab,Grid,FormControl,InputLabel,Select,OutlinedInput,MenuItem,Button} from '@mui/material';
import { Typography,Card,CardContent,CardOverflow } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import CommissionPayments from '../../../Components/CommissionSummary/CommissionPayments';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const filter = [
//   'ALL',
//   'ACH Added/Updated',
//   'ACH Not Added',
//   'Banking Details Not Added',
//   'Issues Reported with ACH',
//   'To Be Paid',
// ];

const filter = [
  'ALL',
  'Bank Details Added/Updated',
  'Bank Details Not Added',
  'ACH In Verification',
  'Issues Reported with ACH',
  'To Be Paid',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function CommissionPayment(props) {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [data, setData] = useState([]);
    const [tobePaidData, setTobePaidData] = useState([]);
    const [achAddedData, setAchAddedData] = useState([]);
    const [achNotAddedData, setAchNotAddedData] = useState([]);
    const [noBankingDetailsData, setNoBankingDetailsData] = useState([]);
    const [issueReportData, setIssueReportData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [readyToPay, setReadyToPay] = useState("");
    const [readyToPayNBD, setReadyToPayNBD] = useState("");
    const [rowSelection, setRowSelection] = useState({});
    const [perPage, setPerPage] = useState(10);
    const { http, user } = AuthUser();
    const [isLoading, setIsLoading] = useState(true);
    const ACHFilterStatus = localStorage.getItem('ACHFilterStatus') ? localStorage.getItem('ACHFilterStatus') : "To Be Paid" ;
    const [status, setStatus] = useState(ACHFilterStatus);
    const [freez, setFreez] = React.useState(true);
    const [filterPage, setFilterPage] = useState(null);
    const [btnStatus, setBtnStatus] = useState("Ready To Pay");
    const [dataCopy, setDataCopy] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const changeToBePaidBtnStatus = () => {
      if(btnStatus == "Ready To Pay") {
        setBtnStatus("All");
        var filteredData = dataCopy.filter((item) =>item.ReadyToPay == 1 ? item : '');
        setData(filteredData);
      }else {
        setBtnStatus("Ready To Pay");
        setData(dataCopy);
      }
    }
    const fetchCommissionPayments = async() => {
      var localStorageAchFilterStatus = localStorage.getItem('ACHFilterStatus');
      console.log("status: " +localStorageAchFilterStatus);
      setFreez(true);
      
      const apiUrl = '/commission-payments-summary';
      const args = {
        ticketStatus: localStorageAchFilterStatus != null ? localStorageAchFilterStatus : ACHFilterStatus
      };       
      
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if(res){
                if(res.success === true){
                    setStatus(localStorageAchFilterStatus != null ? localStorageAchFilterStatus : res.ticketStatus );
                    setData(res.paymentSummaryData);
                    setDataCopy(res.paymentSummaryData);
                    if(status == "To Be Paid") {
                       setBtnStatus("Ready To Pay");
                    }
                    setReadyToPay(res.readyToPay);
                    setReadyToPayNBD(res.readyToPayNBD);
                    handleFreezStop();
                  }else {
                    handleFreezStop();
                  }
      }
    } 

    useEffect(() => {      
      if(user.roles_id < 3) {
        fetchCommissionPayments();
      }else{
        navigate('/login');
      }
      
     
    }, []);

    
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;

      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    }; 

    const  ChangeStatus  = async (event) => {
      var value = event.target.value;
      setStatus(value);
      setFilterPage(0);
      localStorage.setItem('ACHFilterStatus',value);
      fetchCommissionPayments();
  };

  console.log(status);
  console.log(localStorage.getItem('ACHFilterStatus'));
    
    const ContentLayout =
        <>
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                <Typography level="h4" color="primary">Commission Payments</Typography>
                    <Grid container item xs={12} className='contenting'>
                       <Typography level="h6">Projected Payable Today: ${parseFloat(readyToPay).toFixed(2)}</Typography>
                       <Typography level="h6" ml={4}>Projected Payable NBD : ${parseFloat(readyToPayNBD).toFixed(2)}</Typography>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                  <Grid container item md={8} sm={8} xs={8} mt={1}>
                    {/* <Grid container item xs={4}> */}
                      <FormControl sx={{minWidth:300}} size='small'>
                          <InputLabel id="demo-multiple-name-label">ACH issue Status</InputLabel>
                              <Select
                              margin="dense"
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              
                              value={status}
                              onChange={(e) => {ChangeStatus(e)}}
                              input={<OutlinedInput label="ACH issue Status" />}
                              MenuProps={MenuProps}
                              >
                              {filter.map((name) => (
                                  <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(name, personName, theme)}
                                  >
                                  {name}
                                  </MenuItem>
                              ))}
                              </Select>
                      </FormControl>
                    {/* </Grid> */}
                   
                  </Grid>
                  <Grid container item md={4} sm={4} xs={4} mt={1}>
                    {
                      status == "To Be Paid" ?
                      <>
                        <Button variant="contained" color="primary" onClick={changeToBePaidBtnStatus} sx={{ minWidth: 200 }}>Show {btnStatus}</Button>          
                      </>
                      : null
                    }
                    
                  </Grid>
                </Grid>
                    <CommissionPayments payments={data} onValueChange={fetchCommissionPayments} freezStart={handleFreezStart} freezStop={handleFreezStop}  filterPage={filterPage}  />
               
            </Box>
        </>
     
    
    return(
        <>
            <DefaultLayout content={ContentLayout} />
            <div>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
              >
                <Freez />
              </Backdrop>
          </div>
        </>
    );
}
    
export default CommissionPayment;