import { useSelector } from 'react-redux';
import config from '../../AppConfig';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
axios.defaults.withCredentials = true;


export default function AuthUser(){
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const user1 = localStorage.getItem('user');
    const user = user1 ? JSON.parse(user1) : null;
    const company1 = localStorage.getItem('company');
    const company = company1 ? JSON.parse(company1) : null;
    const redirectLocation = localStorage.getItem('redirectLocation');
    const lastloginUserId = useSelector((state) => state.auth.lastloginUserId);
    localStorage.removeItem('redirectLocation');

    const validateRoles = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        const hasLoggedIn = localStorage.getItem('hasLoggedIn');
        const cobrandStatus = localStorage.getItem('cobrandStatus');
        
        if(isAuthenticated){
            if(hasLoggedIn){
                if((redirectLocation && lastloginUserId == null) || (redirectLocation && lastloginUserId && lastloginUserId == user.id)) {
                    navigate(redirectLocation);
                    return null;
                }
                if(user.roles_id===1 || user.roles_id===2){
                    // console.log(hasLoggedIn)
                    navigate('/manageleads/hot-leads')
                }else if(user.roles_id=== 7 || user.roles_id===8){
                    navigate('/partner-admin/dashboard')
                    localStorage.removeItem('hasLoggedIn')
                }else if(user.roles_id=== 11){
                    navigate('/my-profile')
                    localStorage.removeItem('hasLoggedIn')
                }else if(user.roles_id=== 9){
                    navigate('/affiliate/referralprogram/')
                    localStorage.removeItem('hasLoggedIn')
                }else if(user.roles_id === 10){
                    navigate('/my-profile')
                    localStorage.removeItem('hasLoggedIn')
                }else if(user.roles_id === 6){
                    navigate('/manageleads/hot-leads')
                }else if(user.roles_id === 12 || user.roles_id === 13){
                    navigate('/PoolPid')
                }else if(user.roles_id === 14){
                    navigate('/my-profile')
                    localStorage.removeItem('hasLoggedIn')
                }else if(user.roles_id === 3){                                       
                    // console.log('cobrandStatus =>',cobrandStatus) 
                    // console.log((cobrandStatus===true))
                    if(cobrandStatus==="true"){
                        // console.log('true')
                        navigate('/affiliate/dashboard')                        
                    }else{
                        // console.log('false')
                        navigate('/affiliate/initialcobrandsetup')
                    }
                    localStorage.removeItem('hasLoggedIn')
                }
                else
                {
                    navigate('/affiliate/dashboard')
                }
            }else{
            }
        }else{
            localStorage.clear();
        }
    }

    const loginAsAffiliate = () => {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        const user = JSON.parse(localStorage.getItem('user'));
        if(isAuthenticated){
            if(user.roles_id===3){
                navigate('/affiliate/dashboard')
            }else{
                localStorage.clear();
            }
        }
    }

    const http = axios.create({
        baseURL:config.API_BASE_URL,
        headers:{
            "Content-type" : "application/json",
            "Accept" : "application/json",
            "Authorization":`Bearer ${token}`,
        }
    });
    

    const httpFormData = axios.create({
        baseURL:config.API_BASE_URL,
        headers:{
            "Content-type" : "multipart/form-data",
            "Authorization":`Bearer ${token}`,
        }
    });
    

    return {
        validateRoles,
        loginAsAffiliate,
        token,
        user,
        company,
        http,
        httpFormData,
    }
}