import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import {Box,Grid,Card,CardContent} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginHeader from '../../Components/LoginHeader';
import { Typography,Button } from '@mui/joy';
import RegisterFooter from '../../views/Auth/RegisterFooter';
function Thankyou(props) {
    const navigate = useNavigate();
    const location = useLocation();  
    return (
        <>
        <LoginHeader />
        <Box className="back" sx={{ padding: "2%" }}>
            <Card sx={{padding:" 0% 0% 4% 4% "}}>
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4}>
                        <Grid item lg={4} md={6} sm={12} xs={12} >   
                            <img src={require("../../assets/images/thankyou.png")} style={{display:"block",marginLeft:"auto",marginRight:"auto"}} className="images"/>
                            {/* <Button id="submitbtn" onClick={()=>navigate('/login')} >Login Now</Button> */}
                        </Grid>
                        <Grid  item lg={8} md={6} sm={12} xs={12}>
                            <Typography>
                                Hi {location.state.name}
                            </Typography>
                            <ul>
                                <li>Complete Sign up Form </li>
                                <li>Email Verified </li>
                                <li>Open and Complete Affiliate Program Document</li>
                                <li>Receive Affiliate Link and Log In </li>
                                <li>Whats going to happen Next " You will receive a phone call by a representative from 888xxxxxxx to provide you with a free benefits</li>
                                <li>Cobrand/ Snapshot recommended next steps</li>
                            </ul>
                            {/* <Typography>
                                Thank you for your interest in MyFreeScoreNow’s Affiliate Program!
                            </Typography>
                            <Typography>
                            It’s easy to get started as an affiliate and there is:<span style={{color:"#30BE2D",fontWeight:"bold"}}> No cost to you to join! No set-up fees. No minimums.</span>
                            </Typography>
                            <Typography>
                            Get your affiliate links in minutes!
                            </Typography>
                            Plus your company gets a<span style={{color:"#30BE2D",fontWeight:"bold"}}> FREE </span> membership!
                            <Typography>
                            Our affiliates receive the<span style={{color:"#30BE2D",fontWeight:"bold"}}> HIGHEST</span> ongoing residual sales commissions - <span style={{color:"#30BE2D",fontWeight:"bold"}}>$7.00-$16.00 every month.</span>
                            Plus - earn cash by referring other companies to us!
                            </Typography>
                            Each of our affiliate membership levels<span style={{color:"#30BE2D",fontWeight:"bold"}}> includes a 3-Bureau credit report </span>for your client <span style={{color:"#30BE2D",fontWeight:"bold"}}>auto-pulled </span>upon enrollment -
                            and immediately available for 1-Click Auto-Import and a report refresh every 30 days.
                            <Typography>
                            <span style={{color:"#30BE2D",fontWeight:"bold"}}>Yes - </span>we offer 7-day trial period options!
                            </Typography>
                            <span style={{color:"#30BE2D",fontWeight:"bold"}}>Yes - </span>we accept credit and debit cards from your clients! Ask us about our options for prepaid cards and cash apps.
                            <Typography>
                            <span style={{color:"#30BE2D",fontWeight:"bold"}}>Yes - </span>we have options for co-branding and wholesale
                            </Typography> */}
                            <Typography>
                            <span style={{color:"#30BE2D",fontWeight:"bold"}}>So let’s get started!</span>
                            </Typography>
                            <Typography>
                            <span style={{color:"#ffa500",fontWeight:"bold"}}> Next Step ➡ Please check your email!</span>
                            </Typography>
                            <Typography level="h6">
                            Your personalized MyFreeScoreNow affiliate program document is ready for your review.
                            Don’t see it? Please check spam/junk folders.<span style={{color:"#30BE2D",fontWeight:"bold",fontSize:"20px"}}>Thank you!</span> 
                            </Typography>
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
        <RegisterFooter />
        </> 
    );
}
export default Thankyou;