import React, { useState, useEffect,useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Box, Grid, TextField, FormControlLabel, Checkbox, Link, CardContent,
    Button, Divider, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText,Tooltip
} from "@mui/material";
import { Card, Table } from "@mui/joy";
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from './RegisterFooter';
import Typography from '@mui/joy/Typography';
import AuthUser from "../../Components/Auth/AuthUser";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from 'formik';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import Freez from '../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import InstagramIcon from "../../assets/icons/instagram.svg";
import messangerIcon from "../../assets/icons/messenger.svg";
import wstpImgIcon from "../../assets/icons/wstpImg.svg";
import { fetchData } from '../../redux/apiSlice';
import config from '../../AppConfig';

function Login(props) {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { http, validateRoles } = AuthUser();
    const [loading, setLoading] = useState('');
    const [error, setError] = useState();
    const [fieldError, setFieldError] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const marqueeRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    //Check Login
    useEffect(() => {
        if (isAuthenticated) {
            localStorage.setItem('hasLoggedIn', true)
            validateRoles();
            // navigate("/manageleads/hot-leads");
        }
    }, []);

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")       //commented for old affiliate login

            .required('Please enter your email address.'),
        password: Yup.string()
            .required('Please enter password.'),
    })

    const backgroundImage = require('../../assets/images/banner_02.png');

    const styles = {
        cardcontent: {
            padding: 0,
            "&:last-child": {
                paddingBottom: 0
            }
        }
    };

    return (
        <>
            <LoginHeader />
            <Box>
                <Card size="sm" sx={{ boxShadow: 'none', backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', borderRadius: 0 }}>
                    <CardContent sx={{ minHeight: '19vw' }}>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                            <Typography level='h4'>Welcome to The Home Of</Typography>
                        </Grid> */}
                        <Grid container item  lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                            <img src={require("../../assets/images/EPRLogo.png")} className='epicproimg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                            <Typography level='h4'>From</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                            <img src={require("../../assets/images/mfsn_logo.png")} />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                            <Button variant="contained" onClick={handleClickOpen}>LEARN MORE</Button>
                        </Grid>
                    </CardContent>
                    {/* <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                        <Chip label="REGISTER NOW" className="registerbtn" color="primary" size="small" sx={{float:'right'}} />
                    </CardOverflow> */}
                </Card>
                <Divider />
                <Card size="sm" style={{ marginTop: "2%" }}>
                    <Grid container item className='row-reverse'>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ borderRight: 1, borderColor: '#c6c6c6' }}>
                            <Box textAlign="center" >
                                <Typography textAlign={'center'} level="h3">Welcome to the Affiliate Partner Portal</Typography>
                                <Typography sx={{ fontWeight: "bold", textAlign: "center" }} level="h5" mt={1}>Not an Affiliate Yet?&nbsp;
                                    <span style={{ cursor: "pointer" }}>
                                        <Link href="/register" onClick={() => { localStorage.setItem('origin', "mfsnloginpage") }} underline="none" sx={{ cursor: 'pointer' }} >
                                            Join Here
                                        </Link>
                                    </span>
                                </Typography>
                            </Box>
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={7} sx={{ '& .MuiGrid-item': { paddingTop: "0px" } }} mt={2}>
                                <Grid item lg={5} md={5} sm={12} xs={12} >
                                    <Box className="flexing" mt={4} sx={{ display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}>
                                        <img src={require("../../assets/images/loginContact.png")} className='loginimg' />
                                    </Box>
                                </Grid>

                                <Grid item lg={7} md={7} sm={12} xs={12} >
                                    <Box className="margins">
                                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="h3" color={'success'}>Need Help?</Typography>
                                            <Box sx={{ display: { lg: 'none', xl: 'none', md: 'none', xs: 'contents' } }}>
                                                <img src={require("../../assets/images/loginContact.png")} className='loginimg' />
                                            </Box>
                                        </Grid>
                                        <div className='loginWrapperDiv'>
                                            <div className='loginWrapperIcon'> <AccessTimeIcon /></div>
                                            <div><Typography level="body1" fontSize="lg">
                                                We’re available 7days a week
                                                <br />
                                                Mon-Fri (8am-9pm ET)
                                                <br />
                                                Sat-Sun (9am-7pm ET)
                                            </Typography></div>
                                        </div>
                                        <div className='loginWrapperDiv'>
                                            <div className='loginWrapperIcon'><WifiCalling3Icon /></div>
                                            <div><Typography level="body1" fontSize="lg">1-888-548-2008</Typography></div>
                                        </div>
                                        <div className='loginWrapperDiv'>
                                            <div className='loginWrapperIcon'><EmailOutlinedIcon /></div>
                                            <div><Typography level="body1" fontSize="lg">info@myfreescorenow.com</Typography></div>
                                        </div>
                                        <Box sx={{marginTop:'10px' }} className="socialMediaIcons">
                                            <Typography level="h5" color={'success'}>Affiliate Partner support also available on</Typography>
                                            <a href='https://m.me/myfreescorenow' target='_blank'><img src={messangerIcon} alt='messenger img' /></a>&nbsp;&nbsp;
                                            <a href='https://api-wa.co/JrzyQs' target='_blank'><img src={wstpImgIcon} alt='whatsapp img' /></a>&nbsp;&nbsp;
                                            <a href='https://ig.me/m/myfreescorenow' target='_blank'><img src={InstagramIcon} alt='instagram img' /></a>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent="center">
                                <Box component="form" noValidate autoComplete="off">
                                    <Box textAlign="center">
                                        <Typography level="h2" color="primary">Login</Typography>
                                    </Box>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: '',
                                        }}
                                        validationSchema={SignupSchema}
                                        onSubmit={(values, errors) => {
                                            handleFreezStart();
                                            setLoading(<CircularProgress />);
                                            //Call API for login

                                            http.post("/login", {
                                                email: values.email,
                                                password: values.password,
                                            })
                                                .then(async (res) => {
                                                    if (res.data.success === true) {
                                                        // localStorage.setItem('SnapShotStatus', JSON.stringify(res.data.SnapShotStatus));                                                        
                                                        await dispatch(login(res.data));
                                                        // console.log('login')
                                                        localStorage.removeItem('cobrandStatus');
                                                        localStorage.setItem('hasLoggedIn', 'true');
                                                        localStorage.setItem('user', JSON.stringify(res.data.user));
                                                        localStorage.setItem('company', JSON.stringify(res.data.company));                                                        
                                                        localStorage.setItem('aidUser', res.data.aidUser[0] ? JSON.stringify(res.data.aidUser[0]): '');
                                                        localStorage.setItem('token', res.data.token);
                                                        localStorage.setItem('isAuthenticated', true);
                                                        localStorage.setItem('SnapShotStatus', res.data.SnapShotStatus);
                                                        localStorage.setItem('cobrandStatus', res.data.cobrandStatus);  
                                                        localStorage.setItem('taxFlag', res.data.taxFlag);  
                                                        
                                                        // console.log('localstorage')
                                                        // console.log(res.data)
                                                        
                                                        // navigate('/manageleads/hot-leads')
                                                        validateRoles();
                                                        handleFreezStop();

                                                    } else {
                                                        //   document.getElementsByClassName("submitbtn").classList.remove("d-none");
                                                        setFieldError(res.data.message);
                                                        setError(res.data.message.error);
                                                        setLoading('');
                                                        handleFreezStop();
                                                    }
                                                })
                                                .catch((error) => {
                                                    // document.getElementsByClassName("submitbtn").classList.remove("d-none");
                                                    setError(error.message);
                                                    setLoading('');
                                                    handleFreezStop();
                                                });
                                        }}
                                    >
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                            <Box sx={{ paddingTop: '5%' }}>
                                                <Typography level="body1" color="danger">{error}</Typography>
                                                <TextField
                                                    required
                                                    autoComplete="email"
                                                    name="email"
                                                    onChange={handleChange('email')}
                                                    onBlur={handleBlur('email')}
                                                    fullWidth
                                                    margin="normal"
                                                    size="small"
                                                    id="email"
                                                    label="Enter Your Email Address"
                                                    helperText={touched.email && errors.email ? errors.email : null}
                                                    error={touched.email && Boolean(errors.email)}
                                                />
                                                <TextField
                                                    required
                                                    autoComplete="password"
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    onChange={handleChange('password')}
                                                    onBlur={handleBlur('password')}
                                                    fullWidth
                                                    margin="normal"
                                                    size="small"
                                                    id="password"
                                                    label="Enter Your Password"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end"> {
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}>
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    helperText={touched.password && errors.password ? errors.password : null}
                                                    error={touched.password && Boolean(errors.password)}
                                                />
                                                <Typography level="body1" color="danger">{fieldError.email}</Typography>
                                                <Typography level="body1" color="danger">{fieldError.password}</Typography>
                                                <Button variant="contained" className="d-none" sx={{ marginTop: 2 }} fullWidth onClick={handleSubmit}>
                                                    Login
                                                </Button>
                                                <Grid container item>
                                                    <Grid item xs>
                                                        <FormControlLabel control={<Checkbox id='remembereMe'/>} label="Remember me" />
                                                    </Grid>
                                                    <Grid item mt={1}>
                                                        <Link underline="none" href="/forgot-password">Forgot Password?</Link>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Formik>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                {/* <Box className='cardstyle mobile' style={{marginTop:"2%",backgroundColor:"white"}}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent="center">
                                    <Typography level="h2" sx={{textAlign:"center"}}>
                                        Welcome to the Affiliate Portal
                                    </Typography>
                                    <Typography level="h3" sx={{color:"#2D6DB4",textAlign:"center"}}>Login</Typography>
                                    <Box component="form"  noValidate>
                                        <TextField
                                            onChange={(e) => {setEmail(e.target.value)}}
                                            id="email"
                                            label="Email Address"
                                        //    helperText={fieldError.email}
                                        //    error={fieldError.email}
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            size="small"
                                            placeholder="Enter Email Address"
                                        />
                                            <span className="text text-danger">{fieldError.email}</span>
                                        <TextField
                                            onChange={(e) => {setPassword(e.target.value)}}
                                            id="outlined-error-helper-text"
                                            label="Password"
                                            helperText={fieldError.password}
                                            error={fieldError.password}
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            autoComplete="password"
                                            size="small"
                                            placeholder="Enter Password"
                                        />
                                        <span className="text text-danger">{fieldError.password}</span>
                                        <Button variant="contained" className="submitbtn" sx={{marginTop: 2}} fullWidth onClick={()=>{LoginSubmit()}}>
                                            Login
                                        </Button>
                                        <Grid container>
                                            <Grid item xs>
                                                <FormControlLabel control={<Checkbox />} label="Remember me" />
                                            </Grid>
                                            <Grid item>
                                                <Link href="/forgot-password" underline="none">Forget Password?</Link>
                                            </Grid>
                                        </Grid>
                                        <Typography sx={{fontWeight:"bold",marginTop: 3,textAlign:"center"}}>Not an Affiliate Yet? <Link href="#" underline="none" >Join Here</Link></Typography>
                                    </Box>
                                
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent="center" >
                                <Box>
                                    <img  src={require("../../assets/images/Loginimg.png")}/>
                                    <Typography sx={{textAlign:"center"}} level="h4" color={'success'} >Need Help?</Typography>
                                    <p className="text-center text-black" style={{textAlign:"center"}}>We're Available 7 Days A Week Mon-Fri (7 AM - 11 PM ET)<br/>
                                    Sat-Sun (8am-9pm ET) 1-888-548-2008<br/>
                                    info@myfreescorenow.com
                                    </p>  
                                </Box>      
                            </Grid>
                        </Grid>
                    </Grid>
                </Box> */}
                {/* </Box> */}
                <Dialog
                    fullWidth={fullWidth}
                    fullScreen={fullScreen}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Grid container item xs sx={{ justifyContent: "space-between" }}>
                            <Grid item xs>
                                <Typography level="h4">Epic Pro Report</Typography>
                            </Grid>
                            <Grid item xs sx={{ textAlign: "right" }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <iframe
                                src="https://www.epicproreport.com/model.html"
                                title="Website Modal"
                                style={{ width: '100%', height: '600px', border: 'none', overflow: 'hidden' }}
                            ></iframe>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </Box>
            <RegisterFooter />

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}

        </>
    );
}

export default Login;