
import React,{useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box,Table ,TableBody ,TableCell ,Dialog,DialogActions ,DialogTitle,TextField,DialogContent,FormControl,
  TableContainer,TableHead,TableRow,Paper,Button,Grid  } from '@mui/material';
  import TextareaAutosize from '@mui/base/TextareaAutosize';
import MaterialReactTable from 'material-react-table';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate, Link,useLocation } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Moment from 'moment';
//Mock Data
import { data } from './makeData';
import ApprovedNow from '../../views/Web/ManageLeadsPanel/ApprovedNow';
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {currencyFormat} from '../../Components/CommonFormula';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ];
function CommissionView(props) {

  const [itemList, setItemList] = useState(props.itemList);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
};

const handleClose = () => {
    setOpen(false);
};
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const q = search == '' ? 'null' : search;
  const [perPage, setPerPage] = useState(100000);

    useEffect(() => {
    
    }, []);
    useEffect(() => {
    }, [rowSelection]);
   
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.aid.aid}`,
        id:'aid',
        header: 'AID',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.customer_id}`,
        id:'customer_id',
        header: 'Customer ID',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.first_name} `,
        id:'first_name',
        header: 'First Name',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.last_name}`,
        id:'last_name',
        header: 'Last Name',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.effective_date ? Moment(row.effective_date).format('MM-DD-YYYY') : ''}`,
        id:'effective_date',
        header: 'Effective Date',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.pid}`,
        id:'pid',
        header: 'PID',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.description}`,
        id:'description',
        header: 'Description',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.approved_commission}`,
        id:'commission',
        header: 'Commission($)',
        size: 50,
        Cell:({renderedCellValue,row}) =>row.original.approved_commission != null ? (currencyFormat(parseFloat(row.original.approved_commission))) : '$0.00',
        //custom conditional format and styling
      },
    ]
  )
//csv option start here
const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'Commission View Details',
  headers: ['AID','Customer ID','First Name','Last Name','Effective Date','PID','Description','Commission($)',],
};

const csvExporter = new ExportToCsv(csvOptions);
const handleExportData = () => {
  const exportedData = itemList.map((row) => {
    return {
      aid: `${row.aid.aid}` || 'N/A',
      customer_id: `${row.customer_id}` || 'N/A',
      first_name:`${row.first_name} ` || 'N/A',
      last_name : `${row.last_name}` || 'N/A',
      effective_date: `${row.effective_date }` || 'N/A',
      pid : `${row.pid}` || 'N/A',
      description:`${row.description}` || 'N/A',
      commission : `${row.approved_commission}` || 'N/A'
    };
  });

  const csvContent = convertToCSV(exportedData);
  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
};
//csv end here
   
        return (
            <>
            
            <MaterialReactTable
                columns={columns}
                data={itemList}
                enableColumnFilterModes
                filterFns={{
                  customSearchFilterFn: (row, id, filterValue) => {
                    const searchValues = filterValue.toLowerCase().split(' ');
                    const firstName = row.original.first_name ? row.original.first_name.toLowerCase() : '';
                    const lastName = row.original.last_name ? row.original.last_name.toLowerCase() : '';
                    const nameMatches = searchValues.every(value =>
                      firstName.includes(value) || lastName.includes(value)
                    );
                    return nameMatches ||row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, ''));
                  },
                }}
              globalFilterFn="customSearchFilterFn"//custom search function
                enableColumnFilters={config.DatatableColumnFilter}
                enableRowSelection
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      backgroundColor:"#F6FAFF",
                    },
                  }} 
                renderDetailPanel={({ row }) => (
                  <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                              <TableHead>
                                  <TableRow>
                                    <TableCell sx={{fontWeight:"bold"}}>AID</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Customer ID</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>First Name</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Last Name</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Effective Date</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>PID</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Description</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Commission</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                      <TableRow
                                        key={row.original.aid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.original.aid.aid}
                                        </TableCell>
                                        <TableCell align="center">{row.original.customer_id}</TableCell>
                                        <TableCell align="center">{row.original.first_name}</TableCell>
                                        <TableCell align="center">{row.original.last_name}</TableCell>
                                        <TableCell align="center">{row.original.effective_date}</TableCell>
                                        <TableCell align="center">{row.original.pid}</TableCell>
                                        <TableCell align="center">{row.original.description}</TableCell>
                                        <TableCell align="center">{row.original.approved_commission != null ?  currencyFormat(parseFloat(row.original.approved_commission)) : '$0.00'}</TableCell>
                                      </TableRow>
                              </TableBody>
                      </Table>
                    </TableContainer>
              </Box>
              )}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                <Button
                    color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                variant='contained'
                    >
                    CSV
                    </Button>
                </Box>
            )
            }
                />
            </>
            );
        
    }
    
    
    export default CommissionView;