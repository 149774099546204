import React, {useEffect, useState, useRef } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Button, FormControlLabel, Checkbox, FormHelperText, FormControl,Box, Divider } from '@mui/material';
import Typography from '@mui/joy/Typography';
import AuthUser from '../../../Components/Auth/AuthUser';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import AppConfig from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';

export const exampleHTML = (advertiser) =>`
        <p>These Standard Terms and Conditions for Lead Generation Services (“Standard Terms”) are agreed to and effective as of ${advertiser.date} between (“Advertiser”) to and MyFreeScoreNow Inc. (“Vendor”). The Standard Terms shall be known as the “Agreement.” Both Advertiser and Vendor are also herein referred to individually as a “Party” and collectively as the “Parties.”</p>
        <p>1. Definitions.</p>
        <p>1.1 Program. The Term “Program” is meant to and does encompass data in the form of Leads provided by Vendor to Advertiser that Advertiser uses to outbound dial and/or email customers provided by Vendor.</p>
        <p>1.2 Lead or Leads. A Lead is the use of true and accurate customer information applicable to a Program that has come through Vendor’s web site. A Lead is generated pursuant to the terms of this Agreement and does not include any invalid lead (including, but not limited to, an invalid,disconnected phone number; invalid email; no such person; never requested or uninterested in product or service offered under Program; immediate hang-up; not a U.S. citizen; interested in prize; under 18; etc.) or duplicate lead (including, but not limited to, a Lead that is the same as a prior Lead received within 30 days).</p>
        <p>2. Term of Agreement. The Agreement commences on the effective date which is the date of the last signature which will be solely by Vendor for one (1) year and renews automatically each year on the anniversary date. In the event of termination for any reason, anything herein to the contrary notwithstanding, Sections 3-14, and any other provisions which by its nature should survive termination, shall survive the expiration or termination of the Agreement for any reason.</p>
        <p>3. Lead Exclusivity. Leads generated under this Agreement are non-exclusive.</p>
        <p>4. Payments. Vendor will invoice Advertiser for all payments to which Vendor is entitled under this Agreement, if applicable. The cost per lead is set at $0.00. Vendor has the right to initiate a fee and charge advertiser per lead at a specific price, determined in the sole discretion of Vendor, after written notice to Advertiser within 30 days. During the notice period, the Advertiser has the right to decline a charge per lead from Vendor. Declining the charge by Vendor for a charge per lead will also automatically initiate termination of the Agreement by the Parties within ten (10) days of receipt by Advertiser of Vendor’s election to decline a charge per lead. Vendor agrees that it is an independent contractor and agrees to be paid accordingly. All payments made under this Agreement shall be deemed commission payments and shall be due seven (7) calendar days from the end of each calendar week in which Vendor has invoiced Advertiser. All commission payments are exclusive of taxes. Advertiser will pay for leads as defined in 1.2. Advertiser will not pay for any Leads submitted before the Effective Date, but will pay commissions to Vendor after this Agreement terminates if those commissions are generated from leads transferred from Vendor before termination.</p>
        <p>5. CAN-SPAM &amp; the Telemarketing Sales Rule. Advertiser and Vendor represent and warrant that each is, and will remain, in full compliance with the CAN-SPAM Act, 15 U.S.C. § 7701 et seq. and all amendments thereto, laws governing deceptive trade practices and/or online marketing and advertising, the Telemarketing Sales Rule, the Telephone Consumer Protection Act, and all other applicable federal, state, county, and local laws, ordinances, regulations and codes throughout the performance of this Agreement. Vendor agrees to maintain a regularly updated suppression list containing current unsubscribe requests in conformance with the CAN-SPAM Act, and to notify Advertiser of any and all unsubscribe requests no more than forty-eight (48) hours after each unsubscribe request.</p>
        <p>6. Terminations; Effect of Termination. Either Party may terminate this Agreement for any reason upon three (3) business days prior written notice to the other Party. In the event of a material breach by either Party, the non-breaching Party may terminate this Agreement immediately without prior notice or cure period. Vendor shall be entitled to payment, pursuant to the terms of this Agreement, through the date of termination.</p>
        <p>7. Representations and Warranties.  Each Party warrants, represents, covenants and acknowledges to the other that (a) it holds the necessary right, power, legal capacity, and authority to enter into, deliver and fully perform under this Agreement; (b) neither the execution, delivery, nor performance of this Agreement will result in a violation or breach of any contract, agreement, order, judgment, decree, rule, regulation or law to which such Party is bound; (c) it will provide and maintain the resources, personnel and facilities suitable to perform its obligations under this Agreement; and (d) the use, reproduction, distribution, transmission or display of any advertisement used in connection with the Program, including but not limited to any web sites, newsletters, or other advertising or promotional content (“Advertisement”), any data regarding users of such Advertisement, and any material to which users can link, or any products or services made available to users, through or as a result of the Advertisement, shall not (i) materially violate any federal, state and local laws, rules and regulations or any rights of any third party including, without limitation, laws relating to advertising, the Internet, privacy, e-mail, data protection, and unfair business practices; (ii) contain any material that is unlawful or otherwise objectionable, including without limitation any material that encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable law; or (iii) use any trademark, trade name, or corporate name of the other Party other than for the purposes of the Program.</p>
        <p>8. Permissions. Vendor hereby gives Advertiser permission to outbound dial customers on behalf of Vendor for the purposes of the Program. Vendor warrants, represents, covenants, and acknowledges that (a) it has lawfully obtained and will lawfully obtain any and all data regarding users or other consumer information that Vendor provides to Advertiser; and (b) it holds all necessary rights and permissions to use and permit the lawful use of the data and Leads it provides to Advertiser and to allow Advertiser to use such data and Leads for the purposes of this Agreement (for example, all Leads it provides to Advertiser for the purposes of this Agreement have made an inquiry sufficient to permit, under all applicable state and federal law, a call from Advertiser on Vendor’s behalf and have not asked to be added to Vendor’s internal Do Not Call List). Advertiser further warrants, represents, covenants, and acknowledges that it will not re-sell any Leads provided to it by Vendor without Vendor’s prior written consent.</p>
        <p>9. Indemnification. Each Party (the “Indemnifying Party”) agrees to indemnify, defend and hold the other Party (the “Indemnified Party”) and any third parties harmless from and against any and all liability, loss, damages, expenses, claims or causes of action, including reasonable legal fees and expenses, arising out of or related to (a) the Indemnifying Party’s or any of its agents, affiliates, subsidiaries, directors, officers, employees, licensors, licensees, consultants, contractors, and partners’ breach of any of the representations, warranties, covenants, agreements or obligations under this Agreement; (b) any offer, advertisement, or other materials used by the Indemnifying Party which are not preapproved in writing by the Indemnified Party; (c) any third party claim arising from the use of or access to advertisements or any material to which users can link, or any third party products or services made available to users through any Advertisement other than those specifically created and promoted by the Indemnified Party; and (d) any violation of law and/or violation of any third party’s rights by the Indemnifying Party, its agents, affiliates, subsidiaries, directors, officers, employees, licensors, licensees, consultants, contractors and/or partners which results in liability to the Indemnified Party. Vendor further agrees to indemnify, defend and hold Advertiser and any third parties harmless from and against any and all liability, loss, damages, expenses, claims or causes of action, including reasonable legal fees and expenses, arising out of or related to any third party or regulatory claim arising from Advertiser’s or any third party’s use of any Lead. </p>
        <p>10. Confidentiality. During the term of this Agreement, and until such time as the “Confidential Information” (as defined below) is no longer protected as a trade secret under Utah law, neither Party will use or disclose any Confidential Information of the other Party except as specifically contemplated herein. “Confidential Information” means information that: (a) is sufficiently secret to derive economic value, actual or potential, from not being generally known to other persons who can obtain economic value from its disclosure or use; and (b) is the subject of efforts that are reasonable under the circumstances to maintain its secrecy or confidentiality. Subject to the foregoing, Confidential Information shall include, without limitation, technical or non-technical data, a formula, pattern, compilation, program, device, method, technique, drawing, process, financial data, or list of actual or potential customers or suppliers, the advertisement and/or offer before publication, and the terms of the Insertion Order. Confidential Information does not include information that: (i) has been independently developed by the receiving Party without access to the other Party’s Confidential Information; (ii) has become publicly known through no breach of this section by the receiving Party; (iii) has been rightfully received from a third party authorized to make such disclosure; (iv) has been approved for release in writing by the disclosing Party; or (v) is required to be disclosed by a competent legal or governmental authority. At the request of the disclosing Party, the receiving Party shall return all of the disclosing Party’s Confidential Information.</p>
        <p>11. No Warranty. EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, ADVERTISER MAKES NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO ANY MATTER, INCLUDING WITHOUT LIMITATION ANY SERVICES OR PRODUCTS IT PROVIDES, OR THE SITES, AND EXPRESSLY DISCLAIMS THE WARRANTIES OR CONDITIONS OF NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR PURPOSE. EXCEPT AS EXPRESSLY SET FORTH IN THE AGREEMENT, ADVERTISER EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY REGARDING THE PERFORMANCE, AVAILABILITY, FUNCTIONALITY OR ANY OTHER ASPECT OF ADVERTISER’S SERVICES OR PRODUCTS OR THE SITES, OR ANY OF ADVERTISER’S CLIENTS’ SERVICES OR PRODUCTS.</p>
        <p>12. MODIFICATION OF SERVICES.</p>
        <p>12.1 MFSN may, at its discretion and without notice to you, modify or discontinue any of the Services or Content or a portion thereof. You agree that MFSN will not be liable to you or any third party for any modification or discontinuance of any of the Services or Content, or for any failure to notify you of same.</p>
        <p>12.2 MFSN may, at its sole discretion and without notice to you, replace the lead generation service provider or any of the providers of materials or services and transfer your membership and securely transfer any personal information that is necessary to enroll you or your clients in another MFSN web site and/or enroll you with another service provider of comparable materials and services.</p>
        <p>13. Changes to Prices and Terms and Discontinuance of Service.</p>
        <p>13.1 We reserve the right to change prices or to institute new charges for the lead generation services, or any portion thereof, at any time. Price changes and new charges will be on effective date of the change and to all new users of the Services from such effective date forward.</p>
        <p>13.2 We reserve the right at any time to modify, suspend, or discontinue the Site and the Lead Generation Services, or any part, version or feature thereof, in our sole discretion without prior notice to you. We will not notify you of any such material modifications to or suspensions or discontinuations of the Site or the Lead Generation Services. If you have a paid subscription to the Services and we modify, suspend or discontinue the Services due to no action on your part, when applicable, we may provide a pro rata refund of advance payments made. Your continued use of the Services or of the Site, or your failure to terminate your subscription, after any such changes, any modifications, change in service providers or after any other changes or charges have been made, will constitute your acceptance of those changes, modifications, and charges.</p>
        <p>14. Limitations of Liability. The Parties shall have no liability for any failure or delay resulting from any governmental action, fire, flood, insurrection, earthquake, power failure, national or global pandemic, riot, explosion, embargo, strikes whether legal or illegal, labor or material shortage, transportation interruption of any kind, work slowdown or any other condition affecting production or delivery in any manner beyond the control of that Party. Vendor acknowledges that Advertiser has entered into this Agreement in reliance upon the limitations of liability set forth herein and that the same is an essential basis of the bargain between the Parties.</p>
        <p>15. Non-Solicitation. During the term of this Agreement, and for a period of one (1) year thereafter, neither party shall directly or indirectly, by itself or in collaboration with any other person or entity, solicit, induce recruit or encourage, for its own benefit or the benefit of any other person or entity, any employee or officer of the other party or any employee or officer of an affiliated business of the other party, to leave his or her employment with the other party, whether as employees or independent contractors, other than through general advertisements for employment.</p>
        <p>16. Miscellaneous</p>
        <p>16.1 This Agreement constitutes the entire agreement between the Parties concerning the subject matter hereof and supersedes all prior and contemporaneous agreements and communications, whether oral or written, between the Parties relating to the subject matter hereof.</p>
        <p>16.2 This Agreement shall only be amended and modified by a writing executed by a duly authorized representative of each Party hereto.</p>
        <p>16.3 This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to principles of conflicts of law.</p>
        <p>16.4 Any notices under this Agreement shall be sent to the address of the other Party by facsimile, email, postal mail or express delivery service and deemed given upon receipt.Advertiser agrees that the Vendor may use as the address of record of the Advertiser the email,postal or express delivery service address provided previously to Vendor by Advertiser on any other Agreement between the Parties, and that the Advertiser warrants the address provided to Vendor is true and correct. Vendor also has the right to provide notice to Advertiser at any address of Advertiser obtained independently by the Vendor.</p>
        <p>16.5 The waiver of any breach or default of this Agreement will not constitute a waiver of any subsequent breach or default, and will not act to amend or negate the rights of the waiving Party.</p>
        <p>16.6 If any provision contained in this Agreement is determined to be invalid, illegal or unenforceable in any respect under any applicable law, then such provision will be severed and replaced with a new provision that most closely reflects the original intention of the Parties, and the remaining provisions of this Agreement shall be valid and shall remain in full force and effect to the extent permitted by law.</p>
        <p>16.7 This Agreement may be executed in any number of counterparts, each of which when so executed and delivered shall be deemed an original, and such counterparts together shall constitute one and the same instrument. For purposes hereof, a facsimile copy of this Agreement shall be deemed an original.</p>
        <p>16.8 In the event of any dispute, question, controversy or claim (collectively “Dispute”) arising out of or relating to this Agreement, the parties shall meet and attempt in good faith to satisfactorily resolve the Dispute. Any Dispute that the parties cannot resolve shall be settled by mediation in accordance with the rules of the American Arbitration Association. Any Dispute not resolved by mediation within sixty (60) days after submission by either party shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association then in force. The arbitration hearing shall take place in the state of Washington, which the parties agree is reasonable. The decision of the arbitrator shall be final and binding on the parties. The parties agree that each may bring any Dispute against the other only in his/her/its individual capacity and not as a plaintiff or class member in any purported class or representative proceeding. The parties hereby voluntarily, knowingly, irrevocably and unconditionally waive any right to have a jury participate in resolving any Dispute between or among them arising out of or in any way related to the Agreement.</p>
        <p>16.9 No action, regardless of form, arising in connection with this Agreement, may be brought against either Party more than one (1) year after the date such cause of action first arises.</p>
        <p>16.10 Time is expressly made of the essence of all the provisions of this Agreement.</p>
        <p>16.11 This Agreement shall inure to the benefit of and be binding upon the Parties hereto and their respective successors and permitted assigns. Either Party may assign its obligations under this Agreement upon giving written notice to the other Party, except that neither Party shall assign its obligations under this Agreement to a competitor of the other Party without the other Party’s prior written consent.</p>
     
            <p><strong>IN WITNESS WHEREOF</strong>, the Parties have executed this Agreement effective as of the date first set forth above.</p>
        <div class="footerCredit"> 
            <div class="forMD-12" style="display: flex; flex-wrap: wrap;margin-top:2px;">
                <div class="forMD-6"  style=" flex: 1; min-width: 65%;">
                    <p style="margin: 0; padding: 2px;"><strong> Advertiser: ${advertiser.adverstise}</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Signature: ${advertiser.signature}</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Print Name: ${advertiser.printName}</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Title: ${advertiser.title}</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Date: ${advertiser.date} </strong></p>
                </div>
                <div class="forMD-6" style=" flex: 1; min-width: 35%;">
                    <p style="margin: 0; padding: 2px;"><strong> Vendor: MyFreeScoreNow Inc.</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Signature: Bruce J. Cornelius</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Print Name: Bruce J. Cornelius</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Date: ${advertiser.date}</strong></p>
                    <p style="margin: 0; padding: 2px;"><strong> Title: President</strong></p>
                </div>
            </div>
      </div>   
    `;

function CreditTermsAndCondition(props) {
    const dispatch = useDispatch();
    const { user} = AuthUser();
    const inputRef = useRef();
    const [checked, setChecked] = useState(false);
    const { titleData, companyData, nameData } = props;
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const [advertiser, setAdvertiser] = useState({
        adverstise: companyData,
        signature: nameData,
        printName: nameData,
        title: titleData,
        date: ''
    });
    
    useEffect(() => {
        setAdvertiser(prevState => ({
            ...prevState,
            adverstise: companyData,
            signature: nameData,
            printName: nameData,
            title: titleData || 'N/A'
        }));
    }, [companyData, nameData, titleData]);

    const [vendor, setVendor] = useState({
        signature: 'Bruce J. Cornelius',
        printName: 'Bruce J. Cornelius',
        title: 'President',
        date: ''
      });

    useEffect(() => {
        const today = new Date();
        const options = { timeZone: 'America/Los_Angeles', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const [
          { value: month },,
          { value: day },,
          { value: year }
        ] = formatter.formatToParts(today);
    
        const formattedDate = `${month}/${day}/${year}`;
    setVendor(prevVendor => ({
        ...prevVendor,
        date: formattedDate
    }));

    setAdvertiser(prevAdvertiser => ({
        ...prevAdvertiser,
        adverstise: props.companyData,
        signature: props.nameData,
        printName: props.nameData,
        title: props.titleData,
        date:formattedDate
    }))
    }, []);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const AcceptCondition = async() => {
        handleFreezStart();
        if (checked) {
            const apiUrl = '/updateSnapshotTerms';
            const args = {
                status:2,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if (res.message == 'Information already Updated.')
                {
                    handleFreezStop();
                    swal({
                        title: "Success",
                        text: "Terms and Conditions Already Accepted",
                        icon: "success",
                        button: "Ok",
                    }).then(() => {
                        window.location.href='/affiliate/creditsnapshot'
                    });
                }else if (res.success === true) {
                    handleFreezStop();
                    swal({
                        title: "Success",
                        text: "Here we go for your Lead Generation Program",
                        icon: "success",
                        button: "Ok",
                    }).then(() => {
                        window.location.href='/affiliate/creditsnapshot'
                    });
                } else {
                    handleFreezStop();
                }
            }else{
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
            }
        } else {
            handleFreezStop();
            swal({
                title: "Failed",
                text: "Please select terms and condition checkbox",
                icon: "info",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef.current?.focus();

                }
            });
        }
    };

    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Typography color="primary" level="h4"> Terms and Conditions - Lead Generation Service</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Box
                        sx={{
                        width: '100%',
                        textAlign: 'justify',
                        '& div': {                          
                            padding: '1px 0',
                        },
                        '& p': {
                            marginBottom: '0px',
                        },
                        }}
                        dangerouslySetInnerHTML={{ __html: exampleHTML(advertiser) }}
                    />
                </Grid>
                <div className='footerCreditAccept'>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <FormControl error={!checked ? true : false} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputRef={inputRef}
                                    name="termsAndConditions"
                                    color="primary"
                                />
                            }
                            label="I accept"
                        />
                        {!checked ? <><FormHelperText className='error'>{"Please select the checkbox and click the button below to accept terms and conditions."}</FormHelperText></> : null}
                    </FormControl>
                </Grid>
                {user.roles_id<=3?
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                    <Button color="success" variant="contained" size="medium"  onClick={AcceptCondition}>I ACCEPT THE TERMS & CONDITIONS</Button>
                   
                </Grid>
   :null }
   </div>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
export default CreditTermsAndCondition;