import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Alert, OutlinedInput, Radio, RadioGroup, FormControlLabel, Tab, Tabs, ListItemIcon, Checkbox, ListItemText, 
  Grid, Button, Dialog, DialogActions, FormControl, Box, InputLabel, Select, MenuItem, DialogContent, DialogTitle, TextField, 
  Typography, Tooltip,Paper, Stack, FormHelperText,ListItem,List,IconButton,InputAdornment} from '@mui/material';
import {format,subDays} from "date-fns";
import MaterialReactTable from 'material-react-table';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Moment from 'moment';
import Loader from '../Loader/Loader';
import AiFillPhone from '@mui/icons-material/Phone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../Auth/AuthUser';
import { useLocation } from 'react-router-dom';
import { MenuProps, useStyles } from "../SuspendedMembers/utils";
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
import { fetchData, postData } from '../../redux/apiSlice';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {DateRangePicker,createStaticRanges} from "react-date-range";
import DialogContentText from '@mui/material/DialogContentText';
import {openOrFocusWindow,calendarFilter,useDateSelectionRange,getInitialSelectionRange } from  '../CommonFormula';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/joy/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Badge from '@mui/material/Badge';
import _ from 'underscore';
import InputMask from 'react-input-mask';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const RequestFormInitialValues1 = {
  otp: '',

};


const RequestFormMemberValues = {
  memberId : '',
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >


      {value === index && (

        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );

}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,

};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AbandonedMemberList(props) {
  //style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  //style

  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [openAuthUser, setopenAuthUser] = React.useState(false);
  const [AbandonedMember, setAbandonedMember] = useState([]);
  const [AbandonedDeclinedMember, setAbandonedMemberDeclined] = useState([]);
  const [AbandonedDeferredMember, setAbandonedMemberDeferred] = useState([]);
  const [adminList, setAdminList] = React.useState([]);
  const [reasonList, setReason] = React.useState([]);
  const [assignedTo, setAssignedTo] = useState('all');
  const [assignedOpen, setAssignedOpen] = useState(false);
  const [assignedToAction, setAssignedToAction] = useState('Action');
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { http, user } = AuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [token, setToken] = useState();
  const [dToken, setDoken] = useState();
  const [rutoken, setRutoken] = useState();
  const [smfaToken, setSmfaToken] = useState();
  const [rtoken, setRtoken] = useState();
  const [ssn, setssn] = useState();
  const companyId = queryParams.get('companyId');
  const rowsSeleted = Object.keys(rowSelection);
  const [emailId, setEmailId] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [phoneNo, setphoneNo] = useState('');
  const [aid, setAid] = useState('');
  const [pid, setPid] = useState('');
  const [abandonedStep, setabandonedStep] = useState(0);
  const [enrollmentStatus, setenrollmentstatus] = useState(0);
  const [completedStatus, setcompletedStatus] = useState(0);
  const [isDVError, setIsDVError] = useState(false);
  const [DispositionList, setDisposition] = useState('');
  const [loginLink, setLoginLink] = useState(0);
  const [memberIdflag, setmemberIdflag] = useState(0);
  const [newTabValue, setTabValue] = useState(0);
  const [ActiveDisposition, setActiveDisposition] = useState([])
  const [testId, setTestId] = useState('');
  const [brithDate, setDate] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [currentFollowUpDate, setFollowUpDate] = useState("");
  const [sourceStatus, setsourceStatus] = useState('');
  const [abandonedMemberFilterData, setAbandonedMemberFilterData] = useState([])
  const [abandonedDecliendMemberFilterData, setAbandonedDecliendMemberFilterData] = useState([])
  const [abandonedDeferredMemberFilterData, setAbandonedDeferredMemberFilterData] = useState([])
  const[assignedToSource,setAssignedToSource]= useState('All') 
  const [callType ,setCallType] = useState('');
  const [disposition ,setDispositions] = useState('');
  const [reason ,setReasons] = useState('');
  const [contactType ,setContactType] = useState('');
  const [submitEnable, setSubmitEnable] = useState(false);
  const [DispositionValue, setDispositionValue] = useState(0);
  const [errors1, setErrors] = useState({});
  const [timeLeft, setTimeLeft] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [notesState, setNotesState] = useState(0);
  const [verificationfail, setVerificationFail] = useState(false);
  const [BlackboxObj, setBlackboxObj] = useState([]);
  const [openConfrimationBox, setOpenConfirmationBox] = useState(false);
  const [emailToAuthenticate, setEmailToAuthenticate] = useState('');
  const [abandonLog, setAbandonLog] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [selectedDisposition, setSelectedDisposition] = useState(false);
  const [abortFollowUpDate, setAbortFollowUpDate] = useState("");
  const [abortFollowUpTime, setAbortFollowUpTime] = useState("");
  const [search, setSearch] = useState(null);
  const [listData,setListData] = useState();
  const handleAbandonOpen = () => setOpenLog(true);
  const handleAbandonClose = () => setOpenLog(false);
  const [searchingForMember, setSearchingForMember] = useState(false);
  const [searchRecord, setSearchRecord] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } =useDateSelectionRange(getInitialSelectionRange('abandon'));
  let preferenceId='abandonedMemberActive';
  const RequestFormInitialValueslaststep = {
    contactType: 'Outbound',
    callType: 'Abandoned Member List',
    disposition: '',
    reason: '',
  }
  let RequestFormInitialValues = {};
  if (completedStatus == 2) {
    RequestFormInitialValues = {
      ssn: '',
      dob: dayjs(currentFollowUpDate),
    };
  } else {
    RequestFormInitialValues = {
      ssn: '',
      dob: '',
    };
  }

  
const RequestassignToMe = {
    assignReason: '',

}
const RequestabortFirstStep = {
     contactType: 'Outbound',
      callType: 'Abandoned Member List',
      disposition: '',
      note: '',
      reason: '',
      custMemberId:'',
      followupRequired: selectedDisposition,
      followupDate: dayjs(abortFollowUpDate),
      followupTime: (abortFollowUpTime), 
}
 
  const [value, setValue] = React.useState(0);
  let tabValue = 0;
  let assignedToValue = 'all';

  let callTypes = '';
  let abortStatus = 1;
  let source = '';


  const tablePreferencesArray = {

  }
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
        setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
}, [rowSelection]);

useEffect(() => {
  tablePreferences();
}, [rowSelection,value]);

useEffect(()=>{  
  saveTablePreferences();
},[columnVisibility])
////////////////////////


const tablePreferences = async() => {  
  // check tab value 
  if (value === 0) {
 preferenceId = 'abandonedMemberActive';
} else if (value === 1) {
   preferenceId = 'abandonedMemberDeclined';
} else if (value === 2) {
   preferenceId = 'abandonedMemberDeferred';
}
  const apiUrl = `/fetchTablePreferences/${preferenceId}`;
  const res = await dispatch(fetchData(apiUrl)).unwrap();
  if(res) {
        if (Object.values(res).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.preferences)));
        }
  }
}

const saveTablePreferences = async() => {
  if (Object.values(columnVisibility).length > 0) {
    const apiUrl = '/saveTablePreferences';
    const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: value === 0 ? 'abandonedMemberActive' : value === 1 ? 'abandonedMemberDeclined' : value === 2 ? 'abandonedMemberDeferred' : ''
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
  } 
}


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  const handleTabClick = (event, newValue) => {
    tabValue = newValue;
    setTabValue(newValue);
    assignedToValue = 'all';
    setAssignedTo('all')
    setAssignedToSource('All')
    getAbandonedMember();
   
  };

  useEffect(() => {
    getAbandonedMember();
    // if (document.readyState !== 'loading') {
    //   if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
    //       var BlackboxObj1 = window.IGLOO.getBlackbox();
    //       setBlackboxObj(BlackboxObj1);
    //   }
    // } else {
    //     document.addEventListener('DOMContentLoaded', function () {
    //         if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
    //             var BlackboxObj1 = window.IGLOO.getBlackbox();
    //             setBlackboxObj(BlackboxObj1);
    //         }
    //     });
    // }
  }, [])

  useEffect(() => {
    // If timer shouldn't start, exit early
    if (!startTimer) return;

    // Exit early if the timer is at 0
    if (timeLeft <= 0) return;

    // Setup the interval to decrement the timer every second
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    // Clear the interval when the component is unmounted or the timer reaches 0
    return () => clearInterval(intervalId);
  }, [timeLeft, startTimer]);

  const sideBar = calendarFilter();

  const staticRanges = [
  // ...defaultStaticRanges,
  ...createStaticRanges(sideBar)
  ];
    
  const handleSelectionRange = () => {
    tabValue = value;
  }

  const getAbandonedMember = async () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    handleFreezStart();
    const apiUrl = '/abandonedMember';
    const args = {
      assignto: assignedToValue,
      tabValue: tabValue,
      fromDate: startDate,
      toDate: endDate
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    handleFreezStop();
    if(res.success == true){

      setAbandonedMember(res.active);
      setAbandonedMemberFilterData(res.active)
      setAbandonedMemberDeclined(res.declined);
      setAbandonedDecliendMemberFilterData(res.declined);
      setAbandonedMemberDeferred(res.deferred);
      setAbandonedDeferredMemberFilterData(res.deferred);
      let filteredData;  
    
      switch (newTabValue) {
        case 0:
          filteredData = filterAssignData(res.active, assignedToSource);
          setAbandonedMember(filteredData);
          break;
        case 1:
          filteredData = filterAssignData(res.declined, assignedToSource);  
          setAbandonedMemberDeclined(filteredData);
          break;
        default:
          filteredData = filterAssignData(res.deferred, assignedToSource);
          setAbandonedMemberDeferred(filteredData);
      }
    }else{
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
      setTimeout(function () {
        setErrorMessage('');
      }, 8000);
    }
  }

  const resetData = () => {
		// setData();
		document.getElementById("globalSearchList").style.display = "none";
	}

  const clearSearchValue = () => {
		setSearch("");
		document.getElementById("globalSearchList").style.display = "none";
	}

  const globalSearch = async ()=>{
    handleFreezStart();
		const apiUrl = '/searchAbandonedMember?search='+search;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
			if (res.success === true){
				setListData(res.data);
				document.getElementById("globalSearchList").style.display = "block";
			}else{
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
			}
			
		}
    handleFreezStop(); 
	}

  const AbandonedMembersnapshotEnrollments2 = async (email, fName, lName, phoneNo, completedStatus, aid, pid, enrollmentstatus, testId, dvError) => {
    // setSearchingForMember(true);
    // setSearchRecord(row);
    const newDate = new Date();
    const myDate = new Date();
    myDate.setDate(myDate.getDate());
    var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY');
    var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY');
    setCurrentDate(cDate);
    setFollowUpDate(todaysDate);

    if (completedStatus == 2 || completedStatus == 3) {
      setsourceStatus('MFSN');
      source = 'MFSN';
    } else {
      setsourceStatus('CD');
      source = 'CD';
      
    }
    setEmailId(email);
    setfirstName(fName);
    setlastName(lName);
    setphoneNo(phoneNo);
    setAid(aid);
    setPid(pid);
    setcompletedStatus(completedStatus);
    setIsDVError(dvError == 1?true:false);
    setenrollmentstatus(enrollmentstatus);
    setTestId(testId);
    setLoginLink(0);
    setNotesState(0);
    setOpen7(false);
    console.log("searchingForMember:"+searchingForMember);

    handleFreezStart();
    const apiUrl = '/checkAssignAgent';
    const args = {
      email: email,
      firstName: fName,
      mobile: phoneNo,
      sourceStatus:source,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      handleFreezStop();
      if (res.message == 'Member assign') {
        setSearchOpen(true);
        setabandonedStep(1);
      } else {
        if (res.message == 'ssn') {
          if (enrollmentstatus == 'Completed' && ((completedStatus == 2 && dvError == false) || completedStatus == '')) {
            setOpen6(true);
          }
          else if (completedStatus == 2 && dvError == true) {
            setOpenDVFail(true);
          } else if (enrollmentstatus == 'Completed' || completedStatus == 3) {
            setOpen7(true);
          }else {
            setOpen3(true);
          }
        }
      }
    } else {
      setSearchOpen(true);
    }
    setSearchingForMember(false);
    clearSearchValue();
  };

  const handleSearchAction = async(row) => {
    // setTimeout(function () {
      setSearchingForMember(true);
      setSearchRecord(row);
      AbandonedMembersnapshotEnrollments2(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments ? row.snapshot_enrollments.enrolled_status:null, row.is_test, row.dv_error);
    // }, 4000);
   
  }

  const filterAssignData = (data, sourceValue) => {
    return data.filter((item) => {
      if (sourceValue === 'MFSN') {
        return item.completed == 2 || item.completed == 3;
      } else if (sourceValue === 'CD') {
        return item.completed == 'YES' || item.completed == '';
      } else {
        return true;
      }
    });
  };
  const fetchAdminList = async () => {
    const apiUrl = '/fetchOnlyAdmins'; // Replace with your API endpoint
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      setAdminList(res);
    }
  }

  const handlecallType = async (callType, isAbandoned) => {

    if (callType == undefined) {
      callTypes = "Abandoned Member List";
    } else {
      callTypes = callType;
    }
    const apiUrl = '/getdisposition';
    const args = {
      callType: callTypes,
      abortStatus: 1,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setDisposition(res.disposition);
    }

  }
  const handleMarkAscallType = async (callType) => {

    if (callType == undefined) {
      callTypes = "Abandoned Member List";
    } else {
      callTypes = callType;
    }
    const apiUrl = '/getdisposition';
    const args = {
      callType: callTypes,
      abortStatus: 0,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setActiveDisposition(res.disposition);
    }

  }

  const handledisposition = async (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    if(event == '160'){
     setDispositionValue(1);
    }else{
      setDispositionValue(0);
    }
    const apiUrl = '/getAbandondedReason';
    const args = {
      dispositionId: event,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    setReason(res.reason);
  };

  useEffect(() => {
    if(user.roles_id < 3 ){
      fetchAdminList();
      handlecallType();
      handleMarkAscallType();
    }else{
      navigate('/login');
    }
    
  }, []);

  const navigate = useNavigate();

 
  const handleCloseAssigned = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setAssignedOpen(false);
    setAssignedToAction('Action');
  };

  useEffect(() => {
  }, [rowSelection]);

  //select dropdown
  const [open, setOpen] = useState(false);
  {/* AbandonedMember Resistant Process start here */ }
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [openDVFail, setOpenDVFail] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [OpenAssignToMe, setOpenAssignToMe] = useState(false);

  const [memberIdPopup,setmemberIdPopup] = useState(false);

  const AbandonedMembersnapshotEnrollments = async (email, fName, lName, phoneNo, completedStatus, aid, pid, enrollmentstatus, testId, dvError) => {
    const newDate = new Date();
    const myDate = new Date();
    myDate.setDate(myDate.getDate());
    var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY');
    var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY');
    setCurrentDate(cDate);
    setFollowUpDate(todaysDate);

    if (completedStatus == 2 || completedStatus == 3) {
      setsourceStatus('MFSN');
      source = 'MFSN';
    } else {
      setsourceStatus('CD');
      source = 'CD';
      
    }
    setEmailId(email);
    setfirstName(fName);
    setlastName(lName);
    setphoneNo(phoneNo);
    setAid(aid);
    setPid(pid);
    setcompletedStatus(completedStatus);
    setIsDVError(dvError == 1?true:false);
    setenrollmentstatus(enrollmentstatus);
    setTestId(testId);
    setLoginLink(0);
    setNotesState(0);
    setOpen7(false);
    handleFreezStart();
    const apiUrl = '/checkAssignAgent';
    const args = {
      email: email,
      firstName: fName,
      mobile: phoneNo,
      sourceStatus:source,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      handleFreezStop();
      console.log("isDVError:"+dvError);
      if (res.message == 'Member assign') {
        setOpen2(true);
        setabandonedStep(1);
      } else {
        if (res.message == 'ssn') {
          if (enrollmentstatus == 'Completed' && ((completedStatus == 2 && dvError == false) || completedStatus == '')) {
            setOpen6(true);
          }
          else if (completedStatus == 2 && dvError == true) {
            setOpenDVFail(true);
          } else if (enrollmentstatus == 'Completed' || completedStatus == 3) {
            setOpen7(true);
          }else {
            setOpen3(true);
          }
        }
      }
    } else {
      handleFreezStop();
      tabValue = value;
      getAbandonedMember();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
      setTimeout(function () {
        setErrorMessage('');
      }, 9000);
    }
  };

  const AbandonedMemberFirst = async (email, fName, lName, phoneNo, completedStatus, aid, pid, testId, dvError) => {
    
    setEmailId(email);
    setfirstName(fName);
    setlastName(lName);
    setphoneNo(phoneNo);
    setAid(aid);
    setPid(pid);
    setcompletedStatus(completedStatus);
    setIsDVError(dvError == 1?true:false);
    setNotesState(0);
    setTestId(testId);
    handleFreezStart();
    setLoginLink(0);
    
    if (completedStatus == 2 || completedStatus == 3) {
      setsourceStatus('MFSN');
      source = 'MFSN';
    } else {
      setsourceStatus('CD');
      source = 'CD';
    }

    const apiUrl = '/checkAssignAgent';
    const args = {
      email: email,
      firstName: fName,
      mobile: phoneNo,
      sourceStatus:source,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {

      handleFreezStop();
      console.log("isDVError:"+dvError);
      if (res.message == 'Member assign') {
        setOpen2(true);
        setabandonedStep(1);
      } else {
        if (res.message == 'ssn') {
          if (enrollmentStatus == 'Completed' && ((completedStatus == 2 && dvError == false) || completedStatus == '')) {
            setOpen6(true);
          }
          else if (completedStatus == 2 && dvError == true) {
            setOpenDVFail(true);
          } else if (enrollmentStatus == 'Completed' || completedStatus == 3 || completedStatus == 'YES') {
            setOpen7(true);
          }else {
            setOpen3(true);
          }
        }

      }
    } else {
      handleFreezStop();
      tabValue = value;
      getAbandonedMember();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })

      setTimeout(function () {
        setErrorMessage('');
      }, 9000);
    }
  };
  const AbandonedMemberFirstClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen2(false);
  };

  const searchClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setSearchOpen(false);
  };
  ///////////
  const memberIdPopupClose = () =>{
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setmemberIdPopup(false);
  }
  const AbandonedMemberSecond = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen2(false);
    setSearchOpen(false);
    setOpenAssignToMe(false);
    handleFreezStart();
    if (completedStatus == 2 || completedStatus == 3) {
      setsourceStatus('MFSN');
    } else {
      setsourceStatus('CD');
    }
    
    const apiUrl = '/assignAgent';
    let args = {};
    if(notesState == 0){
      args = {
        step: abandonedStep,
        email: emailId,
        firstName: firstName,
        mobile: phoneNo,
        notes:'',
        // blackbox:BlackboxObj.blackbox,
        // isDVError:isDVError
      }
    }else{
      args = {
        step: abandonedStep,
        email: emailId,
        firstName: firstName,
        mobile: phoneNo,
        notes:values.assignReason,
        // blackbox:BlackboxObj.blackbox,
        // isDVError:isDVError
      }

    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      setabandonedStep(2);
      handleFreezStop();
      tabValue = value;
      getAbandonedMember();
      console.log("isDVError:"+isDVError);
      if (enrollmentStatus == 'Completed' && ((completedStatus == 2 && isDVError == false) || completedStatus == '')) {
        setOpen6(true);
      }
      else if (completedStatus == 2 && isDVError == true) {
        setOpenDVFail(true);
      }
      else if (enrollmentStatus == 'Completed' || completedStatus == 3 || completedStatus == 'YES') {
        setOpen7(true);
      } else {
        setOpen3(true);
      }
    } else {
      getAbandonedMember();
      handleFreezStop();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
      
    }

  };
  const AbandonedMemberSecondClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen3(false);
  };
  ///////////
  const AbandonedMemberAbort = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setVerificationFail(false);
    setOpenDVFail(false);
    const newDate = new Date();
    const myDate = new Date();
    newDate.setDate(newDate.getDate());
    newDate.setHours(newDate.getHours() + 1);
    myDate.setDate(myDate.getDate());
    var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY');
    var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
    var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('HH:mm:ss');
    setAbortFollowUpDate(todaysDate);
    setAbortFollowUpTime(todaysTime);
    setOpen4(true);
  };
  const AbandonedMemberAbortClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen4(false);
    setSelectedDisposition(false);
  };
  ////////////
  const AbandonedGenerateOTP = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setssn(values.ssn);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);

    handleFreezStart();
    const apiUrl = '/aboundant-member-snapshot-enroll';
    let args = {};

    if (completedStatus == 2) {
      args = {
        ssn: values.ssn,
        mobile: phoneNo,
        email: emailId,
        step: 1,
      }
    } else {
      setDate(values.dob)
      args = {
        ssn: values.ssn,
        mobile: phoneNo,
        email: emailId,
        step: 1,
        cdregistrantFlag: true,
        dob: values.dob,
      }

    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    console.log("resp2",res);
    if (res.success == true) {
      if (res.data.step === 'VerifyPopup') {
        setStartTimer(true)
        setToken(res.data.utoken);
        setRutoken(res.data.rutoken)
        setSmfaToken(res.data.smfaToken)
        setDoken(res.data.dtoken)
        setRtoken(res.data.rtoken);
        setOpen5(true);
        tabValue = value;
        getAbandonedMember();
      }
    } else {
      if (res.success == false) {
        handleFreezStop();
        setVerificationFail(true);
        // if(res.data.errorByStichCredit == 'User Already Registered'){
        //   setOpen6(true);
        //   setabandonedStep(4);
        // }else{
        setopenAuthUser(false);
        if (res.data) {
          setOpen4(true);
          swal({
            title: "Error",
            text: res.data.errorByStichCredit,
            icon: "error",
            button: "Ok",
          })
        } else {
          swal({
            title: "Error",
            text:'Something went wrong, please try again later.',
            icon: "error",
            button: "Ok",
          })
        }
        setTimeout(function () {
          setErrorMessage('');
        }, 9000);
        
      }

    }
    handleFreezStop();
  };

  const resendOtp = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    handleFreezStart();
    setabandonedStep(3);
    const apiUrl = '/aboundant-member-snapshot-enroll';
    let args = {};
    if (completedStatus == 2) {
      args = {
        step: 2,
        mobile: phoneNo,
        ssn: ssn,
        email: emailId,
        utoken: token,
        dtoken: dToken,
        rutoken : rutoken,
        smfaToken : smfaToken,
        rtoken : rtoken,
        counter: 1,
      }
    } else {
      args = {
        step: 2,
        mobile: phoneNo,
        ssn: ssn,
        email: emailId,
        utoken: token,
        dtoken: dToken,
        rutoken : rutoken,
        smfaToken : smfaToken,
        rtoken : rtoken,
        counter: 1,
        cdregistrantFlag: true,
        dob: brithDate,
      }
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    console.log("resp1",res);
    if (res.success == true) {
      if (res.data.step === 'VerifyPopup') {
        setTimeLeft(60);
        setSuccessMessage('Link has been Resent Successfully');
        setTimeout(function () {
          setSuccessMessage('');
        }, 9000);
      }
      handleFreezStop();
      setabandonedStep(4);
      tabValue = value;
      getAbandonedMember();
      setOpen5(true);

    } else {
      if (res.data) {
        handleFreezStop();
        swal({
          title: "Error",
          text: res.data.errorByStichCredit,
          icon: "error",
          button: "Ok",
        })
      } else {
        handleFreezStop();
        swal({
          title: "Error",
          text: 'Something went wrong, please try again later.',
          icon: "error",
          button: "Ok",
        })
      }

      setTimeout(function () {
        setErrorMessage('');
      }, 9000);
    }
    handleFreezStop();
  };
  const AbandonedGenerateOTPClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen5(false);
  };

  /////////////////
  const abortFirstStep = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    } 
    if(DispositionValue == 1 && values.custMemberId == '' ){
      errors1.custMemberId = 'Please enter Member Id';
      setErrors(errors1);
      return false;
    }else if (DispositionValue == 1 && values.custMemberId.length != 7) {
      errors1.custMemberId = 'Min & Max 7 digits required.';
      setErrors(errors1);
      return false;
    }else{
      errors1.custMemberId = '';
      setErrors(errors1);
    }
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setmemberIdPopup(false);
    handleFreezStart();
    const date = new Date(values.followupDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    var mydate = year+"/"+month+"/"+day;
    const apiUrl = '/abandonedActivityLog';
    const args = {
      step: abandonedStep,
      email: emailId,
      callType: values.callType,
      disposition: values.disposition,
      note: values.note,
      reason: values.reason,
      contactType: values.contactType,
      mobile: phoneNo,
      completedStatus: completedStatus,
      enrollmentStatus: enrollmentStatus,
      testId: testId,
      sourceStatus:sourceStatus,
      memberId:values.custMemberId,
      dvError: isDVError,
      followupDate: mydate,
      followupTime: values.followupTime,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      handleFreezStop();
      if (res.flag == 1) {
        setOpen8(true);
        
      } else {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })

        tabValue = value;
        getAbandonedMember();
        setTimeout(function () {
          setSuccessMessage('');
        }, 9000);
      }
    } else {
      handleFreezStop();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
      setTimeout(function () {
        setSuccessMessage('');
      }, 9000);
    }


  }
  const VerifyAuthentication = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setmemberIdPopup(false);
    handleFreezStart();
    setabandonedStep(3);
    const apiUrl = '/aboundant-member-snapshot-enroll';
    let args = {};
    if (completedStatus == 2) {
      args = {
        step: "VerifyPopup",
        mobile: phoneNo,
        ssn: ssn,
        email: emailId,
        utoken: token,
        rutoken : rutoken,
        smfaToken : smfaToken,
      }
    } else {
      args = {
        step: "VerifyPopup",
        mobile: phoneNo,
        ssn: ssn,
        email: emailId,
        utoken: token,
        cdregistrantFlag: true,
        dob: brithDate,
        rutoken : rutoken,
        smfaToken : smfaToken,
      }
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      handleFreezStop();
      setabandonedStep(4);
      tabValue = value;
      getAbandonedMember();
      setOpen6(true);

    } else {
      if (res.data) {
        if (res.step == 'OTP') {
          setabandonedStep(4);
          setOpen5(true);
        }
        handleFreezStop();
        swal({
          title: "Error",
          text: res.data.errorByStichCredit,
          icon: "error",
          button: "Ok",
        })
      } else {
        handleFreezStop();
        swal({
          title: "Error",
          text: 'Something went wrong, please try again later.',
          icon: "error",
          button: "Ok",
        })
      }

      setTimeout(function () {
        setErrorMessage('');
      }, 9000);
    }
    handleFreezStop();
  };
  const VerifyAuthenticationClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen6(false);
  };
  ////////////////
  const AuthenticationSuccess = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen8(false);
    setmemberIdPopup(false);
    handleFreezStart();

    const apiUrl = '/abandonedVerificationLog';
    const args = {
      step: abandonedStep,
      email: emailId,
      verify: 1,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      setabandonedStep(5);
      tabValue = value;
      setOpen7(true);
      handleFreezStop();
    } else {
      handleFreezStop();
    }
  };
  const AuthenticationSuccessClose = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpen7(false);
  };
  ///////////////


  const PaymentSuccess = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    if (loginLink == 1) {
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
      setOpen6(false);
      setOpen7(false);
      setmemberIdPopup(false);
      handleFreezStart();
      const apiUrl = '/abandonedVerificationLog';
      const args = {
        step: abandonedStep,
        email: emailId,
        verify: 0,
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();

      if (res.success == true) {
        setabandonedStep(6);
        tabValue = value;

        setOpen8(true);
        handleFreezStop();

      } else {
        handleFreezStop();
      }

    } else {
      setErrorMessage('please send login link.');
      setTimeout(function () {
        setErrorMessage('');
      }, 9000);

    }



  };

  const manuallyAuthenticate = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
      
    setOpenDVFail(false);
    handleFreezStart();
    const apiUrl = '/manuallyAuthenticate';
    const args = {
      step: abandonedStep,
      email: emailId,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();

    if (res.success == true) {
      setOpen7(true);
      setcompletedStatus(3);
      handleFreezStop();

    } else {
      handleFreezStop();
    }

  };
  const callToOpenManuallyAuthenticateModal = async (email) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setEmailToAuthenticate(email);  
    setOpenConfirmationBox(true);
  };

  const directlyAuthenticate = async() => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
      
    setOpenConfirmationBox(false);
    handleFreezStart();
    const apiUrl = '/manuallyAuthenticate';
    const args = {
      email: emailToAuthenticate,
      step: abandonedStep,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();

    if (res.success == true) {
      handleFreezStop();
      swal({
        title: "Success",
        text: res.message,
        icon: "success",
        button: "Ok",
      })
    } else {
      handleFreezStop();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
    }
    tabValue = value;
    getAbandonedMember();
  }

  const PaymentSuccessClose = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setCallType(values.callType);
    setDispositions(values.disposition);
    setReasons(values.reason);
    setContactType(values.contactType);
    setmemberIdflag(0);

    setOpen8(false);
    handleFreezStart();
    const apiUrl = '/markAsDone';
    let args = {};
    if(memberIdflag == 0){
       args = {
        step: abandonedStep,
        email: emailId,
        mobile: phoneNo,
        callType: values.callType,
        disposition: values.disposition,
        reason: values.reason,
        contactType: values.contactType,
        completedStatus: completedStatus,
        enrollmentStatus: enrollmentStatus,
        testId: testId,
        sourceStatus:sourceStatus,
        flag: 0,
      }
    }else{
      setmemberIdPopup(false);
       args = {
        step: abandonedStep,
        email: emailId,
        memberId: values.memberId,
        mobile: phoneNo,
        callType: callType,
        disposition: disposition,
        reason: reason,
        contactType: contactType,
        completedStatus: completedStatus,
        enrollmentStatus: enrollmentStatus,
        testId: testId,
        sourceStatus:sourceStatus,
        flag:1,
      }
      
    }
   
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
      if(res.flag == 1){
        handleFreezStop();
        getAbandonedMember();
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        setTimeout(function () {
          setSuccessMessage('');
        }, 9000);
        tabValue = value;
        getAbandonedMember();
      }else{
        setmemberIdPopup(true);
        setmemberIdflag(1);
      }
     
    } else {
      handleFreezStop();
      swal({
        title: "Error",
        text: res.message,
        icon: "error",
        button: "Ok",
      })
      setTimeout(function () {
        setErrorMessage('');
      }, 9000);
    }
  };

  const sendLink = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setLoginLink(1);
    setOpen7(false);
    handleFreezStart();
    const apiUrl = '/sendAbandonedLink';
    const args = {
      step: abandonedStep,
      email: emailId,
      firstName: firstName,
      mobile: phoneNo,
      aid: aid,
      pid: pid,

    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    handleFreezStop();
    setOpen7(true);

    setSuccessMessage('Link Sent Successfully');
    setTimeout(function () {
      setSuccessMessage('');
    }, 6000);

  };

  {/* AbandonedMember Resistant Process end here */ }

 
  const handleMemberId = (event) => {
    if(event == ''){
      errors1.custMemberId = 'Please enter Member Id';
      setErrors(errors1);
    }else{
      errors1.custMemberId = '';
      setErrors(errors1);
    }
  }

  const handleClickOpenAssigned = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setAssignedOpen(true);
  };

  const handleActionChange = (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    if (rowsSeleted.length > 0) {
      setAssignedToAction(event.target.value);
      if (event.target.value === "assignToStaff") {
        handleClickOpenAssigned();
      } else if (event.target.value === "assignToNone") {
        setIsLoading(true);
        http.post('/assignToNoneSignupMembers', {
          memberId: rowsSeleted,
        })
          .then((res) => {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              button: "Ok",
            })

            tabValue = value;
            getAbandonedMember();

            setSelectedID([]);
            setSelected([]);
            setRowSelection([]);
            setAssignedToAction('Action');
            setAssignedToSource('All');
            setAssignedTo('all')
            setIsLoading(false);
          });
      }
    } else {
      alert('Please select at least one lead');
    }
  }


  //Start Request Add Missing Member
  //Schema for formik form
  const RequestFormSchema = Yup.object().shape({
    ssn: Yup.string()
      .min(9, 'Must be 9 digits.')
      .max(9, 'Must be 9 digits.')
      .required('Please enter ssn'),

    dob: Yup.string()
      .required('Please enter dob'),
  })

  const RequestFormSchemaabortFirstStep = Yup.object().shape({
    disposition: Yup.string()
      .required('Please enter disposition'),
    note: Yup.string()
      .required('Please enter note'),
    reason: Yup.string()
      .required('Please enter reason'), 
  })
  const RequestFormSchema1 = Yup.object().shape({
   
  })

  const RequestFormSchemalaststep = Yup.object().shape({
    disposition: Yup.string()
      .required('Please enter disposition'),
    reason: Yup.string()
      .required('Please enter reason'),
  })

  const assignedToStaffSchema = Yup.object().shape({
    staffId: Yup.array()
      .required('Please select User to Assign.'),

  });

  const validationassignToMe = Yup.object().shape({
    assignReason: Yup.string()
      .required('Please enter reason'),

  });

  const assignedToMeMembers = async (values) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setOpenAssignToMe(false);
    handleFreezStart();
    const apiUrl = '/assignedToMeMember';
    const args = {
      email: emailId,
      reason: values.assignReason,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if(res.success == true){
        handleFreezStop();
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        tabValue = value;
        getAbandonedMember();
        
      }else{
        handleFreezStop();
        swal({
          title: "error",
          text: res.message,
          icon: "error",
          button: "Ok",
        })
      
       
      }

  };


    
  
//member Id validation 
  const RequestFormSchemaMember = Yup.object().shape({
    memberId: Yup.string()
      .required('Please enter Member Id')
      .min(7, 'At least 7 digits required')
      .max(7, 'Maximum 7 digits'),
  })
  // Member Id Validation End

  const handleSelectChange = (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    const selectedValue = event.target.value; // Get the selected value from event
    setAssignedTo(selectedValue); // Set the selected value to state  
    assignedToValue = selectedValue;
    tabValue = newTabValue;
    getAbandonedMember();

  }
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const isAllSelected =
    adminList.length > 0 && selected.length === adminList.length;
  const handleChange2 = async (event, obj) => {
    const {
      target: { value }
    } = event;
    const extractedArray = adminList.map(arr => arr.id);
    setSelectedID(value);
    if (value[value.length - 1] === "all" || value[0] === "all") {
      setSelected(selected.length === adminList.length ? [] : extractedArray);
      setSelectedID(extractedArray);
      return;
    }
    setSelected(value);

  };
  const SourceOptions = [
    'MFSN',
    'CD',
  ];

  const fetchAbandonedMemberLog = async (id) => {
    handleFreezStart();
    const apiUrl = '/getAbandonedMemberLog/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res.success == false){
      handleFreezStop();
      setAbandonLog([]);
      swal({
        title: res.message,
        // text: res.message,
        icon: "info",
        button: "Ok",
      })
    }else{
      handleFreezStop();
      setAbandonLog(res.data);
      handleAbandonOpen();
    }
  };

  const handleClickOpen = (email, fName, lName, phoneNo, completedStatus, aid, pid, testId, dvError) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
      setEmailId(email);
      setfirstName(fName);
      setlastName(lName);
      setphoneNo(phoneNo);
      setAid(aid);
      setPid(pid);
      setcompletedStatus(completedStatus);
      setIsDVError(dvError == 1?true:false);
      setNotesState(1);
      setTestId(testId);
      setLoginLink(0);
		setOpenAssignToMe(true);
	};
  const handleCloseAssignToMe = () => {
    setOpenAssignToMe(false);
  };

  
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
        accessorKey: 'Email',
        header: 'Email',
        enablePinning: true,
        minSize: 230,
        maxSize: 400,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => `${row.aid ? row.aid.trim() : ''}`,
        accessorKey: 'AID',
        header: 'AID',
        enablePinning: true,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'Name',
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
        header: 'Name',
        size: 50,
        enableColumnFilter: false,
      },
      
      {
        accessorKey: 'phone_number',
        accessorFn: (row) => `${row.phone_number ? formatPhoneNumber(row.phone_number.trim()) : 'N/A'}`,
        filterVariant: 'range',
        id: 'phoneNo',
        header: 'Phone No.',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          row.original.phone_number ?
            <div>
              {formatPhoneNumber(row.original.phone_number.trim())}
              &nbsp;&nbsp;
              <span>
                {row.original.current_hours != '' ?
                  row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                    <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                  : ''
                }
              </span>
              <Tooltip title="Dial" arrow>
                  <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_number); }} >
                    <AiFillPhone color="primary" />
                  </a>
              </Tooltip>
            </div> : 'N/A'
        ),
      },
      {
        accessorKey: 'Enrollment Date',
        accessorFn: (row) => `${row.created_at ? row.created_at : ''}`,
        header: 'Enrollment Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Updated Date',
        accessorFn: (row) => `${row.updated_at ? row.updated_at : ''}`,
        header: 'Last Updated Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted Date',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.updated_at : ''}`,
        header: 'Last Contacted Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted By',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.user? row.member_disposition.user.name : 'N/A': 'N/A'}`,
        header: 'Last Contacted By',
        size: 50,
      },
      {
        accessorKey: 'Notes',
        accessorFn: (data) => `${data.member_disposition ? data.member_disposition.notes ? data.member_disposition.notes : "N/A" : 'N/A'}`,
        header: 'Notes',
        id: "notesActive",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
					<div className="datatable">
						{row.original.member_disposition ? row.original.member_disposition.notes : "N/A"}
					</div>
				),
      },
      {
        accessorKey: 'Agent',
        accessorFn: (row) => `${(row.user) ? (row.user.name.trim()) : ''}`,
        header: 'Agent Name',
        size: 50,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (row.original.user ? row.original.user.name.trim() : 'N/A'),
      },
      {
        accessorKey: 'completed',
        accessorFn: (row) => `${row.completed != "" ?
          row.completed == 2  &&  row.dv_error ? "Digital Verification" :  row.completed == 2 ? "ID Verification" : row.completed == 3 && row.dv_error ? "Payment Details (Manually Authenticated)" : row.completed == 3 ? "Payment Details" :
              row.completed == 'YES' ? "Payment Details" : "Payment Details"
            : row.street_address != '' ? "ID Verification" : ''}`,
        id: 'completed',
        header: 'status',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {
              row.original.completed != "" ?
                row.original.completed == 2  &&  row.original.dv_error ? "Digital Verification" :  row.original.completed == 2 ? "ID Verification" : row.original.completed == 3 && row.original.dv_error ? "Payment Details (Manually Authenticated)" : row.original.completed == 3 ? "Payment Details" :
                  row.original.completed == 'YES' ? "Payment Details" : "Payment Details"
                : row.original.street_address != '' ? "ID Verification" : "Other"

            }
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.completed!=""?(row.completed == 2 || row.completed == 3) ? "MFSN" : "CD":"CD"}`,
        id: 'Source',
        header: 'Source',
        filterSelectOptions: SourceOptions,
        filterVariant: 'select',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.completed!=""?(row.original.completed == 2 || row.original.completed == 3) ? "MFSN" : "CD":"CD"}
          </div>
        ),
      },

      {
        accessorFn: (row) => <>
          {row.snapshot_enrollments === null ? <>
            <Button sx={{minWidth:'20px'}} onClick={() => { AbandonedMemberFirst(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test,row.dv_error) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {
              row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
              <>
               <Tooltip title="View" arrow>
                <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                  <VisibilityIcon color="primary"  />
                </Button>
              </Tooltip>
              </> : '' : ''
            }
           
            {row.agent_id != null ? 
            user.id != row.agent_id ?
              <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test, row.dv_error)}>
                Assign to Me
            </Button></>
            :'' :''}
          </> : <><Button onClick={() => { AbandonedMembersnapshotEnrollments(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {
              row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
              <>
               <Tooltip title="View" arrow>
                <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                  <VisibilityIcon color="primary"  />
                </Button>
              </Tooltip>
              </> : '' : ''
            }
            {row.agent_id != null ? 
              user.id != row.agent_id ?
                <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error)}>
                  Assign to Me
              </Button></>
              :'' :''}
          </> }
         {
          row.completed == 2  &&  row.dv_error ?
          <>
            <Button onClick={() =>callToOpenManuallyAuthenticateModal(row.email)} >
              <Tooltip title="Authenticate Manually" arrow><CheckCircleSharpIcon ></CheckCircleSharpIcon></Tooltip>
            </Button>
          </> 
          :
          null
         }     
        </>,
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
        enableColumnFilter: false,
      },
      

    ]
  )

  const columns1 = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
        accessorKey: 'Email',
        header: 'Email',
        enablePinning: true,
        minSize: 230,
        maxSize: 400,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorFn: (row) => `${row.aid ? row.aid.trim() : ''}`,
        accessorKey: 'AID',
        header: 'AID',
        size: 50,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
      },
      {
        accessorKey: 'Name',
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
        header: 'Name',
        size: 50,
      },
      
      {
        accessorKey: 'phone_number',
        accessorFn: (row) => `${row.phone_number ? formatPhoneNumber(row.phone_number.trim()) : 'N/A'}`,
        filterVariant: 'range',
        id: 'phoneNo',
        header: 'Phone No.',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          row.original.phone_number ?
            <div>
              {formatPhoneNumber(row.original.phone_number.trim())}
              &nbsp;&nbsp;
              <span>
                {row.original.current_hours != '' ?
                  row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                    <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                  : ''
                }
              </span>
              <Tooltip title="Dial" arrow>
                  <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_number); }} >
                    <AiFillPhone color="primary" />
                  </a>
              </Tooltip>
            </div> : 'N/A'
        ),

      },
      {
        accessorKey: 'Enrollment Date',
        accessorFn: (row) => `${row.created_at ? row.created_at : ''}`,
        header: 'Enrollment Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Updated Date',
        accessorFn: (row) => `${row.updated_at ? row.updated_at : ''}`,
        header: 'Last Updated Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted Date',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.updated_at : ''}`,
        header: 'Last Contacted Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted By',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.user? row.member_disposition.user.name : 'N/A': 'N/A'}`,
        header: 'Last Contacted By',
        size: 50,
      },
      {
        accessorKey: 'Notes',
        accessorFn: (data) => `${data.member_disposition ? data.member_disposition.notes ? data.member_disposition.notes : "N/A" : 'N/A'}`,
        header: 'Notes',
        id: 'NotesDeclined',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
					<div className="datatable">
						{row.original.member_disposition ? row.original.member_disposition.notes : "N/A"}
					</div>
				),
      },
      {
        accessorFn: (row) => `${(row.user) ? (row.user.name.trim()) : ''}`,
        accessorKey: 'Agent',
        header: 'Agent Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.user ? row.original.user.name.trim() : 'N/A'),
      },
      {
        accessorFn: (row) => `${row.decline_status}`,
        id: 'Declined status',
        header: 'Declined Status',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {
                row.original.decline_status == 1 ? "Declined" : 'N/A'
            }
          </div>
        ),
      },
      {
        accessorKey: 'completed',
        accessorFn: (row) => `${row.completed != "" ?
          row.completed == 2  &&  row.dv_error ? "Digital Verification" :  row.completed == 2 ? "ID Verification" : row.completed == 3 && row.dv_error ? "Payment Details (Manually Authenticated)" : row.completed == 3 ? "Payment Details" :
              row.completed == 'YES' ? "Payment Details" : "Payment Details"
            : row.street_address != '' ? "ID Verification" : ''}`,
        id: 'completed',
        header: 'status',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {
              row.original.completed != "" ?
              row.original.completed == 2  &&  row.original.dv_error ? "Digital Verification" :  row.original.completed == 2 ? "ID Verification" : row.original.completed == 3 && row.original.dv_error ? "Payment Details (Manually Authenticated)" : row.original.completed == 3 ? "Payment Details" :
                  row.original.completed == 'YES' ? "Payment Details" : "Payment Details"
                : row.original.street_address != '' ? "ID Verification" : ''

            }
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.completed!=""?(row.completed == 2 || row.completed == 3) ? "MFSN" : "CD":"CD"}`,
        id: 'Source',
        header: 'Source',
        filterSelectOptions: SourceOptions,
        filterVariant: 'select',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.completed!=""?(row.original.completed == 2 || row.original.completed == 3) ? "MFSN" : "CD":"CD"}
          </div>
        ),
      },
      {
        accessorFn: (row) => <>
          {row.snapshot_enrollments === null ? <>
            <Tooltip title="Action" arrow>
            <Button sx={{minWidth:'20px'}} onClick={() => { AbandonedMemberFirst(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test, row.dv_error) }}>
              <img src={require('../../assets/images/reactivate.png')} />
              
              </Button>
              </Tooltip>
              {
              row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
              <>
               <Tooltip title="View" arrow>
                <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                  <VisibilityIcon color="primary"  />
                </Button>
              </Tooltip>
              </> : '' : ''
            }
            {row.agent_id != null ? 
            user.id != row.agent_id ?
              <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test, row.dv_error)}>
                Assign to Me
            </Button></>
            :'' :''}
          </> : <><Button onClick={() => { AbandonedMembersnapshotEnrollments(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {
             row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
             <>
              <Tooltip title="View" arrow>
               <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                 <VisibilityIcon color="primary"  />
               </Button>
             </Tooltip>
             </> : '' : ''
            }
          {row.agent_id != null ? 
            user.id != row.agent_id ?
              <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error)}>
                Assign to Me
            </Button></>
            :'' :''}</>
          }
          {
          row.completed == 2  &&  row.dv_error ?
          <>
            <Button onClick={() =>callToOpenManuallyAuthenticateModal(row.email)}>
              <Tooltip title="Authenticate Manually" arrow><CheckCircleSharpIcon  ></CheckCircleSharpIcon></Tooltip>
            </Button>
          </> 
          :
          null
         }
          
          
        </>,
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
      },
      

    ]
  )

  const columns2 = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
        accessorKey: 'Email',
        header: 'Email',
        enablePinning: true,
        minSize: 230,
        maxSize: 400,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorFn: (row) => `${row.aid ? row.aid.trim() : ''}`,
        accessorKey: 'AID',
        header: 'AID',
        enablePinning: true,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
      },
      {
        accessorKey: 'Name',
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
        header: 'Name',
        size: 50,
      },
      
      {
        accessorKey: 'phone_number',
        accessorFn: (row) => `${row.phone_number ? formatPhoneNumber(row.phone_number.trim()) : 'N/A'}`,
        filterVariant: 'range',
        id: 'phoneNo',
        header: 'Phone No.',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          row.original.phone_number ?
            <div>
              {formatPhoneNumber(row.original.phone_number.trim())}
              &nbsp;&nbsp;
              <span>
                {row.original.current_hours != '' ?
                  row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                    <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                  : ''
                }
              </span>
              <Tooltip title="Dial" arrow>
								<a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_number); }} >
									<AiFillPhone color="primary" />
								</a>
							</Tooltip>
            </div> : 'N/A'
        ),

      },
      {
        accessorKey: 'Enrollment Date',
        accessorFn: (row) => `${row.created_at ? row.created_at : ''}`,
        header: 'Enrollment Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Updated Date',
        accessorFn: (row) => `${row.updated_at ? row.updated_at : ''}`,
        header: 'Last Updated Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted Date',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.updated_at : ''}`,
        header: 'Last Contacted Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'Last Contacted By',
        accessorFn: (row) => `${row.member_disposition ? row.member_disposition.user? row.member_disposition.user.name : 'N/A': 'N/A'}`,
        header: 'Last Contacted By',
        size: 50,
      },
      {
        accessorKey: 'Notes',
        accessorFn: (data) => `${data.member_disposition ? data.member_disposition.notes ? data.member_disposition.notes :"N/A" : 'N/A'}`,
        header: 'Notes',
        id : "notesDeferred",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
					<div className="datatable">
						{row.original.member_disposition ? row.original.member_disposition.notes : "N/A"}
					</div>
				),
      },
      {
        accessorFn: (row) => `${(row.user) ? (row.user.name.trim()) : ''}`,
        accessorKey: 'Agent',
        header: 'Agent Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.user ? row.original.user.name.trim() : 'N/A'),
      },
      {
        accessorKey: 'completed',
        accessorFn: (row) => `${row.completed != "" ?
          row.completed == 2  &&  row.dv_error ? "Digital Verification" :  row.completed == 2 ? "ID Verification" : row.completed == 3 && row.dv_error ? "Payment Details (Manually Authenticated)" : row.completed == 3 ? "Payment Details" :
              row.completed == 'YES' ? "Payment Details" : "Payment Details"
            : row.street_address != '' ? "ID Verification" : ''}`,
        id: 'completed',
        header: 'status',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {
              row.original.completed != "" ?
              row.original.completed == 2  &&  row.original.dv_error ? "Digital Verification" :  row.original.completed == 2 ? "ID Verification" : row.original.completed == 3 && row.original.dv_error ? "Payment Details (Manually Authenticated)" : row.original.completed == 3 ? "Payment Details" :
                  row.original.completed == 'YES' ? "Payment Details" : "Payment Details"
                : row.original.street_address != '' ? "ID Verification" : ''
            }
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.completed!=""?(row.completed == 2 || row.completed == 3) ? "MFSN" : "CD":"CD"}`,
        id: 'Source',
        header: 'Source',
        filterSelectOptions: SourceOptions,
        filterVariant: 'select',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.completed!=""?(row.original.completed == 2 || row.original.completed == 3) ? "MFSN" : "CD":"CD"}
          </div>
        ),
      },
      {
        accessorFn: (row) => <>
          {row.snapshot_enrollments === null ? <>
            <Button sx={{minWidth:'20px'}} onClick={() => { AbandonedMemberFirst(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test, row.dv_error) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {
              row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
              <>
               <Tooltip title="View" arrow>
                <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                  <VisibilityIcon color="primary"  />
                </Button>
              </Tooltip>
              </> : '' : ''
            }
            
            {row.agent_id != null ? 
                user.id != row.agent_id ?
                  <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.is_test, row.dv_error)}>
                    Assign to Me
                </Button></>
            :'' :''}
          </> :<> <Button onClick={() => { AbandonedMembersnapshotEnrollments(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {
             row.abandoned_activity_count ? row.abandoned_activity_count.length > 0 ?
             <>
              <Tooltip title="View" arrow>
               <Button sx={{minWidth:'20px'}}  onClick={() => { fetchAbandonedMemberLog(`${row.id}`);  }}>
                 <VisibilityIcon color="primary"  />
               </Button>
             </Tooltip>
             </> : '' : ''
            }
            {row.agent_id != null ? 
              user.id != row.agent_id ?
                <><Button variant="contained" color="success" sx={{ minWidth: 100 }} onClick={() => handleClickOpen(row.email, row.first_name, row.last_name, formatPhoneNumber(row.phone_number), row.completed, row.aid, row.pid, row.snapshot_enrollments.enrolled_status, row.is_test, row.dv_error)}>
                  Assign to Me
              </Button></>
              :'' :''}
          </>}
          {
          row.completed == 2  &&  row.dv_error ?
          <>
            <Button onClick={() =>callToOpenManuallyAuthenticateModal(row.email)} >
              <Tooltip title="Authenticate Manually" arrow><CheckCircleSharpIcon ></CheckCircleSharpIcon></Tooltip>
            </Button>
          </> 
          :
          null
         }
        </>,
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
        enableColumnFilter: false,
      },
      
    ]
  )
  const handleSourceChange = (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    let filteredData;
    let sourceValue = event.target.value;
  
    switch (newTabValue) {
      case 0:
        filteredData = filterData(abandonedMemberFilterData, sourceValue);
        setAbandonedMember(filteredData);
        break;
      case 1:
        filteredData = filterData(abandonedDecliendMemberFilterData, sourceValue);
        setAbandonedMemberDeclined(filteredData);
        break;
      default:
        filteredData = filterData(abandonedDeferredMemberFilterData, sourceValue);
        setAbandonedMemberDeferred(filteredData);
    }
  
    setAssignedToSource(sourceValue);
  };
  const filterData = (data, sourceValue) => {
    return data.filter((item) => {
      if (sourceValue == 'MFSN') {
        return item.completed == 2 || item.completed == 3;
      } else if (sourceValue == 'CD') {
        return item.completed == 'YES' || item.completed == '';
      } else {
        return true;
      }
    });
  };

  //csv start here
  const abandonedCombinedData = [...AbandonedMember];
  const declinedCombinedData = [...AbandonedDeclinedMember];
  const deferredCombinedData = [...AbandonedDeferredMember];
  // ... (abandoned CSV options start)
  const abandonedCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Active Abandoned Members Data.csv', // Change the file name
    headers: ['Aid', 'Name', 'Email', 'Phone No.', 'Enrollment Date','Last Updated Date','Last Contacted Date','Last Contacted By','Notes','Agent Name', 'Status', 'Source']
  };
  // ... (abandoned CSV options end)
  // ... (declined CSV options start)
  const declinedCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Declined Abandoned Members Data.csv', // Change the file name
    headers: ['Aid', 'Name', 'Email', 'Phone No.', 'Enrollment Date','Last Updated Date','Last Contacted Date','Last Contacted By','Notes','Agent Name','Declined Status', 'Status', 'Source']
  };
  // ... (declined CSV options end)
  // ... (deferred CSV options start) 
  const deferredCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Deferred Abandoned Members Data.csv', // Change the file name
    headers: ['Aid', 'Name', 'Email', 'Phone No.', 'Enrollment Date','Last Updated Date','Last Contacted Date', 'Last Contacted By','Notes','Agent Name', 'Status', 'Source']
  };
  // ... (deferred CSV options end)
  // Define the convertToCSV function start
  const convertToCSV = (data, headers) => {
    const csvContent = headers.join(',') + '\n' +
      data.map((row) => (
        Object.values(row).map((value) => (
          typeof value === 'string' && value.includes(',') ? `"${value}"` : value
        )).join(',')
      )).join('\n');

    return csvContent;
  };
  // Define the convertToCSV function end
  // Define export functions for  abandoned start
  const handleExportAbandonedData = (abandonedCombinedData) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    const exportedData = abandonedCombinedData.map((row) => {
      let status,source;
      // Logic for the "status" column
      if (row.original.completed != "") {
        if (row.original.completed == 2) {
          status = "ID Verification";
        } else if (row.original.completed == 3) {
          status = "Payment Details";
        } else if (row.original.completed == 'YES') {
          status = "Payment Details";
        } else {
          status = "Payment Details";
        }
      } else {
        status = row.original.street_address != '' ? "ID Verification" : "Other";
      }

      // Logic for the "source" column
      if (row.original.completed != "") {
        if (row.original.completed == 2 || row.original.completed == 3) {
          source = "MFSN";
        } else if (row.original.completed == 'YES') {
          source = "CD";
        } else {
          source = "CD";
        }
      } else {
        source = "CD";
      }
      
      return {
        aid: `${row.original.aid ? row.original.aid.trim() : 'N/A'}`,
        name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
        email: `${row.original.email ? row.original.email.trim() : 'N/A'}`,
        phone_number: `${row.original.phone_number ? formatPhoneNumber(row.original.phone_number.trim()) : 'N/A'}`,
        enrollment_date: row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_updated_date: row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_contacted_date: row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm'):"N/A",
        last_contacted_by: row.original.member_disposition ? (row.original.member_disposition.user ? row.original.member_disposition.user.name : "N/A") : "N/A",
        note:`${row.original.member_disposition ? row.original.member_disposition.notes : 'N/A'}`,
        agent: `${row.original.user && row.original.user.name ? row.original.user.name.trim() : 'N/A'}`,
        status: status || "N/A",
        source: source || "N/A",
      };
    });

    // Generate CSV content
    const csvContent = convertToCSV(exportedData, abandonedCsvOptions.headers);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = abandonedCsvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };
  // Define export functions for  abandoned end
  // Define export functions for  Declined start
  const handleExportDeclinedData = (declinedCombinedData) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    const exportedData = declinedCombinedData.map((row) => {
      let status, source;
      // Logic for the "status" column
      if (row.original.completed != "") {
        if (row.original.completed == 2) {
          status = "ID Verification";
        } else if (row.original.completed == 3) {
          status = "Payment Details";
        } else if (row.original.completed == 'YES') {
          status = "Payment Details";
        } else {
          status = "Payment Details";
        }
      } else {
        status = row.original.street_address != '' ? "ID Verification" : "Other";
      }

      // Logic for the "source" column
      if (row.original.completed != "") {
        if (row.original.completed == 2 || row.original.completed == 3) {
          source = "MFSN";
        } else if (row.original.completed == 'YES') {
          source = "CD";
        } else {
          source = "CD";
        }
      } else {
        source = "CD";
      }
      return {
        aid: `${row.original.aid ? row.original.aid.trim() : 'N/A'}`,
        name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
        email: `${row.original.email ? row.original.email.trim() : 'N/A'}`,
        phone_number: `${row.original.phone_number ? formatPhoneNumber(row.original.phone_number.trim()) : 'N/A'}`, 
        enrollment_date: row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_updated_date: row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_contacted_date:`${row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}`,
        last_contacted_by:`${row.original.member_disposition ? row.original.member_disposition.user? row.original.member_disposition.user.name : 'N/A': 'N/A'}`,
        note:`${row.original.member_disposition ? row.original.member_disposition.notes : 'N/A'}`,
        agent: `${row.original.user && row.original.user.name ? row.original.user.name.trim() : 'N/A'}`,
        decline_status:`${ row.original.decline_status == 1 ? "Declined" : 'N/A'}`,
        status: status || "N/A",
        source: source || "N/A",
      };
    });
  
    // Generate CSV content
    const csvContent = convertToCSV(exportedData, declinedCsvOptions.headers);
  
     // Create a Blob object from the CSV data
     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

     // Create a download link element
     const link = document.createElement('a');
     link.href = URL.createObjectURL(blob);
     link.download = declinedCsvOptions.fileName + '.csv';
 
     // Append the link to the document body
     document.body.appendChild(link);
 
     // Programmatically click the link to trigger the file download
     link.click();
 
     // Clean up the temporary URL and remove the link from the document
     URL.revokeObjectURL(link.href);
     link.remove();
  };
  // Define export functions for  Declined end
  // Define export functions for  Deferred start
  const handleExportDeferredData = (deferredCombinedData) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    const exportedData = deferredCombinedData.map((row) => {
      let status, source;
      // Logic for the "status" column
      if (row.original.completed != "") {
        if (row.original.completed == 2) {
          status = "ID Verification";
        } else if (row.original.completed == 3) {
          status = "Payment Details";
        } else if (row.original.completed == 'YES') {
          status = "Payment Details";
        } else {
          status = "Payment Details";
        }
      } else {
        status = row.original.street_address != '' ? "ID Verification" : "Other";
      }

      // Logic for the "source" column
      if (row.original.completed != "") {
        if (row.original.completed == 2 || row.original.completed == 3) {
          source = "MFSN";
        } else if (row.original.completed == 'YES') {
          source = "CD";
        } else {
          source = "CD";
        }
      } else {
        source = "CD";
      }
      return {
        aid: `${row.original.aid ? row.original.aid.trim() : 'N/A'}`,
        name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
        email: `${row.original.email ? row.original.email.trim() : 'N/A'}`,
        phone_number: `${row.original.phone_number ? formatPhoneNumber(row.original.phone_number.trim()) : 'N/A'}`, 
        enrollment_date: row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_updated_date: row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A',
        last_contacted_date: `${row.original.member_disposition ? Moment(row.original.member_disposition.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}`,
        last_contacted_by:`${row.original.member_disposition ? row.original.member_disposition.user? row.original.member_disposition.user.name : 'N/A': 'N/A'}`,
        note: `${row.original.member_disposition ? row.original.member_disposition.notes : 'N/A'}`,
        agent: `${row.original.user && row.original.user.name ? row.original.user.name.trim() : 'N/A'}`,
        status: status || "N/A",
        source: source || "N/A",
      };
    });
  
    // Generate CSV content
    const csvContent = convertToCSV(exportedData, deferredCsvOptions.headers);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = deferredCsvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };
  // Define export functions for  Deferred end
  //csv end here
  return (
    <>
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}

      
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} mb={2}>
            <FormControl fullWidth margin='dense' size="small">
              <InputLabel id="demo-multiple-name-label">Assigned to</InputLabel>
              <Select
                id="assignedto"
                name="assignedto"
                input={<OutlinedInput label="Assigned To" />}
                value={assignedTo}
                onChange={handleSelectChange}
                MenuProps={MenuProps}
                size='small'
              >
                <MenuItem
                  value='all'
                >
                  All
                </MenuItem>
                {adminList.map((array) => (
                  <MenuItem
                  key={array.id} // Ensure each MenuItem has a unique key
                    value={array.id}
                  >
                    {array.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
         


          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl fullWidth size='small' margin='dense'>
              <InputLabel id="demo-multiple-name-label">Source</InputLabel>
              <Select
                id="assignedToSource"
                value={assignedToSource}
                onChange={handleSourceChange}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Source" />}
              >
                <MenuItem key="Action" value="All" >
                  All
                </MenuItem>
                <MenuItem key="MFSN" value="MFSN">
                  MFSN
                </MenuItem>
                <MenuItem key="CD" value="CD">
                  CD
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={3} md={3} xs={3} sm={3} mt={1}>
            <Stack direction={'row'} justifyContent={'space-between'} >
              <TextField
              id="globalSearch"
              name="globalSearch"
              value={search}
              size='small'
              fullWidth
              label="Search Here..."
              onChange={(e)=>setSearch(e.target.value)}
              onBlur={()=>resetData()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search && (
                      <IconButton onClick={clearSearchValue}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon onClick={globalSearch} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
            </Stack>
            <List sx={{ width: '100%', maxWidth: 380,border:'1px solid #b2b8c3', overflow: 'auto', maxHeight: 300,borderRadius: 'sm', bgcolor: 'background.paper', display:'none', position:'absolute', zIndex:'5' }}  id="globalSearchList" >
              {listData?listData.map((row) => (
                <ListItem height={400}>
                  <a className='btn btn-sm btn-info text-white' href="#" onClick={() => { 
                      handleSearchAction(row)
                    }}
                      >
                      {row.first_name} ({row.email}) ({row.phone_number}) ({row.status?row.status:null}) 
                  </a></ListItem>
              )):''} 
            </List>
          </Grid>

        { value == 1 ? 
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid  item lg={4} md={4} sm={6} xs={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value="date"
                    input={<OutlinedInput label="Select Date Range" />}
                  >
                    <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                    <DateRangePicker
                      className='custom-date-picker'
                      onChange={item => setSelectionRange([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={selectionRange}
                      onBlur={handleSelectionRange()}
                      direction="horizontal"
                      maxDate={subDays(new Date(), 2)}
                      staticRanges={staticRanges}

                    />
                    
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={8} md={8} sm={6} xs={6}>
                <Button color="primary" onClick={getAbandonedMember} variant="contained" sx={{ minWidth: 200 }}>
                  Submit
                </Button>
              </Grid>
          </Grid>
          : null }
         
        </Grid>
        
        
       
      <Formik
        initialValues={{
          staffId: selected,
        }}
        validationSchema={assignedToStaffSchema}
        onSubmit={async (values, error) => {
          const loginUser = JSON.parse(localStorage.getItem('user'));
          if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
          }
          if (selectedID.length == 0) {
            alert("Please select agent to assign");
            return false;
          }
          setSubmitEnable(true);
          handleCloseAssigned();
          handleFreezStart();
          const apiUrl = '/assignToMultipleSignupToAgent';
          const args = {
            assignedTo: selectedID,
            memberId: rowsSeleted,
          }
          const res = await dispatch(postData({apiUrl,args})).unwrap();
          if(res)
          {
            handleFreezStop();
            handleCloseAssigned();
            
            if(res.abandonedMemberError == 1){
              setSuccessMessage(res.message);
              setTimeout(function () {
                setSuccessMessage('');
              }, 8000);
            
            }else{
              swal({
                title: "Error",
                text: res.message,
                icon: "error",
                button: "Ok",
              })
              setTimeout(function () {
                setErrorMessage('');
              }, 8000);
            }

            tabValue = value;
            getAbandonedMember();
            setSelectedID([]);
            setSelected([]);
            setRowSelection([]);
            setAssignedToAction('Action');
            setAssignedToSource('All');
            setAssignedTo('all');
            setSubmitEnable(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Dialog
            open={assignedOpen}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleCloseAssigned();
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
              <Typography variant='h4' color='primary'>Assign Selected Leads To Staff</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                <FormControl className={classes.formControl} fullWidth size="small">
                  <InputLabel id="mutiple-select-label">Action</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    id="staffId"
                    name="staffId"
                    input={<OutlinedInput label="Action" />}
                    value={selected}
                    onChange={handleChange2}
                    renderValue={(select) =>
                      select
                        .map((id) => {
                          const selectedOption = adminList.find((option) => option.id === id);
                          return selectedOption ? selectedOption.name : '';
                        })
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 && selected.length < adminList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {adminList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.staffId && errors.staffId ? <div className='error'>{errors.staffId}</div> : null}
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={() => { handleCloseAssigned() }}>
                    CLOSE
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" disabled={submitEnable} onClick={handleSubmit}>
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
      {isLoading ? <><Loader /></> : ''}

      <Box sx={{ width: '100%', typography: 'body1' }}>
        {/* <TabContext value={value}> */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Active"   {...a11yProps(0)} onClick={(event) => handleTabClick(event, 0)} />
            <Tab label="Declined"   {...a11yProps(1)} onClick={(event) => handleTabClick(event, 1)} />
            <Tab label="Deferred"   {...a11yProps(3)} onClick={(event) => handleTabClick(event, 2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <MaterialReactTable
            columns={columns}
            data={AbandonedMember}
            filterFns={{
              customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().includes(filterValue.toLowerCase()),
            }}                                   
          globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            enableColumnFilterModes
             enablePinning
            // enableRowSelection
            enableColumnFilters={config.DatatableColumnFilter}
            getRowId={(data) => data.email} //give each row a more useful id
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{
              showColumnFilters: false,
              density: 'compact',
              columnPinning: { left: ['mrt-row-expand','Email','AID'] }
            }}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <TableContainer component={Paper}>
                  <Table className="back">
                    <TableHead>
                      <TableRow >
                        <TableCell style={{ fontWeight: "bold" }}>Company Name</TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>Affiliate Name</TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>Affiliate Email</TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>Communication Email</TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>Affiliate Phone</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow >
                        <TableCell >{row.original.aid_master?.aids_company?.company_name ?? 'N/A'}</TableCell>
                        <TableCell >{row.original.first_name ? `${row.original.first_name} ${row.original.last_name}` : 'N/A'}</TableCell>
                        <TableCell >{row.original.email ?? 'N/A'}</TableCell>
                        <TableCell >{row.original.aid_master?.reference_affiliates?.communication_email  ?? 'N/A'}</TableCell>
                        <TableCell >{row.original.phone_number ?? 'N/A'}</TableCell>
                      </TableRow>
                      </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            enableDensityToggle={false}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }
            }
            //top csv option
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  sx={{ bgColor: "#2A68B3" }}
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() =>
                    handleExportAbandonedData(table.getPrePaginationRowModel().rows)
                  }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  // onClick={handleExportAbandonedData}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className='export'
                >
                  CSV
                </Button>
              </Box>
            )
            }
          />

        </TabPanel>
        <TabPanel value={value} index={1}>
          <MaterialReactTable
            columns={columns1}
            data={AbandonedDeclinedMember}
            filterFns={{
              customSearchFilterFn1: (row, id, filterValue) =>
              {     const rowValue = row.getValue(id).toLowerCase().replace(/\s/g, ''); // Trim spaces
              const filterLowerCase = filterValue.toLowerCase().replace(/\s/g, ''); // Trim spaces in filter
          
              return rowValue.includes(filterLowerCase);}
            }}   
            globalFilterFn="customSearchFilterFn1" //exact serach filter functionality
            enableColumnFilterModes
            enablePinning
            // enableRowSelection
            enableColumnFilters={config.DatatableColumnFilter}
            getRowId={(data) => data.email} //give each row a more useful id   
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{
              showColumnFilters: false,
              density: 'compact',
              columnPinning: { left: ['mrt-row-expand','Email','AID'] }
            }}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <TableContainer component={Paper}>
                  <Table className="back">
                   <TableHead>
                    <TableRow >
                      <TableCell style={{ fontWeight: "bold" }}>Company Name</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Name</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Email</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Communication Email</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Phone</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow >
                      <TableCell >{row.original.aid_master?.aids_company?.company_name ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.first_name ? `${row.original.first_name} ${row.original.last_name}` : 'N/A'}</TableCell>
                      <TableCell >{row.original.email ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.aid_master?.reference_affiliates?.communication_email  ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.phone_number ?? 'N/A'}</TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            enableDensityToggle={false}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }
            }
            //top csv option
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  sx={{ bgColor: "#2A68B3" }}
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() =>
                    handleExportDeclinedData(table.getPrePaginationRowModel().rows)
                  }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className='export'
                >
                  CSV
                </Button>
              </Box>
            )
            }
          />

        </TabPanel>
        <TabPanel value={value} index={2}>
          <MaterialReactTable
            columns={columns2}
            filterFns={{
              customSearchFilterFn2: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().includes(filterValue.toLowerCase()),
            }}   
            globalFilterFn="customSearchFilterFn2" //exact serach filter functionality
            data={AbandonedDeferredMember}
            enableColumnFilterModes
            enablePinning
            // enableRowSelection
            enableColumnFilters={config.DatatableColumnFilter}
            getRowId={(data) => data.email} //give each row a more useful id   
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{
              showColumnFilters: false,
              density: 'compact',
              columnPinning: { left: ['mrt-row-expand','Email','AID'] }
            }}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <TableContainer component={Paper}>
                  <Table className="back">
                    <TableHead>
                    <TableRow >
                      <TableCell style={{ fontWeight: "bold" }}>Company Name</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Name</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Email</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Communication Email</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Affiliate Phone</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow >
                      <TableCell >{row.original.aid_master?.aids_company?.company_name ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.first_name ? `${row.original.first_name} ${row.original.last_name}` : 'N/A'}</TableCell>
                      <TableCell >{row.original.email ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.aid_master?.reference_affiliates?.communication_email  ?? 'N/A'}</TableCell>
                      <TableCell >{row.original.phone_number ?? 'N/A'}</TableCell>
                    </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            enableDensityToggle={false}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }
            }
            //top csv option
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  sx={{ bgColor: "#2A68B3" }}
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() =>
                    handleExportDeferredData(table.getPrePaginationRowModel().rows)
                  }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className='export'
                >
                  CSV
                </Button>
              </Box>
            )
            }
          />

        </TabPanel>
        {/* </TabContext> */}
      </Box>
      {/* AbandonedMember Resistant Process first start here */}
      <Dialog
        open={open2}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AbandonedMemberFirstClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={9} md={9} sm={9} xs={9} >
              <Typography variant="h5">Abandoned Registrant Process</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {(completedStatus == 2 && isDVError == false) || completedStatus == '' ?
                <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                :
                  completedStatus == 2 && isDVError == true ?
                  <Typography variant="body1" gutterBottom>Digital Verification</Typography>
                : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{emailId}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberFirstClose} sx={{ minWidth: 250 }}>
                Cancel
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberSecond} color="success" sx={{ minWidth: 250 }}>
                Assign To Me
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* AbandonedMember Resistant Process first end here */}
      {/* AbandonedMember Resistant Process Second start here */}
      <Dialog
        open={open3}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AbandonedMemberSecondClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestFormInitialValues}
          validationSchema={RequestFormSchema}
          onSubmit={AbandonedGenerateOTP}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {completedStatus == 2 || completedStatus == '' ?
                  <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                  : <Typography variant="body1" gutterBottom>Payment Details</Typography>}

              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{emailId}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name='ssn'
                id='ssn'
                label="ssn"
                value={values.ssn}
                variant="outlined"
                size="small" fullWidth
                onChange={handleChange('ssn')}
              />
              {touched.ssn && errors.ssn ? <div className='error'>{errors.ssn}</div> : null}
            </Grid>
            {completedStatus == 2  ?
              <Grid container item lg={12} md={12} sm={12} xs={12} style={{ display: 'none' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                  <DemoContainer components={['DatePicker']} >
                    <DatePicker label="Follow Up Date"
                      margin="dense"
                      id="followupDate"
                      name="followupDate"
                      fullWidth
                      value={values.dob}
                      onChange={(value) => { setFieldValue("followupDate", value, true) }}
                      minDate={dayjs(currentDate)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {touched.dob && errors.dob ? <div className='error'>{errors.dob}</div> : null}
              </Grid> :
              <Grid container item lg={12} md={12} sm={12} xs={12} >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker']} >
                      <DatePicker label="Date Of Brith"
                        margin="dense"
                        id="dob"
                        name="dob"
                        size="small" fullWidth
                        sx={{ display: 'block' }}
                        value={values.dob}
                        onChange={(value) => { setFieldValue("dob", value, true) }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {touched.dob && errors.dob ? <div className='error d-block'>{errors.dob}</div> : null}
                </Grid>
              </Grid>
            }
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
              <Alert severity="info" sx={{ width: '100%' }}>Call The Registrant To Get Full SSN</Alert>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 250 }}>
                  Abort
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                  Send Verify Link
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>
      {/* AbandonedMember Resistant Process Second end here */}
      {/* AbandonedMember Resistant Process Abort Start here */}
      <Dialog
        open={open4}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AbandonedMemberAbortClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestabortFirstStep}
          validationSchema={RequestFormSchemaabortFirstStep}
          onSubmit={abortFirstStep}  
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
          <DialogContent>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  id="contactType"
                  name="contactType"
                  value={values.contactType}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <FormControlLabel value="Inbound" control={<Radio />} label="Inbound" />
                  <FormControlLabel value="Outbound" control={<Radio />} label="Outbound" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name='callType'
                id='callType'
                label="callType"
                value={values.callType}
                variant="outlined"
                size="small" fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Disposition:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="disposition"
                  name="disposition"
                  value={values.disposition}
                  label="Disposition:"
                  onChange={(e) => {
                    handledisposition(e.target.value);
                    if(e.target.value == '163' || e.target.value == '164') {
                      setSelectedDisposition(true);
                    }else{
                      setSelectedDisposition(false);
                    }
                    handleChange(e);
                  }}
                >
                  {Object.values(DispositionList).map((disposition, index) => (
                    <MenuItem 
                        key={index} 
                        value={disposition.id} 
                        // disabled={verificationfail && disposition.disposition === 'Activated'}
                    >
                        {disposition.disposition}
                    </MenuItem>
                ))}
                </Select>
                {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Outcome:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="reason"
                  name="reason"
                  value={values.reason}
                  label="Outcome:"
                  onChange={handleChange}
                >
                  {reasonList.map((reason) => (
                    <MenuItem
                      value={reason.id}
                    >
                      {reason.reason}
                    </MenuItem>
                  ))}
                </Select>
                {touched.reason && errors.reason ? <div className='error'>{errors.reason}</div> : null}
              </FormControl>
            </Grid>
            {DispositionValue == 0 ? 
              <Grid container item lg={12} md={12} sm={12} xs={12} style={{ display: 'none' }}>
                <TextField
                  name='custMemberId'
                  id='custMemberId'
                  label="memberId"
                  value={values.custMemberId}
                  variant="outlined"
                  size="small" fullWidth
                  onChange={handleChange('custMemberId')}
                  inputProps={{ type: "number" }}
                />
              </Grid>
            :
            <>
              <Grid container item lg={12} md={12} sm={12} xs={12} >
                <TextField
                  name='custMemberId'
                  id='custMemberId'
                  label="memberId"
                  value={values.custMemberId}
                  variant="outlined"
                  size="small" fullWidth
                  onChange={(e) => {
                    handleMemberId(e.target.value);
                    handleChange(e);
                  }}
                  inputProps={{
                    maxLength: 7,
                    pattern: '[0-9]*', // Enforce numeric input
                  }}
                />
                {errors1.custMemberId && <div className="error">{errors1.custMemberId}</div>}
              </Grid>
              </> 
            }
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  required
                  margin="dense"
                  aria-label="minimum height"
                  minRows={6}
                  placeholder="Enter Note"
                  name="note"
                  id="note"
                  value={values.note}
                  onChange={handleChange}
                />
                {touched.note && errors.note ? <div className='error'>{errors.note}</div> : null}
              </FormControl>
            </Grid>
            {
              selectedDisposition ?
              <>
                <Grid container item lg={12} md={12} sm={12} xs={12} id="noFollowup" sx={{ mt: 1 }}>
                  <Stack direction="row" spacing={3}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                              <DemoContainer components={['DatePicker']}  fullWidth>
                                  <DatePicker label="Followup Date*"
                                      margin="dense"
                                      onChange={(value) => {setFieldValue("followupDate", value, true)}}
                                      onBlur={handleBlur}
                                      value={values.followupDate} 
                                      name="followupDate" 
                                      minDate={dayjs(currentDate)}
                                      maxDate={dayjs().add(75, 'year')}
                                  />
                              </DemoContainer>
                          </LocalizationProvider>
                          <FormHelperText style={{ color: touched.followupDate && errors.followupDate ? 'red' : 'inherit' }}>
                              {touched.followupDate && errors.followupDate ? errors.followupDate : ''}
                          </FormHelperText>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputMask
                          mask='99:99'
                          label="Followup Time*"
                          maskChar={null}
                          name='followupTime'
                          fullWidth
                          margin="dense"
                          id='followupTime'
                          value={values.followupTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          >
                          {(inputProps) =>
                              <TextField
                                  label={values.followUpTimeLabel + "*"} variant="outlined"
                                  {...inputProps}
                              />
                          }
                      </InputMask>
                      {
                      touched.followupTime && errors.followupTime ? (
                          <div className='error'>{errors.followupTime}</div>
                      ) : (
                          ''
                      )
                      }
              
                      </Grid>
                  </Stack>
                </Grid>
              </>
              : ''
            }
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={AbandonedMemberAbortClose} sx={{ minWidth: 250 }}>
                  Back
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>

        )}
        </Formik>
      </Dialog>
      {/* AbandonedMember Resistant Process Abort end here */}
      {/* AbandonedMember Resistant Process Generated OTP  Start here */}
      <Dialog
        open={open5}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AbandonedGenerateOTPClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestFormInitialValues1}
          validationSchema={RequestFormSchema1}
          onSubmit={VerifyAuthentication}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
          <DialogContent>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {completedStatus == 2 || completedStatus == '' ?
                  <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                  : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom className="datatable">{emailId}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              
              <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Alert severity="info" sx={{ width: '100%' }}>
                  {timeLeft > 0 ?
                    <> <Typography variant="body1" gutterBottom>Link will be valid for 15 minutes. Haven't received yet Resend it after {timeLeft} seconds</Typography></>
                    : <Typography variant="body1" gutterBottom>Link will be valid for 15 minutes. Haven't received yet Resend it now</Typography>}
                  </Alert>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 150 }}>
                  Abort
                </Button>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 150 }}>
                  Authenticate
                </Button>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button disabled={timeLeft > 0} variant="contained" onClick={resendOtp} color="success" sx={{ minWidth: 150 }}>
                  Resend Link
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>
      {/* AbandonedMember Resistant Process Generated OTP end here */}
      {/* AbandonedMember Resistant Process Authentication Done  Start here */}
      <Dialog
        open={open6}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            VerifyAuthenticationClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={9} md={9} sm={9} xs={9} >
              <Typography variant="h5">Abandoned Registrant Process</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
            <CheckCircleOutlineOutlinedIcon color='success' style={{ fontSize: '6em' }} />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
            <Typography variant="h4" sx={{ color: "#2e7d32" }}><b>Verification Success</b></Typography>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center" }}>
            <Alert severity="info" sx={{ width: '100%' }}>Proceed with Manual Authentication on SupportLink and then go to the next step </Alert>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 250 }}>
                Abort
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AuthenticationSuccess} color="success" sx={{ minWidth: 250 }}>
                Authentication Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* AbandonedMember Resistant Process Authentication Done end here */}
      {/* AbandonedMember Resistant Process Send Link  Start here */}
      <Dialog
        open={open7}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AuthenticationSuccessClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={9} md={9} sm={9} xs={9} >
              <Typography variant="h5">Abandoned Registrant Process</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {completedStatus == 2 || completedStatus == '' ?
                <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{emailId}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Send Login Link:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" color="success" onClick={sendLink}>Send</Button>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
            <Alert severity="info" sx={{ width: '100%' }}>Check with the Registrant if he is able to Login</Alert>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 250 }}>
                Abort
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={PaymentSuccess} color="success" sx={{ minWidth: 250 }}>
                Logged In Successfully
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* AbandonedMember Resistant Process Send Link end here */}
      {/* DV fail Process Start here */}
      <Dialog
        open={openDVFail}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            AuthenticationSuccessClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={9} md={9} sm={9} xs={9} >
              <Typography variant="h5">Abandoned Registrant Process</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {completedStatus == 2 || isDVError == true ?
                <> <Typography variant="body1" gutterBottom>Digital Verification</Typography></>
                : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{emailId}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
            </Grid>
          </Grid>
          {/* <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Send Login Link:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" color="success" onClick={sendLink}>Send</Button>
            </Grid>
          </Grid> */}
          <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
            <Alert severity="info" sx={{ width: '100%' }}>Kindly verify this member manually through their SupportLink account before proceeding with authentication here.</Alert>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 250 }}>
                Abort
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={manuallyAuthenticate} color="success" sx={{ minWidth: 250 }}>
                Manually Authenticated
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* DV fail Process End Link end here */}
      {/* AbandonedMember Resistant Process Payment Success Start here */}
      <Dialog
        open={open8}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            PaymentSuccessClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestFormInitialValueslaststep}
          validationSchema={RequestFormSchemalaststep}
          onSubmit={PaymentSuccessClose}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {completedStatus == 2 || completedStatus == '' ?
                  <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                  : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{emailId}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  id="contactType"
                  name="contactType"
                  value={values.contactType}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <FormControlLabel value="Inbound" control={<Radio />} label="Inbound" />
                  <FormControlLabel value="Outbound" control={<Radio />} label="Outbound" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name='callType'
                id='callType'
                label="callType"
                value={values.callType}
                variant="outlined"
                size="small" fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Disposition:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="disposition"
                  name="disposition"
                  value={values.disposition}
                  label="Disposition:"
                  onChange={(e) => {
                    handledisposition(e.target.value);
                    handleChange(e);
                  }}
                >
                  {ActiveDisposition.map((disposition) => (
                    <MenuItem
                      value={disposition.id}
                    >
                      {disposition.disposition}
                    </MenuItem>
                  ))}
                </Select>
                {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Outcome:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="reason"
                  name="reason"
                  value={values.reason}
                  label="Outcome:"
                  onChange={handleChange}
                >
                  {reasonList.map((reason) => (
                    <MenuItem
                      value={reason.id}
                    >
                      {reason.reason}
                    </MenuItem>
                  ))}
                </Select>
                {touched.reason && errors.reason ? <div className='error'>{errors.reason}</div> : null}
              </FormControl>
            </Grid>
           
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
              <Alert severity="info" sx={{ width: '100%' }}>Ensure that the Payment is Successfull by checking on SupportLink and that the member is Active.</Alert>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={AbandonedMemberAbort} sx={{ minWidth: 250 }}>
                  Abort
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                  Mark As Done
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>
      {/* AbandonedMember Resistant Process Payment Success end here */}

       {/* AbandonedMember Member Id Second start here */}
       <Dialog
        open={memberIdPopup}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            memberIdPopupClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestFormMemberValues}
          validationSchema={RequestFormSchemaMember}
          onSubmit={PaymentSuccessClose}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {completedStatus == 2 || completedStatus == '' ?
                  <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                  : <Typography variant="body1" gutterBottom>Payment Details</Typography>}

              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{emailId}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name='memberId'
                id='memberId'
                label="memberId"
                value={values.memberId}
                variant="outlined"
                size="small" fullWidth
                onChange={handleChange('memberId')}
                inputProps={{ type: "number" }}
              />
              {touched.memberId && errors.memberId ? <div className='error'>{errors.memberId}</div> : null}
            </Grid>
           
           
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
               
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                 Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>


      <Dialog
      open={OpenAssignToMe}
      onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleCloseAssignToMe();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >   
        <Formik
          initialValues={RequestassignToMe}
          validationSchema={validationassignToMe}
          onSubmit={AbandonedMemberSecond}
          
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">	Assign To Me </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
          <DialogContentText>
              <Typography level="h6">Assign To Me reason:*</Typography>
          </DialogContentText>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <Select
                  label="Reason"
                  id="assignReason"
                  name="assignReason"
                  variant="outlined"
                  value={values.assignReason}
                  onChange={handleChange}
                >
                   <MenuItem value="">
                  </MenuItem>
                  <MenuItem value="Received Call From Member">Received Call From Member</MenuItem>
                  <MenuItem value="Agent Is Not Available">Agent Is Not Available</MenuItem>
                </Select>
                {touched.assignReason && errors.assignReason ? <div className='error'>{errors.assignReason}</div> : null}
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleCloseAssignToMe} sx={{ minWidth: 250 }}>
                Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                 Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>

     
      {/* AbandonedMember Member Id Process Second end here */}

      <Dialog
          fullWidth
          maxWidth={'sm'}
          open={openConfrimationBox}
          // onClose={handleClose}
          onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                  setOpenConfirmationBox(false);
              }
          }}
      >
                 
        <DialogContent>
            <Box mt={1} sx={{textAlign:'center'}}>
                <Typography level='h4'>Are you sure you want to authenticate this user?</Typography>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <Alert severity="info" sx={{ width: '100%' }}>Kindly verify this member manually through their SupportLink account before proceeding with authentication here.</Alert>
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 'auto' }} >
            <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={directlyAuthenticate} color="success" sx={{ minWidth: 200 }} >
                    Authenticated
                </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 200 }}>
                    Cancel
                </Button>
            </Grid>
        </DialogActions>
      </Dialog>

      {/* Display search member details */}
      <Dialog
        open={searchOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            searchClose();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'lg'}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={9} md={9} sm={9} xs={9} >
              <Typography variant="h5">Abandoned Registrant Process</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Abandoned At:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {(completedStatus == 2 && isDVError == false) || completedStatus == '' ?
                <> <Typography variant="body1" gutterBottom>ID Verification</Typography></>
                :
                  completedStatus == 2 && isDVError == true ?
                  <Typography variant="body1" gutterBottom>Digital Verification</Typography>
                : <Typography variant="body1" gutterBottom>Payment Details</Typography>}
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Source Status:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{sourceStatus}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Name:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{firstName + ' ' + lastName}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Email:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{emailId}</Typography>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom><b>Phone Number:</b></Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant="body1" gutterBottom>{phoneNo}</Typography>
            </Grid>
          </Grid>
          {
            searchRecord.abandoned_activity_logs_details &&  searchRecord.abandoned_activity_logs_details.length > 0 ?
            <>
             { console.log("searchRecord.abandoned_activity_logs_details.length: "+searchRecord.abandoned_activity_logs_details.length)}
             <Grid container item lg={12} md={12} sm={12} xs={12}  mt={2} sx={{display:'flex', justifyContent:'center'}}>
                <Typography variant="body1" align="center"><b>Activity Log ({searchRecord.activity_count})</b></Typography>
            </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table borderAxis="none" variant="plain" stripe="odd" >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '16px' }}>Event By</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Notes</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Contact Type</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Disposition</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Reason</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Followup Date</TableCell>
                        <TableCell sx={{ padding: '16px' }}>Last Updated At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { searchRecord.abandoned_activity_logs_details.map((record, index) => (
                        <TableRow key={record.id}>
                          <TableCell sx={{ padding: '16px' }}>{record.user ? record.user.name.trim() : '' || 'N/A'}</TableCell>
                          <TableCell sx={{ padding: '16px' }} className='datatable'>{record.notes ? (record.notes == 'Assign To' ? (record.notes + ' ' +(record.user ? record.user.name.trim() : '')) : record.notes == 'SSN' ? "SSN Captured" : record.notes == 'Otp' ? "OTP sent" : record.notes == 'Login Link' ?  'Login Link Sent' : record.notes == "Verification/Authentication" ?  "Authenticated" : record.notes): 'N/A' }</TableCell>
                          <TableCell sx={{ padding: '16px' }}>{record.contact_type || 'N/A'}</TableCell>
                          <TableCell sx={{ padding: '16px' }}>{record.call_type_disposition ? record.call_type_disposition.disposition : 'N/A'}</TableCell>
                          <TableCell sx={{ padding: '16px' }}>{record.call_type_reason ? record.call_type_reason.reason : 'N/A'}</TableCell>
                          <TableCell sx={{ padding: '16px' }}>{record.next_followup ? Moment(record.next_followup).format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A'}</TableCell>
                          <TableCell sx={{ padding: '16px' }}>{record.updated_at ? Moment(record.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
            : null
          }
         
        </DialogContent>
        <DialogActions>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={searchClose} sx={{ minWidth: 250 }}>
                Cancel
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button variant="contained" onClick={AbandonedMemberSecond} color="success" sx={{ minWidth: 250 }}>
                Assign To Me
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
          
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
      <Modal
        open={openLog}
        onClose={handleAbandonClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container item lg={12} md={12} sm={12} xs={12}  mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level="h3" color="primary">Activity Log ({abandonLog && abandonLog.length > 0 ? abandonLog[0].email : ''})</Typography>
            <CloseIcon onClick={handleAbandonClose} />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table borderAxis="none" variant="plain" stripe="odd" >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: '16px' }}>Event By</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Notes</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Contact Type</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Disposition</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Reason</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Followup Date</TableCell>
                    <TableCell sx={{ padding: '16px' }}>Last Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {abandonLog.map((record, index) => (
                    <TableRow key={record.id}>
                      <TableCell sx={{ padding: '16px' }}>{record.user ? record.user.name.trim() : '' || 'N/A'}</TableCell>
                      <TableCell sx={{ padding: '16px' }} className='datatable'>{record.notes ? (record.notes == 'Assign To' ? (record.notes + ' ' +(record.user ? record.user.name.trim() : '')) : record.notes == 'SSN' ? "SSN Captured" : record.notes == 'Otp' ? "OTP sent" : record.notes == 'Login Link' ?  'Login Link Sent' : record.notes == "Verification/Authentication" ?  "Authenticated" : record.notes): 'N/A' }</TableCell>
                      <TableCell sx={{ padding: '16px' }}>{record.contact_type || 'N/A'}</TableCell>
                      <TableCell sx={{ padding: '16px' }}>{record.call_type_disposition ? record.call_type_disposition.disposition : 'N/A'}</TableCell>
                      <TableCell sx={{ padding: '16px' }}>{record.call_type_reason ? record.call_type_reason.reason : 'N/A'}</TableCell>
                      <TableCell sx={{ padding: '16px' }}>{record.next_followup ? Moment(record.next_followup).format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A'}</TableCell>
                      <TableCell sx={{ padding: '16px' }}>{record.updated_at ? Moment(record.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}


export default AbandonedMemberList;