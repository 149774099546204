import React, { useMemo, useState, useEffect,useRef } from 'react'
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box, Grid, OutlinedInput, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Collapse, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {calendarFilter, useDateSelectionRange} from '../../../Components/CommonFormula';
import { format, subDays } from 'date-fns';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { Typography } from '@mui/joy';
import { CardOverflow } from '@mui/joy';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import AuthUser from '../../../Components/Auth/AuthUser';
import { MenuProps } from "./utils";
import MaterialReactTable from 'material-react-table';
import { Chart } from "react-google-charts";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@mui/material/CardActions';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarIcon from '@mui/icons-material/StarRate';
import Moment from 'moment';
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
export const options1 = {
    colors: ["#76C044", "#2A68B3"],
    height: 150,
};

function AgentDashboard(props) {
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const [freez, setFreez] = React.useState(false);
    const [agentId, setAgentId] = useState(user.roles_id == 2 ? user.id : '');
    const [agentName, setAgenName] = useState(user.roles_id == 2 ? user.name : '');
    const [adminList, setAdminList] = React.useState([]);
    const [dashboardList, setDashboardList] = React.useState([]);
    const [expanded, setExpanded] = useState(false);
    const [expandedText, setExpandedText] = useState('Show');
    const [barGraph, setBarGraph] = React.useState([]);
    const [openSlider, setOpenSlider] = React.useState(false);
    const [reviewsList, setReviwsList] = useState([]);
    const [reviewFlag, setReviewFlag] = useState(0);
    const [totalReviwsList, setTotalReviwsList] = useState([]);
    const[index,setIndex]= useState(0);
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();

    const circulateGraphPercentage = 75;
    const navigate = useNavigate();

    const options = {
        title: "Saved %",
        vAxis: { title: " %" },
        hAxis: { title: " Individual & Other" },
        seriesType: "bars",
        series: { 5: { type: "line" } },
        colors: ['#76C044', '#d6d6d6',]
    };
    const handleClickOpen = (index) => {
        setOpenSlider(true);
        setIndex(index)
    };
    const handleClickClose = () => {
        setOpenSlider(false);
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
        if (expanded) {
            setExpandedText('Show')
        } else {
            setExpandedText('Hide')
        }
    };

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const handleChangeAdmin = (e) => {
        setAgentId(e.target.value);
        if (user.roles_id == 1) {
            getAgentName(e.target.value);
        }

    };

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.callTypeReason}` + " (" + `${row.callType}` + ")",
                id: 'reason',
                header: 'Reason',
                size: 200,
            },
            
            {
                accessorFn: (row) => `${row.saves}` + '/' + `${row.allSaves}`,
                id: 'saved',
                header: 'Saved',
                size: 100,
                Cell: ({ row }) => (
                    <Box>
                        <>
                            <Typography sx={{ color: "#76C044", fontSize: "1.0rem", fontWeight: "bold", textAlign: "center" }}>
                                {`${row.original.saves}`}<span style={{ color: 'black', fontSize: "1rem", }}> / {`${row.original.allSaves}`}</span>
                            </Typography>
                        </>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => `${row.percentage}` + '/' + `${row.allPercentage}`,
                id: 'saveper',
                header: 'Save %',
                size: 100,
                Cell: ({ row }) => (
                    <Box>
                        <>
                            <Typography sx={{ color: "#76C044", fontSize: "1.0rem", fontWeight: "bold", textAlign: "center" }}>
                                {dashboardList.saveData.saves != 0 ? (row.original.saves / dashboardList.saveData.saves * 100).toFixed(2) : '0'}<span style={{ color: 'black', fontSize: "1rem", }}> / {dashboardList.saveData.totalsaves != 0 ? (row.original.allSaves / dashboardList.saveData.totalsaves * 100).toFixed(2) : '0'}</span>
                            </Typography>
                        </>
                    </Box>
                ),
            }
        ]
    );

    const agentDashboardData = async () => {
        handleFreezStart();
        const apiUrl = '/agent-dashboard-data';
        const args = {
            fromDate: startDate,
            toDate: endDate,
            agentId: agentId
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success === true) {
                setDashboardList(res.data);
                setBarGraph(res.data.barGraph);

            }

        }
        if (reviewFlag === 1) {
            handleFreezStop();
        }
    }

    const getAgentName = async (value) => {
        const apiUrl = '/fetchUserDetails/' + value;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success === true) {
                setAgenName(res.details.name);
            }

        }
    }

    const fetchAdminList = async () => {
        const apiUrl = '/fetchOnlyAdmins';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setAdminList(res);
            if (user.roles_id == 1) {
                setAgentId(res[0].id);
                setAgenName(res[0].name);
            }

        }
    };

    const fetchReviewsList = async () => {
        handleFreezStart();
        const apiUrl = '/reviews-data/1/10';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setReviwsList(res.data.reviews.reviews);
            // console.log(JSON.stringify(res))
            const url = `/reviews-data/1/${res.data.reviews.total_results}`;
            const resp = await dispatch(fetchData(url)).unwrap();
            if (resp.status == "success") {
                setTotalReviwsList(resp.data.reviews.reviews);
                handleFreezStop();
                setReviewFlag(1)
            } else {
                setReviewFlag(1)
            }
        }

    };

    useEffect(() => {
        fetchAdminList();
        fetchReviewsList();
    }, []);

    useEffect(() => {
        if (user.roles_id < 3) {
            agentDashboardData();
        } else {
            navigate('/login');
        }

    }, [agentId, startDate, endDate])
    //Clear date ranges
    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "80px",
        slidesToShow: 1,
        speed: 500,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                }
            }
        ]
    };
    const sliderRef = useRef(null);
    const goToFirstSlide=()=>{
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
            setIndex(0);
          }
    }
    
    const ContentLayout =
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <div className="marquee-container">
                    <marquee behavior="scroll" direction="left" scrollamount="4" loop="infinite">
                        {reviewsList.map((item, index) => (
                            <div onClick={()=>handleClickOpen(index)} className='card' key={index}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={3} md={3} sm={3} xs={3}>
                                        <div className="profile">
                                        <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography></div>
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={9} xs={9}>
                                        <div className='wrapper-profil-info'>
                                        <Typography level="h6">{item.displayname}</Typography>
                                        <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                        </div>
                                        {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                        <Typography level="body1" className="text-start">{(item.review && item.review.trim() !== '') ? (item.review.slice(0, 22) + "...") : (item.settings && item.settings.placeholder_text && (item.settings.placeholder_text).slice(0, 25) + "..." )}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </marquee>
                </div>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color='primary'>Hi, {agentName ? agentName : ''}! Welcome to your Dashboard :</Typography>
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value="date"
                            input={<OutlinedInput label="Select Date Range" />}
                        >
                            <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>


                            <DateRangePicker
                                className='custom-date-picker'
                                onChange={item => setSelectionRange([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={selectionRange}
                                direction="horizontal"
                                maxDate={new Date()}
                                staticRanges={staticRanges}
                            />
                        </Select>
                    </FormControl>
                </Grid>
                {user.roles_id == 1 ?
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Select Agent</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="agent"
                                name="agent"
                                value={agentId}
                                label="Select Agent"
                                MenuProps={MenuProps}
                                onChange={(e) => { handleChangeAdmin(e) }}
                            >
                                {adminList.map((row, idx) => (
                                    <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem> // Ensure each MenuItem has a unique key
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    : ''}
            </Grid>

            <Card>
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                        <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Card variant='outlined'>
                                <CardOverflow
                                    sx={{
                                        backgroundColor: "#F6FAFF", padding: '1%',
                                    }}>
                                    <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>Opportunities</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        {/* <Stack direction={'row'} justifyContent={'space-between'}> */}
                                        <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                            <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                                {dashboardList.saveData ? dashboardList.saveData.savable : 0}  <span style={{ color: 'black', fontSize: "1rem", }}>
                                                    / {dashboardList.saveData ? dashboardList.saveData.totalsavable : 0}
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8} xs={8} sx={{ alignSelf: 'center' }}>
                                            
                                            <div style={{ width: 120, height: 120 }}>
                                                <CircularProgressbar
                                                    value={dashboardList.saveData ? (dashboardList.saveData.savablePer ? dashboardList.saveData.savablePer : 0) : 0}
                                                    text={`${dashboardList.saveData ? (dashboardList.saveData.savablePer ? dashboardList.saveData.savablePer : 0) : 0}%`}
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        strokeLinecap: 'butt',
                                                        textSize: '20px',
                                                        strokeWidth: "9",
                                                        pathTransitionDuration: 0.5,

                                                        // Colors
                                                        pathColor: `rgba(118, 192, 68, ${circulateGraphPercentage / 100})`,
                                                        textColor: '#76C044',
                                                        trailColor: '#d6d6d6',
                                                        backgroundColor: '#3e98c7',

                                                    })}
                                                />
                                            </div>
                                        </Grid>
                                        {/* </Stack> */}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Card variant='outlined'>
                                <CardOverflow
                                    sx={{
                                        backgroundColor: "#F6FAFF", padding: '1%',

                                    }}>
                                    <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>Saved</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                            <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                                {dashboardList.saveData ? dashboardList.saveData.saves : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                                    / {dashboardList.saveData ? dashboardList.saveData.totalsaves : 0}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8} xs={8} sx={{ alignSelf: 'center' }}>
                                            <div style={{ width: 120, height: 120 }}>
                                                <CircularProgressbar
                                                    value={dashboardList.saveData ? (dashboardList.saveData.savePer ? dashboardList.saveData.savePer : 0) : 0}
                                                    text={`${dashboardList.saveData ? (dashboardList.saveData.savePer ? dashboardList.saveData.savePer : 0) : 0}%`}
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        strokeLinecap: 'butt',
                                                        textSize: '20px',
                                                        strokeWidth: "9",
                                                        pathTransitionDuration: 0.5,

                                                        // Colors
                                                        pathColor: `rgba(118, 192, 68, ${circulateGraphPercentage / 100})`,
                                                        textColor: '#76C044',
                                                        trailColor: '#d6d6d6',
                                                        backgroundColor: '#3e98c7',

                                                    })}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Card variant='outlined'>
                                <CardOverflow
                                    sx={{
                                        backgroundColor: "#F6FAFF", padding: '1%',
                                    }}>
                                    <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>Save %</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                            <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                                {dashboardList.saveData ? dashboardList.saveData.percentage : 0}% <span style={{ color: 'black', fontSize: "1rem", }}>
                                                    / {dashboardList.saveData ? dashboardList.saveData.totalSavePer : 0}%</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8} xs={8}>
                                            <Chart
                                                chartType="ComboChart"
                                                width="100%"
                                                height="120px"
                                                data={barGraph}
                                                options={options}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography level="h6" sx={{ fontWeight: "500" }}>{expandedText} by Save Type</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Grid>

                    </Grid>
                </CardActions>
                <Collapse in={expanded}>
                    <MaterialReactTable
                        columns={columns}
                        data={dashboardList.saveData ? dashboardList.saveData.callTypeSavableData : []}
                        enableClickToCopy
                        enableRowSelection={false}
                        initialState={{
                            showColumnFilters: false,
                            density: 'compact',
                            showGlobalFilter: false,
                        }}
                        enableHiding={false}
                        enableColumnFilters={false}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                    />
                </Collapse>
            </Card>
            <Grid item container lg={12} md={12} sm={12} xs={12} mt={2} spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12} >
                    <Card variant='outlined' sx={{ minHeight: 180, maxHeight: 250 }}>
                        <CardOverflow sx={{ backgroundColor: "#F6FAFF", padding: '1%' }}>
                            <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>
                                Contact Logs : &nbsp;&nbsp;
                                <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                    {dashboardList.totalCallData ? dashboardList.totalCallData.totalCalls : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                        / {dashboardList.outboundCallsData ? dashboardList.outboundCallsData.totalCallsofAllAgents : 0}</span>
                                </Typography>
                            </Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} textAlign={"center"}>
                                <Grid item lg={4} md={4} sm={4} xs={4} >
                                    <Typography level="h6">Inbound</Typography>
                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.inboundCallsData ? dashboardList.inboundCallsData.inboundCalls : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.inboundCallsData ? dashboardList.inboundCallsData.totalInboundCalls : 0}</span>
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Typography level="h6">Outbound</Typography>
                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.outboundCallsData ? dashboardList.outboundCallsData.outboundCalls : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.outboundCallsData ? dashboardList.outboundCallsData.totalOutboundCalls : 0}</span>
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Typography level="h6">Email</Typography>
                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.totalCallData ? dashboardList.totalCallData.emailCalls : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.outboundCallsData ? dashboardList.outboundCallsData.totalEmailCalls : 0}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} >
                    <Card variant='outlined' sx={{ minHeight: 180, maxHeight: 250 }}>
                        <CardOverflow sx={{ backgroundColor: "#F6FAFF", padding: '1%' }}>
                            <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>
                                Collect Balance
                            </Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} textAlign={"center"}>
                                <Grid item lg={6} md={6} sm={6} xs={6} >
                                    <Typography level="h6">Dialed</Typography>
                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.collectBalance ? dashboardList.collectBalance.dialed : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.collectBalance ? dashboardList.collectBalance.totalDialed : 0} </span>
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Typography level="h6">Collected</Typography>
                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.collectBalance ? dashboardList.collectBalance.collected : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.collectBalance ? dashboardList.collectBalance.totalCollected : 0}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} >
                    <Card variant='outlined' sx={{ minHeight: 180, maxHeight: 250 }}>
                        <CardOverflow sx={{ backgroundColor: "#F6FAFF", padding: '1%' }}>
                            <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>
                                First Month Save Bill Rate
                            </Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} textAlign={"center"}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>

                                    <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                        {dashboardList.agentSaveBillCount ? dashboardList.agentSaveBillCount : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                            / {dashboardList.totalSaveBillCount ? dashboardList.totalSaveBillCount : 0}</span> <span style={{ color: 'black', fontSize: "1rem", }}> ({dashboardList.saveBillPer ? dashboardList.saveBillPer : 0}%)</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid mt={2}>
                <Card variant='outlined'>
                    <CardOverflow sx={{ backgroundColor: "#F6FAFF", padding: '1%' }}>
                        <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>
                            Walkthroughs Completed : &nbsp;&nbsp;
                            <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalWalkthroughsCompleted : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                    /  {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalWalkthroughsCompletedByAllAgents : 0}</span>
                            </Typography>
                        </Typography>
                    </CardOverflow>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} textAlign={"center"}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Typography level="h6" >Completed Enrollment</Typography>
                                <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                    {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalCompletedEnrollement : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                        /  {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalCompletedEnrollementByAllAgents : 0}</span>
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Typography level="h6" >Walkthrough Completed</Typography>
                                <Typography sx={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                                    {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalCompletedWalkthrough : 0} <span style={{ color: 'black', fontSize: "1rem", }}>
                                        / {dashboardList.totalWalkthroughs ? dashboardList.totalWalkthroughs.totalCompletedWalkthroughByAllAgents : 0}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    
                </Card>
            </Grid>
           

            <Dialog
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClickClose();
                    }
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                fullWidth
                maxWidth={'md'}
                open={openSlider}
                className='slider-popup'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* Modal title */}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="slider-container agentSlider">
                        <button onClick={goToFirstSlide} className='back-button'>Go To First Review</button>
                        <Slider ref={sliderRef} {...settings} initialSlide={index}>
                            {totalReviwsList.map((item, index) => (
                                <div key={index} className="slider-card">
                                    <div className='card-slider-inner-block'>
                                        <div style={{ display: 'flex' }}>
                                            <div className="profile">
                                                <Typography level="h4" color='primary'>{(item.displayname).charAt(0)}</Typography>
                                            </div>
                                            <div className='profile-info'>
                                                <Typography level="h6">{item.displayname}</Typography>
                                                {[...new Array(item.rating)].map(_ => <StarIcon sx={{ color: "#76C044" }} />)}
                                                <Typography level="body1">{Moment(item.date).format('MM-DD-YYYY')}</Typography>
                                            </div>
                                        </div>
                                        <div className='reviews-block'>
                                            <Typography level="body1" className="text-start">{item.review ? item.review : item.settings.placeholder_text}</Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </Slider>
                    </div>
                </DialogContent>

            </Dialog>
        </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
export default AgentDashboard;