import { Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthUser from '../Components/Auth/AuthUser';
import { logout } from '../redux/authSlice';


const PrivateRoute = () => {
    const dispatch = useDispatch();
    const hasLoggedIn = localStorage.getItem('hasLoggedIn');
    
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);
    const userObj = JSON.parse(user);
    const location = useLocation();
    localStorage.setItem('redirectLocation', location.pathname);
    const cobrandStatus = localStorage.getItem('cobrandStatus');
    const admin = localStorage.getItem('admin');

    return isAuthenticated ? 
        admin ?
            <Outlet />
        :
        userObj.roles_id === 3 ?
            cobrandStatus === "true" ?
                <Outlet />
            :
            location.pathname === "/affiliate/initialcobrandsetup" ? 
                <Outlet />
            :
            <Navigate to="/affiliate/initialcobrandsetup" state={{ from: location }} replace />
        :
        <Outlet />
    : <Navigate to="/login" state={{ from: location }} replace />;

    //   return (
    //     <Route
    //       render={(props) =>
    //         isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />
    //       }
    //     />
    //   );
};

export default PrivateRoute;