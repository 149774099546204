import React from 'react';
import { Typography, Grid } from '@mui/material';
import LoginHeader from '../Components/LoginHeader';
import RegisterFooter from './Auth/RegisterFooter';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function AnnualGala(props) {
    return (
        <div>
            <LoginHeader />
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: "10% !important" }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                    <CloudUploadIcon color="primary" sx={{fontSize:"7rem"}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                    <Typography variant="h2" gutterBottom>
                        Coming Soon!
                    </Typography>
                </Grid>
            </Grid>
            <div className='footer-block'> <RegisterFooter  /></div>
        </div>
    );
}

export default AnnualGala;