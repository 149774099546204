import React, { useEffect, useState } from 'react';
import CreditTermsAndCondition from './CreditTermsAndCondition'
import CreditBegin from './Credit';
import CreditSnapFirst from './CreditSnapFirst';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';
import { snapshot } from '../../../redux/authSlice';
function CreditIndex() {
	const dispatch = useDispatch();
    const [flag,setFlag]= useState();
    const [expired,setExpired]= useState();
    const [bounced,setBounced]= useState(false);
    const [name,setName]= useState('');
    const [company,setCompany]= useState('');
    const [title,setTitle]= useState('');
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    useEffect(() => {
        FetchCreditInfo();
    }, []);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end

    //redirect function start
    const redirectfunction=()=>{
    window.location.href='/affiliate/creditsnapshotpanel'
    }
    //redirect function end
    const FetchCreditInfo = async() => {
        handleFreezStart();
        const apiUrl = '/fetchCreditInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success == true) {
                handleFreezStop();
                setFlag(res.data.snapshot_status);
                setExpired(res.data.is_pandadoc_expired);
                var fullName=res.data.reference_affiliates.first_name+ " " + res.data.reference_affiliates.last_name
                setName(fullName);
                setCompany(res.data.company.company_name);
                setTitle(res.data.company.title);
                if(res.data.bounceEmailFlag === 1){
                    setBounced(true);
                }
                await dispatch(snapshot(res.data));
                localStorage.removeItem('SnapShotStatus');
                localStorage.setItem('SnapShotStatus', res.data.snapshot_status);
            }else {
                handleFreezStop();
                alert("fail");
                        
            }
        }else{
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
        }
    }
    return (
        <>
            {
                flag == null ? (
                <CreditBegin titleData={title} nameData={name} companyData={company} />
                ) : flag == 1 ? (
                <CreditTermsAndCondition titleData={title} nameData={name} companyData={company} />
                ) : flag == 2 ? (
                    <CreditSnapFirst />
                ) : null
            }
                    {/* loader code start */}
                    <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                            // onClick={handleFreezStop}
                        >
                            <Freez />
                        </Backdrop>
                    </div>
                    {/* loader code end */}
        </>
    );
}

export default CreditIndex;