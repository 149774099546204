
import React, { useState, useRef } from "react";
import { Grid, Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement5({ highlightButton }) {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h6">disqualify and Void all Sales Commissions then payable to Referrer for all customers in the program and be subject to immediate termination of the Agreement. Referrer shall not be entitled to any future Sales Commissions from referred customers once terminated<br />
                               <br/> “Effective Date” shall be defined as the date on which the last Party (MFSN) has signed this Agreement.<br /><br />
                                “Fraud” shall be defined as: (1) any action, representation or omission by Referrer or their Sub affiliates which knowingly or intentionally attempts to and/or succeeds in creating sales, ad registrations, leads, or click-throughs and/or Conversions using robots, frames, iframes, scripts, or manual "refreshing" of pages, or any other means, for the purpose of creating illegitimate Sales Commissions. (2) Incidences of fraudulent use of credit or debit card, credit or debit card declinations resulting from insufficient funds or any other declination reason that occurs upon the first billing after the expiration of free trial period, or after the second billing (i.e., the second month’s membership fee after the expiration of the free trial period), or after billing of product purchase transactions, if applicable, will result in a Void of Sales Commissions to the Referrer. (3) If the customer rescinds their order or account with MFSN for reasons of alleged fraud or misleading and/or prohibited marketing methods used by Referrer or Sub-Referrer, it will result in a Void of
                                Sales Commissions to the Referrer. (4) if the customers referred by Referrer cancel at unacceptably high rates. Unacceptable cancelation rates will be determined in the sole discretion of MFSN. If Referrer is unable to prove to MFSN that Referrer or Sub- Referrer is not committing Fraud, Referrer will forfeit its entire Sales Commission for all programs. MFSN reserves sole judgment in determining Fraud. MSFN will provide Referrer timely notice of and any information it can lawfully provide related to Fraud incidences<br /><br />
                                “Lead” A Lead is synonymous with, and carries the same definition and meaning as, the term ”Acquisition” that is defined herein.<br /><br />
                                “Link” is defined as an MFSN approved hyperlink placed on a website that, when clicked on, serves a MFSN website to the visitor's browser. The Link may be in the form of text, a product image, a button, a banner, or any other format as is acceptable to MFSN. MFSN reserves the right to demand changes, revisions or an immediate “take down” to Referrer’s Link(s) at any time. Upon demand by MFSN for a change, revision or an immediate “take down” as to Referrer’s Link(s), Referrer will be required to implement those changes and/or modifications within a reasonable time of such request.
                                “Myfreescorenow.com” or “https://member.myfreescorenow.com shall refer to the website(s) and URL(s) www.myfreescorenow.com, or https://member.myfreescorenow.com which are owned and operated by MFSN.<br/><br/>
                                "Performance Marketing" is defined as lead generation, customer acquisition, affiliate and similar marketing programs that are designed to generate traffic to a website for an agreed compensation.<br /><br/>
                                “Referrer” shall mean the owner or operator of the URL of the webpage from which a Link was followed in order to ultimately land on MFSN’s website. It is part of the http request sent by the browser program to the webserver.<br /><br/>
                                “Sales Commission" shall mean the compensation MFSN agrees to pay Referrer in accordance with this Agreement. </Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement5;