import React, { useRef, useState, useEffect } from 'react';
import { Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup, Grid, FormControlLabel, Radio, TextField, FormHelperText, FormControl } from '@mui/material';
import { Typography } from '@mui/joy';
import config from '../../AppConfig';
import swal from "sweetalert";
import AuthUser from '../Auth/AuthUser';
import axios from 'axios';
import InputMask from 'react-input-mask';
axios.defaults.withCredentials = true;

function LogoEdit({id,setOpen,logoFlagEdit,updateFlag,logoName,logoEditURL,source,handleEditLogoURLFlag}) {
	const { httpFormData } = AuthUser();
	const [radioValue, setRadioValue] = useState('defaultLogo');
	const [imageUrl, setImageUrl] = useState(config.BASE_URL+'genericlogo.png');
	const [imageFile, setImageFile] = useState(null);
	const [stampText, setStampText] = useState('');
	const [stampTextError, setStampTextError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	
	const fileInputRef = React.useRef(null);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRadioChange = (event) => {
		setRadioValue(event.target.value);
		if(event.target.value == "defaultLogo"){
			setStampText('');
			// handleSaveImage(stampText);
			setImageUrl(config.BASE_URL+'genericlogo.png');
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "block";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "none";
		}else{
			setImageUrl(config.BASE_URL+'uploadLogo.png');
			const genericLogoGrid = document.getElementById("genericLogoGrid");
            genericLogoGrid.style.display= "none";
			const customLogoGrid = document.getElementById("customLogoGrid");
            customLogoGrid.style.display= "block";
		}
	}
	const handleStampChange = async(event) => {
		setStampText(event.target.value);
		if(event.target.value === ''){
			setStampTextError('Please add Logo text.');
		}else{
			if((event.target.value).length > 32){
				setStampTextError('Text should be in max 32 characters.');
			}else{
				setStampTextError('');
			}
		}		
		// await handleSaveImage(event.target.value);
	};

	const handleSaveImage = async () => {
		if(stampText === ''){
			setImageUrl(config.BASE_URL+'genericlogo.png');
		}else{
			var canvas = document.createElement('canvas');
			var context = canvas.getContext('2d');
			var img = new Image();	
			
			// clear the canvas
			context.clearRect(0, 0, canvas.width, canvas.height);
            
	        var regex = /\s*(?:(\S{16})|([\s\S]{1,16})(?!\S))/g;
            var cname2= stampText.replace(regex, function($0,$1,$2) { return $1 ? $1 + "\n" : $2 + "\n"; } );

			var line=cname2.split("\n");
            var linelength = line.length;

			img.crossOrigin = 'anonymous';
			img.src = config.BASE_URL+'genericlogo.png';
			img.onload = async() => {
				canvas.width = "300";
				canvas.height = "90";
				
				context.drawImage(img, 0, 0);
				context.font = '22px Arial';
				context.fillStyle = 'black';

				var count = 100 / linelength;				
				for(var i=0; i < linelength-1; i++){
					context.fillText(line[i], img.width - 200, (count + (count*i)));
				}
				// context.fillText(stampText, img.width - 200, img.height - 50);
				setImageUrl(canvas.toDataURL('image/png'));	
				// Save stampedImageUrl to database or use it as the source of an <img> tag
			};
			// img.src = imageUrl;	
		}
	}

	useEffect(()=>{			
		handleSaveImage();			
	},[stampText])

	const handleUploadFile = (event) => {
		setImageFile(event.target.files[0]);
		let textValueError = document.getElementById("uploadLogoError");
		textValueError.style.display= "none";

		setImageUrl(URL.createObjectURL(event.target.files[0]));		
		const img = new Image();
		img.src = URL.createObjectURL(event.target.files[0])
		img.onload = () => {			
			if (img.width != 300 || img.height != 90) {
				alert('Image must be 300px in width and 90px in height.');
				fileInputRef.current.value = null;
				document.getElementById("uploadedLogo").value = '';
				setImageUrl(config.BASE_URL+'uploadLogo.png');
			}
		};
	};

	const handleCancelFile = () => {
		fileInputRef.current.value = null;
		document.getElementById("uploadedLogo").value = '';
		setImageUrl(config.BASE_URL+'uploadLogo.png');
	}

	const UploadLogo = () => {
		
		const formData = new FormData(); // Create a FormData object
		if(radioValue == 'defaultLogo'){
			let textValue = document.getElementById("stampText");
			if(textValue.value === ''){
				setStampTextError('Please add Logo text.');
				return false;
			}else{
				if(textValue.length > 32){
					setStampTextError('Text should be in max 32 characters.');
					return false;
				}else{
					setStampTextError('');
				}
			}
			formData.append('uploadedLogo', imageUrl);
		}else{
			let textValue = document.getElementById("uploadedLogo");
			if(textValue.value === ''){
				let textValueError = document.getElementById("uploadLogoError");
				textValueError.style.display= "block";
				return false;
			}else{
				let textValueError = document.getElementById("uploadLogoError");
				textValueError.style.display= "none";
			}
			formData.append('uploadedLogo', imageFile);
		}

		formData.append('aidMasterId', id);
		formData.append('logoType', radioValue);

		setIsSubmitting(true);
		if(source == "company"){
			httpFormData.post('/uploadCompanyLogo',formData).then((res)=>{
				if(res.data.success === true){
					handleClose();				
					swal({
						title: "Success",
						text: res.data.message,
						icon: "success",
						Button: "Ok",
					})  
					logoFlagEdit(1);
					logoEditURL(res.data.filename);
				}else{
					logoFlagEdit(0);
					setIsSubmitting(false);
					swal({
						title: "Alert",
						text: "Something went wrong please try again",
						icon: "error",
						button: "Ok",
					})
				}            
			}).catch(error => {
				logoFlagEdit(0);
				setIsSubmitting(false);
				swal({
					title: "Alert",
					text: "Network Error",
					icon: "error",
					button: "Ok",
				})
			})
		}
		else if(source == "register"){
			// formData.append('uploadedLogo', imageFile);
			formData.append('imageUrl', imageUrl);
			logoEditURL(formData)
			handleEditLogoURLFlag(1)
			handleClose();
		}
		else{
			if(updateFlag == 1){
				formData.append('updateFlag', updateFlag);
			}
			httpFormData.post('/uploadLogo',formData).then((res)=>{
				if(res.data.success === true){
					handleClose();				
					swal({
						title: "Success",
						text: res.data.message,
						icon: "success",
						Button: "Ok",
					})  
					logoFlagEdit(1);
					logoEditURL(imageUrl);
					logoName(res.data.fileName);
				}else{
					logoFlagEdit(0);
					setIsSubmitting(false);
					swal({
						title: "Alert",
						text: "Something went wrong please try again",
						icon: "error",
						button: "Ok",
					})
				}            
			}).catch(error => {
				logoFlagEdit(0);
				setIsSubmitting(false);
				swal({
					title: "Alert",
					text: "Network Error",
					icon: "error",
					button: "Ok",
				})
			})
		}
	}
	
  

  return (
    <>      
		<DialogTitle textAlign={'center'} id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }}>
			<Typography color="primary" varient="h2">
			Upload Logo
			</Typography>
		</DialogTitle>
		<DialogContent mt={2}>
			<Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center",alignContent:'center' }}>
				<Grid>
					<img src={imageUrl} />
				</Grid>
			</Grid>
			<RadioGroup
				aria-labelledby="demo-radio-buttons-group-label"
				value={radioValue}
				name="radio-buttons-group"
				onChange={handleRadioChange}
			>
				<Grid container item lg={12} md={12} sm={12} xs={12}>				
					<FormControlLabel value="defaultLogo" control={<Radio />} label="Use Generic Logo" />				
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>(you may change later)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>Logo Text (Max 32 characters)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id="genericLogoGrid" sx={{display:'block'}}>
					<FormControl fullWidth >
						<TextField
							id="stampText"
							label="Logo Name Text"
							variant="outlined"
							margin="dense"
							size="small"
							fullWidth 
							value={stampText}
							onChange={handleStampChange}
							inputProps={{ maxLength: 32 }}
						/>
						{
							stampTextError ?
							<FormHelperText id="stampTextError" sx={{color:'red'}}>{stampTextError}</FormHelperText>
							:null

						}
					</FormControl>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<FormControlLabel value="customLogo" control={<Radio />} label="Upload Your Own Logo" />
				</Grid>
				
				<Grid container item lg={12} md={12} sm={12} xs={12}>
					<Typography>(size 300px X 90px, file size 50kb)</Typography>
				</Grid>
				<Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id="customLogoGrid" sx={{display:'none'}}>
					<TextField
						id="uploadedLogo"
						// label="Logo Name Text"
						variant="outlined"
						margin="dense"
						size="small"
						type="file" 
						name="uploadedLogo"
						fullWidth 
						ref={fileInputRef}
						onDrop={(e) => {
							e.preventDefault();
						}}
						onChange={handleUploadFile}
						onClick={handleCancelFile}
						inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}  //only accept png,jpeg,jpg formats
					/>
					<FormHelperText id="uploadLogoError" sx={{display:'none',color:'red'}}>Please choose logo image</FormHelperText>
				</Grid>
			</RadioGroup>
		</DialogContent>
		<DialogActions sx={{ display: "flex", justifyContent: "center" }}>
			<Button sx={{ minWidth: 250 }} variant="contained" color="primary" onClick={handleClose}>
			CLOSE
			</Button>
			<Button variant="contained" sx={{ minWidth: 250 }} color="warning" disabled={isSubmitting} onClick={UploadLogo}>
			SAVE
			</Button>
		</DialogActions>
    </>
  );

}


export default LogoEdit;