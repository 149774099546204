// apiSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { logout } from './authSlice';
import config from '../AppConfig';


// Async thunk action
export const fetchData = createAsyncThunk(
  'api/fetchData',
  async (url, {dispatch}) => {
    const token = localStorage.getItem('token');
    const admin = JSON.parse(localStorage.getItem('admin'));
    try {
      const response = await axios.get(config.API_BASE_URL+url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "adminid" : admin ? admin.id : '',
          // 'User-Agent': navigator.userAgent,
        },
      })
      return response.data;
    } catch (error) {
        // Handle error scenarios
        if (error.response.status === 500 || error.response.status === 401) {
            // Handle 500 error by dispatching the logout action
            dispatch(logout());
            localStorage.clear();
            throw new Error('Server error. Please try again later.');
        } else {
            throw new Error(error.response.data.message);
        }
    }
  }
);

// Async thunk action
export const postData = createAsyncThunk(
    'api/postData',
    async (data, {dispatch}) => {
      const token = localStorage.getItem('token');
      const admin = JSON.parse(localStorage.getItem('admin'));
      const { apiUrl, args} = data;
      try {
        const response = await axios.post(config.API_BASE_URL+apiUrl, args, {
          headers: {
            Authorization: `Bearer ${token}`,
            "adminid" : admin ? admin.id : '',
            // 'User-Agent': navigator.userAgent,
          },
        })
        return response.data;
      } catch (error) {
          // Handle error scenarios
          if (error.response.status === 500 || error.response.status === 401) {
              // Handle 500 error by dispatching the logout action
              dispatch(logout());
              throw new Error('Server error. Please try again later.');
          } else {
              throw new Error(error.response.data.message);
          }
      }
    }
);

// Async thunk action
export const postFormData = createAsyncThunk(
  'api/postData',
  async (data, {dispatch}) => {
    const token = localStorage.getItem('token');
    const admin = JSON.parse(localStorage.getItem('admin'));
    const { apiUrl, args} = data;
    try {
      const response = await axios.post(config.API_BASE_URL+apiUrl, args, {
        headers: {
          "Content-type" : "multipart/form-data",
          "Authorization": `Bearer ${token}`,
          "adminid" : admin ? admin.id : '',
          // 'User-Agent': navigator.userAgent,
        },
      })
      return response.data;
    } catch (error) {
        // Handle error scenarios
        if (error.response.status === 500 || error.response.status === 401) {
            // Handle 500 error by dispatching the logout action
            dispatch(logout());
            throw new Error('Server error. Please try again later.');
        } else {
            throw new Error(error.response.data.message);
        }
    }
  }
);

// Slice
const apiSlice = createSlice({
  name: 'api',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default apiSlice.reducer;