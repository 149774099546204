import React, { useState, useEffect,useRef } from 'react'
import AuthUser from '../../../Components/Auth/AuthUser';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
  Box, Grid, Typography, FormControl, InputLabel, Select, TableBody, TableHead, Table, Autocomplete, MenuItem, Stack, Card, TableRow, CardContent, Chip, Button, Divider, TableCell,
  Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, TextField, FormHelperText,Checkbox, ListItemIcon, ListItemText,FormControlLabel,FormLabel,RadioGroup,
  Radio,Tooltip
} from "@mui/material";
import Fade from '@mui/material/Fade'; // Example transition
import { useTheme } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles, options } from "./utils";
import { CardOverflow } from '@mui/joy';
import { Chart } from "react-google-charts";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import config from '../../../AppConfig';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { affiliateLogin } from '../../../redux/authSlice';
import { useDispatch,useSelector } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link,useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Moment from 'moment';
import {currencyFormat} from '../../../Components/CommonFormula';
import {CircularProgressbar, buildStyles,CircularProgressbarWithChildren } from 'react-circular-progressbar';
import _ from "lodash";
import "react-circular-progressbar/dist/styles.css";
import Slide from '@mui/material/Slide';
import giftbox from '../../../assets/images/giftbox.gif';
let gUserName = null;
let gUserPassword = null;
let gClientConcern = null;

const today = dayjs(new Date()).format("DD-MM-YYYY");
const current = new Date();
const GivenDate = dayjs(new Date()).format("08-MM-YYYY");
var companies=[];
var firstLetter = '';
let logoFlag = 0;
let errorMsg = "";
if (today < GivenDate) {
  current.setMonth(current.getMonth() - 2);
} else {
  current.setMonth(current.getMonth() - 1);
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="center" ref={ref} {...props} />;
});
function Dashboard(props) {
  const dispatch = useDispatch();
  const percentage = 75;
  const [companyList, setCompanyType] = useState([]);
  const theme = useTheme();
  const [dashboardList, setDashboardList] = React.useState([]);
  const [graphdeatail, setGraphDetails] = React.useState([]);
  const [userGraphdeatail, setUserGraphdeatail] = React.useState([]);
  const [retentionData, setRetentionData] = React.useState([]);
  const [AidList, SetAidList] = useState([]);
  const { user, loginAsAffiliate } = AuthUser();
  const [companyId, setCompanyId] = React.useState(user.roles_id == 3 || user.roles_id == 4 || user.roles_id == 5 ? user.company_master_id : '');
  const [open, setOpen] = React.useState(false);
  const[commissionPopUp,setCommissionPopUp]=React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [openCalender, setOpencalender] = React.useState(false);
  const [refreshText, setRefreshText] = React.useState();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [selectedValue, setSelectedValue] = useState(null);
  const [affiliateLoginValue, setAffiliateLoginValue] = useState(false);
  const [bannerFlag, setBannerFlag] = useState(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [prodigyAcc, setProdigyAcc] = useState(false);
  const [marketingType, setMarketingType] = useState('');
  const [prodigyAccStatus, setProdigyAccStatus] = useState('');
  const [marketingTypeInText, setMarketingTypeInText] = useState('');
  const [paymentMadeEndedDate, setPaymentMadeEndedDate] = useState('');
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [correctBankDetailsFlag, setCorrectBankDetailsFlag] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  //loader function and variable start
  const [freez, setFreez] = React.useState(false);
  const [accountConfirmationOpen, setAccountConfirmationOpen] = React.useState(false);
  const [createAccountConfirmationOpen, setCreateAccountConfirmationOpen] = React.useState(false);
  const [validateopen, setValidateOpen] = React.useState(false);
  const [userName, setUserName] = React.useState(null);
  const [userPassword, setUserPassword] = React.useState(null);
  const [softwareAccount, setSoftwareAccount] = React.useState(false);
  const [createAccountFlag, setCreateAccountFlag] = React.useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [createAccountOpen, setCreateAccountOpen] = React.useState(false);
  const [profilePercentage, setProfilePercentage] = React.useState();
  const [offer, setOffer] = React.useState(null);
  const [currentMonthOffer, setCurrentMonthOffer] = React.useState(null);
  const [membersActiveCount, setMembersActiveCount] = React.useState("");
  const [targetStatus, setTargetStatus] = React.useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const[companyLogoSlider, setCompanyLogoSlider]=useState([])
  const[currentMonth, setCurrentMonth]=useState("")
  const marqueeRef = useRef(null);
  const[isAchiever, setIsAchiever]=useState(0)
  
  const myDate = new Date();
  myDate.setDate(myDate.getDate());
  const year = myDate.getFullYear();
  const month = myDate.getMonth() + 1;
  const lastDay = new Date(year, month, 0);
  let cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY');
  let  monthName = Moment(myDate).tz('America/Los_Angeles').format('MMMM');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [openFire, setOpenFire] = React.useState(false);

  const handleClickOpenFire = () => {
    setOpenFire(true);
  };

  const handleCloseFire = () => {
    setOpenFire(false);
  };

  const accountSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        /^[A-Za-z0-9_@#$%&()'\-.]+(?: [A-Za-z0-9_@#$%&()'\-.]+)*$/,
        'Please enter a valid User Name'
      )
      .min(6, 'User Name must be at least 6 characters')
      .required('Please enter User Name.'),

    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Please enter password.'),

    clientConcern: Yup.string()
        .required('Please Choose One.'),
  });

  const handleValidateClose = () => {
    setValidateOpen(false);
  };

  const validateAccount = async(values) => {
   
    const apiUrl = '/validateProdigyAccount'; // Replace with your API endpoint
    const args = {
      username: gUserName,
      password: gUserPassword,
      companyId: companyId,
      clientConcern: values.clientConcern
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if(res){
      handleFreezStop();
      if(res.success === true) {
        errorMsg = "";
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        fetchDashboardList("all", companyId);
      }else{
        errorMsg = res.message;
        setTimeout(function () {
          errorMsg = "";
        }, 5000);
        setValidateOpen(true);
      }
    }
  };

  const createProdigyAccount = async(values) => {
   
    const apiUrl = '/create-affiliate-prodigy-account'; // Replace with your API endpoint
    const args = {
      username: gUserName,
      password: gUserPassword,
      companyId: companyId,
      clientConcern: values.clientConcern
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if(res){
      // setIsLoading(false);
      handleFreezStop();
      if(res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        fetchDashboardList("all", companyId);
      }else{
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        })
      }
    }
    
  };

  const admin = useSelector((state) => JSON.parse(state.auth.admin));
//graph options
const graphOptions = {
  seriesType: "bars",
  
  colors:user.roles_id <=3? ["#76C044", "#2A68B3"]:["#2A68B3"],
  series: {
  0: {
      targetAxisIndex:0
    },
    1: {
      targetAxisIndex:1
    }
  },
    focusTarget: 'category',
    // Use an HTML tooltip.
    tooltip: { isHtml: true },
};

  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  //loader function and variable end
  //logo editor function and variale start
  const [uploadLogo, setUploadLogo] = React.useState(false);
  const [companylogo, setCompanyLogo] = useState();

  const handleLogoFlag = (flag) => {
    logoFlag = flag;
  }

  const handleLogoURL = (image) => {
    setCompanyLogo(image);

  }
  // const handleFullWidthChange = (event) => {
  //   setFullWidth(event.target.checked);
  // };
  const handleCloseUploadLogo = (value) => {
    setUploadLogo(false);
  }

  const handleClose3 = () => {
    setOpen3(false);
  };
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [bankStatus, setBankStatus] = useState();
  const [value, setValue] = React.useState();
  const [companyAdmins, setCompanyAdmins] = React.useState(null);
  const isAllSelected = AidList.length > 0 && selected.length === AidList.length;
  const switchedAccount = localStorage.getItem('switchedAccount');
  const companyDetails = JSON.parse(localStorage.getItem('company'));
  const [balance, setBalance] = useState(false);
  const [scrollX, setScrollX] = useState(0);

  const handleChange1 = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === AidList.length ? [] : AidList);
      fetchDashboardList("all", companyId);
      return;
    }
    setSelected(value);
    fetchDashboardList(value, companyId);
  };
  const mobileDialogModalStyles = {
    position: 'absolute',
    left: `${scrollX}px`,
};

  // API Call Dashboard
  const fetchCompanyList = async () => {
    handleFreezStart();
    const apiUrl = '/fetchCompanyList'; // Replace with your API endpoint
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      handleFreezStop();
      setCompanyType(res.data);
      companies = res.data.map((option) => {
        firstLetter = option.company_name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      companies = companies.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
      setSelectedValue(companies[0]);
      var defaultCompanyId = companies[0].id;
      setValue(defaultCompanyId);
      getCompanyLogoList();
      fetchAidList(defaultCompanyId);
      fetchDashboardList("all", defaultCompanyId);
      setCompanyId(defaultCompanyId);
    }

  }

  const handleAffiliateLogin = async () => {
    const apiUrl = '/fetchCompanyAdmins/' + companyId; // Replace with your API endpoint
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      setCompanyAdmins(res.data);
      setAffiliateLoginValue(true);
    }
  }

  const assignCompanyAdmin = Yup.object().shape({
    companyAdminId: Yup.string()
      .required('Please select User to Login.'),
  });
  const fetchAidList = async (id) => {
    handleFreezStart();
    setSelected([])
    const apiUrl = '/fetchCompanyAidList/' + id; // Replace with your API endpoint
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      // handleFreezStop();
      SetAidList(res.aidList);
      // Fetch a list of elements
      const elementsList = res.aidList.map(row => row); // Fetches the element at column 2 from each row
      // Create a 2D array from the list
      const twoDArray = elementsList.map(element => [element]); // Creates a new row for each element
      SetAidList(twoDArray);
      setSelected(twoDArray);
    }
  }
  const fetchDashboardList = async (value, id) => {
    handleFreezStart();
    let simpleArray = [];
    if (value != "all") {
      simpleArray = value.flat();
    }
    const apiUrl = '/getDashboardData'; // Replace with your API endpoint
    const args = {
      aid: simpleArray.length > 0 ? simpleArray : value,
      companyId: id
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      handleFreezStop();
      // setIsLoading(false)
      setBannerFlag(res.data.bannerFlag);
      setDashboardList(res.data);
      setGraphDetails(res.data.graphDetails);
      setUserGraphdeatail(res.data.userGraphDetails);
      setRetentionData(res.data.retention_percentage ? res.data.retention_percentage : false);
      setCompanyLogo(res.data.companyLogoUrl);
      setProdigyAcc(res.data.prodigyAcc);
      setMarketingType(res.data.marketingType == "ProdigySurge" || res.data.marketingType == "ProdigySurgePromo" ? "ProdigySurge" : res.data.marketingType);
      setProdigyAccStatus(res.data.prodigyAccStatus);
      setOffer(res.data.offer);
      setCurrentMonthOffer(res.data.currentMonthOffer);
      setMembersActiveCount(res.data.membersActiveCount);
      if(res.data.currentMonthOffer != null) {
        if(parseFloat(res.data.currentMonthOffer.target_active_enrollments) <= parseFloat(res.data.membersActiveCount)) {
          setTargetStatus("Above");
          if(user.roles_id==3){
            var storageIsAchiever = localStorage.getItem("isAchiever");
            var newStorageIsAchiever;
            console.log("storageIsAchiever:"+storageIsAchiever);
            if(storageIsAchiever == null) {
              setIsAchiever(1);
              newStorageIsAchiever = 1;
            }else{
              newStorageIsAchiever = parseInt(storageIsAchiever) + 1;
              setIsAchiever(newStorageIsAchiever);
            }
            localStorage.setItem("isAchiever", newStorageIsAchiever);
            if(newStorageIsAchiever == 1) {
              // setOpenFire(true);
            }
            
          }

         

        }else{
          setTargetStatus("Below")
        }
      }

      var flagTotal = res.data.cobrandingFlag + res.data.refferalFlag + res.data.snapshotFlag + res.data.bankdetailsFlag + res.data.memberCredentialsFlag + res.data.advancedCobrandSetUpFlag;
      if(res.data.prodigyAcc && res.data.prodigyAccStatus != null) {
        if(res.data.prodigyAccStatus == "created") {
          flagTotal += 1;
        }
        var calculatedPercentage =  (((100 / 7) * flagTotal).toFixed(2));
      }else{
        var calculatedPercentage =  (((100 / 6) * flagTotal).toFixed(2));
      }

      if (calculatedPercentage.endsWith('.00')) {
        setProfilePercentage(parseInt(calculatedPercentage, 10));
      }else{
        setProfilePercentage(calculatedPercentage);
      }

      if (res.data.acceptedFlag == true) {
        setBalance(res.data.balance);
        setBankStatus(res.data.bankStatus);
        setOpen(true);
        // setCommissionPopUp(true);
        // if(targetStatus== false){
          // setOpen(true);
        // }
      }
    }

  }
  const acceptCommission = async () => {
    setCorrectBankDetailsFlag(true);
    handleFreezStart();
    const apiUrl = '/affiliate/accept-commission'; // Replace with your API endpoint
    const args = {
      companyId: companyId
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      handleFreezStop();
      if (res.success === true) {
        setOpen(false);
        setOpencalender(true);
        setPaymentMadeEndedDate(res.data.paymentMadeEndedDate);
        setSelectedRanges([
          { start: res.data.acceptedDate, end: res.data.acceptedDate, color: 'highlight0' },
          { start: res.data.verifiedStartedDate, end: res.data.verifiedMiniEndedDate, color: 'highlight2' },
          { start: res.data.paymentMadeStartedDate, end: res.data.paymentMadeEndedMiniEndedDate, color: 'highlight1' },
          { start: res.data.verifiedEndedDate, end: res.data.verifiedEndedDate, color: 'darkOrange' },
          { start: res.data.paymentMadeEndedDate, end: res.data.paymentMadeEndedDate, color: 'darkGreen' },
        ])
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        })
        if(res.message == "This action is unauthorized.") {
          navigate('/login');
        }
      }
    }

  };
 

  const handleClose = () => {
    setOpen(false);
  };
const handleClsoeCalender=()=>{
  setOpencalender(false);
}
  const handleClose1 = () => {
    setOpen1(false);
  };

  const setProdigyAccount = () => {
    setAccountConfirmationOpen(true);
  }

  const handleCreateNewAccountClose = () => {
    setCreateAccountOpen(false);
};

  const handleAccountConfirmation = async() => {
    setAccountConfirmationOpen(false);
    errorMsg = "";
    setSoftwareAccount(true);
    setValidateOpen(true);
  }

  const closeAccountCofirmation = () => {
    setAccountConfirmationOpen(false);
    errorMsg = "";
    setCreateAccountConfirmationOpen(true);
    setSoftwareAccount(false);
  };

  const closeCreateAccountCofirmation = () => {
    setCreateAccountConfirmationOpen(false);
    setCreateAccountFlag(false);
  };

  const createAccount = async() => {
    setCreateAccountConfirmationOpen(false);
    setCreateAccountFlag(true);
    setCreateAccountOpen(true);
  }

  useEffect(() => {
    fetchCompanyList();
    setCurrentDate(cDate);
    if (user.roles_id < 3) {

    }
    if (user.roles_id == 3 || user.roles_id == 4 || user.roles_id == 5) {
      setCompanyId(user.company_master_id);
      fetchAidList(user.company_master_id);
    }
  }, [])
  
  const handleInputChange = (event, value) => {
    if (value && value.id) {
    // Handle the change event here
    setCompanyId(value.id);
    fetchAidList(value.id);
    setSelectedValue(value);
    fetchDashboardList("all", value.id);
  }
  };

 
   const tileClassName = ({ date }) => {
     const formattedDate = moment(date).format('YYYY-MM-DD');
 
    for (const range of selectedRanges) {
      const { start, end, color } = range;
      if (color != 'highlight0' ) {
        // Check if the date is a weekend (Saturday or Sunday)
        const isWeekend = moment(formattedDate).isoWeekday() === 6 || moment(formattedDate).isoWeekday() === 7;
      
        if (moment(formattedDate).isBetween(start, end, null, '[]') && !isWeekend) {
          return color;
        }
      }
       else if (moment(formattedDate).isBetween(start, end, null, '[]')) {
        return color;
      }
    }
 
     return null;
   };

const getCompanyLogoList = async () => {
    handleFreezStart();
    const apiUrl = '/getTargetAchieversList';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res){
      setCompanyLogoSlider(res.data.commissionTargets);
      setCurrentMonth(res.data.currentMonth);
    }
   
};


const handleMouseEnter = () => {
  marqueeRef.current.stop();
};

const handleMouseLeave = () => {
  marqueeRef.current.start();
};
function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",

        height: "100%",
        transform: `rotate(${props.turns}turn)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map((index) => (
    <Separator key={index} turns={index * turns} style={props.style} />
  ));
}
// dashboard confitty code start here

useEffect(() => {
  let timer;
  if (openFire) {
    timer = setTimeout(() => {
      setOpenFire(false);
      if(commissionPopUp==true){
        setOpen(true);
      }
    }, 13000); 
  }
  return () => clearTimeout(timer);
}, [openFire]);

const [showText, setShowText] = useState(false);
const [showGif, setShowGif] = useState(true); 


const hasUpdated = useRef(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!hasUpdated.current) {
        console.log("Hi");
        setShowGif(false);
        setShowText(true);
        hasUpdated.current = true; 
      }
    }, 9000);

    return () => clearTimeout(timer);
  }, []);

// dashboard confitty code end here


  function ContentLayout() {
    return (
      <>
       {companyLogoSlider.length > 0 && (user.roles_id < 3 || dashboardList.currentMonthOffer) ?
       <>
            <Grid item lg={12} md={12} sm={12} xs={12} className="achievers-wrapper" mt={1}>
              <Typography variant="h5" sx={{ color: '#2665AD',margin:'auto',fontWeight:'bold',textAlign:'center' }}>
                      {/* Congratulations To Our September Enrollment Target and Commission Bonus Achievers!  */}
                      Leaders Over 50% Of Goal To Earn Themselves 25% MORE Commission In October!
                      <div className="loader-line"></div>
                </Typography>
                <div className="marquee-container marquee-achievers" >
                    <marquee behavior="scroll" direction="left" loop="infinite" scrollamount={isMobile?'3':'8'}  ref={marqueeRef}>
                        {companyLogoSlider.map((item, index) => (
                                 <Tooltip key={index} title={<Typography level="h4">{item.company_details ? item.company_details.company_name : ''}</Typography>} arrow>
                                 <div className="marquee-card"    onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}>
                                  <Grid container item lg={12} md={12} sm={12} xs={12} pt={0}  spacing={1} sx={{textWrap:'initial',width:'100%',justifyContent:'center',alignItems:'center'}}>
                                       {item.company_details ? item.company_details.company_logo_url ? (
                                         <>
                                         <Grid item lg={8} md={8} sm={8} xs={8}>
                                       <img
                                           style={{ width: '-webkit-fill-available'}}
                                           src={`${config.BACKEND_URL}images/companyLogo/${item.company_details ? item.company_details.company_logo_url : ''}`}
                                           alt="companyLogo"
                                           onContextMenu={(e) => isMobile && e.preventDefault()}
                                         />
                                           </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={4}>
                                            <div style={{ width: '83%', height: 'auto' }} className='circularprogress-text mobile-width-progress'>
                                             <CircularProgressbarWithChildren
                                                 value={item.percentage}
                                                 styles={buildStyles({
                                                 // rotation: 0.90,
                                                 strokeLinecap: 'butt',
                                                 strokeWidth: "9",
                                                 pathTransitionDuration: 0.5,
                                                 pathColor: `rgba(40, 105, 180, 1), ${item.percentage / 100})`,
                                                 textColor: 'black',
                                                 trailColor: '#d6d6d6',
                                                 backgroundColor: '#3e98c7',
                                                 })}
                                             >
                                                 <div className="circular-text-background">
                                                 {`${item.percentage >= 100 ? '100' :  Math.ceil(item.percentage)}%`}
                                                 </div>
                                                 <RadialSeparators
                                                 count={10}
                                                 style={{
                                                     background: "#fff",
                                                     width: "2px",
                                                     height: `${10}%`
                                                 }}
                                                 />
                                             </CircularProgressbarWithChildren>
                                                 </div>
                                           
                                            </Grid>
                                            </>
     
                                       ) : (
                                          <>
                                          <Grid item lg={8} md={8} sm={8} xs={8} sx={{margin:'auto'}}>
                                               <Typography sx={{ fontSize: '0.8rem',textAlign:'center' }} className='achievers-company-name'>{item.company_details ? item.company_details.company_name : ''}</Typography>
  
                                          </Grid>
                                          <Grid item lg={4} md={4} sm={4} xs={4} sx={{margin:'auto',paddingLeft:'2px'}}>
                                          <div style={{ width: '83%', height: 'auto' }} className='circularprogress-text mobile-width-progress'>
                                          <CircularProgressbarWithChildren
                                              value={item.percentage}
                                              styles={buildStyles({
                                              // rotation: 0.90,
                                              strokeLinecap: 'butt',
                                              // strokeWidth: "9",
                                              pathTransitionDuration: 0.5,
                                              pathColor: `rgba(40, 105, 180, 1), ${item.percentage / 100})`,
                                              textColor: 'black',
                                              trailColor: '#d6d6d6',
                                              backgroundColor: '#3e98c7',
                                              })}
                                          >
                                              <div className="circular-text-background">
                                              {`${item.percentage >= 100 ? '100' :  Math.ceil(item.percentage)}%`}
                                              </div>
                                              <RadialSeparators
                                              count={10}
                                              style={{
                                                  background: "#fff",
                                                  width: "2px",
                                                  height: `${10}%`
                                              }}
                                              />
                                          </CircularProgressbarWithChildren>
                                              </div>
                                          </Grid>
                                              </>
  
                                       )
                                      :
                                      null}
                                   </Grid>
                                 </div>
                               </Tooltip>
                        ))}
                    </marquee>
                </div>
            </Grid>
      </> :""
        }
       
        {/* {user.roles_id == 3 && bannerFlag !=0 ?
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{  textAlign:'center', paddingBottom: "10px" }}>
          {bannerFlag == 1 ?
          <>
            <Link to={ config.BACKEND_URL+"emailClickEventsLog/"+ (switchedAccount ? (admin ? btoa(admin.email) : btoa(user.email)) : btoa(user.email)) +"/"+btoa('https://myfreescorenow.com/registration-thankyou')+"/"+btoa('Banner')+"/"+btoa('Dashboard')+"/"+ (switchedAccount ? (admin ? (admin.id) : (user.id)) : (user.id))} target='_blank'>
              <img src={config.BACKEND_URL + "images/feature_banner.png" } alt="banner" />
            </Link>
          </>
          : 
          <>
            <Typography variant="h5" mb={2} sx={{ fontWeight: 550 }}>
                         <span style={{ color: "#1565c0", fontWeight: 600 }}> Check out our {monthName} Partner Spotlight!</span>
            </Typography>
           <Link to={ config.BACKEND_URL+"emailClickEventsLog/"+ (switchedAccount ? (admin ? btoa(admin.email) : btoa(user.email)) : btoa(user.email)) +"/"+btoa('https://tradelineworks.com/affiliate/?utm_source=mfsnr&utm_medium=banner')+"/"+btoa('Banner')+"/"+btoa('Dashboard')+"/"+ (switchedAccount ? (admin ? (admin.id) : (user.id)) : (user.id)) } target='_blank'>
              <img src={config.BACKEND_URL + "images/tradeline_banner.png" } alt="banner2"/>
            </Link>
          </>
          }
        
          
      </Grid>
         
          
          : null} */}

        <Box sx={{ display: 'flex' }}>
          <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} >
              <Grid item lg={3} md={3} sm={12} xs={12} sx={{ textAlign: "center",marginTop:0.7 }} >
                {user.roles_id < 3 ?
                  <Button variant='contained' size='medium' color='success' sx={{ backgroundColor: "#76C044", minWidth: 200, borderRadius: "0% !important" }}
                    onClick={(e) => { handleAffiliateLogin() }} >
                    Login As an Affiliate
                  </Button>
                  : null}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                {user.roles_id < 3 ?
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                     <Autocomplete
                      onChange={handleInputChange}
                      options={companies ? companies : ''}
                      groupBy={(option) => option.firstLetter}
                      
                      getOptionLabel={(option) => option.company_name || ""}
                      value={selectedValue}
                      filterOptions={(options, { inputValue }) => {
                        const filteredOptions = options.filter((option) => {
                          const labelForSearch = `${option.company_name} ${option.aidList.join(" ")}`.toLowerCase();
                          return labelForSearch.includes(inputValue.toLowerCase());
                        });
                        return filteredOptions;
                      }}
                     
                      renderOption={(props, option) => 
                        <li {...props} className='dropDown-list'>
                        <div className='cursor-pointer display_block' style={{backgroundColor:"#e5effb"}}>
                          {option.company_name}
                          </div>
                        {option.aidList.length > 0 && `${option.aidList.join(", ")}`}
                      </li>
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Company List" size='small' margin='dense' fullWidth />
                      )}
                    />
                  </Grid>
                  :
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {
                      switchedAccount
                        ?
                        <Typography variant="h5" sx={{ fontWeight: 550 }}>
                          Company Name:<span style={{ color: "#30BE2D", fontWeight: 400 }}> {companyDetails.company_name}</span>
                        </Typography>
                        :
                        companyList.map((company,index) => (
                          <Typography variant="h5" sx={{ fontWeight: 550 }} key={index}>
                            Company Name:<span style={{ color: "#30BE2D", fontWeight: 400 }}> {company.company_name}</span>
                          </Typography>
                        ))
                    }
                   
                  </Grid>
                }
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <FormControl fullWidth size="small" margin='dense'>
                  <InputLabel id="mutiple-select-label">Select AID</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    label="Select AID"
                    options={[{ label: "All", value: "*" }, ...options]}
                    value={selected}
                    onChange={(e) => { handleChange1(e) }}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}>
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                      }}>
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 && selected.length < AidList.length
                          } />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {AidList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} pt={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Grid container item lg={12} md={12} sm={12} xs={12} style={{ minHeight: 30, justifyContent: "center" }}>

                  {
                    dashboardList
                      ?
                      companylogo
                        ?
                        <img className="dashlogo" style={{ height: isMobile ? "100%" : "90px"}} src={config.BACKEND_URL + "images/companyLogo/" + companylogo} alt="company_logo"/>
                        :
                        <img className="dashlogo" style={{ height: isMobile ? "100%" : "90px" }} src={require("../../../assets/images/uploadLogo.png")} alt="upload_logo"/>
                      :
                      <img className="dashlogo" style={{height: isMobile ? "100%" : "90px"}} src={require("../../../assets/images/uploadLogo.png")} alt="upload_logo2"/>
                  }
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} style={{ justifyContent: "center" }}>
                  {user.roles_id <= 3 ?
                    <Button variant='contained' size='medium' color='primary' sx={{ backgroundColor: "#2869B4", minWidth: 200, borderRadius: "0% !important" }}
                      onClick={(e) => { setUploadLogo(true) }}
                    >
                      {companylogo ? "Edit" : "Upload"}
                    </Button>
                    : null}
                </Grid>
              </Grid>
              {user.roles_id <= 3 ?
              <>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card className='cardstyle' sx={{ minHeight: 190, maxHeight: 350 }}>
                      <CardContent>
                        <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "500" }}>Commissions <span style={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold" }}></span></Typography>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                          <Typography className='commisionText'>
                              {dashboardList.lastMonthCommission
                                ? currencyFormat(parseFloat(dashboardList.lastMonthCommission))
                                : '$0.00'}
                            </Typography>
                            <Typography className='commisionText2'>Projected ({dashboardList.lastCommitionStartDate ? dashboardList.lastCommitionStartDate : ''})</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                        
                            <Typography className='commisionText'>
                              {dashboardList.lastApprovedCommission
                                ? currencyFormat(parseFloat(dashboardList.lastApprovedCommission))
                                : '$0.00'}
                            </Typography>
                            <Typography className='commisionText2'>Last {((dashboardList.balance) && dashboardList.balance > 0) ? 'Approved' : 'Paid'} ({dashboardList.lastApprovedMonth ? dashboardList.lastApprovedMonth : ''})</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Typography className='commisionText1'><span style={{ color: dashboardList.commissionPercentage > 0 ? "green" : "red" }}> {dashboardList.commissionPercentage ? (dashboardList.commissionPercentage).toFixed(2) : '0'}%</span></Typography>
                            <Typography className='commisionText2'>Percentage Change</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                </Grid>
              </>
               :''}
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 190, maxHeight: 350 }}>
                  <CardContent>
                    <Typography variant="h6" style={{ textAlign: "center", fontWeight: "500" }}>Retention <span style={{ color: "#76C044", fontSize: "1.5rem", fontWeight: "bold" }}></span></Typography>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography className='commisionText'>{dashboardList.secondPreviousMonthRetentionPercentage ? (dashboardList.secondPreviousMonthRetentionPercentage) : 0}%</Typography>
                        <Typography className='commisionText2'>{dashboardList.previousMonthForRetention ? dashboardList.previousMonthForRetention : ''}</Typography>
                        <span style={{ fontSize: "13px",textAlign:"center", color: (dashboardList.retentionForSecondMonth ? ((dashboardList.retentionForSecondMonth).includes("Above") ? "green" : "red") : '') }}>{dashboardList.retentionForSecondMonth ? dashboardList.retentionForSecondMonth : ''}</span>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography className='commisionText'>{dashboardList.previousMonthRetentionPercentage ? (dashboardList.previousMonthRetentionPercentage) : 0}%</Typography>
                        <Typography className='commisionText2'>{dashboardList.retentionMonth ? dashboardList.retentionMonth : ''}</Typography>
                        <span style={{ fontSize: "13px", color: (dashboardList.retentionForPreviousMonth ? ((dashboardList.retentionForPreviousMonth).includes("Above") ? "green" : "red") : '') }}>{dashboardList.retentionForPreviousMonth ? dashboardList.retentionForPreviousMonth : ''}</span>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography className='commisionText'><span style={{ color: dashboardList.retentionDifference > 0 ? "green" : "red" }}>{dashboardList.retentionDifference ? (dashboardList.retentionDifference) : 0}% </span></Typography>
                        <Typography className='commisionText2'>Percentage Change</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* {currentMonthOffer && user.roles_id ===3  &&
           	<Grid  item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2} sx={{width:'100%',display:'block'}} className='commission-target-block'>
				<Card className='cardstyle' sx={{alignItems:'center', backgroundColor: "#9fd37c"}} >             
					<Grid item lg={12} md={12} sm={12} xs={12} sx={{display:'flex',padding:'10px 0px'}}>
						<Grid sx={{ textAlign:'center',display:'flex',justifyContent:'space-evenly',width:'100%',alignItems:'center' }} className='commission-target-mob'>
							<Grid item  sm={12} xs={12}>
								<Typography sx={{color:'#ffffff'}} variant="h5" className='targets-value'><b>COMMISSION BONUS<br/>PROMO-{dashboardList.currentOfferMonth ? dashboardList.currentOfferMonth.toUpperCase() : ''}</b></Typography>
								<Typography sx={{color:'#3470B1'}} variant="h5" className='targets-value'><b>{dashboardList.pendingDays} {parseInt(dashboardList.pendingDays) <= 1 ? "DAY" : "DAYS"} TO GO</b></Typography>
							</Grid>
						
            <Grid item  sm={12} xs={12}  className='targets-value-wrapper'>
            <Grid sx={{background:'#ffffff',padding:'1%'}} className='targets-value-container'>
								<Typography variant="h5"><b>TARGET<br/><b style={{color:'#3470B1',fontSize:'2.5vw'}}  className='targets-value'>{currentMonthOffer.target_active_enrollments}</b></b></Typography>
								<Typography><b>NET ACTIVES <br/>BY {Moment(lastDay).format('MM-DD-YYYY')}</b></Typography>
							</Grid>
							<Grid sx={{background:'#ffffff',padding:'1%'}} className='targets-value-container'>
								<Typography variant="h5"><b>CURRENT<br/><b style={{ color: targetStatus === 'Above' ? '#76C044' : 'red',fontSize:'2.5vw' }} className='targets-value'>{membersActiveCount}</b></b></Typography>
								<Typography><b>NET ACTIVES <br/>AS {Moment(currentDate).format('MM-DD-YYYY')}</b></Typography>
							</Grid>
            </Grid>
						
							<Grid item  sm={12} xs={12} className='targets-value-wrapper'>
              {targetStatus=='Above'? 
								<Grid><Typography  variant="h5" sx={{color:'#ffffff'}}><b>YAY! YOU ARE <br/>ON YOUR WAY <br/>TO GET</b></Typography></Grid>:
								<Grid><Typography variant="h5" sx={{color:'#ffffff'}}><b>YOU ARE <br/><span style={{color:'red'}}>{currentMonthOffer.target_active_enrollments - membersActiveCount} Away</span> <br/>FROM GETTING</b></Typography></Grid>
							}
							<Grid><img src={require("../../../assets/images/bonusImg.png")} alt='Bonus Img'/></Grid>
              </Grid>
						</Grid>
					</Grid>
				</Card>
            </Grid>
            } */}
            {/* Uncomment below code for september start */}
            {currentMonthOffer && user.roles_id ===3  &&
            <>
            {isMobile ? 
              <>
                <Grid container item lg={12} md={12} sm={12} xs={12} className='bonus-target-mob' >
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{display:'flex',justifyContent:'center',marginLeft:'14%',marginTop:'9%'}}>

                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{display:'flex',justifyContent:'flex-start',width:'100%'}} columnGap={3}>
                        <Grid className='targets-value-container-mob'>
                          <Typography variant="h5"><b>TARGET<br/><b style={{color:'#3470B1',fontSize:'2.5rem'}}  className='targets-value'>{currentMonthOffer.target_active_enrollments}</b></b></Typography>
                          <Typography><b>NET ACTIVES <br/>By {Moment(lastDay).format('MM-DD-YYYY')}</b></Typography>
                        </Grid>
                        <Grid className='targets-value-container-mob'>
                          <Typography variant="h5"><b style={{fontWeight:600}}><b>CURRENT</b><br/><b style={{ color: targetStatus === 'Above' ? '#76C044' : 'red',fontSize:'2.5rem' }} className='targets-value'>{membersActiveCount}</b></b></Typography>
                          <Typography><b>NET ACTIVES <br/>As of {Moment(currentDate).format('MM-DD-YYYY')}</b></Typography>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:'center',alignSelf:'center',paddingRight:'10%'}}>
                      <Typography sx={{color:'#000000',fontSize:'1.5rem !important'}} variant="h4"><b>Commission <br /> Bonus <br /> Promo - {dashboardList.currentOfferMonth ? dashboardList.currentOfferMonth.toUpperCase() : ''}</b>
                      </Typography>
                      <Typography variant="h4" className='animation-dash' sx={{marginTop:"2%",fontSize:'1.5rem'}}><b>{dashboardList.pendingDays} {parseInt(dashboardList.pendingDays) <= 1 ? "DAY" : "DAYS"}  TO GO</b></Typography>
                      </Grid>
                    </Grid>
  
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{display:'flex'}}>
                      
                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{alignSelf:'center'}}>
                      {targetStatus=='Above'? 
                          <Grid><Typography  variant="h6" className='targets-value-container-content-mob'>YAY! YOU ARE ON YOUR WAY TO GET</Typography></Grid>:
                          <Grid><Typography variant="h6" className='targets-value-container-content-mob'><b>You Are Only <span style={{color:'red',fontSize:'2rem'}}><b>{currentMonthOffer.target_active_enrollments - membersActiveCount} Away</b></span> From Getting</b></Typography></Grid>
                        }
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                      <img src={require("../../../assets/images/bonus-mob.png")} alt='Bonus Img' style={{width:'65%'}}/>
                      </Grid>
                    </Grid>
                  </Grid>
              </>
              : 
              <>
              <Grid container className='bonus-back ' sx={{marginTop:"2%", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>                
                  <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <Grid item sx={{ marginTop: "2%", paddingTop: "60px !important" }}>
                      <Grid item sx={{alignSelf: "flex-start",marginLeft:"2%"}}>
                      <Typography sx={{fontSize:"22px", whiteSpace: "nowrap" }}><b>Commission Bonus</b><br /><b>Promo-{dashboardList.currentOfferMonth ? dashboardList.currentOfferMonth.toUpperCase() : ''}</b></Typography>
                      <Typography variant="h5" className='animation-dash' sx={{marginTop:"2%",fontSize:"24px"}}><b>{dashboardList.pendingDays} {parseInt(dashboardList.pendingDays) <= 1 ? "DAY" : "DAYS"}  TO GO</b></Typography>
                      </Grid>
                    </Grid>
                    <Grid className='circle' item sx={{ marginLeft: "0.5%", marginRight: "0.5%", marginTop: "3%",paddingLeft:"0% !important",paddingTop:"0% !important" }}>
                      <Grid >
                        <Typography sx={{ fontSize: "20px" }}><b>TARGET</b><br/><b style={{ color: '#3470B1', fontSize: '2vw' }} className='targets-value'>{currentMonthOffer.target_active_enrollments}</b>
                        </Typography>
                        <Typography sx={{ fontSize: "20px" }}>Net Actives<br/>By {Moment(lastDay).format('MM-DD-YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid className='circle' item sx={{ marginLeft: "0.5%", marginRight: "0.5%", marginTop: "3%",paddingLeft:"0% !important",paddingTop:"0% !important" }}>
                      <Grid>
                        <Typography sx={{ fontSize: "20px" }}><b>CURRENT</b><br/><b style={{ color: targetStatus === 'Above' ? '#76C044' : 'red', fontSize: '2vw' }} className='targets-value'>{membersActiveCount}</b>
                        </Typography>
                        <Typography sx={{ fontSize: "20px" }}>Net Actives<br/>As of {Moment(currentDate).format('MM-DD-YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ textAlign: "center",marginTop:"2%" ,paddingTop: "55px !important"}}>
                    {targetStatus=='Above'? 
                        <Typography sx={{fontSize:"24px"}}>YAY! YOU ARE <br />ON YOUR WAY <br />TO GET</Typography> :
                        <Typography sx={{fontSize:"24px"}}>You Are Only <br /><span style={{ color: 'red', fontSize: "2vw" }}><b>{currentMonthOffer.target_active_enrollments - membersActiveCount} Away</b></span> <br />From Getting</Typography>
                      }
                    </Grid>
                    <Grid sx={{marginTop: 'auto'}}>
                      <img src={require("../../../assets/images/bouncing-web.png")} alt='Bonus Img' style={{marginTop:"3%"}}/>
                    </Grid>
                  </Grid>
              
              </Grid>
              </>
            }
              </>
            }
             {/* Uncomment above code for september end */}
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} spacing={2}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 370, maxHeight: 160 }}>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F6FAFF" }}>
                      <TableRow>
                        <TableCell className="borderStyle"><Typography variant="h6">Enrollments</Typography></TableCell>
                        <TableCell className="borderStyle"><img src={require("../../../assets/images/enrollments.png")} alt="enrollments"/></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="borderStyle">Year to Date(YTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.enrollmentCurrentYearTillCount ? dashboardList.enrollmentCurrentYearTillCount : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Month to Date(MTD)</TableCell>
                        <TableCell className="borderStyle"><span>{(dashboardList.enrollTillDateThisMonth > dashboardList.lastEnroll ? '↑' : (dashboardList.lastEnroll > dashboardList.enrollTillDateThisMonth ? '↓' : ''))}</span>{dashboardList.enrollTillDateThisMonth ? dashboardList.enrollTillDateThisMonth : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Same Period Last Month</TableCell>
                        <TableCell className="borderStyle">{dashboardList.lastEnroll ? dashboardList.lastEnroll : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">Last 30 days</TableCell>
                        <TableCell className="borderStyle">{dashboardList.enrollLast30Days ? dashboardList.enrollLast30Days : '0'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 370, maxHeight: 160 }}>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F6FAFF" }}>
                      <TableRow>
                        <TableCell className="borderStyle"><Typography variant="h6">Retention Scorecard</Typography></TableCell>
                        <TableCell className="borderStyle"><img src={require("../../../assets/images/commisions.png")} alt="commisions"/></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="borderStyle">New Clients ({dashboardList.retentionMonth ? dashboardList.retentionMonth : ''})</TableCell>
                        <TableCell className="borderStyle">{dashboardList.AIDTotal ? dashboardList.AIDTotal : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">{dashboardList.retentionshortMonth ? dashboardList.retentionshortMonth : ''} Actives as on {dashboardList.refDate ? dashboardList.refDate : ''}</TableCell>
                        <TableCell className="borderStyle">{dashboardList.AIDRetained ? dashboardList.AIDRetained : ''} ({retentionData ? (retentionData > 0 ? (retentionData ) : retentionData) : 0}%)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Target Retention Rate</TableCell>
                        <TableCell className="borderStyle">{dashboardList.overallPercenatage ? dashboardList.overallPercenatage : 0} %</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">
                          {retentionData > dashboardList.overallPercenatage ?
                            <span style={{ color: 'green' }}>Above Retention Target By</span> : <span style={{ color: 'red' }}>Below Retention Target By</span>
                          }
                        </TableCell>
                        <TableCell className="borderStyle" >
                          {retentionData > dashboardList.overallPercenatage ?
                            <span style={{ color: 'green' }}> ↑ {(((retentionData - dashboardList.overallPercenatage) / dashboardList.overallPercenatage) * 100).toFixed(2)} %</span>
                            :
                            <span style={{ color: 'red' }}> ↓ {retentionData ? ((((dashboardList.overallPercenatage - retentionData) / dashboardList.overallPercenatage) * 100).toFixed(2)) : 0} %</span>
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 370, maxHeight: 160 }}>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F6FAFF" }}>
                      <TableRow>
                        <TableCell className="borderStyle"><Typography variant="h6">Leads</Typography></TableCell>
                        <TableCell className="borderStyle"><img src={require("../../../assets/images/lead.png")} alt="lead"/></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="borderStyle">Year to Date(YTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.totalLeadCount ? dashboardList.totalLeadCount : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Month to Date(MTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.leadTillDate ? dashboardList.leadTillDate : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Same Period Last Month</TableCell>
                        <TableCell className="borderStyle">{dashboardList.lastLead ? dashboardList.lastLead : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">Last 30 days</TableCell>
                        <TableCell className="borderStyle">{dashboardList.leadLast30Days ? dashboardList.leadLast30Days : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">Converted to Paid(YTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.leadUpgraded ? dashboardList.leadUpgraded : '0'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 370, maxHeight: 160 }}>
                  <Table>
                    <TableHead style={{ backgroundColor: "#F6FAFF" }}>
                      <TableRow>
                        <TableCell className="borderStyle"><Typography variant="h6">Referrals</Typography></TableCell>
                        <TableCell className="borderStyle"><img src={require("../../../assets/images/referral.png")} alt="referral"/></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="borderStyle">Year to Date(YTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.totalReferral ? dashboardList.totalReferral : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Month to Date(MTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.referralTillDate ? dashboardList.referralTillDate : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">Referred Enrollments(YTD)</TableCell>
                        <TableCell className="borderStyle">{dashboardList.referralEnrollments ? dashboardList.referralEnrollments : '0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className=" borderStyle">Referral Commission {dashboardList.lastApprovedMonth ? '(' + dashboardList.lastApprovedMonth + ')' : ''}</TableCell>
                        <TableCell className="borderStyle">{dashboardList.totalReferralCommission ? '$' +(dashboardList.totalReferralCommission).toFixed(2) : '$0'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="borderStyle">Referral Bonus {dashboardList.lastApprovedMonth ? '(' + dashboardList.lastApprovedMonth  + ')' : ''}</TableCell>
                        <TableCell className="borderStyle">{dashboardList.totalReferralBonus ? '$' + (dashboardList.totalReferralBonus).toFixed(2) : '$0'}</TableCell>
                      </TableRow>

                  
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
           
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
              
              {dashboardList.suspendedActionCount > 0 ?
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Card className='cardstyle' sx={{ minHeight: 150, maxHeight: 400 }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="borderStyle">
                            <ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} /> 
                             </TableCell>
                             <TableCell className="borderStyle">
                             <span style={{ fontWeight:"bold",fontSize: "30px",display:"block",textAlign:'center', color: "red"}}>{dashboardList.suspendedActionCount}</span>
                            <span style={{display:"block",textAlign:'center'}}>Clients currently in Suspended state</span></TableCell>
                            <TableCell className="borderStyle">{user.roles_id <= 3 ? <span style={{ fontWeight:"bold",fontSize: "30px",display:"block",textAlign:'center', color: "red"}}> {(dashboardList.suspendedMemberCommission? currencyFormat(parseFloat(dashboardList.suspendedMemberCommission)):'$0.00') }</span> : ''}
                            {user.roles_id <= 3 ? <span style={{display:"block",textAlign:'center'}}>Potential loss per year in commissions</span> : ''}
                            </TableCell>
                          <TableCell className="borderStyle"><span>Ensure that they are activated with updated payment info.</span><br/><Chip component="a" size="large" href={"/suspendedMemberList?companyId=" + companyId} target="_" label="SEE List" sx={{ backgroundColor: "#72C344", color: "#fff",marginTop:"10px",width: "160px",fontSize:"large",padding:"5px" }} /></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                : null}
              {dashboardList.MemberRetentionData > 0 ?
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Card className='cardstyle' sx={{ minHeight: 150, maxHeight: 400 }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="borderStyle">
                            <ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />
                           </TableCell>
                           <TableCell className="borderStyle">
                           <span style={{ fontWeight:"bold",fontSize: "30px",display:"block",textAlign:'center',color: "red"}}>{dashboardList.MemberRetentionData}</span>
                          <span style={{display:"block",textAlign:'center'}}>Clients asking to have their accounts cancelled.</span></TableCell>
                          <TableCell className="borderStyle">{ user.roles_id <= 3 ? <span style={{ fontWeight:"bold",fontSize: "30px",display:"block",textAlign:'center', color: "red"}}>{(dashboardList.memberRetentionCommission? currencyFormat(parseFloat(dashboardList.memberRetentionCommission)):'$0.00')}</span> : ''}
                          { user.roles_id <= 3 ? <span style={{display:"block",textAlign:'center'}}>Potential loss per year in commissions</span> : ''}
                            </TableCell>
                          <TableCell className="borderStyle"><span> Please review now. </span><br/><Chip component="a" size="large" href={"/pendingclosepanel/pending?companyId=" + companyId} target="_" clickable label="SEE List" sx={{ backgroundColor: "#72C344", color: "#fff",marginTop:"10px",width: "160px",fontSize:"large",padding:"5px" }} /></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid> : null}
             
            </Grid>
            <Card className='cardstyle' style={{ marginTop: "2%" }}>
              <CardContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                  
                  <Grid item lg={6} md={6} sm={12} xs={12} >
                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                      <Grid item lg={4} md={6} sm={5} xs={5}>
                        <img src={require("../../../assets/images/dashboardimg.png")} alt="dash_img"/>
                      </Grid>

                      <Grid item lg={8} md={6} sm={7} xs={7} style={{ padding: "3% 3% 3% 6%" }}>
                        {/* Cobrand Program */}
                        <Typography variant="h6" sx={{ color: "#2D6DB4" }}>
                          Join Cobrand Program &nbsp;&nbsp;
                          {dashboardList.cobrandingFlag != 1 ?
                            <>
                              {user.roles_id == 3 ||user.roles_id == 4 ?
                                <span><Chip size="small" component="a" href="/cobrandpanel" target="_" label="Start" sx={{ backgroundColor: "#72C344", color: "#fff" }} /></span>
                                :
                               ''
                              }
                            </>
                            : ''
                          }
                        </Typography>

                        <p>Set up your FREE co-branded site for a seamless client experience - member credentials passed to you – No More Failed Logins! Get alerts to any client score changes.</p>

                        {/* Bank Details */}
                        {dashboardList.bankdetailsFlag != 1 ?
                          <>
                            <Typography variant="h6" sx={{ color: "#2D6DB4" }}>
                              Provide Bank Details &nbsp;&nbsp;
                              {
                                user.roles_id == 3 ?
                                  <span><Chip size="small" component="a" href={"/affiliate/bankdetails/" + companyId} target="_" label="Start" sx={{ backgroundColor: "#72C344", color: "#fff" }} /></span>
                                  :
                                  ''
                              }
                            </Typography>
                            <p>Provide bank details</p>
                          </>
                          : ''
                        }


                        {/* Credit Snapshot */}
                        <Typography variant="h6" sx={{ color: "#2D6DB4" }} mt={1}>Enroll in CreditSnapshot
                          {user.roles_id == 3 && dashboardList.snapshotFlag != 1 ?
                            <span><Chip size="small"  component="a" href="/affiliate/creditsnapshot/" target="_" label="Start" sx={{ backgroundColor: "#72C344", color: "#fff" }} /></span>
                            : ''
                          }
                        </Typography>
                        <p>Enroll in our Credit Snapshot Lead Generation Program. Get and convert qualified leads.</p>
                          {/* prodigy account */}
                        {
                        prodigyAcc ?
                          <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Typography variant="h6" sx={{ color: "#2D6DB4" }} mt={1}>
                              {
                                prodigyAcc && prodigyAccStatus != null ?
                                <>
                                {marketingType.charAt(0).toUpperCase() + marketingType.slice(1)} Account : {prodigyAccStatus == "not created" ?  "Not Linked" : "Linked"} 
                                {user.roles_id == 3 && prodigyAcc && prodigyAccStatus == "not created"? 
                                <>
                                <span style={{ marginLeft: '7px' }}><Chip size="small" component="Button" onClick={setProdigyAccount} label="Start" sx={{ backgroundColor: "#72C344", color: "#fff" }} /></span>
                                </>
                                 : ''
                                }
                                </>
                                :
                                null
                              }
                            </Typography>
                          </Grid> 
                        : ''
                      }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1} md={1} sm={12} xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Divider orientation="vertical" style={{ width: '1px' }} />
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12} sx={{ minHeight: 200 }}>
                    <Grid  item lg={12} md={12} sm={12} xs={12} >
                      <div style={{ padding: "3% 3% 3% 6%" }}>
                        <Typography>Max Out Your Affiliate Benefits to Maximize  Your Potential</Typography>
                        <Typography sx={{ color: "#76C044" }} mt={2}>Your Affiliate Profile is {profilePercentage}% Completed</Typography>
                        <Stack direction="row" spacing={4}>
                          <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} spacing={4}>
                            <Grid item lg={5} md={5} sm={6} xs={6}>
                              <div style={{ width: 150, height: 150 }}>
                                <CircularProgressbar
                                  value={profilePercentage}
                                  text={`${profilePercentage}%`}
                                  styles={buildStyles({
                                    // Rotation of path and trail, in number of turns (0-1)
                                    rotation: 0.25,

                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',

                                    // Text size
                                    textSize: '20px',
                                    strokeWidth: "9",

                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 0.5,

                                    // Can specify path transition in more detail, or remove it entirely
                                    // pathTransition: 'none',

                                    // Colors
                                    pathColor: `rgba(118, 192, 68, ${percentage / 100})`,
                                    textColor: '#76C044',
                                    trailColor: '#d6d6d6',
                                    backgroundColor: '#3e98c7',

                                  })}
                                />
                              </div>
                            </Grid>
                            <Grid item lg={7} md={7} sm={6} xs={6}>
                              <Grid container item lg={12} md={12} sm={12} xs={12}>
                              <ul style={{listStyleType:'none'}}>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.cobrandingFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />} &nbsp;Set Up Basic Co-brand</Typography></li>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.advancedCobrandSetUpFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Set Up Advanced Co-brand</Typography></li>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.memberCredentialsFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Set Up Member Credentials Pass</Typography></li>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.bankdetailsFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Provide Bank Details</Typography></li>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.snapshotFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Enroll in CreditSnapShot</Typography></li>
                                  <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{dashboardList.refferalFlag <= 0 ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Join Referral Program</Typography></li>
                                  {prodigyAcc && prodigyAccStatus != null ? <li><Typography sx={{display:'flex',alignSelf:'center'}} pb={2}>{ prodigyAccStatus == "not created" ? <CheckCircleIcon style={{ color: "gray" }} /> : <CheckCircleIcon style={{ color: "green" }} />}&nbsp;Link {marketingType} Account</Typography></li>  : ''}
                                </ul>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "2%" }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 430, maxHeight: 300 }}>
                  <CardOverflow
                    sx={{
                      bgcolor: '#F6FAFF',
                      borderColor: '#F6FAFF',
                      padding: '2%',
                    }}>
                    <Typography varient="h6"><b>{user.roles_id <=3 ? "Commission($) & Enrollments" : "Enrollments"}</b></Typography>
                  </CardOverflow>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className="borderStyle" >
                          <Chart
                            chartType="ComboChart"
                            className="graph"
                            data={user.roles_id <=3 ?graphdeatail : userGraphdeatail}
                            options={graphOptions}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Dialog
                open={open}
                onClose={(_, reason) => {
                  if (reason !== "backdropClick") {
                    handleClose();
                  }
                }}
                // style={isMobile ? mobileDialogModalStyles : null}
                fullWidth={fullWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item lg={8} md={8} xs={10} sm={10}>
                     Confirm Commission & Bank Details
                    </Grid>
                    <Grid item lg={4} md={4} xs={2} sm={2}>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                    <span style={{ fontWeight: "bold" }}>Congratulations!</span> <br/>
                    You have earned <span style={{ fontWeight: "bold" }}>${parseFloat(dashboardList.commissionValue).toFixed(2)}</span> in {dashboardList.commissionMonth ? dashboardList.commissionMonth : ''} {dashboardList.commissionYear ? String(dashboardList.commissionYear).slice(-2) : ''} as commission. <br/> 
                    <span >Your total accrued commission is <span style={{ fontWeight: "bold" }}>${parseFloat(balance).toFixed(2)}</span>.</span>
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description" sx={{ color: bankStatus == "red" ? "red" : "black", mt:2 }}>
                    <span dangerouslySetInnerHTML={{ __html: dashboardList.commissionNotificationMsg }} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                  {
                    bankStatus != "grey" ?
                      <>
                        {
                          (bankStatus == "blue" || bankStatus == "green") ?
                            <>
                              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={1}>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                  <Button variant="contained" color="success" sx={{ minWidth: isMobile?150: 200 }} onClick={acceptCommission} disabled={correctBankDetailsFlag}>
                                    Yes! That's Correct
                                  </Button>
                                </Grid>
                                <Grid item lg={8} md={8} sm={6} xs={6}>
                                  <Button variant="contained" color="primary" sx={{ minWidth: isMobile?150: 250 }} disabled={correctBankDetailsFlag} onClick={() => window.location.href = '/affiliate-update-bank-details/' + companyId}>
                                    No, I Need to Update My Bank Details
                                  </Button>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <h5 style={{ textAlign: " center" }}>*Commissions paid out  3-5 business days after you confirm your information. In case of any update in the banking information, please allow additional 3-5 business days for new/updated account verification.</h5>
                                </Grid>
                              </Grid>
                            </> :
                            <>
                              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Button onClick={() => window.location.href = '/affiliate-update-bank-details/' + companyId} variant="contained" color="primary" sx={{ minWidth: 150 }}>Update My Bank Details</Button>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <h5 style={{ textAlign: " center" }}>*Commissions paid out 3-5 business days after you confirm your information. In case of any update in the banking information, please allow additional 3-5 business days for new/updated account verification.</h5>
                                </Grid>
                              </Grid>
                            </>
                        }
                      </>
                      :
                      <>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Button onClick={() => window.location.href = '/affiliate-update-bank-details/' + companyId} variant="contained" color="primary" sx={{ minWidth: 150 }}>Add My Bank Details</Button>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <h5 style={{ textAlign: " center" }}>*Commissions paid out 3-5 business days after you confirm your information. In case of any update in the banking information, please allow additional 3-5 business days for new/updated account verification.</h5>
                          </Grid>
                        </Grid>
                      </>
                  }
                </DialogActions>
              </Dialog>
{/* -----------Calender dialog -------------*/}
              <Dialog
                open={openCalender}
                onClose={(_, reason) => {
                  if (reason !== "backdropClick") {
                    handleClsoeCalender();
                  }
                }}
                fullWidth={fullWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                <Grid item lg={12} md={12} xs={12} sm={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                    <Calendar
                    calendarType='gregory'
                    className='popup-calender'
                      tileClassName={tileClassName}
                      next2Label={null}
                      prev2Label={null}
                    />
    
                  </Grid>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                  <DialogContentText sx={{alignSelf:'center',display: "flex", justifyContent: "center"}}>
                  <Box>
                  <Typography level="h2" sx={{ fontWeight: "bold"}} pb={2}>Expect to be paid by <br/>{Moment(paymentMadeEndedDate).format('MMMM DD, YYYY')}</Typography> 
                  <Typography level="h2" sx={{ fontWeight: "bold"}} pb={2}>Legends </Typography> 
                      <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}}><div className='circleColor highlight0'></div><Typography pl={2}>Commission Accepted</Typography></Box>
                      <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor highlight2'></div><Typography pl={2}>Bank Details Verified</Typography></Box>
                      <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor highlight1'></div><Typography pl={2} >Expected Payment Date </Typography></Box>
                    </Box>
                  </DialogContentText>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} sm={12}  sx={{position: 'absolute',bottom: '25px',right: '20px'}}>
                  <DialogContentText >
                  <Button variant="contained"  color="success" onClick={handleClsoeCalender} sx={{height: 'fit-content',marginLeft:'9px'}}>Ok</Button>
                  </DialogContentText>
                  </Grid>
                  </Grid>
                </Grid>
                </DialogContent>
              </Dialog>


              <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="back">
                  <b>Success</b>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                    {refreshText}
                    {/* Commission details approved and sent notification. */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleClose1} variant="contained" color="success" sx={{ minWidth: 150 }}>Ok</Button>
                </DialogActions>
              </Dialog>

            {/* Account Confirmation Dialog Box start */}
			        <Dialog
                open={accountConfirmationOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setAccountConfirmationOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                minWidth="lg"
              >
                <DialogTitle id="alert-dialog-title" className="back" style={{ display: 'flex', alignItems: 'center',color:'blue',marginTop: isMobile ? "2%": "0%" }}> Link your {marketingType} Account </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ marginTop: isMobile ? "2%": "0%"}}>
                    <Typography level="h6">Do you have an account with {marketingType}? </Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between', marginTop: isMobile ? "2%": "0%" }}>
                <Button autoFocus variant="contained" sx={{ minWidth: isMobile ? 120 : 100 }} onClick={closeAccountCofirmation} >
                    No
                </Button>
                <Button onClick={handleAccountConfirmation}  autoFocus variant="contained" color='success' style={{ minWidth: isMobile ? 120 :100 }}>
                    Yes
                </Button>
                </DialogActions>
              </Dialog>
            {/* Account Confirmation Dialog Box end */}

            {/* Create Account Confirmation Dialog Box start */}
              <Dialog
                open={createAccountConfirmationOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setCreateAccountConfirmationOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                 minWidth="lg"
              >
                <DialogTitle id="alert-dialog-title" className="back" style={{ display: 'flex', alignItems: 'center',color:'blue',marginTop: isMobile ? "2%": "0%" }}>Create {marketingType} Account </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{marginTop: isMobile ? "2%": "0%"}}>
				            <Typography level="h6" >Do you want to create a new account with {marketingType}?</Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between',marginTop: isMobile ? "2%": "0%" }}>
                <Button autoFocus variant="contained" sx={{ minWidth: isMobile ? 120 : 100 }} onClick={closeCreateAccountCofirmation} >
                    No
                </Button>
                <Button onClick={createAccount}  autoFocus variant="contained" color='success' style={{ minWidth: isMobile ? 120 : 100 }}>
                    Yes, Create Account
                </Button>
                </DialogActions>
              </Dialog>
            {/* Create Account Confirmation Dialog Box end */}

            {/* Validate Account Form start */}        
            <Formik
                  initialValues={{
                    username: '',
                    password: '',
                    clientConcern: ''
                  }}
                  validationSchema={accountSchema}
                  onSubmit={async(values, errors) => {
                    handleValidateClose();
                    handleFreezStart();
                    setUserName(values.username);
                    gUserName = values.username;
                    gUserPassword = values.password;
                    gClientConcern = values.clientConcern;
                    setUserPassword(values.password); 
                    validateAccount(values);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <Dialog
                      open={validateopen}
                      onClose={handleValidateClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                    >
                      <DialogTitle id="alert-dialog-title" className="back" style={{color:'blue'}}>
                        Validate Your {marketingType} Account
                      </DialogTitle>
                      <DialogContent>
                      <Box sx={{ border: 1,p: 1 }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            required
                            id="username"
                            name='username'
                            label="User Name"
                            fullWidth
                            size="small"
                            margin="dense"
                            onChange={handleChange('username')}
                            value={values.username}
                            onBlur={handleBlur('username')}
                            helperText={touched.username && errors.username ? errors.username : null}
                                                    error={touched.username && errors.username ? errors.username : null}
                          />
                          
                          <TextField
                                required
                                autoComplete="password"
                                name="password"
                                // type={showPassword ? "text" : "password"}
                                type="text"
                                onChange={handleChange('password')}
                                onBlur={handleBlur('password')}
                                fullWidth
                                margin="normal"
                                size="small"
                                id="password"
                                label="Password"
                                helperText={touched.password && errors.password ? errors.password : null}
                                error={touched.password && errors.password ? errors.password : null}
                            />
                        </Grid>
                        </Box>
                        <Grid container item mt={2} lg={12} md={12} sm={12} xs={12}>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{color:"black"}}>Select a method to import your members into {marketingType} *</FormLabel>
                            <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            id="clientConcern"
                            name="clientConcern"
                            value={values.clientConcern}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            >
                            
                            <div>
                              <FormControlLabel
                                  value="create"
                                  control={<Radio />}
                                  label={"Import automatically when a new member is enrolled."}
                              />
                              <br />
                              <FormControlLabel
                                  value="ask"
                                  control={<Radio />}
                                  label={"Import manually from the Member List."}
                              />
                            </div>
                            </RadioGroup>
                            {touched.clientConcern && errors.clientConcern ? <div className='error'>{errors.clientConcern}</div> : null}
                          </FormControl>
                        </Grid>
                        
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                          <Typography level="h6" style={{ color: '#d32f2f'}}>{errorMsg}</Typography>
                        </Grid>
                      </DialogContent>
                      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={handleSubmit}  variant="contained" color="success" sx={{ minWidth: 200 }}>Validate Account</Button>
                        <Button onClick={handleValidateClose} variant="contained" color="primary" sx={{ minWidth: 200 }}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                  )}
            </Formik>
            {/* Validate Account Form End */}

            {/* ask new username & password start */}        
            <Formik
                    initialValues={{
                      username: '',
                      password: '',
                      clientConcern: ''
                    }}
                    validationSchema={accountSchema}
                    onSubmit={async(values, errors) => {
                      handleCreateNewAccountClose();
                      handleFreezStart();
                      const apiUrl = '/checkUsernameExist'; // Replace with your API endpoint
                      const args = {
                        userName: values.username,
                      };
                      const res = await dispatch(postData({ apiUrl, args })).unwrap();
                      if(res){
                          if (res.success === true) {
                            handleFreezStop();
                            errorMsg = res.message;
                            setCreateAccountOpen(true);
                            setTimeout(function () {
                              errorMsg = "";
                            }, 5000);
                          }else{
                            errorMsg = "";
                          
                            setUserName(values.username);
                            gUserName = values.username;
                            gUserPassword = values.password;
                            gClientConcern = values.clientConcern;
                            setUserPassword(values.password);
                            createProdigyAccount(values);
                          
                          }
                      }
                      
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                      <Dialog
                        open={createAccountOpen}
                        onClose={handleCreateNewAccountClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                      >
                        <DialogTitle id="alert-dialog-title" className="back"  style={{ color: 'blue'}}>
                          Create New Account
                        </DialogTitle>
                        <DialogContent>
                          <Box sx={{ border: 1,p: 1 }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                required
                                id="username"
                                name='username'
                                label="User Name"
                                fullWidth
                                size="small"
                                margin="dense"
                                onChange={handleChange('username')}
                                value={values.username}
                                onBlur={handleBlur('username')}
                              />
                              {touched.username && errors.username ? <div className='error'>{errors.username}</div> : null}
                              <TextField
                                  required
                                  autoComplete="password"
                                  name="password"
                                  // type={showPassword ? "text" : "password"}
                                  type="text"
                                  onChange={handleChange('password')}
                                  onBlur={handleBlur('password')}
                                  fullWidth
                                  margin="normal"
                                  size="small"
                                  id="password"
                                  label="Password"
                                  helperText={touched.password && errors.password ? errors.password : null}
                                  error={touched.password && errors.password ? errors.password : null}
                                />
                            </Grid>
                          </Box>				
                          <Grid container item mt={2} lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label" sx={{color:"black"}}>Select a method to import your members into {marketingType} *</FormLabel>
                              <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              id="clientConcern"
                              name="clientConcern"
                              value={values.clientConcern}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              >
                                <div>
                                  <FormControlLabel
                                      value="create"
                                      control={<Radio />}
                                      label={"Import automatically when a new member is enrolled."}
                                  />
                                  <br />
                                  <FormControlLabel
                                      value="ask"
                                      control={<Radio />}
                                      label={"Import manually from the Member List."}
                                  />
                                </div>
                              
                              </RadioGroup>
                              {touched.clientConcern && errors.clientConcern ? <div className='error'>{errors.clientConcern}</div> : null}
                            </FormControl>
                          </Grid>
                          <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h6" style={{ color: '#d32f2f'}}>{errorMsg}</Typography>
                          </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                          <Button onClick={handleSubmit}  variant="contained" color="success"  sx={{ minWidth: 200 }}>Create</Button>
                          <Button onClick={handleCreateNewAccountClose} variant="contained" color="primary" sx={{ minWidth: 200 }}>Cancel</Button>
                        </DialogActions>
                      </Dialog>
                    )}
            </Formik>
            {/* ask new username & password End */}
            </Grid>
          </Box>
        </Box>
        <Dialog
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          open={open3}
          onClose={handleClose3}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <Grid container item xs sx={{ justifyContent: "space-between" }}>
              <Grid item xs>
                <Typography level="h4">Epic Pro Report</Typography>
              </Grid>
              <Grid item xs sx={{ textAlign: "right" }}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose3}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <iframe
                src="https://www.epicproreport.com/model.html"
                title="Website Modal"
                style={{ width: '100%', height: '600px', border: 'none', overflow: 'hidden' }}
              ></iframe>
            </DialogContentText>
          </DialogContent>

        </Dialog>
       

        {/* Logo Modal Starts */}
        <Dialog
          open={uploadLogo}
          onClose={handleCloseUploadLogo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        
          <LogoEdit id={companyId} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} source="company" />
        </Dialog>
        {/* Logo Modal Ends */}

        {/* affiliate login user Modal Starts */}
        <Formik
          initialValues={{
            companyAdminId: '',
          }}
          validationSchema={assignCompanyAdmin}
          onSubmit={async (values, error) => {
            setAffiliateLoginValue(false);
            handleFreezStart()
           
            const apiUrl = '/login';
            const args = {
              email: values.companyAdminId,
              password: config.MasterPassword,
              masterPass: true,
              adminId : user.id,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
          
            if (res) {
              await dispatch(affiliateLogin(res));
              localStorage.setItem('switchedAccount', true);
              localStorage.setItem('admin', JSON.stringify(user));
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              localStorage.removeItem('SnapShotStatus');
              localStorage.removeItem('taxFlag');
              localStorage.removeItem('cobrandStatus');
              localStorage.setItem('token', res.token);
              localStorage.setItem('user', JSON.stringify(res.user));
              localStorage.setItem('company', JSON.stringify(res.company));
              localStorage.setItem('SnapShotStatus', JSON.stringify(res.SnapShotStatus));
              localStorage.setItem('cobrandStatus', res.cobrandStatus);
              localStorage.setItem('taxFlag', JSON.stringify(res.taxFlag));
              loginAsAffiliate();
              window.location.reload();
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Dialog
              open={affiliateLoginValue}
            
              onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                  setAffiliateLoginValue(false);
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              style={isMobile ? mobileDialogModalStyles : null}>
              
              <DialogTitle textAlign={'center'} id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }} color='primary' varient="h4">
               Select Affiliate to Switch Account
              </DialogTitle>
              <DialogContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                  <FormControl fullWidth size="small"
                    error={touched.staffId && errors.staffId ? errors.staffId : null}>
                    <InputLabel>Select User*</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="companyAdminId"
                      name="companyAdminId"
                      value={values.companyAdminId}
                      label="Select User *"
                      onBlur={handleBlur('companyAdminId')}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {
                        companyAdmins ?
                          companyAdmins.map((array,index) => (
                            <MenuItem
                            key={index}
                              value={array.email}
                            >
                              {array.name}
                            </MenuItem>
                          ))
                          : null}
                    </Select>
                    <FormHelperText sx={{ color: "red !important" }}>{touched.companyAdminId && errors.companyAdminId ? errors.companyAdminId : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                  <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                    <Button autoFocus variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }} onClick={() => setAffiliateLoginValue(false)} >
                      CLOSE
                    </Button>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                    <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }} color="success" type="submit" onClick={handleSubmit}>
                      Switch Account
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
        {/* affiliate login user Modal Ends */}
       
      <Dialog
      fullScreen
      open={openFire}
      onClose={handleCloseFire}
      // TransitionComponent={Fade}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          boxShadow: "none",
          borderRadius: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center content vertically and horizontally
        }
      }}
    >
      
      
      
       {showGif && (
        // <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", position: "absolute", bottom: "30%" }}>
          <img src={giftbox} alt="Gift box" style={{ textAlign: "center", marginBottom:isMobile?"85%":"0%"}} />
        // </Grid>
      )}

      {showText && (
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
          <Typography sx={{ fontSize: "1.8rem", color: "white", textAlign: "center" }} className="zoom-in-zoom-out">
            <b>GREAT JOB! YOU HIT THE TARGET. NOW KEEP GOING! <br /> MAX OUT ON YOUR 25% BONUS FOR AUGUST</b>
          </Typography>
        </Grid>
      )}
    </Dialog>

      </>
    );
  }
  return (
    <div>

      <DefaultLayout content={<ContentLayout />} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        // onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}


export default Dashboard;
