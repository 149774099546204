import React, { useState, useEffect, useRef } from 'react';
import {
	Box,Grid,FormGroup,FormControlLabel,Checkbox ,TextField,Button,MenuItem,FormControl,OutlinedInput,InputLabel,Select,FormHelperText,
    Dialog,DialogActions,DialogContent
} from '@mui/material';
import { Typography, Card, CardOverflow, CardContent, Divider, Option, } from '@mui/joy';
import OperationalHours from '../../../Components/OperationalHours';
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import DefaultLayout from '../../../Components/DefaultLayout';
import config from '../../../AppConfig';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import swal from "sweetalert";
import Loader from '../../../Components/Loader/Loader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


let logoURL = '';
let logoFlag = 0;
let logoUpdated = 0;
let blankValueIndexes1 = [];
let blankValueIndexes2 = [];
let operationalhoursvalues = [
    { label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "18:00" }] },
    { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }]}
];

function InitialCobrandSetup() {
    const formikRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const aidUserObj = localStorage.getItem('aidUser');
    const aidUser = aidUserObj ? JSON.parse(aidUserObj) : '';
    const company = JSON.parse(localStorage.getItem('company'));
    const user = JSON.parse(localStorage.getItem('user'));
    const cobrandStatus = localStorage.getItem('cobrandStatus');
    const id = aidUser ? aidUser.id : '';    

    const [uploadLogo, setUploadLogo] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [basePlansArray, setBasePlans] = useState([]);
    const [defaultPlan, setDefaultPlan] = useState(false);
    const [selectPlanCount, setSelectPlanCount] = useState(2);

    const initialValues = {
        companyName: company? company.company_name ? company.company_name:'':'',
        email: user.email ? user.email:'',
        email2: '',
        contactName: user.name ? user.name : '',
        phoneNo: user.phone_no ? formatPhoneNumber(user.phone_no) : '',                                
        timezone: 'PDT',
        workingHours: '',
        subtitle: '',            
        companywebsite: '',
        selectedPlanValues: [],
        specialMsg1: (company ? company.company_name ? company.company_name:'<Company Name>':'<Company Name>') +' offers a comprehensive program that enables customers to improve their credit and better manage their finances.',
        specialMsg2: '',                                
        logoFlag: logoFlag,
        logo: '',
        isFormDirty: false,
    }

    const [formikValues, setFormikValues] = useState(initialValues);

    const getCobrandData = async() =>{
        const apiUrl = '/cobrandRequest/show/'+id;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
            if(res.success === true) {
                if(res.data.cobrandObj){
                    if(res.data.cobrandObj.company_name){
                        localStorage.removeItem('cobrandStatus');
                        localStorage.setItem('cobrandStatus', "true"); 
                        navigate('/affiliate/dashboard');
                    }
                    if(res.data.cobrandObj.company_logo && res.data.cobrandObj.company_logo != null){
                        logoURL = config.BACKEND_URL+"images/cobrandLogo/"+res.data.cobrandObj.company_logo;
                        logoFlag = res.data.cobrandObj.company_logo ? 1 : logoFlag;
                    }
                }
                if(logoURL == ""){
                    if(company.company_logo_url != '' && company.company_logo_url != null){
                        logoURL = config.BACKEND_URL+"images/cobrandLogo/"+company.company_logo_url;
                        logoFlag = 1;
                    }else{
                        logoURL = require("../../../assets/images/uploadLogo.png");
                    }
                }
			    setIsLoading(false);
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                window.location.href= config.BASE_URL + 'login' ;
                return false;
            }            			
		}
	} 
    const getAvailablePlansArray = async() =>{
        const apiUrl = '/initialAvailablePID';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
            console.log(res.data)
            if(res.success === true) {
                setBasePlans(res.data.basePlans);
                if(res.data.basePlans && res.data.basePlans[0] && res.data.basePlans[0].id == 18) {
                    setDefaultPlan(true);
                }
                setSelectPlanCount(res.data.selectCount);
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                window.location.href= config.BASE_URL + 'login' ;
                return false;
            }            			
		}
	} 

    useEffect(() => {
        if(user.roles_id != 3 ) {
          navigate('/login');
        }
        else if(cobrandStatus==="true"){
            navigate('/affiliate/dashboard')
        }
        else{ 
            getAvailablePlansArray(); 
            getCobrandData();  
            setFormikValues(initialValues);
        }
       
    },[])


    useEffect(()=>{
        if(defaultPlan) {
            initialValues.selectedPlanValues = [basePlansArray[0].base_pid];
            setFormikValues(initialValues);
        }
    },[defaultPlan])
    
    const handleChildValue = (value1) => {
        blankValueIndexes1 = []
        blankValueIndexes2 = []
        operationalhoursvalues = value1
        if(value1.length == 2) {
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
            value1[1]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes2.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes2.push(index);
                }
            });
        }else{
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
        }
       
    };
    
    const CobrandSchema =  Yup.object().shape({
        companyName: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Please enter Company Name.'),
        contactName: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Please enter Contact Name.'),
        email: Yup.string()
                    .email('Invalid Email Format.')
                    .required('Please enter your Email Address.'),
        phoneNo: Yup.string()
                    .min(14, 'Must be 10 digits.')
                    .max(14, 'Must be 10 digits.')
                    .required('Please enter your mobile number.'),
        // selectedPlanValues: Yup.array()
        //             .test('atLeastTwoSelected', 'Please select 2 plans from the list above', (value) => value.length === 2)
        //             .required('Please select 2 plans from the list above'),
        // selectedPlanValues: Yup.array().min(2, 'Please select 2 plans from the list above'), 
        selectedPlanValues: Yup.array()
                    .min(selectPlanCount, `Select exactly ${selectPlanCount} option${selectPlanCount === 1 ? '' : 's'}`)
                    .max(selectPlanCount, `Select exactly ${selectPlanCount} option${selectPlanCount === 1 ? '' : 's'}`)
                    .required(`Select exactly ${selectPlanCount} option${selectPlanCount === 1 ? '' : 's'}`),
    });
    
    
    const handleUploadLogo = (values) => {
        setUploadLogo(true);
        setFormikValues(values);
    }

    const handleCloseUploadLogo = () => {
        setUploadLogo(false);
    }

    const handleLogoURL = (image) => {
        logoURL = image;
    }

    const handleLogoName = (name) => {
        console.log(name)
        if(logoUpdated == 1){
            formikRef.current.values.logo = name;
            formikRef.current.setFieldValue('logo',name);
        }     
        console.log(formikRef.current.values)  
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }

    console.log(formikValues)

    const ContentLayout=
            <Box>
            <Box className={window.location.pathname==='/affiliate/initialcobrandsetup'?"initial-cobrand-wrapper":''} sx={{padding: '1% 10%'}}>     
                {isLoading?<Loader/>:<></>}           
                    <Grid xs={12} mt={2}> 
                        <Grid xs={12} mb={2} className='initial-cobrand-text'>
                            <Typography level="h4">Congratulations! Your MyFreeScoreNow Affiliate Account is now Active.</Typography>
                        </Grid>                       
                        <Card variant='outlined' >
                            <CardOverflow
                                sx={{
                                    bgcolor:'#F6FAFF',
                                    borderColor:'#F6FAFF',
                                    padding: '1%',                            
                                }}
                            >                            
                                <Typography level="h4" color="primary" pl={2} pr={2}>Let us setup your Enrollment Plans for your clients:- </Typography>
                            </CardOverflow>    
                            <Formik
                                initialValues={formikValues}
                                validationSchema={CobrandSchema}
                                innerRef={formikRef} // Assign the ref to Formik instance
                                onSubmit={ async(values) =>{
                                    console.log(values)
                                    if(logoFlag == 0){
                                        swal({
                                            title: "Alert",
                                            text: "Please Upload Logo First",
                                            icon: "warning",
                                            button: "Ok",
                                        })
                                        return false;
                                    }
                                    if (blankValueIndexes1.length > 0) {
                                        swal({
                                            title: "Alert",
                                            text: "Some fields are missing in weekdays (Monday - Friday) shift either delete the shift or add its valus!",
                                            icon: "error",
                                            button: "Ok",
                                        })
                                    } else if (blankValueIndexes2.length > 0) {
                                        swal({
                                            title: "Alert",
                                            text: "Some fields are missing in weekends (Saturday - Sunday) shift either delete the shift or add its valus!",
                                            icon: "error",
                                            button: "Ok",
                                        })
                                    }
                                    else{
                                        const apiUrl = '/checkAffiliateOfSameCompany';
                                        const args = {
                                            id: id,
                                        }
                                        const response = await dispatch(postData({apiUrl,args})).unwrap();
                                        if(response){                                        
                                            if(response.success === true){
                                                setIsLoading(true);
                                                // setDisable(true);
                                                const apiUrl = '/enableAutoCobrand'; // Replace with your API endpoint
                                                const args = {
                                                    companyName: values.companyName,
                                                    email: values.email,
                                                    email2: values.email2,
                                                    name: values.contactName,
                                                    phone: values.phoneNo,
                                                    timezone: values.timezone,
                                                    workingHours: operationalhoursvalues,
                                                    subtitle: values.subtitle,
                                                    websiteUrl: values.companywebsite,
                                                    plan: values.selectedPlanValues,
                                                    specialMsg1: values.specialMsg1,
                                                    specialMsg2: values.specialMsg2,
                                                    logo: values.logo,
                                                    logoFlag: logoFlag,
                                                    aidMasterId: id,
                                                    aid:aidUser.aid,
                                                    companyId:company.id,
                                                    url:config.BASE_URL,
                                                };
                                                const res = await dispatch(postData({apiUrl,args})).unwrap();
                                                if(res){  
                                                    console.log(res);
                                                    if(res.success){                                                        
                                                        swal({
                                                            title: "Success",
                                                            text: res.message,
                                                            icon: "success",
                                                            button: "Ok",
                                                        }).then(()=>{
                                                            localStorage.removeItem('cobrandStatus');
                                                            localStorage.setItem('cobrandStatus', "true"); 
                                                            navigate('/affiliate/dashboard');
                                                        })                                                                                        
                                                    }else{
                                                        console.log();
                                                        setIsLoading(false);                                                        
                                                        swal({
                                                            title: "Failed",
                                                            text: res.message,
                                                            icon: "error",
                                                            button: "Ok",
                                                        })
                                                    }
                                                }
                                            }else{
                                                swal({
                                                    title: "Failed",
                                                    text: "Something went wrong.",
                                                    icon: "error",
                                                    button: "Ok",
                                                })
                                                navigate('/login');
                                                return false;
                                            }
                                        }
                                    }
                                }}
                                enableReinitialize={true}
                            >     
                            {({values, errors,touched,isSubmitting,setFieldValue, handleChange, handleBlur, handleSubmit}) => (         
                                <CardContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} p={2}>
                                        <Grid item lg={8} md={8} sm={12} xs={12} >
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography level="h5">Select Enrollment Plans:</Typography>                                                
                                                <Typography level="body"><i>(Note: Please select {selectPlanCount} {selectPlanCount > 1 ? "plans" : " plan"} from the list below. You can select other plans as well once you'll successfully complete this setup.)</i></Typography>
                                            </Grid>                                            
                                            {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <ul>
                                                    <li>Your own personalized link which will display your logo and messaging to your clients</li>
                                                    <li>Special alerts emailed to you about your client's account activity (example: "John Smith score went up +80 points")</li>
                                                    <li>No more failed login attempts. Our site will pass you the member credentials after the client enrolls. No other company has this feature!</li>
                                                </ul>
                                            </Grid> */}
                                            {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Typography level="h6" sx={{color: "red" }}>*Please select minimum of 2 plans from below*</Typography>
                                            </Grid> */}
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <FormControl required 
                                                    helperText={errors.selectedPlanValues ? errors.selectedPlanValues : null}
                                                    error={errors.selectedPlanValues ? errors.selectedPlanValues : null}
                                                    component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        {
                                                            basePlansArray.length > 0 ?
                                                            basePlansArray.map((row)=>{
                                                                return (
                                                                    <FormControlLabel className="plan-padding" control={
                                                                        <Checkbox 
                                                                            onChange={() => {
                                                                                setFieldValue('selectedPlanValues', values.selectedPlanValues.includes(row.base_pid)
                                                                                ? values.selectedPlanValues.filter((item) => item !== row.base_pid)
                                                                                : [...values.selectedPlanValues, row.base_pid]);
                                                                            }}                                                        
                                                                            checked={values.selectedPlanValues.includes(row.base_pid)} name={row.base_pid} 
                                                                        />}  
                                                                        label={row.plan_name}
                                                                        // label="Bronze - $24.97, No Trial - $8.25/mo commission" 
                                                                    />
                                                                )
                                                            })
                                                            :
                                                            <></>
                                                        }                                                        
                                                    </FormGroup>
                                                    <FormHelperText>{errors.selectedPlanValues ? errors.selectedPlanValues : null}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{textAlign:'center'}} >
                                                <Typography level="h5" >Your Co-branding Logo:</Typography>
                                            </Grid>
                                            <Grid item sx={{display:'flex',justifyContent:'center'}}>
                                                <Box >                                                    
                                                    <Card variant='outlined' size='md' sx={{ height: { xs: 136, md: 200 },width:{ xs: 220, md: 300 } }} >
                                                        <CardContent sx={{textAlign:'center',justifyContent:'center'}}>                                                            
                                                            <img className="dashlogo" src={logoURL}  />                                                            
                                                        </CardContent>
                                                    </Card>                                                    
                                                    <Box py={2} sx={{textAlign:'center'}}>
                                                        <Button variant="contained" size="sm" color="success" onClick={()=>{handleUploadLogo(values)}} >Edit</Button>
                                                    </Box>                                                    
                                                </Box>
                                            </Grid> 
                                        </Grid>
                                    </Grid>

                                    <Grid md={12} container item p={2} >                                           
                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="h5" >Set Co-branding Details:</Typography>
                                        </Grid>                            
                                        <Grid item md={4} px={2}>                                  
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                margin='dense'
                                                label="Company Name*"                                    
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={values.companyName}
                                                onChange={(e)=>{                                                       
                                                    handleChange('companyName')
                                                    setFieldValue('companyName',e.target.value)
                                                    let msgVal = e.target.value+ ' offers a comprehensive program that enables customers to improve their credit and better manage their finances.';
                                                    setFieldValue('specialMsg1',msgVal)
                                                }}
                                                onBlur={handleBlur('companyName')}
                                                helperText={touched.companyName && errors.companyName ? errors.companyName : null}
                                                error={touched.companyName && errors.companyName ? errors.companyName : null}
                                            />    
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Email Address*"                                            
                                                fullWidth
                                                margin='dense'
                                                variant="outlined"
                                                size="small"     
                                                value={values.email}
                                                onChange={(e)=>{
                                                    handleChange('email')
                                                    setFieldValue('email',e.target.value)                                                        
                                                }}
                                                onBlur={handleBlur('email')}
                                                helperText={touched.email && errors.email ? errors.email : null}
                                                error={touched.email && errors.email ? errors.email : null} 
                                            />
                                        </Grid>
                                        <Grid item md={4} px={2}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Contact Name*"
                                                fullWidth
                                                margin='dense'
                                                variant="outlined"
                                                size="small"    
                                                value={values.contactName}
                                                onChange={(e)=>{
                                                    handleChange('contactName')
                                                    setFieldValue('contactName',e.target.value)                                                        
                                                }}
                                                helperText={touched.contactName && errors.contactName ? errors.contactName : null}
                                                error={touched.contactName && errors.contactName ? errors.contactName : null}    
                                                onBlur={handleBlur('contactName')}                                    
                                            />                                           
                                            {/* <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Email Address 2"                                            
                                                fullWidth
                                                margin='dense'
                                                variant="outlined"
                                                size="small"
                                                value={values.email2}
                                                onChange={(e)=>{
                                                    handleChange('email2')
                                                    setFieldValue('email2',e.target.value)
                                                }}
                                                onBlur={handleBlur('email2')}
                                            /> */}
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Company Website(URL)"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin='dense'
                                                value={values.companywebsite}
                                                onChange={(e)=>{
                                                    handleChange('companywebsite')
                                                    setFieldValue('companywebsite',e.target.value)
                                                }}
                                                onBlur={handleBlur('companywebsite')}
                                            />     
                                        </Grid>
                                        <Grid item md={4} px={2}>
                                            <InputMask
                                                id="outlined-start-adornment"
                                                mask='(999) 999-9999'
                                                maskChar={null}
                                                type="text"
                                                label="Phone No*"
                                                fullWidth
                                                margin='dense'
                                                variant="outlined"
                                                size="small"
                                                value={values.phoneNo}
                                                onChange={(e)=>{
                                                    handleChange('phoneNo')
                                                    setFieldValue('phoneNo',e.target.value)
                                                }}
                                                helperText={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                error={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                onBlur={handleBlur('phoneNo')}
                                            > 
                                                {(inputProps) =>
                                                    <TextField
                                                    label="Phone Number" variant="outlined"
                                                    {...inputProps}
                                                    />
                                                }
                                            </InputMask>
                                            
                                        </Grid>
                                        <Grid item md={12} px={2}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Co-Branding Sub-Title (E.G. Could Be Your Tag Line, Motto, Your Slogan)"
                                                fullWidth
                                                margin='dense'
                                                variant="outlined"
                                                size="small"
                                                value={values.subtitle}
                                                onChange={(e)=>{
                                                    handleChange('subtitle')
                                                    setFieldValue('subtitle',e.target.value)
                                                }}
                                            /> 
                                        </Grid>
                                        <Grid item md={12} px={2}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 1"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin='dense'
                                                placeholder="<Company Name> offers a comprehensive program that enables customers to improve their credit and better manage their finances."
                                                value={values.specialMsg1 || ''}
                                                multiline
                                                onChange={(e)=>{
                                                    handleChange('specialMsg1')
                                                    setFieldValue('specialMsg1',e.target.value)
                                                }}
                                                onBlur={handleBlur('specialMsg1')}                                                
                                            />
                                        </Grid>
                                        <Grid item md={12} px={2}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 2"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                margin='dense'
                                                value={values.specialMsg2}
                                                onChange={(e)=>{
                                                    handleChange('specialMsg2')
                                                    setFieldValue('specialMsg2',e.target.value)                                                    
                                                }}
                                                onBlur={handleBlur('specialMsg2')}
                                            /> 
                                        </Grid>
                                    </Grid>
                                    <Divider />     
                                    <Grid md={12} container item p={2}>
                                        <Grid md={12} container >
                                            <Typography level="h5" >Hours Of Operation: (Include Days Of Operation And Time Zone) *</Typography>
                                        </Grid>
                                        <Grid md={12} container sx={{mt:0.5}} spacing={2}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' margin='dense'>
                                                    <InputLabel id="demo-multiple-name-label">Default Timezone</InputLabel>
                                                    <Select
                                                        name="timezone"
                                                        labelId="demo-simple-select-label"
                                                        id="timezone"
                                                        value={values.timezone}
                                                        label="Default Timezone"
                                                        fullWidth
                                                        size='small'
                                                        margin='dense'
                                                        onChange={handleChange('timezone')}
                                                        onBlur={handleBlur('timezone')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        <MenuItem value="PST">PST - Pacific Standard Time</MenuItem>
                                                        <MenuItem value="CT">CT - Central Time</MenuItem>
                                                        <MenuItem value="EST">EST - Eastern Standard Time</MenuItem>
                                                        <MenuItem value="IST">IST - Indian Standard Time</MenuItem>
                                                        <MenuItem value="EDT">EDT - Eastern Daylight Time</MenuItem>
                                                        <MenuItem value="UTC">UTC - Coordinated Universal Time</MenuItem>
                                                        <MenuItem value="PDT">PDT - Pacific Daylight Time</MenuItem>
                                                        <MenuItem value="WET">WET - Western European Time</MenuItem>
                                                        <MenuItem value="CDT">CDT - Central Daylight Time</MenuItem>
                                                        <MenuItem value="WST">WST - Western Standard Time</MenuItem>
                                                        <MenuItem value="CST">CST - Central Standard Time</MenuItem>
                                                        <MenuItem value="MST">MST - Mountain Standard Time</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                            <OperationalHours onValueChange={handleChildValue} props={operationalhoursvalues} />
                                        </Grid>
                                    </Grid>
                                    <Grid container  item md={12} py={2}>                                       
                                        <Grid p={2} sx={{display:"block",marginLeft:"auto", marginRight:"auto" }}textAlign="center">
                                            <Button variant="contained" sx={{minWidth:{ xs: 0, md: 300 },maxWidth:300,}} color="success" type="submit" size='lg' disabled={(isSubmitting)}  onClick={()=>{window.scrollTo(0, 0);handleSubmit()}} >SUBMIT</Button>
                                        </Grid>  
                                    </Grid>
                                </CardContent>
                            )}
                            </Formik>  
                        </Card>
                    </Grid>
                </Box>
                <Dialog
                    open={uploadLogo}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseUploadLogo();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <LogoEdit id={id} logoEditURL={handleLogoURL} logoName={handleLogoName} updateFlag="0" logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} />
                </Dialog>
            </Box>
    

    return (
        <div>
            <DefaultLayout content={ContentLayout } />
        </div>
    );
}
export default InitialCobrandSetup;