import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import AuthUser from "../Auth/AuthUser";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Grid, FormLabel, TextField, FormControl, RadioGroup, FormControlLabel, Radio,
    Box, Tab, InputLabel, Tooltip, IconButton, OutlinedInput, Stack, InputAdornment, Button,ClickAwayListener
} from '@mui/material';
import Loader from '../Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../redux/apiSlice';
import InputMask from 'react-input-mask';
import useMediaQuery from '@mui/material/useMediaQuery';


const UpdateBankDetails = ({ bank_details,bankOperation, onBankDetailSubmit }) => {
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const { id } = useParams();
    const [form_details, setFormDetails] = useState(bank_details);
    const [loader, setLoader] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [btntext, setBtntext] = useState(bankOperation);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openTooltip1, setOpenTooltip1] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [firstlable, setFirstLable] = useState(false);
    const [isAccount, setIsAccount] = useState(form_details ? form_details.account_category : null);
    const [bankNameReadonly, setBankNameReadonly] = useState(true);

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen1 = () => {
        setOpenTooltip1(true);
    };

    const handleTooltipClose1 = () => {
        setOpenTooltip1(false);
    };
    //Bank validation Schema start
    const BankSchema = Yup.object().shape({
        BankName: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Please Enter Your Bank Name.'),

        NameonAccount: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .notOneOf([Yup.ref('BankName'), null], 'Name on Account must not match Bank Name')
            .required('Please enter your Name On Account.')
            .test(
                "name-validation",
                "Please enter first and last name (e.g., John Smith)",
                function (value) {
                  if (isAccount === 'personal') {
                    // return /\w+\s+\w+/.test(value); // Checks if the value contains at least two words
                    return /^[A-Za-z]+\s+[A-Za-z]+$/.test(value);
                  } else {
                    return !!value;
                  }
                }
              ),

        BankRouting: Yup.string()
            .matches(/^\d+$/, 'Invalid Format')
            .min(9, 'At least 9 digit required')
            .max(9, 'maximum 9 digit')
            .required('Please enter your Bank Routing number.'),

        AccountNumber: Yup.string()
            .required('Please enter your Account Number.'),

        AccountType: Yup.string()
            .required('Please select Account Type.'),

        AccountCategory: Yup.string()
            .required('Please select Is Account.'),

        EmailAddresstonotifyofpaymenttransfers: Yup.string()
            // .email('Invalid email format.')
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address to notify payment transfers.'),
    });

    const EditBankDetails = (values) => {
        var updateBankDetails = [];
        updateBankDetails['bank_name'] = values.BankName;
        updateBankDetails['name_on_account'] = values.NameonAccount;
        updateBankDetails['routing_number'] = values.BankRouting;
        updateBankDetails['account_number'] = values.AccountNumber;
        updateBankDetails['account_category'] = values.AccountCategory;
        updateBankDetails['account_type'] = values.AccountType;
        updateBankDetails['email_to_notify'] = values.EmailAddresstonotifyofpaymenttransfers;
        updateBankDetails['is_form_updated'] = 1;
        setFormDetails(updateBankDetails);
        onBankDetailSubmit(updateBankDetails);
    }

    const fetchBankName = async (routingNumber, values, setFieldValue) => {
        const apiUrl = "/bankname/"+routingNumber;
        setFieldValue('BankName','');
        if(routingNumber.length == 9)
        {
            setFieldValue('BankName','...');
            setIsLoading(true);
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setIsLoading(false);
                setFieldValue('BankName', res.data);
                setBankNameReadonly(true);
            }
            else
            {
                setIsLoading(false);
                setFieldValue('BankName','');
                setBankNameReadonly(false);
            }
        }
    };

    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
        }
    }

    return (
        <>
           {isLoading?<Loader/>:<></>}
            <Box>
                <Card variant="outlined" size="lg">
                    <CardOverflow
                        sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}
                    >
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Typography level="h4">
                                Bank Details
                            </Typography >


                        </Grid>
                    </CardOverflow>
                    <CardContent>
                        <Formik enableReinitialize
                            initialValues={{
                                BankName: form_details.bank_name,
                                NameonAccount: form_details.name_on_account,
                                BankRouting: form_details.routing_number,
                                AccountNumber: form_details.account_number,
                                AccountType: form_details.account_type,
                                AccountCategory: form_details.account_category,
                                EmailAddresstonotifyofpaymenttransfers: form_details.email_to_notify
                            }}
                            validationSchema={BankSchema}
                            onSubmit={(values, errors) => {
                                if(checkUsaTldStatus == true) {
                                    return false;   
                                }
                                 EditBankDetails(values);
                            }}
                        >
                            {({ values, errors, touched,dirty, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (

                            <Box>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Card variant="outlined" >
                                            <FormControl>
                                                <FormLabel sx={{ color: "black" }}>Account is*</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="AccountCategory"
                                                    id='AccountCategory'
                                                    onChange={(e)=>{
                                                        handleChange(e);
                                                        setIsAccount(e.target.value);
                                                    }}
                                                    value={values.AccountCategory || ''}
                                                    onBlur={handleBlur('AccountCategory')}
                                                >
                                                    <FormControlLabel value="personal" control={<Radio />} label="Personal" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="business" control={<Radio />} label="Business" sx={{ fontWeight: "bold" }} />
                                                </RadioGroup>
                                                {touched.AccountCategory && errors.AccountCategory ? <div className='error'>{errors.AccountCategory}</div> : null}
                                            </FormControl>
                                        </Card>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth size="small" variant="outlined" sx={{ m: 1,ml:0 }} >
                                            <InputLabel htmlFor="outlined-adornment-password">Bank Routing (ABA / ACH) Number*</InputLabel>
                                                <InputMask
                                                        mask='999999999'
                                                        maskChar={null}
                                                        required
                                                        type="text"
                                                        fullWidth
                                                        size="small"
                                                        autoComplete='BankRouting'
                                                        margin="dense"
                                                        label="Bank Routing (ABA / ACH) Number*"
                                                        name='BankRouting'
                                                        id='BankRouting'
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            fetchBankName(e.target.value,values, setFieldValue);
                                                        }}
                                                        value={values.BankRouting || ''}
                                                        onBlur={handleBlur('BankRouting')}
                                                        >
                                                        {(inputProps) =>
                                                            <OutlinedInput
                                                                label="Bank Routing (ABA / ACH) Number*" variant="outlined"
                                                                {...inputProps}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                                                            <Tooltip
                                                                            PopperProps={{
                                                                                disablePortal: true,
                                                                            }}
                                                                            onClose={handleTooltipClose}
                                                                            open={openTooltip}
                                                                            disableFocusListener
                                                                            disableHoverListener
                                                                            disableTouchListener            
                                                                                title={<div style={{ maxWidth: isMobile?"150px": '400px' }}><img src={require("../../assets/images/tooltip.png")}
                                                                                    style={{ width: '100%', height: 'auto' }} /></div>} placement="left-start"
                                                                                    interactive>
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    edge="end"
                                                                                    onClick={handleTooltipOpen}
                                                                                >
                                                                                    <img src={require("../../assets/images/i_icon.png")} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </ClickAwayListener>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        }
                                                </InputMask>
                                            {touched.BankRouting && errors.BankRouting ? <div className='error'>{errors.BankRouting}</div> : null}
                                        </FormControl>
                                            <TextField
                                                required
                                                name='BankName'
                                                id='BankName'
                                                label="Bank Name"
                                                margin="dense"
                                                type="text"
                                                size="small"
                                                autoComplete='BankName'
                                                fullWidth
                                                onChange={(e)=>{
                                                    handleChange(e)
                                                }}
                                                value={values.BankName  || ''}
                                                onBlur={handleBlur('BankName')}
                                                InputProps={{
                                                    readOnly: bankNameReadonly, // This sets the field as read-only
                                                }}
                                            />
                                            {touched.BankName && errors.BankName ? <div className='error'>{errors.BankName}</div> : null}
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>                                    
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                        <TextField
                                           label={`${isAccount && isAccount === 'personal' ? 'Your Name' : 'Business Name'}*`}
                                            type="text"
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            name='NameonAccount'
                                            autoComplete='NameonAccount'
                                            id='NameonAccount'
                                            onChange={(e)=>{
                                                handleChange(e)
                                            }}
                                            value={values.NameonAccount || ''}
                                            onBlur={handleBlur('NameonAccount')}
                                        />
                                        {touched.NameonAccount && errors.NameonAccount ? <div className='error'>{errors.NameonAccount}</div> : null}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                        <FormControl fullWidth size="small" variant="outlined" sx={{ m: 1 ,ml:0}}>
                                            <InputLabel htmlFor="outlined-adornment-password">Account Number*</InputLabel>
                                                <OutlinedInput
                                                    label="Account Number*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='AccountNumber'
                                                    autoComplete='AccountNumber'
                                                    margin="dense"
                                                    id='AccountNumber'
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                    }}
                                                    value={values.AccountNumber || ''}
                                                    onBlur={handleBlur('AccountNumber')}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                             <ClickAwayListener onClickAway={handleTooltipClose1}>
                                                                <Tooltip
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                onClose={handleTooltipClose1}
                                                                open={openTooltip1}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener         
                                                                title={<div style={{ maxWidth: isMobile?"150px": '400px' }} ><img src={require("../../assets/images/tooltip.png")}
                                                                    style={{ width: '100%', height: 'auto' }} /></div>} placement="left-start"
                                                                    interactive>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                    onClick={handleTooltipOpen1}
                                                                >
                                                                    <img src={require("../../assets/images/i_icon.png")} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            </ClickAwayListener>
                                                        </InputAdornment>
                                                    }
                                                />
                                        </FormControl>    
                                    </Grid>
                                </Grid>
                                <Typography variant="subtitle1" gutterBottom className="font2" color="blue">
                                    Enter the name of the Account held with the Bank.<span style={{color:"red"}}>(NOT YOUR BANK'S NAME)</span>
                                </Typography>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4} >
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Card variant="outlined" >
                                            <FormControl>
                                                <FormLabel sx={{ color: "black" }} >Type of Account*</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name='AccountType'
                                                    id='AccountType'
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                    }}
                                                    value={values.AccountType || ''}
                                                    onBlur={handleBlur('AccountType')}
                                                >
                                                    <FormControlLabel value="checking" control={<Radio />} label="Checking" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="savings" control={<Radio />} label="Savings" sx={{ fontWeight: "bold" }} />
                                                </RadioGroup>
                                                {touched.AccountType && errors.AccountType ? <div className='error'>{errors.AccountType}</div> : null}
                                            </FormControl>
                                        </Card>
                                        </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={2}>
                                        <TextField
                                            required
                                            label="Email Address to notify of payment transfers"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            autoComplete='EmailAddresstonotifyofpaymenttransfers'
                                            name='EmailAddresstonotifyofpaymenttransfers'
                                            onChange={(e)=>{
                                                handleChange(e);
                                                checkUsaTLD(e.target.value);
                                            }}
                                            value={values.EmailAddresstonotifyofpaymenttransfers || ''}
                                            onBlur={handleBlur('EmailAddresstonotifyofpaymenttransfers')}
                                        />
                                        <div className='error'>{touched.EmailAddresstonotifyofpaymenttransfers && errors.EmailAddresstonotifyofpaymenttransfers ?errors.EmailAddresstonotifyofpaymenttransfers : checkUsaTldStatus ? "Invalid email format." : '' }</div>  
                                        {firstlable ? <Typography variant="subtitle1" gutterBottom className="font2" color="blue">
                                            This is email address with which {bank_details.company_name} has been registered with us. You can change this if you want another email for banking purpose.
                                        </Typography> : null}
                                    </Grid>
                                    
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                                    <Grid item>
                                        <Button variant="contained" sx={{ minWidth: 200, maxWidth: "100%" }} color="success" disabled={!dirty} onClick={handleSubmit}>
                                        {btntext}
                                        </Button>
                                    </Grid>
                                </Grid>             
                            </CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <Typography variant="subtitle1" gutterBottom className="font2" color="blue">*Commission paid within 3-5 business days. Adding new/changing banking information will add an additional 3-5 business days. We appreciate your patience - bank processing for new/updated account verification can take up to 3-5 business days.</Typography>
                            </Grid>
                                </Box>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}



export default UpdateBankDetails;