import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Card, CardOverflow,CardContent,Table } from '@mui/joy';
import { styled } from '@mui/material/styles';
import AuthUser from "../Auth/AuthUser";
import Loader from '../Loader/Loader';
import {
    Grid, FormControlLabel, Dialog,DialogContentText,DialogContent,
    Box, Button,Checkbox,TableBody, TableHead, TableCell,TableRow
} from '@mui/material';
import swal from "sweetalert";
import { tr } from 'date-fns/locale';
import config from '../../AppConfig';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import moment from 'moment';
import Moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const ViewBankDetails = ({bankDetails, operation,commission,onBankDetailSubmit,setLoaderOn,setLoaderOff}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [bank_details, setBankDetails] = useState(bankDetails);
    const [submitBankDetails, setSubmitBankDetails] = useState(false);
    const { http } = AuthUser();
    const navigate = useNavigate();
    const [confirm, setConfirmation] = React.useState(false);
    const [openCalender, setOpencalender] = React.useState(false);
    const [selectedRanges, setSelectedRanges] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [paymentMadeEndedDate, setPaymentMadeEndedDate] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');

    const changeFlagValue = () => {
        setSubmitBankDetails(!submitBankDetails);
    }

    useEffect(() => {
        setBankDetails(bankDetails);
    }, [bankDetails])

    const handleClsoeCalender=()=>{
        setOpencalender(false);
        navigate('/affiliate/dashboard');
    }

    const tileClassName = ({ date }) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
    
       for (const range of selectedRanges) {
         const { start, end, color } = range;
         if (color != 'highlight0' ) {
           // Check if the date is a weekend (Saturday or Sunday)
           const isWeekend = moment(formattedDate).isoWeekday() === 6 || moment(formattedDate).isoWeekday() === 7;
         
           if (moment(formattedDate).isBetween(start, end, null, '[]') && !isWeekend) {
             return color;
           }
         }
          else if (moment(formattedDate).isBetween(start, end, null, '[]')) {
           return color;
         }
       }
    
        return null;
    };

    const EditBankDetails = async() => {
        console.log(bank_details);
        if((!("bank_name" in bank_details)) || 
            (!("name_on_account" in bank_details)) || 
           (!("routing_number" in bank_details)) || 
           (!("account_number" in bank_details)) || 
           (!("account_type" in bank_details)) || 
           (!("account_category" in bank_details)) || 
           (!("email_to_notify" in bank_details))) {
            onBankDetailSubmit(bank_details);
            return false;

        }else if( bank_details.bank_name == null || bank_details.name_on_account == null ||
                bank_details.routing_number == null || bank_details.account_number == null ||
                bank_details.account_type == null || bank_details.account_category == null || 
                bank_details.email_to_notify == null
                ) {
                onBankDetailSubmit(bank_details);
                return false;
                
        }else if(bank_details.bank_name == bank_details.name_on_account){
            onBankDetailSubmit(bank_details);
            return false;

        }else if(bank_details.routing_number.toString().length != 9){
            onBankDetailSubmit(bank_details);
            return false;

        }else if(submitBankDetails == true) {
            setLoaderOn();
            setConfirmation(true);
            if(operation == "Update") {
                const apiUrl = '/update-company-bank-details';
                const args = {
                    id: id,
                    bank_name: bank_details.bank_name,
                    name_on_account: bank_details.name_on_account,
                    routing_number: bank_details.routing_number,
                    account_number: bank_details.account_number,
                    account_type: bank_details.account_type,
                    account_category: bank_details.account_category,
                    email_to_notify: bank_details.email_to_notify,
                    is_form_updated: bank_details.is_form_updated == 1 ?  0 : 1,
                    acceptFlag : 1
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res){
                    if (res.success === true) {
                        // swal({
                        //     title: "Success",
                        //     text: res.message,
                        //     icon: "success",
                        //     button: "Ok",
                        // })
                        setPaymentMadeEndedDate(res.data.paymentMadeEndedDate);
                        setOpencalender(true);
                        setSelectedRanges([
                            { start: res.data.acceptedDate, end: res.data.acceptedDate, color: 'highlight0' },
                            { start: res.data.verifiedStartedDate, end: res.data.verifiedMiniEndedDate, color: 'highlight2' },
                            { start: res.data.paymentMadeStartedDate, end: res.data.paymentMadeEndedMiniEndedDate, color: 'highlight1' },
                            { start: res.data.verifiedEndedDate, end: res.data.verifiedEndedDate, color: 'darkOrange' },
                            { start: res.data.paymentMadeEndedDate, end: res.data.paymentMadeEndedDate, color: 'darkGreen' },
                        ])
                        // navigate('/affiliate/dashboard');
                        // navigate('/affiliate/dashboard', { state: { acceptedDate: res.data.acceptedDate, verifiedStartedDate: res.data.verifiedStartedDate, verifiedEndedDate:  res.data.verifiedEndedDate, paymentMadeStartedDate: res.data.paymentMadeStartedDate, paymentMadeEndedDate: res.data.paymentMadeEndedDate, verifiedMiniEndedDate: res.data.verifiedMiniEndedDate, paymentMadeEndedMiniEndedDate:  res.data.paymentMadeEndedMiniEndedDate} });
                           
                    } else {

                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }

            }else if(operation == "Create") {
                const apiUrl = '/add-company-bank-details';
                const args = {
                    company_master_id: id,
                    bank_name: bank_details.bank_name,
                    name_on_account: bank_details.name_on_account,
                    routing_number: bank_details.routing_number,
                    account_number: bank_details.account_number,
                    account_type: bank_details.account_type,
                    account_category: bank_details.account_category,
                    email_to_notify: bank_details.email_to_notify,
                    ach_info_added_to_bank: 0,
                    acceptFlag : 1,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res){
                    if (res.success === true) {
                        // swal({
                        //     title: "Success",
                        //     text: res.message,
                        //     icon: "success",
                        //     button: "Ok",
                        // })
                     
                        setPaymentMadeEndedDate(res.data.paymentMadeEndedDate);
                        setOpencalender(true);
                        setSelectedRanges([
                            { start: res.data.acceptedDate, end: res.data.acceptedDate, color: 'highlight0' },
                            { start: res.data.verifiedStartedDate, end: res.data.verifiedMiniEndedDate, color: 'highlight2' },
                            { start: res.data.paymentMadeStartedDate, end: res.data.paymentMadeEndedMiniEndedDate, color: 'highlight1' },
                            { start: res.data.verifiedEndedDate, end: res.data.verifiedEndedDate, color: 'darkOrange' },
                            { start: res.data.paymentMadeEndedDate, end: res.data.paymentMadeEndedDate, color: 'darkGreen' },
                        ])
                        // navigate('/affiliate/dashboard');

                    } else {
    
                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }
            }
        }else{
            alert("Please Click on Checkbox to confirm bank details");
        }
        setLoaderOff();
        setConfirmation(false);
        }

        return (
            <>
                <Box>
                    <Card variant="outlined" size="lg" >
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <Typography level="h4">
                                    Bank Details
                                </Typography >
                            </Grid>
                        </CardOverflow>
                       
                        <CardContent>
                            <Grid  >
                                <Table borderAxis="none" variant="plain" stripe="odd" size="sm" sx={{minWidth: isMobile ? "550px" : "100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="borderStyle custom-table-cell" >Bank Name</TableCell>
                                            <TableCell className="borderStyle custom-table-cell" >Name on Account</TableCell>
                                            <TableCell className="borderStyle custom-table-cell datatable">Bank Routing (ABA/SCH) Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle details"><Typography level="h5">{bank_details.bank_name}</Typography></TableCell>
                                            <TableCell className="borderStyle details" ><Typography level="h5">{bank_details.name_on_account}</Typography></TableCell>
                                            <TableCell className="borderStyle details" ><Typography level="h5">{bank_details.routing_number}</Typography></TableCell>
                                        </TableRow>
                                        </TableBody>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell className=" borderStyle custom-table-cell" >Account No.</TableCell>
                                            <TableCell className="borderStyle custom-table-cell" >Account Type</TableCell>
                                            <TableCell className="borderStyle custom-table-cell" >Is Account</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle details" ><Typography level="h5">{bank_details.account_number}</Typography></TableCell>
                                            <TableCell className="borderStyle details" ><Typography level="h5">{bank_details.account_type}</Typography></TableCell>
                                            <TableCell className="borderStyle details" ><Typography level="h5">{bank_details.account_category}</Typography></TableCell>
                                        </TableRow>
                                        </TableBody>
                                        <TableHead>
                                        <TableRow>
                                        <TableCell className=" borderStyle custom-table-cell" colSpan={3}>Email Address to notify of Payment Transfers</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle details" colSpan={3}><Typography level="h5">{bank_details.email_to_notify}</Typography></TableCell>
                                        </TableRow>
                                        </TableBody>
                                </Table>
                                </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center", marginTop: isMobile ? "2%":"0%"}}>
                                <FormControlLabel control={<Checkbox value={submitBankDetails} onClick={changeFlagValue}/>} label={`I confirm that the above payment details are correct. Pay $${parseFloat(commission).toFixed(2)} to the above account.`} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center"}}>
                                <Button variant="contained" sx={{ minWidth: 300, maxWidth: "100%" }} color="success" disabled={confirm} onClick={EditBankDetails}>
                                   Confirm
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                {/* -----------Calender dialog -------------*/}
                    
                </Box>
                <Dialog
                    open={openCalender}
                    onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClsoeCalender();
                    }
                    }}
                    fullWidth={fullWidth}
                    maxWidth={'sm'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                  
                    <DialogContent>
                        <Grid container lg={12} md={12} xs={12} sm={12} >
                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                <Calendar
                                    calendarType='gregory'
                                    className='popup-calender'
                                    tileClassName={tileClassName}
                                    next2Label={null}
                                    prev2Label={null}
                                    // onChange={handleSelect}
                                    // value={selectedRanges}
                                />            
                            </Grid>
                            <Grid item lg={6} md={6} xs={6} sm={6}>
                                <Grid item lg={12} md={12} xs={12} sm={12}>
                                    <DialogContentText sx={{alignSelf:'center',display: "flex", justifyContent: "center"}}>
                                        <Box>
                                            <Typography level="h4" sx={{ fontWeight: "bold"}} pb={2}>Expect to be paid by <br/>{Moment(paymentMadeEndedDate).format('MMMM DD, YYYY')}</Typography> 
                                            <Typography level="h4" sx={{ fontWeight: "bold"}} pb={2}>Legends </Typography> 
                                            <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}}><div className='circleColor highlight0'></div><Typography pl={2}>Commission Accepted</Typography></Box>
                                            <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor highlight2'></div><Typography pl={2}>Bank Details Verified</Typography></Box>
                                            <Box sx={{display:'flex',justifyContent: 'start',alignItems:'center'}} pt={1}><div className='circleColor highlight1'></div><Typography pl={2} >Expected Payment Date </Typography></Box>
                                        </Box>
                                    </DialogContentText>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12} sm={12}  sx={{position: 'absolute',bottom: '25px',right: '20px'}}>
                                    <DialogContentText >
                                        <Button variant="contained"  color="success" onClick={handleClsoeCalender} sx={{height: 'fit-content',marginLeft:'9px'}}>Ok</Button>
                                    </DialogContentText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>      
            </>
        );
    }

    

export default ViewBankDetails;