import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
//Material-UI Imports
import AuthUser from '../Auth/AuthUser';
import config from '../../AppConfig';
import AiFillPhone from '@mui/icons-material/Phone';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { Box, Button,Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useNavigate } from 'react-router-dom';
import {openOrFocusWindow } from  '../CommonFormula';

function Closed(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});



  const tablePreferencesArray = {
    plan: false,
    phone_no: false,
    SubscriptionDate: false,
  }

  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setData(props.tableData);
    }
    else{
      setData([]);
    } 
    setIsLoading(false);
  }, [props.tableData])
 

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  //fetch table preferences from database
  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/closeMemberlist';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'closeMemberlist'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  const handleClick = (phone_no) => {
    window.open(`${config.CallHippoDialer}${phone_no}`, 'CallHippo Dialer');
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        width: 100,
        enablePinning: true,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        accessorKey: 'AID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no}`,
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue,row }) => (
          row.original.phone_no ?
					<div>
						{row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
						&nbsp;&nbsp;
				<span>
					{ row.original.current_hours != '' ? 
							row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
							<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
						:''
					}
				</span>
          <Tooltip title="Dial" arrow>
            <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
              <AiFillPhone color="primary" />
            </a>
          </Tooltip>
					</div>: 'N/A'
				),
			},
      {
        accessorFn: (data) => `${data.subscription_date ? data.subscription_date : ''}`,
        // `${data.subscription_date ? Moment(data.subscription_date).format('MM-DD-YYYY hh:mm') + " Hrs" : ''}`,
        enableClickToCopy: false,
        header: 'Subscription Date',
        width: 100,
        id: 'SubscriptionDate',
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs":"N/A"}
          </div>          
          ),
      },

      {
        accessorFn: (data) =>`${data.close_date ? data.close_date : ''}`,
        // `${data.close_date ? Moment(data.close_date).format('MM-DD-YYYY') : ''}`, 
        enableClickToCopy: false,
        header: 'Close Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div>
            {row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY'):"N/A"}
           </div>          
         ),
      },

      {
        accessorFn: (data) => `${data.user ? data.user.first_name.trim() + " " + data.user.last_name.trim() : ''}`,
        enableClickToCopy: false,
        header: 'Assigned To',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <>
            {row.original.user ? row.original.user.first_name.trim() + " " + row.original.user.last_name.trim() : 'N/A'}
           </>          
         ),
      },
    ],
    [],
  );

  const columns2 = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        width: 100,
        enablePinning: true,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        accessorKey: 'AID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no}`,
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue,row }) => (
					<div>
						{row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
						&nbsp;&nbsp;
				<span>
					{ row.original.current_hours != '' ? 
							row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
							<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
						:''
					}
				</span>
					</div>
				),
			},
      
      {
        accessorFn: (data) =>`${data.subscription_date ? data.subscription_date : '0000-00-00 00:00:00'}`,
        // `${data.subscription_date ? Moment(data.subscription_date).format('MM-DD-YYYY hh:mm') + " Hrs" : ''}`,
        enableClickToCopy: false,
        header: 'Subscription Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs":"N/A"}
          </div>          
          ),
      },

      {
        accessorFn: (data) =>`${data.close_date ? data.close_date : ''}`,
        // `${data.close_date ? Moment(data.close_date).format('MM-DD-YYYY') : ''}`,
        enableClickToCopy: false,
        header: 'Close Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div>
            {row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY'):"N/A"}
           </div>          
         ), 
      },
      // {
      //   accessorFn: (data) => `${data.user ? data.user.first_name.trim() + " " + data.user.last_name.trim() : ''}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //   enableClickToCopy: false,
      //   id: 'assign_to',
      //   header: 'Assigned To',
      //   width: 100,
      //   Cell:({renderedCellValue,row}) => (
      //     <>
      //       {row.original.user ? row.original.user.first_name.trim() + " " + row.original.user.last_name.trim() : 'N/A'}
      //      </>          
      //    ),
      // },

    ],
    [],
  );
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id > 6){
      navigate('/login');
      return false;
    }

    let exportedData;
    let exportedColumns;

    if (user.roles_id < 3) {
      // Export data and columns for columns
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YY hh:mm') + " Hrs" : ''}`,
          close_date: `${row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : ''}`,
          assignedTo: `${row.original.user ? row.original.user.first_name + " " + row.original.user.last_name : 'N/A'}`,
        };
      });

      exportedColumns = columns;
    } else {
      // Export data and columns for columns2
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YY hh:mm') + " Hrs" : ''}`,
          close_date: `${row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : ''}`,
          assignedTo: `${row.original.user ? row.original.user.first_name + " " + row.original.user.last_name : 'N/A'}`,
        };
      });

      exportedColumns = columns2;
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Closed panel',
      headers: exportedColumns.map((column) => column.header),
    };

     // Convert data to CSV format
  const csvContent = convertToCSV(exportedData, csvOptions);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data, csvOptions) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
  };
  return (
    <>
      {/* {isLoading ? <Loader /> : <></>} */}
      <MaterialReactTable
        columns={user.roles_id < 3 ? columns : columns2}
        data={data}
        enableColumnFilterModes
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        // enableRowSelection
        enableColumnFilters={config.DatatableColumnFilter}
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        enablePinning
        state={{ columnVisibility, rowSelection,isLoading:isLoading}} //pass our managed row selection state to the table to use
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          columnPinning: { left: ['mrt-row-select','member_id'] }
        }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
                color="primary"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>
          </Box>
        )}
      />
    </>
  );
}


export default Closed;