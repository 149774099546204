
import React,{useMemo,useState,useEffect} from 'react';
import { useNavigate} from 'react-router-dom';

import DefaultLayout from '../../../Components/DefaultLayout';
import {Box,Button,Tooltip,Grid,FormControl,InputLabel,Select,MenuItem} from '@mui/material';
import { Typography } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { fetchData,postData} from '../../../redux/apiSlice';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { currencyFormat } from '../../../Components/CommonFormula';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import swal from "sweetalert";
import LinearProgress from '@mui/joy/LinearProgress';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};

function CommissionTargets(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const {user } = AuthUser();
    const [freez, setFreez] = React.useState(false);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const navigate = useNavigate();
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [projectedMonth, setProjectedMonth] = useState('');
    const [targetMonth, setTargetMonth] = useState('');
    const [targetyYear, setTargetYear] = useState('');
    const [currentDate, setCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const customSortPendingTarget = (rowA, rowB) => {
      const flagA =  rowA.original.monthly_active_count.length > 0 ? (rowA.original.target_active_enrollments < rowA.original.monthly_active_count[0].count ? 0 : rowA.original.monthly_active_count[0].count - rowA.original.target_active_enrollments) : rowA.original.target_active_enrollments;
      const flagB =  rowB.original.monthly_active_count.length > 0 ? (rowB.original.target_active_enrollments < rowB.original.monthly_active_count[0].count ? 0 : rowB.original.monthly_active_count[0].count - rowB.original.target_active_enrollments) : rowB.original.target_active_enrollments;
      return flagA - flagB;
    };

    const customSortPotentialEarning = (rowA, rowB) => {
      const flagA =  (rowA.original.inputMonthCommission * 25/100).toFixed(2);
      const flagB =  (rowB.original.inputMonthCommission * 25/100).toFixed(2);
      return flagA - flagB;
    };

    const customSortTotalCommission = (rowA, rowB) => {
      const flagA =  parseFloat(rowA.original.inputMonthCommission * 25 / 100) + parseFloat(rowA.original.inputMonthCommission);
      const flagB =  parseFloat(rowB.original.inputMonthCommission * 25 / 100) + parseFloat(rowB.original.inputMonthCommission);
      return flagA - flagB;
    };


    useEffect(() => {      
      if(user.roles_id < 3) {
        const mycurrentDate = new Date();
        const monthName = mycurrentDate.getMonth();
        // setTargetMonth(monthName);
        setTargetYear(mycurrentDate.getFullYear());

        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setCurrentDate(cDate);

        const startDate = new Date('2005-01-01');
        startDate.setDate(startDate.getDate());
        var cDate = Moment(startDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setMinDate(cDate);
      }else{
        navigate('/login');
      }
    }, []);

    const submitForm = async(event) => {
      const sendYear = new Date(selectedYear).getFullYear();
      if((sendYear === null || isNaN(sendYear)) || selectedMonth === '') {
        swal({
          title: "Failed",
          text: "Please select Month & Year",
          icon: "error",
          button: "Ok",
        })
        return false;
      }
      setFreez(true);
      const apiUrl = '/commission-targets';
      const args = {
        month: selectedMonth,
        year: sendYear
      };
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if(res) {
          if(res.success == true) {
              setData(res.data.commissionTargets);
              // setMonth(res.data.commissionFetchData.month);
              // setYear(res.data.commissionFetchData.year);

              // const currentMonthIndex = monthNames.findIndex(month => month === res.data.commissionFetchData.month);
              // const nextMonthIndex = (currentMonthIndex + 1) % 12;
              // const projectedMonthName = monthNames[nextMonthIndex];
              // const targetMonthIndex = (nextMonthIndex + 1) % 12;
              // const targetMonthName = monthNames[targetMonthIndex];
              // setProjectedMonth(targetMonthName);
              setTargetMonth(selectedMonth);
              setTargetYear(sendYear);
          }else{
            setData([]);
            swal({
              title: "Failed",
              text: res.message,
              icon: "error",
              button: "Ok",
            })
          }
      }
      handleFreezStop();

  };

    const columns = useMemo(
      //column definitions...
      () => [
        {
          accessorKey: 'companyName',
          accessorFn: (row) => `${row.company_details ? row.company_details.company_name : ''}`,
          id:'companyName',
          header: 'Company Name',
          size: 50,
        },
        {
          accessorKey: 'name',
          accessorFn: (row) => `${row.reference_affiliates ?   (row.reference_affiliates.first_name ? row.reference_affiliates.first_name : '') + " " + (row.reference_affiliates.last_name ? row.reference_affiliates.last_name : 'N/A') : '' }`,
          id:'Name',
          header: 'Name',
          size: 50,
        },
        {
          accessorKey: 'email',
          accessorFn: (row) => `${row.reference_affiliates ?   (row.reference_affiliates.communication_email ? row.reference_affiliates.communication_email : 'N/A')  : '' }`,
          id:'email',
          header: 'Communication Email',
          size: 50,
        },
        {
          accessorKey: 'phone_no',
          accessorFn: (row) => `${row.reference_affiliates ?  ((row.reference_affiliates.phone_no !== '' && row.reference_affiliates.phone_no != null && row.reference_affiliates.phone_no != '0' ? formatPhoneNumber(row.reference_affiliates.phone_no ): 'N/A') ): 'N/A'  }`,
          id:'phone',
          header: 'Phone No.',
          size: 50,
          Cell:({renderedCellValue,row}) =>(row.original.reference_affiliates ?  ((row.original.reference_affiliates.phone_no !== '' && row.original.reference_affiliates.phone_no != null && row.original.reference_affiliates.phone_no != '0' ? formatPhoneNumber(row.original.reference_affiliates.phone_no ): 'N/A') ): 'N/A' ),
        },
        {
          accessorFn: (row) => `${row.inputMonthCommission}`,
          id:'projectedCommission',
          header:`${targetMonth ? targetMonth : ''} Commission ($) (Projected)`,
          size: 50,
          Cell:({renderedCellValue,row}) =>((currencyFormat(parseFloat(row.original.inputMonthCommission))))
        },
        {
          accessorKey: 'lastMonthActives',
          accessorFn: (row) => `${row.active_enrollments}`,
          id:'lastMonthActives',
          header: 'Last Month Actives',
          size: 50,
        },
        {
          accessorKey: 'newTargets',
          accessorFn: (row) => `${row.target_active_enrollments}`,
          id:'newTargets',
          header: 'New Targets',
          size: 50,
        },
        {
          accessorKey: 'currentActives',
          accessorFn: (row) => `${row.monthly_active_count.length > 0 ? row.monthly_active_count[0].count : 0}`,
          id:'currentActives',
          header: 'Current Actives',
          size: 50,
          Cell:({renderedCellValue,row}) => (
            row.original.monthly_active_count.length > 0 ? row.original.target_active_enrollments < row.original.monthly_active_count[0].count ?
            <div style={{ color: 'green'}} >
                { row.original.monthly_active_count[0].count }
             </div>
             :
              row.original.active_enrollments < row.original.monthly_active_count.length > 0 ?
              <div style={{ color: 'green'}} >
                { row.original.monthly_active_count[0].count }
              </div>
             :          
             row.original.active_enrollments > row.original.monthly_active_count[0].count ?
             <div style={{color: 'red' }} >
              { row.original.monthly_active_count[0].count }
            </div>
            :
            <div>{row.original.monthly_active_count[0].count}</div>
            :
            0
           ),
        },
        {
          accessorKey: 'pendingTargets',
          accessorFn: (row) => `${row.target_active_enrollments}`,
          id: 'pendingTargets',
          header: 'Pending Targets',
          size: 50,
          sortingFn: customSortPendingTarget,
          Cell: ({ renderedCellValue, row }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: 'green', marginRight: '10px' }}>
              {row.original.monthly_active_count.length > 0 ? (row.original.target_active_enrollments < row.original.monthly_active_count[0].count ? 0 : row.original.target_active_enrollments - row.original.monthly_active_count[0].count) : row.original.target_active_enrollments}
              </div>
              <div style={{width:"100%"}}>
              <LinearProgress determinate value={row.original.monthly_active_count.length > 0 ? (row.original.target_active_enrollments < row.original.monthly_active_count[0].count ? 100 : (parseFloat(row.original.monthly_active_count[0].count/row.original.target_active_enrollments * 100)).toFixed(2)) : 0} thickness={15} color="success" sx={{backgroundColor:'#ded9d9'}}>
                <span style={{fontSize:'12px',zIndex:1,color:'white',fontWeight:800}}>{row.original.monthly_active_count.length > 0 ? (row.original.target_active_enrollments < row.original.monthly_active_count[0].count ? 100 : (parseFloat(row.original.monthly_active_count[0].count/row.original.target_active_enrollments * 100)).toFixed(2)) : 0}%</span>
               </LinearProgress>
                  </div>
                </div>
              )
        },
        // {
        //   accessorFn: (row) => `${row.target_active_enrollments}`,
        //   id:'PotentialEarning',
        //   header: 'Potential Earning ($)',
        //   size: 50,
        //   sortingFn: customSortPotentialEarning,
        //   Cell:({renderedCellValue,row}) =>(currencyFormat(row.original.inputMonthCommission * 25/100))
        // },
        {
          accessorFn: (row) => `${row.target_active_enrollments}`,
          id:'TotalProjectedCommission',
          header: 'Potential Commission With Bonus($)',
          size: 50,
          sortingFn: customSortTotalCommission,
          Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.inputMonthCommission * 25 / 100) + parseFloat(row.original.inputMonthCommission)))
        },
       
      ]);


    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Target Commissions',
      headers: ['Company Name', 'Name', 'Email','Phone No.',`${targetMonth ? targetMonth : ''} Commission ($) (Projected)`, 'Last Month Actives','New Targets','Current Actives','Pending Targets','Potential Commission With Bonus($)'],
    };

    const handleExportData = (data) => {
      const exportedData = data.map((row) => {
        
        return {
          companyName: `${row.original.company_details ?  row.original.company_details.company_name : ''}` || 'N/A',
          name: `${row.original.reference_affiliates ?   (row.original.reference_affiliates.first_name ? row.original.reference_affiliates.first_name : '') + " " + (row.original.reference_affiliates.last_name ? row.original.reference_affiliates.last_name : 'N/A') : '' }` || 'N/A',
          email:  `${row.original.reference_affiliates ? (row.original.reference_affiliates.communication_email ? row.original.reference_affiliates.communication_email : 'N/A')  : '' }` || 'N/A',
          phone:  `${ row.original.reference_affiliates ?  ((row.original.reference_affiliates.phone_no !== '' && row.original.reference_affiliates.phone_no != null && row.original.reference_affiliates.phone_no != '0' ? formatPhoneNumber(row.original.reference_affiliates.phone_no ): 'N/A') ): 'N/A' }` || 'N/A',
          projected_commission : `${row.original.inputMonthCommission}`,
          active_enrollments : `${row.original.active_enrollments}`,
          target_active_enrollments : `${row.original.target_active_enrollments}`,
          currentActives : `${row.original.monthly_active_count.length > 0 ? row.original.monthly_active_count[0].count : 0}`,
          pendingTargets : `${row.original.monthly_active_count.length > 0 ? (row.original.target_active_enrollments < row.original.monthly_active_count[0].count ? 0 : row.original.target_active_enrollments - row.original.monthly_active_count[0].count) : row.original.target_active_enrollments}`,
          // PotentialEarning : `${(row.original.inputMonthCommission * 25/100).toFixed(2)}`,
          totalCommission : `${(parseFloat(row.original.inputMonthCommission * 25 / 100) + parseFloat(row.original.inputMonthCommission)).toFixed(2)}`
        };
      });
    
      const csvContent = convertToCSV(exportedData);
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';
  
    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted
  
        // Replace newline characters with spaces
        if (typeof value === 'string') {
          value = value.replace(/\n/g, ' '); // Replace newline characters with spaces
        }
  
        // Wrap the value with double quotes if it contains a comma or quote
        if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
          return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
        }
  
        return value;
      }).join(',');
    }).join('\n');
  
    return headers + rows;
  };
    
    function ContentLayout() {
    return (
        <>
            <Box>
                <Typography level="h4" color="primary">Target Enrollments </Typography>

                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <Grid >
                      <FormControl sx={{ width: 200 }} mt={4}>
                          <InputLabel id="month">Select Month</InputLabel>
                          <Select
                              labelId="demo-multiple-name-label"
                              name='month'
                              label="Select Month"
                              value={selectedMonth ? selectedMonth : ''}
                              MenuProps={MenuProps}
                              onChange={(e) => 
                                { 
                                  setSelectedMonth(e.target.value);
                                 }}
                          >
                              <MenuItem value="January" >January</MenuItem>
                              <MenuItem value="February" >February</MenuItem>
                              <MenuItem value="March">March</MenuItem>
                              <MenuItem value="April" >April</MenuItem>
                              <MenuItem value="May" >May</MenuItem>
                              <MenuItem value="June" >June</MenuItem>
                              <MenuItem value="July" >July</MenuItem>
                              <MenuItem value="August" >August</MenuItem>
                              <MenuItem value="September" >September</MenuItem>
                              <MenuItem value="October" >October</MenuItem>
                              <MenuItem value="November" >November</MenuItem>
                              <MenuItem value="December" >December</MenuItem>
                          </Select>
                      </FormControl>&nbsp;&nbsp;&nbsp;  
                  </Grid>
                  <Grid >
                      <FormControl sx={{ width: 200 }} size='small' className='commission-year-selector'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']} >
                                  <DatePicker

                                      id="year"
                                      name="year"
                                      label="Select Year *"
                                      views={['year']}
                                      value={selectedYear ? selectedYear : ''}
                                      onChange={(e) => {
                                        setSelectedYear(e); 
                                      }}
                                      MenuProps={MenuProps}
                                      minDate={dayjs(minDate)}
                                      maxDate={dayjs(currentDate)}
                                  />
                              </DemoContainer>
                          </LocalizationProvider>
                      </FormControl>&nbsp;&nbsp;&nbsp; 
                  </Grid>
                  <Grid sx={{display: 'flex',alignItems: 'center'}}>
                      <Button color='success' variant="contained" onClick={submitForm} sx={{ minWidth: 200 }} >Submit</Button>
                  </Grid>
                </Grid>
                
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableColumnFilterModes
                        filterFns={{
                          customSearchFilterFn: (row, id, filterValue) =>
                          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }} 
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        enableRowSelection={false}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        enableColumnFilters={config.DatatableColumnFilter}
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                        initialState={{
                          showColumnFilters: false,
                          density: 'compact',
                        }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        //top csv option
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                          >
                            <Button
                              color="primary"
                              disabled={table.getPrePaginationRowModel().rows.length === 0}
                              //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportData(table.getPrePaginationRowModel().rows)
                                }
                              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                              startIcon={<FileDownloadIcon />}
                              variant="contained"
                            >
                              CSV
                            </Button>

                          </Box>
                        )
                        }
                      />
                
            </Box>
        </>
        );
    }
     
    
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
      <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
            >
              <Freez />
            </Backdrop>
          </div>
          {/* loader code end */}
        </div>
    );
}
    
export default CommissionTargets;