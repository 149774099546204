import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Card, CardOverflow,CardContent,Table, formLabelClasses } from '@mui/joy';
import { styled } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Grid, FormLabel, TextField, FormControl, RadioGroup, FormControlLabel, Radio,
    Box, Tab, InputLabel,Tooltip,IconButton,OutlinedInput,Stack,InputAdornment,Button,Checkbox
} from '@mui/material';
import AuthUser from '../../../Components/Auth/AuthUser';
import ViewBankDetails from '../../../Components/CommissionSummary/ViewBankDetails';
import UpdateBankDetails from '../../../Components/CommissionSummary/UpdateBankDetails';
import Loader from '../../../Components/Loader/Loader';
import { useSelector } from 'react-redux';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { logout } from "../../../redux/authSlice";
import { fetchData } from '../../../redux/apiSlice';
import Moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
function AffiliateUpdateBankDetailsIndex(props) {
    const { companyId } = useParams();
    const [flag,setFlag]= useState(true);
    const [bank_details, setBankDetails] = useState([]);
    const [balance, setBalance] = useState(0);
    const [commissionMonthYear, setCommissionMonthYear] = useState([]);
    const { http } = AuthUser();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [operation, setOperation] = useState("Update");
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => JSON.parse(state.auth.user));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    useEffect(() => {
        fetchCompanyBankDetails();
    }, [])

    const changeFlag = () => {
        setFlag(!flag); 
    }

    const setLoaderOn = () => {
        setIsLoading(true); 
    }

    const setLoaderOff = () => {
        setIsLoading(false); 
    }

    const saveBankDetails = (formValues) => {
        setFlag(!flag);
        setBankDetails(formValues);
    }

    const fetchCompanyBankDetails = async () => {
        if(isAuthenticated) {
            if(user.roles_id === 3) {
                if(user.company_master_id == id)
                {
                    const apiUrl = '/fetch-company-bank-details-with-commission/' + id;
                    const res = await dispatch(fetchData(apiUrl)).unwrap();
                    if (res) {
                        if(res.success === true) {
                            if (res.data.bankDetails != null) {
                                setBankDetails(res.data.bankDetails);
                                setBalance(res.data.balance);
                                setCommissionMonthYear(res.data.commissionMonthYear);
                                setIsLoading(false);
                            } else {
                                setOperation("Create");
                                setBalance(res.data.balance);
                                setCommissionMonthYear(res.data.commissionMonthYear);
                                setIsLoading(false);
                            }
                        }else{
                            swal({
                                title: "Alert",
                                text: "You have already confirmed your bank details!",
                                icon: "error",
                                button: "Ok",
                            })
                            navigate('/affiliate/dashboard');
                        }
                    }
                }
                else {
                    dispatch(logout());
                    localStorage.clear();
                    // console.log(isAuthenticated)
                    navigate('/login');
                }
            }else {
                navigate('/affiliate/dashboard');
            }
            
        }
        
    };

    const ContentLayout =
        <>
             {isLoading?<Loader/>:<></>}
                <Box >
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Typography level="h3">Your Balance :&nbsp;&nbsp;
                                <span style={{color:"green"}}>${ parseFloat(balance).toFixed(2)}</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{textAlign: isMobile ? "start" : "right" ,marginTop:isMobile?"3%":"0%"}}>
                        {   flag  ? 
                                <Typography>To update this info<span> &nbsp;&nbsp;<Button variant="contained" sx={{ minWidth: 100, maxWidth: "100%" }} color="primary"  onClick={() => {changeFlag()}}>Click Here</Button></span></Typography>
                            :  
                                <Button variant="contained" sx={{ minWidth: 100, maxWidth: "100%" }} color="primary"  onClick={() => {changeFlag()}}>Back</Button>
                        } 
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography level="h6">
                            Last Approved Commission:  &nbsp;
                            {commissionMonthYear.map((commisionValue,index) => (
                            <>
                             {Moment( '2023/'+ commisionValue.month + '01').format('MMM')  + " " + commisionValue.year + ": " + "$" + parseFloat(commisionValue.commission).toFixed(2)}&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                            ))}
                        </Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography level="h6">Your Banking Details With Us</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{paddingBottom:"50px"}} mt={1}>
                        <Typography level="body1">Please review and check the confirm box below or update any information and click the "{operation}" button</Typography>
                    </Grid>
                    {   flag ? 
                        <ViewBankDetails bankDetails={bank_details} operation={operation} commission={balance} onBankDetailSubmit={saveBankDetails} setLoaderOn={setLoaderOn} setLoaderOff={setLoaderOff}/>
                    : <UpdateBankDetails bank_details={bank_details} bankOperation={operation} onBankDetailSubmit={saveBankDetails}/>
                    } 
                </Box>
               
            </>
 
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}

export default AffiliateUpdateBankDetailsIndex;