import React, { useMemo, useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, FormControl, MenuItem, InputLabel, Select, OutlinedInput } from '@mui/material';
import Typography from '@mui/joy/Typography';
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import config from '../../../AppConfig';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { format, subDays } from 'date-fns';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Badge from '@mui/material/Badge';
import Moment from 'moment';
import Table from '@mui/joy/Table';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
function ReferralReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = AuthUser();
  const [referalinfo, setReferalInfo] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const q = search == '' ? 'null' : search;
  const [isLoading, setIsLoading] = useState(true);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();


  const tablePreferencesArray = {

  }
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])
  ////////////////////////

  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/referrals';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'referrals'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }
  //loader function and variable start
  const [freez, setFreez] = React.useState(true);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  //loader function and variable end

  //Clear date ranges start
  const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];
  //Get referal info function start

  //fetchcompany list function start
  const GetReferalinfo = async page => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    handleFreezStart();
    const apiUrl = '/admin/fetchReferredList/' + q + '/' + perPage + '/list';
    const args = {
      fromDate: startDate,
      toDate: endDate
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res.success == true) {
      setReferalInfo(res.data.data)
      setIsLoading(false);
      handleFreezStop();
    }else{
			handleFreezStop();
      setIsLoading(false);
		}
  }
  const handlePageChange = page => {
    GetReferalinfo(page);
  };
  //fetchcompany list function end


  useEffect(() => {
    if (user.roles_id < 3) {
      GetReferalinfo();
    } else {
      navigate('/login');
    }
  }, []);

  //Get referal info function start
  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`, 
        id: 'name',
        header: 'Name',
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {row.original.name ? row.original.name.trim() : "N/A"}
          </Box>
        ),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`, 
        
        id: 'email',
        header: 'Email ID',
        size: 100,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          sx: { justifyContent: 'flex-start' },
        },
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.company_name ? data.company_name.trim() : ''}`, 
        filterVariant: 'range',
        id: 'Company Name',
        header: 'Company Name',
        size: 50,
        //custom conditional format and styling
        Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.memberCount}`,
        filterVariant: 'range',
        id: 'memberCount',
        header: 'Affiliate Enroll Count',
        size: 100,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          sx: { justifyContent: 'flex-center' },
        },
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <Badge badgeContent={`${row.original.memberCount}`} color={row.original.memberCount === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
          </div>
        ),
        //custom conditional format and styling
      },
      {
        accessorFn: (data) => `${data.refferralCount}`,
        filterVariant: 'range',
        id: 'refferralCount',
        header: 'Referral Enroll Count',
        size: 100,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          sx: { justifyContent: 'flex-center' },
        },
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            <Badge badgeContent={`${row.original.refferralCount}`} color={row.original.refferralCount === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
          </div>
        ),
        //custom conditional format and styling
      },


    ]
  )
  //csv start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Referrals-Report',
    headers: ['Name', 'Email ID', 'Company Name', 'Affiliate Enroll Count', 'Referral Enroll Count',],

  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (referalinfo) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    const exportedData = [];

    referalinfo.forEach((row) => {
      // Main Table Data
      const mainTableRow = {
        'Name': row.original.name,
        'Email ID': row.original.email,
        'Company Name': row.original.company_name,
        'Affiliate Enroll Count': row.original.memberCount,
        'Referral Enroll Count': row.original.refferralCount,
      };
      exportedData.push(mainTableRow);
      // Add subheaders
      const subheader = {
        'Referred Affiliate': 'Referred Affiliate', // Subheader 1
        'Name': 'Name', // Subheader 2
        'Email': 'Email', // Subheader 3
        'Referred AID': 'Referred AID', // Subheader 4
        'Date Approved': 'Date Approved', // Subheader 5
        'Member Count': 'Member Count', // Subheader 6
      };
      exportedData.push(subheader);
      if (row.original.childdata) {
        row.original.childdata.forEach((element) => {
          const subRow = {
            referredaffiliate: element.company_name || 'N/A',
            name: element.first_name + " " + element.last_name || 'N/A',
            email: element.email || 'N/A',
            referredaid: element.aidList || "N/A",
            dateapproved: (element.approved_date) ? Moment(element.approved_date).format('MM-DD-YYYY') : 'N/A',
            membercount: element.memberCount
          };
          exportedData.push(subRow);
        });
      } else {
        // If no subrows available, add an empty row for subrows
        exportedData.push({});
      }
      exportedData.push({});
    });
    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here
  const ContentLayout=
      <>
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
          <Typography color="primary" level="h4">Referrals</Typography>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value="date"
                input={<OutlinedInput label="Select Date Range" />}
              >
                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                <DateRangePicker
                  className='custom-date-picker'
                  onChange={item => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="horizontal"
                  maxDate={new Date()}
                  staticRanges={staticRanges}
                />
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={8} md={8} sm={6} xs={6}>
            <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={GetReferalinfo} > Submit</Button>
          </Grid>
        </Grid>

        <MaterialReactTable
          columns={columns}
          data={referalinfo} 
           filterFns={{
            customSearchFilterFn: (row, id, filterValue) =>
            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
          }}   
          globalFilterFn="customSearchFilterFn" //exact serach filter functionality

          enableColumnOrdering={false}
          enableClickToCopy={true}
          enableColumnFilterModes
          enableColumnFilters={config.DatatableColumnFilter}
          getRowId={(row) => row.id} //give each row a more useful id
          onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
          initialState={{
            showColumnFilters: false,
            density: 'compact',
          }}

          
          //my code for expanded row
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Referred Affiliate</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Referred AID</th>
                    <th>Date Approved</th>
                    <th>Member Count</th>
                  </tr>
                </thead>
                <tbody>
                  {/* added  unique "key" for table and also wrapping the map in this syntax<>....</>  */}
                    <>
                    {row.original.childdata ? row.original.childdata.map((element, index) => (
                      <tr  key={index}>
                        <td>{element.company_name}</td>
                        <td>{element.first_name + " " + element.last_name}</td>
                        <td className='datatable'>{element.email}</td>
                        <td className='datatable'>{element.aidList}</td>
                        <td>{(element.approved_date) ? Moment(element.approved_date).format('MM-DD-YYYY') : 'N/A'}</td>
                        <td><Badge badgeContent={`${element.memberCount}`} color={element.memberCount === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
                        </td>
                      </tr>
                       )) : ""}
                    </>
                 
                </tbody>
              </Table>
            </Box>
          )}
          //my code for expanded row
          onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
          positionToolbarAlertBanner="bottom"
          enableDensityToggle={false}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <Button
                color="primary"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                              handleExportData(table.getPrePaginationRowModel().rows)
                            }
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>


            </Box>
          )}
        />
      </>
 
  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}


export default ReferralReport;