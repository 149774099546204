import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
function TermsAndConditions(props) {
    const currentYear = new Date().getFullYear();
    const closeTab = () => {
        props.closeVal(false)
    }
    return (
        <>
            <Box sx={{ minHeight: 300, maxHeight: 500, borderTop: "3px solid #5288db" }} p={4}>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid lg={6} md={6} sm={6} xs={6} item>
                        <Grid item container lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>ACCEPTANCE OF TERMS & CONDITIONS</Typography>
                        </Grid>
                    </Grid>
                    <Grid lg={6} md={6} sm={6} xs={6} item className='companyLogo'>
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="body1" fontSize="lg">Revised November 19, 2015
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="body1" fontSize="lg">
                        Notice:Your use of this web site beyond the home page constitutes acceptance of the Terms of Use.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Introduction
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Welcome to this web site. We are a paid subscription service that provides ongoing services to our members which include, but are not limited to, credit report monitoring and credit score tracking services, we send email alerts to members of certain significant changes to the member’ personal credit information, and we provide our members the ability to place a secure order for a copy of their own personal credit score(s) and credit report(s). Members who are active and in good standing can securely access and view the information we make available to them over the Internet on our web site(s) via certain Internet-connected computers. Smartphones, and other device. If you enroll in the subscription services offered on this web site, or your order your personal credit score and credit report or you purchase a product from this web site, such order or purchase will be governed by the Terms & Conditions set forth herein.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Important Information</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Please read the following information carefully before using this web site. By accessing or using the web site, you acknowledge that you have read, understood, and agree to the Terms & Conditions and to follow all applicable laws and regulations. If you do not agree with the Terms & Conditions, do not use this web site. We reserve the right, in our sole discretion, to modify or update these Terms & Conditions at any time. Please check the Terms & Conditions each time you visit our web site for the most current information.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">This website offers one or more products and services. These products and services are offered at several web addresses or URLs, such as MyFreeScoreNow.com, SmarterScores.com, SmarterScore.com, MyFreeScoresNow.com, and others. Those URLs, individually and collectively, are referred to in these Terms and Conditions as the 'Sites.' We use the terms 'you' and 'your' to include any person who accesses the Sites, obtains Materials (defined below), or purchases or acquires any product or service offered on the Sites ('product(s)' and 'service(s)' collectively referred to as the 'Services') for any amount of time. We use the terms 'we', 'us' and 'our' to refer to MyFreeScoreNow Inc (“MFSN,” “we,” “us,” “our” or similar pronoun). MyFreeScoreNow Inc operates the Sites; our employees, members, officers, partners, subsidiaries, affiliated entities, representatives, attorneys, and agents.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The companies that provide the Materials or the Services to us or to you, include but are not necessarily limited to, TransUnion Interactive, Inc. (“TUI”). All such companies that provide Materials and Services to you on our behalf are referred to collectively in this Agreement as the “Service Providers”. We use the term “Materials” to mean any data or information about you that we provide to you on any web page, email, text message, instant message, or printed page, including full and partial reports and summary alerts, as full or partial fulfillment of the Services.MFSN and its subsidiaries such as SmarterScores LLC, affiliates, divisions, contractors and all data sources and suppliers, all of which are collectively referred to herein as “MFSN.”</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">By submitting your order through our secure order form, you (i) acknowledge receipt of our Privacy Policy and agree to its terms, (ii) confirm your authorization for MFSN to obtain one or more of your consumer credit reports (“Basic Report”), credit scores (“Scores”) and other enhancements to the Basic Report, and other products and services, and (iii) agree to these Terms. If you do not agree with any of these Terms, do not access or otherwise use this Site, the products, services or any information or Content contained on this Site. For purposes of these Terms, the term “Credit Reports” includes Basic Reports, Scores and other enhancements to Basic Reports, individually or collectively, as the context requires.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">YOUR USE OF THE SITE, CONTENT AND SERVICES BEING PROVIDED TO YOU ARE SUBJECT TO THESE TERMS AND CONDITIONS AND ALL APPLICABLE LAWS AND REGULATIONS. PLEASE READ THEM CAREFULLY. YOUR ACCEPTANCE AND USE OF THE SERVICES CONSTITUTE YOUR AGREEMENT TO ABIDE BY EACH OF THE TERMS AND CONDITIONS SET FORTH HEREIN. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS OR CONDITIONS, DO NOT USE THE SERVICES OR ANY OF THE INFORMATION WITHIN THE SERVICES; DISCARD THE SERVICES YOU RECEIVED IMMEDIATELY AND CALL CUSTOMER SERVICE TO CANCEL YOUR MEMBERSHIP. MOREOVER, IF YOU DO NOT AGREE WITH ANY OF THESE TERMS OR CONDITIONS, DO NOT ACCESS OR OTHERWISE USE THIS SITE, THE SERVICES OR ANY INFORMATION CONTAINED ON THIS SITE.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">These Terms are made between you and MFSN, the providers of this Site. Your agreement to these Terms forms a legally binding agreement between you and MFSN. The Site, all products offered on this Site, including but not limited to the membership-based products (including, but not limited to, the Credit Reports) and non-membership based services (collectively, the “Services”), are subject to these Terms that may be updated from time to time. Any information or data about you that we provide to you on any web page, email, text or printed page, including full and partial reports and summary alerts as full or partial fulfillment of the Services, all text, pictures, graphics, logos, button items, Images, works of authorship and other information and all revisions, modifications, and enhancements to the Site (collectively, the “Content”) are subject to these Terms and Conditions, which may be updated from time to time at the sole discretion of MFSN. Please check this Site regularly for updates to these Terms. Each time you order, access or use any of the Services, Site and/or Content, you signify your acceptance and agreement, without limitation or qualification, to be bound by the then current Terms.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Your use of the Services, Site and Content may also be subject to TransUnion Interactive, Inc.'s (“TUI's”) Terms of Use and you agree to such terms therein. Click here for a copy TUI's Terms of Use</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h6">USE OF THIS SITE</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The information on this Site is intended as a convenience and a service for MFSN’s customers and potential customers. You are not permitted to restrict or inhibit any other user from using or enjoying this Site. The copying, framing, mirroring, scraping or data-mining of this Site or any of its Content in any form or by any method is strictly prohibited. Access to this Site does not grant you any right, license or privilege to reproduce, publish or use any material on this Site or any property of MFSN except as expressly set forth herein. Any ideas, concepts, techniques, procedures, methods, systems, designs, plans, charts or other materials you transmit to us through s or this Site will become our property and may be used by us at any time and for any purpose, without restriction, unless otherwise agreed by MFSN. In your use of this Site, you shall not knowingly nor negligently transmit any viruses, Trojan horses, worms, time bombs, cancelbots or other similar harmful or deleterious programming routines.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">USE OF THE SERVICES</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">In consideration of your use of the Content and Services, you agree to provide true, accurate and current information about yourself as prompted by the applications forms on this Site. You certify that you are eighteen (18) years of age or older. If any information you provide is untrue, inaccurate or not current, or if MFSN has reasonable grounds to suspect that such information is untrue, inaccurate or not current, MFSN, at its sole discretion, has the right to suspend or terminate your use of any Services and refuse all current or future access to the Content and use of the Services or suspend or terminate any portion thereof. Further, you agree that MFSN will not be liable to you or any third party if MFSN suspends or terminates your access to the Content or Services for any reason.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN obtains credit information used to prepare Credit Reports from the three national credit repositories (Equifax, Experian, and TransUnion) through relationships with one or more third party service providers including TransUnion Interactive, Inc (“TUI”). The Credit Reports MFSN provides are intended to furnish you with information that you may not otherwise have readily available to you, but should not be relied upon for important personal and financial decisions. You should consult your own professional adviser for specific advice tailored to your personal situation. You agree that MFSN has no liability for information (accurate or inaccurate) in any of your Credit Reports. The Services consist of the products and services described on the Site and include but are not limited to all products and services provided on a recurring, subscription basis ("Subscription Services") or as a one-time transaction (“Transactional Services”). You acknowledge and agree that MFSN obtains Content from the Service Providers. You Agree that MFSN and the Service Providers have no liability for any information (accurate or inaccurate, complete or incomplete) in the Content or any Credit Reports or Scores provided to you as part of the Services.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">How To Cancel Your Enrollment in the Services</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">You may terminate your enrollment in Services at any time by calling us at the toll-free number that appears in Contact Us section of the secure Members Area of the site and if you do not have access to a phone, you may terminate by writing us at PO Box 4798 Seattle, WA 98194 requesting termination. You may not cancel via email to us. We do not provide prorated refunds.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Important Information Regarding your Authorization to Charge your Credit or Debit Card</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">By submitting your order, you authorize MFSN to automatically charge your credit card or debit your bank account the stated enrollment or transaction amount and/or processing fees, and for membership services only, the state amount per month after your free trial has expired. Your enrollment in the Services will continue month-to-month unless and until you call us to cancel at 1-888-548-2008 or MFSN terminates your enrollment.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">CANCELLATION POLICY</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">To cancel your credit monitoring membership, you must call our Customer Service Center toll-free at 1-888-548-2008 during the business hours listed on the Contact Us section of this web site, or send a letter via US Postal Service to: MyFreeScoreNow Inc. PO Box 4798 Seattle, WA 98194. To protect the security of your account, cancellations sent via email, fax, or courier will not be accepted. If you do not cancel before the expiration of the free trial period, your credit or debit card will automatically be charged the then current and applicable monthly fee. You will NOT receive a notice from us that your free trial period has ended or that the paying portion of your membership has begun. We will continue to bill your credit or debit card for your membership fee each month until you cancel. Payments are non-refundable and there are no partial refunds or credits for partially used monthly MyFreeScoreNow membership periods. Refer to the Terms and Conditions for additional membership information</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Refund Policy</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">No Refunds. Payments are non-refundable and there are no partial refunds or credits for partially used monthly MyFreeScoreNow membership periods. Payments are non-refundable for the purchase of any Transactional Services including, but not limited to, the purchase of a new or updated 3-Bureau Credit Report or for the purchase of a new or updated 1-Bureau Credit Report. Following any cancellation, you will no longer be able to log in to the secure members area of the web site or be able to access the services. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members (“credits”). The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The provision of credits in one instance does not entitle you to credits in the future for similar instances, nor does it obligate us to provide credits in the future, under any circumstance.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">How we may contact you</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">We may communicate with and to you at the email address or phone number you provided at the Site. If you have not provided a valid email address, we have the option, but not the obligation, to communicate with you at any postal address that we reasonably believe is your address. You agree that all such notices and other communications that we provide to you via email or on the Site satisfy any legal requirement that such communications be in writing.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">ONLINE REQUIREMENTS</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">You must have an email address and a Java-compatible browser to receive your Services online. As an online customer, you are agreeing to receive all notifications via email at the email address on file with MFSN. To ensure receipt of all notifications, service alerts, and other important messages related to your account, you are obligated to confirm the validity of your email address by following any directions MFSN provides you. Additionally, you are obligated to update the email address on file when your email address changes. In the event that CIC is unable to deliver email messages to you, you agree to accept product notifications in an alternative method. All service alerts are also accessible on your account in the secure Members Area. In the event MFSN is unable to deliver email messages to you, you agree that the Services will be fulfilled at the price agreed upon when you placed your order.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">PERSONAL INFORMATION</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN may use your personal information to the extent necessary to process your order and/or engage in business maintenance.You understand that by submitting your order you are providing “written instructions” in accordance with the Fair Credit Reporting Act, as amended (the “ FRCA ”), for MFSN to obtain information from your personal credit profile from any consumer reporting agency. You also authorize MFSN to access your personal credit profile to verify your identity and to provide credit monitoring, reporting and scoring products.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">YOU UNDERSTAND THAT IT MAY BE A VIOLATION OF FEDERAL AND/OR STATE LAW FOR YOU TO OBTAIN A CREDIT REPORT ON ANY PERSON OTHER THAN YOURSELF, AND THAT UNDER THE FCRA, ANY PERSON WHO KNOWINGLY AND WILLFULLY OBTAINS INFORMATION ON A CONSUMER FROM A CONSUMER REPORTING AGENCY UNDER FALSE PRETENSES SHALL BE FINED UNDER TITLE 18, UNITED STATES CODE, IMPRISONED FOR NOT MORE THAN 2 YEARS, OR BOTH. ARBITRATION</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">YOU UNDERSTAND AND AGREE THAT ALL CLAIMS, DISPUTES OR CONTROVERSIES BETWEEN YOU AND MFSN, INCLUDING, BUT NOT LIMITED TO TORT AND CONTRACT CLAIMS, CLAIMS BASED UPON ANY FEDERAL, STATE OR LOCAL STATUTE, LAW, ORDER, ORDINANCE OR REGULATION, AND THE ISSUE OF ARBITRABILITY, SHALL BE RESOLVED BY FINAL AND BINDING ARBITRATION AT A LOCATION DETERMINED BY THE ARBITRATOR. ANY CONTROVERSY CONCERNING WHETHER A DISPUTE IS ARBITRABLE SHALL BE DETERMINED BY THE ARBITRATOR AND NOT BY THE COURT. JUDGMENT UPON ANY AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED BY ANY STATE OR FEDERAL COURT HAVING JURISDICTION THEREOF. THIS ARBITRATION CONTRACT IS MADE PURSUANT TO A TRANSACTION IN INTERSTATE COMMERCE AND ITS INTERPRETATION, APPLICATION, ENFORCEMENT AND PROCEEDINGS HEREUNDER SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT (“FAA”). NEITHER YOU NOR MFSN SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS IN ARBITRATION BY OR AGAINST OTHER CONSUMERS OR ARBITRATE ANY CLAIM AS A REPRESENTATIVE OR MEMBER OF A CLASS ACTION OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. THE PARTIES VOLUNTARILY AND KNOWINGLY WAIVE ANY RIGHT THEY HAVE TO A JURY TRIAL. FCRA DISCLOSURES</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The FCRA allows you to obtain copy of all of the information in your consumer credit file disclosure from consumer reporting agencies for a reasonable charge. Full disclosure of information in your file at the three national credit repositories must be obtained directly from the repositories:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <ul>
                        <li>
                            <Typography level="body1" fontSize="lg">Equifax Information Service Center
                                <br />1.800.203.7843
                                <br />P.O. Box 740241, Atlanta, GA 30374-0241
                            </Typography>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Typography level="body1" fontSize="lg">TransUnion Corporation
                                <br />1.800.916.8800
                                <br />P.O. Box 1000, Fullerton, CA 19022
                            </Typography>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Typography level="body1" fontSize="lg">Experian Information Solutions, Inc.
                                <br />1.888.397.3742
                                <br />714.830.7000
                                <br />P.O. Box 2002, Allen, TX 75013
                            </Typography>
                        </li>
                    </ul>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The FCRA also states that individuals are entitled to receive a disclosure directly from the consumer reporting agency free of charge under the following circumstances:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <ul>
                        <li> <Typography level="body1" fontSize="lg">You certify in writing that you are unemployed and intend to apply for employment in the 60-day period beginning on the day you make the certification;
                        </Typography>
                        </li>
                        <li>
                            <Typography level="body1" fontSize="lg">You are on public welfare assistance;</Typography>
                        </li>
                        <li><Typography level="body1" fontSize="lg">You have reason to believe that your file at the consumer reporting agency contains inaccurate information due to fraud; or
                        </Typography></li>
                        <li><Typography level="body1" fontSize="lg">You have been denied credit, insurance, or employment within the past 60 days as a result of your Credit Report.
                        </Typography></li>
                    </ul>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The FCRA also permits consumers to dispute inaccurate information in their Credit Report without charge. Accurate information cannot be changed. You do not have to purchase your Credit Report or other information from MFSN to dispute inaccurate or incomplete information in your credit file maintained by Experian, Equifax or TransUnion (collectively, the "Repositories").</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The Credit Report(s) you are requesting from MFSN is not intended to constitute the disclosure of Experian, Equifax or TransUnion information required by the FCRA or similar state laws.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The FCRA allows consumer to get one free comprehensive disclosure of all the information in their credit file from each of the Repositories once every 12 months through a central source. Although comprehensive, the Credit Reports from each of the Repositories that are available from the MFSN Site may not have the same information as a credit report obtained directly from the three Repositories or through the central source. To request your free annual report under the FCRA, you must go to www.annualcreditreport.com. You can also contact the central source to request this free annual disclosure by calling toll free 877-322-8228 or by using the mail request form available at the central source site. The Services at this Site are not related to the free FCRA disclosure that you may be entitled to.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Consumers residing in the states of Colorado, Maine, Maryland, Massachusetts, New Jersey, and Vermont may receive an additional free copy of their credit report once per year and residents of the state of Georgia may receive two (2) additional copies per year. For Illinois residents, credit reporting agencies are required by law to give you a copy of your credit record upon request at no charge or for a nominal fee.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Click here for a full text of your summary of rights.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        MODIFICATION OF SERVICES
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN may, at its discretion and without notice to you, modify or discontinue any of the Services or Content or a portion thereof. You agree that MFSN will not be liable to you or any third party for any modification or discontinuance of any of the Services or Content, or for any failure to notify you of same.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Changes to Prices and Terms and Discontinuance of Service
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">If you enroll in the Services, you agree to pay all charges at the effective monthly price. If you purchase Transactional Services, you agree to pay the price in effect at the time of such purchase. All prices are exclusive of any taxes, except as required by applicable law. We reserve the right to change prices or to institute new charges for the Services, or any portion thereof, at any time. Price changes and new charges will apply to subsequent subscription periods for current paid subscribers immediately on the effective date of the change and to all new users of the Services from such effective date forward.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">We reserve the right at any time to modify, suspend, or discontinue the Site and the Services, or any part, version or feature thereof, in our sole discretion without prior notice to you. We will use reasonable efforts to notify you of any such material modifications to or suspensions or discontinuations of the Site or the Services by posting a notice on the Site. We may also, but are not required to, provide you notice by sending an email message to the current email address listed in your membership. If you have a paid subscription to the Services and we modify, suspend or discontinue the Services due to no action on your part, when applicable, we may provide a pro rata refund of advance payments made. Your continued use of the Services or of the Site, or your failure to terminate your subscription, after any such changes, modifications, or charge have been made, will constitute your acceptance of those changes, modifications, and charges.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        DISCLAIMERS AND LIMITATIONS OF LIABILITY
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg" >Because we use security measures designed to protect your privacy and to safeguard your information, we may not always be able to provide instant online delivery of your Credit Report. For certain Services, when the system is unable to verify your identity, you may be routed through a manual authentication process. After your identity has been verified, we will direct you back to the Site, where you can access your Credit Report online.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">You must have an email address and a Java-compatible browser such as Internet Explorer 5.0 or higher, AOL 8.0 or higher, or Netscape Navigator 6.0 or higher, to receive your reports online. MFSN is not responsible for any overdraft/over-the-limit charges or bank fees if your account contains insufficient funds when your order is processed and billed.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THIS SITE, THE SERVICES AND CONTENT IS AT YOUR SOLE RISK. ALL SERVICES, CONTENT, TOOLS AND CALCULATORS ARE PROVIDED ON AN “AS IS” OR “AS AVAILABLE” BASIS. MFSN AND ITS RESPECTIVE SUPPLIERS EXPRESSLY DISCLAIM ALL WARRANTIES, GUARANTIES AND CONDITIONS OF ANY KIND WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NONINFRINGEMENT. THE INFORMATION CONTAINED IN THIS SITE DOES NOT CONSTITUTE LEGAL, TAX, ACCOUNTING OR OTHER PROFESSIONAL ADVICE. MFSN AND ITS RESPECTIVE SUPPLIERS MAKE NO WARRANTY THAT (i) THE SERVICES AND CONTENT ARE ACCURATE, TIMELY, UNINTERRUPTED OR ERROR-FREE; AND (ii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR CONTENT WILL BE RELIABLE.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">YOU UNDERSTAND AND AGREE THAT MFSN AND ITS RESPECTIVE SUPPLIERS WILL NOT BE LIABLE FOR ANY REASON FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES RESULTING FROM OR IN ANY WAY CONNECTED TO YOUR ACCESS TO, USE, OR INABILITY TO USE THE SITE, SERVICES, MEMBERSHIP BENEFITS OR CONTENT, OR FROM YOUR ACCESS TO, USE, INABILITY TO USE, OR RELIANCE UPON ANY LINKED SITE, EVEN IF MFSN OR ITS RESPECTIVE SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF THOSE DAMAGES. SOME JURISDICTIONS EITHER DO NOT ALLOW OR PLACE RESTRICTIONS UPON THE EXCLUSION OR LIMITATION OF DAMAGES IN CERTAIN TYPES OF AGREEMENTS, AND IN THESE JURISDICTIONS, THE AFOREMENTIONED LIMITATION ON LIABILITY SHALL BE TO THE MAXIMUM DEGREE PERMITTED BY APPLICABLE LAW. IF, NOTWITHSTANDING THE ABOVE, LIABILITY IS IMPOSED UPON MFSN, THEN YOU AGREE THAT ANY SUCH LIABILITY, REGARDLESS OF THE NATURE OF THE LEGAL OR EQUITABLE CLAIM, SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO MFSN FOR THE SERVICE(S) FOR THE ONE MONTH PRIOR TO THE DATE OF YOUR CLAIM AROSE, OR US $30, WHICHEVER IS LESS.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        THIRD-PARTY SITES
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">As a convenience to you, MFSN may provide links to sites owned or operated by other entities and persons. If you use these sites, you do so at your own risk. MFSN makes no warranties or representations regarding, and shall not be liable or responsible for, any linked sites, your use of such sites, or the comprehensiveness or accuracy of information or materials appearing thereon or any products, publications, subject matter or services described thereon. You acknowledge and agree that the MFSN has no control over, or responsibility or liability for, any offer, statement, or representation by, or any other matter connected or associated with, such third party offers or sites. Furthermore, links do not imply that MFSN sponsors, endorses or is affiliated or associated with such sites, or that any linked site is authorized to use any trademark, trade name, logo or copyright of MFSN. You may receive offers from third parties that are unrelated to MFSN. You do not have to accept those offers to enroll in any of their services or to accept their services. However, if you do accept any third party offers, or visit any sites regarding such offers, you do so subject to the Terms and Conditions (sometimes also referred to as Terms of Use or Terms of Site) and the Privacy Policies of those sites.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        INTELLECTUAL PROPERTY
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">You acknowledge and agree that (a) all Content is the copyrighted work of MFSN or its third party content suppliers and is protected by copyright laws, (b) MFSN’s names, the domain www.MyFreeScoreNow.com, the MyFreeScoreNow and MFSN logos, all page headers, customer graphics and button icons are service marks, trademarks and/or trade dress of MFSN and/or MyFreeScoreNow (collectively, the “Marks”), and (c) all other trademarks, product names, company names or logos on the Site are the property of their respective owners and you have no right to use nor copy such property. In addition to agreeing to comply with all applicable laws in connection with your use of this Site, you agree you will not use any such Content or Marks from this Site for any purpose without the appropriate prior written authorization.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h6">
                        SERVICE-SPECIFIC TERMS AND CONDITIONS
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The terms explained below are service specific and apply to the particular service indicated in addition to the terms and conditions listed above.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Registering for any Service:
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">To enroll in a monthly credit monitoring Service or to request a Credit Report or Score, you must complete an online registration form which must include an address within the United States, and you agree to be bound by these Terms. You must provide valid credit card information. We will then evaluate your complete registration information. We are not able to accept and process joint registration for a married couple. You must provide valid debit or credit card payment information at time of enrollment in the Services, or at the time of purchase of Transactional Services. We will verify your debit or credit card information before processing your order, including performing an authorization to a) make sure it is valid and in good standing and b) for an amount up to the stated monthly MyFreeScoreNow membership fee for all Services, and/or all fees associated with the purchase of Transaction Services. The authorization amount may count against your debit or credit limit. For Services which include a free trial period, you card will not be actually billed until the free trial period has passed. In the event that you purchase additional products from MFSN using a different credit or debit card, or if you update your payment information with MFSN, MFSN will charge the latest card provided by you. Please note, if you have ever been a member and received a free trial, MFSN may refuse to give you another free trial offer. Returning members will be billed the membership fee immediately upon renewal. During your free trial period you are eligible for one free report and/or score as dictated by your specific membership terms.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">If you do not cancel your free trial within the free trial period, you will be charged at the monthly rate in effect at that time for the Services for which you enrolled. Your debit or credit card (including, if applicable, as automatically updated by your card provider following expiration or change in account number) will continue to be charged each month at the applicable monthly rate unless and until you cancel the Services. If the Services you select include a free trial period, your free trial period is measured in 24 hour days. For example, if you sign up for a 7-day free trial period at 11:00 a.m. on July 10, 2012, your free trial period will expire at 10:59 a.m. on July 17, 2012. You may be required to provide your Social Security number and other personal information, such as previous addresses, employment, and other names, so that your identity can be verified in order to use some Services. You must provide true, accurate and complete information about yourself as prompted by the applicable form(s) for the Services and to promptly update your information if and when it changes. If you provide any information that is untrue, inaccurate, or incomplete, or we have reason to believe that such information is untrue, inaccurate, or incomplete, we reserve the right to terminate your Services or void your transaction.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4" sx={{ borderBottom: "3px solid #5288db" }}>
                        Credit Monitoring
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">You understand and agree that we provide a credit monitoring service. We give you access to one or more of your Credit Scores and Credit Reports and we help you understand the information contained in your Credit Score (s) and Credit Report(s). We are not a credit repair organization. We cannot improve your Credit Reports, credit history or credit rating, nor do we provide you with advice or assistance in improving your Credit Reports, credit history or credit rating. Accurate adverse information on your Credit Reports cannot be changed. If you believe that your Credit Reports contain inaccurate information, it is your responsibility to contact the relevant Repositories, and follow the appropriate procedures for notifying the Repository that you believe that your Credit Report contains an inaccuracy. Any information provided to you regarding the procedures followed by the various Repositories related to the removal of inaccurate information is provided without charge to you and is available for free. Any such information is not included as part of your credit monitoring service but is provided free of charge to all consumers, regardless of whether they are members of the credit monitoring service.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Your membership in credit monitoring is effective for the period covered by your membership fee and continues upon your payment of the monthly/annual renewal fee. Renewal fees for your membership will be charged automatically at the then-current rate to the credit card or other billing source authorized by you until you cancel your membership. Should you choose to discontinue your membership for any reason before expiration of the then-applicable membership term for which you have paid, you may cancel your membership and terminate further billing by calling the toll-free number listed in the Member Area on this Site. If you are an annual subscriber who is eligible for a refund and choose to cancel within 180 days of when you were billed, you will receive a prorated refund of your current year’s membership fee. If you are a monthly subscriber and choose to cancel after your free trial ends, your membership and monthly billing will terminate immediately and you will not be eligible for a prorated refund of any portion of your paid monthly MyFreeScoreNow membership fee.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Please note that it can take two to three weeks for new members to be enrolled in credit monitoring. MFSN does not control and is not responsible for the enrollment process. Your enrollment in credit monitoring is dependent on the Repositories being able to enroll you in credit monitoring. If you cannot be enrolled in credit monitoring, you will not receive alerts of changes to your Credit Report. If you are successfully enrolled in credit monitoring, alerts will be delivered to the email address that is given to MFSN when you enroll in credit monitoring. It is your responsibility to update your email address if it should change.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Credit Monitoring Membership with Trial Period:
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The trial membership is a special offer limited to select first-time customers only. Returning members will be billed the membership fee immediately upon renewal. During your trial period, you are eligible for one or three free reports and/or scores as dictated by your specific membership terms. You will be asked for valid credit card information when you sign up for the credit monitoring trial. MFSN will verify your credit card information before processing your order. An authorization will be performed on your credit card to make sure it is valid and in good standing. We will disclose to you in the Offer Details, if applicable, that there may be a nominal upfrontfee for your Credit Report or a nominal Member Activation Fee charged upfront. If applicable and specified in the Offer Details, and after you give your electronic consent during enrollment and only after your order is successfully submitted and accepted by the Site, we will billto your credit or debit card immediately for the aforementioned fee before the trial membership period has expired. If you do not cancel during your trial period, your membership will continue automatically and the monthly/annual fee will be billed to the credit card or other billing source authorized by you. Any upfront fee for the credit report or for the Member Activation fee, if applicable, is nonrefundable.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Credit Monitoring Membership Without Trial:
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The monthly/annual fee for a credit monitoring membership without a trial period will be billed immediately to the credit card or other billing source authorized by you.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4" sx={{ borderBottom: "3px solid #5288db" }}>
                        Credit Reports and Credit Scores
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">3-Bureau Credit Reports, 1-Bureau Credit Report, Scores: When you use our online Services to access your personal Credit Report(s) and/or Score(s), you are certifying that you understand and agree to the following:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <ul>
                        <li><Typography level="body1" fontSize="lg">You may access your Credit Report(s) and/or Score(s) for 30 days from the date of payment;</Typography></li>
                        <li><Typography level="body1" fontSize="lg">Refunds will not be issued once we have successfully located and delivered your Credit Report(s) and/or Score(s) or after any trial period;</Typography></li>
                        <li><Typography level="body1" fontSize="lg">If you have any questions regarding any of your Credit Reports or would like to dispute information contained in them, you may request an investigation by contacting the Repository that supplied the information, as indicated in your Credit Report; and</Typography></li>
                        <li><Typography level="body1" fontSize="lg">For your protection, if you are inactive (have not clicked on an item or refreshed the page) for a period of 20 minutes or more when reviewing your Credit Report and/or Score online, your session will conclude and you will be logged out.</Typography></li>
                    </ul>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Disclaimers: MFSN is not responsible for the accuracy or content of your credit files, including but not limited to information delivered in the Credit Reports and/or Scores.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">The Service includes the following features:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <ul>
                        <li><Typography level="body1" fontSize="lg">Where indicated in the product literature as a product benefit, we will deliver on the Site, at our selection and sole discretion, one or more consumer credit reports from any of three (3) credit bureaus: Experian, TransUnion and Equifax;
                        </Typography>
                        </li>
                        <li><Typography level="body1" fontSize="lg">Deliver daily consumer credit monitoring from one (or if applicable, all 3) credit bureaus showing:</Typography></li>
                        <ul>
                            <li><Typography level="body1" fontSize="lg">New accounts;</Typography></li>
                            <li><Typography level="body1" fontSize="lg">Public records;</Typography></li>
                            <li><Typography level="body1" fontSize="lg">Address changes;</Typography></li>
                            <li><Typography level="body1" fontSize="lg">Potentially negative information; and</Typography></li>
                            <li><Typography level="body1" fontSize="lg">New inquiries;</Typography></li>
                        </ul>
                        <li><Typography level="body1" fontSize="lg">Where indicated in the product literature as a product benefit, we will deliver a consumer credit score using the then current credit score algorithm in use by the Service Provider;</Typography></li>
                        <li><Typography level="body1" fontSize="lg">Where indicated in the product literature as a product benefit, calculate and/or track the customer's credit score based on the consumer's credit report from the single bureau credit report that has been selected by MFSN in its sole discretion, and, where indicated in the product literature, three (3) scores based on the consumer's Experian, TransUnion and Equifax credit reports;</Typography></li>
                    </ul>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        GENERAL PROVISIONS
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Governing Law and Location of any Disputes</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">These Terms are governed by the laws of the State of California without regard to conflict of law provisions, choice of law rules, and regardless of your actual place of residence with the exception of the Arbitration provision, which shall be governed by the Federal Arbitration Act. YOU HEREBY IRREVOCABLY SUBMIT TO THE EXCLUSIVE JURISDICTION OF THE STATE AND FEDERAL COURTS OF THE STATE OF CALIFORNIA FOR THE PURPOSES OF ANY PROCEEDINGS ARISING OUT OF THESE TERMS AND CONDITIONS. This Agreement is to be performed in Ventura County, California and any arbitration, suit, action, or other legal proceeding arising out of or related to these Terms, the Site, the Content, or the Services or any issues collateral thereto must be brought in Ventura County, California. Your expressly waive any objection which you may have to the venue of any such arbitration, suit, action or proceeding.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Entire Agreement
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">These Terms, the Privacy Policy, and other policies MFSN may post constitute the entire agreement between MFSN and you in connection with your use of this Site, the Services, and the Content, and supersede any prior versions of the Terms, if applicable. MFSN may update these Terms from time to time by posting revised Terms on this Site, without notice to you, and your subsequent use of the Site is governed by those new Terms. The Terms are effective until terminated by MFSN. In the event of termination, the Intellectual Property, Disclaimers and Limitations of Liability, and Governing Law provisions set forth in these Terms will survive. In the event of a conflict between any other notice, policy, disclaimer or other term contained in this Site, these Terms will control. If any provision is deemed to be unlawful or unenforceable, it will not affect the validity and enforceability of the remaining provisions. The section headings are for convenience only and do not have any force or effect.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        REVIEW AND RECEIPT OF PRIVACY NOTICE
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">By submitting your order, you acknowledge receipt of our Privacy Policy and agree to its terms. Privacy Policy Notice</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN’s privacy policy on how your personal information is used and disclosed is contained in our Privacy Policy. The Services provided under this Agreement are web-based and by using the Site or purchasing any of the Services, you agree that we may use and share your personal information in accordance with the terms of our Privacy Policy. Our Privacy Policy can be found by clicking on the Privacy Policy link at the bottom of any page of the Site.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN’s Service Provider, TUI, has a privacy policy that you may be subject to. TUI’s policy on how your personal information is used and disclosed is contained in TUI’s Privacy Policy. Click here</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Copyright © {currentYear} - MyFreeScoreNow Inc.</Typography>
                </Grid>
            </Box>
        </>
    );
}

export default TermsAndConditions;