import React, { useState, useEffect, useMemo } from 'react'
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Box, Button,MenuItem,FormControl,InputLabel,Select,OutlinedInput,TableContainer, TableHead, TableRow, Paper,TableCell,Table,TableBody } from '@mui/material';
import { Typography } from '@mui/joy';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import AuthUser from '../../../Components/Auth/AuthUser';
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useNavigate } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import swal from "sweetalert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {DateRangePicker,createStaticRanges} from "react-date-range";
import {openOrFocusWindow,calendarFilter,useDateSelectionRange,getInitialSelectionRange } from  '../../../Components/CommonFormula';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function LoginActivities() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = AuthUser();

    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [freez, setFreez] = React.useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [ userRoles, setuserRoles] = useState([]);
    const [roleType,setRoleType]=useState('all')
    const [roleId,setRoleId]=useState('')
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } =useDateSelectionRange(getInitialSelectionRange('loginActivities'));
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const tablePreferencesArray = {
    }
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }

    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);

    const checkColumnVisibility = async () => {
        if (Object.values(columnVisibility).length > 0) {
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'loginLogs'
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
        }
    }

    useEffect(() => {
        checkColumnVisibility();
    }, [columnVisibility])

    const tablePreferences = async () => {
        const apiUrl = '/fetchTablePreferences/loginLogs';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (Object.values(res).length == 0) {
                setColumnVisibility(tablePreferencesArray);
            } else {
                setColumnVisibility((JSON.parse(res.preferences)));
            }
        }
    }

    useEffect(() => {
        getLogsDetails()
    }, [])
    const getLogsDetails = async () => {
        handleFreezStart()
        const apiUrl = '/loginLog';
        const args = {
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.data) {
                setData(res.data)
                fetchUserTypes()
            }
            else {
                handleFreezStop()
            }
        }
    }
    const fetchUserTypes = async() => {
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            // Modify the role_name
            const updatedRoles = res.map(role => ({
                ...role,
                role_name: `${role.role_name} login`
            }));
            const maxId = Math.max(...updatedRoles.map(role => role.id));
            // Add the new role
            updatedRoles.unshift({
                id: maxId + 1,
                role_name: "Logged in as an affiliate",
            });
    
            setuserRoles(updatedRoles);
            handleFreezStop();
            setIsLoading(false);
        }
    }
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.role_name ? row.role_name.trim() : ''}`,
                accessorKey: 'Role',
                header: 'Role',
                Cell: ({ renderedCellValue, row }) => (row.original.role_name ? row.original.role_name.trim() : 'N/A'),

            },
            {
                accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
                accessorKey: 'Email',
                header: 'Email',
                size: 60,
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
            },
            {
                accessorFn: (row) => `${row.event ? row.event.trim() : ''}`,
                accessorKey: 'Event',
                header: 'Event',
                Cell: ({ renderedCellValue, row }) => (row.original.event ? row.original.event.trim() : 'N/A'),

            },
            {
                accessorFn: (row) => `${row.admin_name ? row.admin_name.trim() : ''}`,
                accessorKey: 'Admin',
                header: 'Admin',
                Cell: ({ renderedCellValue, row }) => (row.original.admin_name ? row.original.admin_name.trim() : 'N/A'),

            },
            {
                accessorFn: (row) => `${row.company_name ? row.company_name.trim() : ''}`,
                accessorKey: 'Company',
                header: 'Company',
                Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : 'N/A'),

            },
            {

                accessorFn: (row) => `${row.login_time ? Moment(row.login_time).format('MM-DD-YYYY H:mm') : ''}`,
                header: 'Login Time',
                accessorKey: 'Login Time',
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.login_time ? Moment(row.original.login_time).format('MM-DD-YYYY H:mm') : 'N/A'}
                    </div>
                ),
            },
            {
                accessorFn: (row) => `${row.ip_address ? row.ip_address : ''}`,
                accessorKey: 'Ip Address',
                header: 'Ip Address',
                Cell: ({ renderedCellValue, row }) => (row.original.ip_address ? row.original.ip_address : 'N/A'),

            },
        ]
    )

    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Log Activities',
        headers: ['Role', 'Email', 'Event', 'Admin', 'Company', 'Login Time', 'Ip Address'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        const exportedData = [];
        rows.map((row) => {
            const mainTableRow = {
                name: row.original.role_name ? row.original.role_name : "N/A",
                email: row.original.email ? row.original.email : "N/A",
                event: row.original.event ? row.original.event : "N/A",
                admin_name: row.original.admin_name ? row.original.admin_name : "N/A",
                company_name: row.original.company_name ? row.original.company_name : "N/A",
                login_time: row.original.login_time ? Moment(row.original.login_time).format('MM-DD-YYYY H:mm') : "N/A",
                ip_address: row.original.ip_address ? row.original.ip_address : "N/A",
            };
            exportedData.push(mainTableRow);
       
            const subheader = {
                ...((!row.original.company_name) ? {
                  'First Name': 'First Name', 
                  'Last Name': 'Last Name',
                  'Email': 'Email',
                  'Phone No': 'Phone No',
                } : {
                  'First Name': 'First Name', 
                  'Last Name': 'Last Name',
                  'Company Name': 'Company Name',
                  'Company Email': 'Company Email',
                  'Communication Email': 'Communication Email',
                  'Phone No': 'Phone No',
                  'Total Enrollments': 'Total Enrollments',
                  'Total Active Enrollments': 'Total Active Enrollments',
                  [`Projected Commission (${row.original.projectedMonth?row.original.projectedMonth:'N/A'})`]: `Projected Commission (${row.original.projectedMonth?row.original.projectedMonth:'N/A'})`,
                  [row.original.last_commission_status ? `${row.original.last_commission_status} (${row.original.lastApprovedMonth})` : 'N/A']:row.original.last_commission_status ? `${row.original.last_commission_status} (${row.original.lastApprovedMonth})` : 'N/A'
                })
              };
            exportedData.push(subheader);
                const subRow = {
                    ...((!row.original.company_name) ? {
                        first_name: row.original.first_name?row.original.first_name:'N/A',
                        last_name: row.original.last_name?row.original.last_name:'N/A',
                        email:row.original.email?row.original.email:'N/A',
                        phone_no: row.original.phone_no?row.original.phone_no:'N/A',
                      } : {
                        first_name: row.original.first_name?row.original.first_name:'N/A',
                        last_name: row.original.last_name?row.original.last_name:'N/A',
                        company_name: row.original.company_name?row.original.company_name:'N/A',
                        ref_email:row.original.ref_email?row.original.ref_email:'N/A',
                        communication_email: row.original.communication_email?row.original.communication_email:'N/A',
                        phone_no: row.original.phone_no?row.original.phone_no:'N/A',
                        new_activities:row.original.newEnrollements?row.original.newEnrollements:'N/A',
                        active_activities:row.original.activeEnrollements?row.original.activeEnrollements:'N/A',
                        Projected_Commission:row.original.projectedCommission ? `$ ${row.original.projectedCommission}` : '$ 0.00',
                        last_commission_status:row.original.lastApprovedCommission ? `$ ${row.original.lastApprovedCommission}` : '$ 0.00'
                      })
                    
                } 
                exportedData.push(subRow);
                exportedData.push({});
        
          });  
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const convertToCSV = (data) => {

        const headers = csvOptions.headers.join(',') + '\n';

        const rows = data.map((row) => {
            return Object.values(row).map((value) => {
                // Handle any necessary formatting or escaping of values
                // For simplicity, we assume all values are already properly formatted

                // Wrap the value with double quotes if it contains a comma
                if (typeof value === 'string' && value.includes(',')) {
                    return `"${value}"`;
                }

                return value;
            }).join(',');
        }).join('\n');

        return headers + rows;
    };

    //csv end here

    const changeRoleType=async(id,name)=>{
     handleFreezStart()
        const apiUrl = '/loginLog';
        const args = {
            roleId:id,
            fromDate: startDate,
            toDate: endDate,
            loginAsAffiliate:name==='Logged in as an affiliate'? true:false
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.data) {
                setData(res.data)
                handleFreezStop()
                setIsLoading(false)
            }
            else {
                handleFreezStop()
            }
        }
    }
    const sideBar = calendarFilter();

    const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar)
    ];
      
  
    const ContentLayout = <>
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
            <Typography level="h4" color="primary">Login Activities</Typography>
        </Grid>

        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-name-label">User Event</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    name='UserType'
                    input={<OutlinedInput label="User Type" />}
                    id='UserType'
                    MenuProps={MenuProps}
                    value={roleType}
                    onChange={(event) => {
                        const selectedUserType = userRoles.find(
                            (usertype) => usertype.role_name === event.target.value
                        );
                        if (selectedUserType) {
                            setRoleId(selectedUserType.id)
                            setRoleType(event.target.value)
                            changeRoleType(selectedUserType.id,event.target.value);
                        }
                        else{
                            setRoleId('')
                            setRoleType(event.target.value)
                            getLogsDetails()
                        }
                        }}
                >
                        <MenuItem
                        value='all'
                        >
                        All
                        </MenuItem>
                        {userRoles.map((usertype,index) => (
                        <MenuItem
                            key={index}
                            value={usertype.role_name}
                        >
                            {usertype.role_name}
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>                       
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} >
              <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid  item lg={4} md={4} sm={6} xs={6}>
                    <FormControl fullWidth size='small'>
                    <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value="date"
                        input={<OutlinedInput label="Select Date Range" />}
                    >
                        <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                        <DateRangePicker
                            className='custom-date-picker'
                            onChange={item => setSelectionRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={selectionRange}
                            direction="horizontal"
                            maxDate={new Date()}
                            staticRanges={staticRanges}
                            />
                    </Select>
                    <Button onClick={clearFilter} >Clear Dates</Button>
                    </FormControl>
                </Grid>
                <Grid item lg={8} md={8} sm={6} xs={6}>
                    <Button color="primary" onClick={()=>changeRoleType(roleId,roleType)} variant="contained" sx={{ minWidth: 200 }}>
                    Submit
                    </Button>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        <Box mt={2}>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                }}
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnFilters={config.DatatableColumnFilter}
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        backgroundColor: "#F6FAFF",
                    },
                }}
                renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                                {row.original.company_name?
                                <>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>First Name</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Last Name</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Company</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Company Email</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Communication Email</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Phone No</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Enrollments</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Active Enrollments</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Projected Commission ({row.original.projectedMonth})</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>{row.original.last_commission_status ? `${row.original.last_commission_status} (${row.original.lastApprovedMonth})` : 'N/A'}</TableCell>
                              </>:
                              <>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>First Name</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Last Name</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Email</TableCell>
                              <TableCell align="center" sx={{ fontWeight: "bold" }}>Phone No</TableCell>
                              </>
                                }
                            
                              
                            </TableRow>
                          </TableHead>
      
                          <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                {row.original.company_name?
                                <>
                                <TableCell align="center">{row.original.first_name?row.original.first_name:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.last_name?row.original.last_name:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.company_name?row.original.company_name:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.ref_email?row.original.ref_email:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.communication_email?row.original.communication_email:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.phone_no?row.original.phone_no:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.newEnrollements?row.original.newEnrollements:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.activeEnrollements?row.original.activeEnrollements:'N/A'}</TableCell>
                                  <TableCell align="center">
                                    {row.original.projectedCommission ? `$ ${row.original.projectedCommission}` : '$ 0.00'}
                                    </TableCell>
                                    <TableCell align="center">
                                    {row.original.lastApprovedCommission ? `$ ${row.original.lastApprovedCommission}` : '$ 0.00'}
                                    </TableCell>
                                  </>:
                                <>
                                  <TableCell align="center">{row.original.first_name?row.original.first_name:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.last_name?row.original.last_name:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.email?row.original.email:'N/A'}</TableCell>
                                  <TableCell align="center">{row.original.phone_no?row.original.phone_no:'N/A'}</TableCell>
                                  </>
                                  
                                }
                                </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            CSV
                        </Button>

                    </Box>
                )
                }
            />

        </Box>
    </>

    return (
        <>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </>
    );
}


export default LoginActivities;