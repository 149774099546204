import React,{useMemo,useState,useEffect} from 'react';
import {Box,Button  } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import AuthUser from '../Auth/AuthUser';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { useNavigate } from "react-router-dom";
function SignUpByDay(props) {
    const dispatch = useDispatch();
    const [data, setData] = useState(props.data);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const { user } = AuthUser();
    const navigate = useNavigate();
 const tablePreferencesArray = { 

    }
    useEffect(() => {
    }, [rowSelection]);
     ////////////////////////
     useEffect(() => {
      if(Object.values(columnVisibility).length>0){
        setColumnVisibility(columnVisibility);
      }
      //do something when the row selection changes...
    }, [rowSelection]);
  
    useEffect(() => {
      tablePreferences();
    }, [rowSelection]);

    useEffect(()=>{  
      saveTablePreferences();
    },[columnVisibility])
    ////////////////////////
  
    const tablePreferences = async() => {   
      const apiUrl = '/fetchTablePreferences/signupbyday';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        if (Object.values(res).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.preferences)));
        }
      }
    }

    const saveTablePreferences = async() => {
      if (Object.values(columnVisibility).length > 0) {
          const apiUrl = '/saveTablePreferences';
          const args = {
              columnArray: columnVisibility,
              userId: user.id,
              tableId: 'signupbyday'
          }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
      } 
    }

    const columns = useMemo(
      //column definitions...
      () => [
        {
          accessorFn: (data) => `${data.dateRangeFilter? data.dateRangeFilter :''}`,
          id:'Enrollment Date',
          header: 'Enrollement Date',
          size: 50,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.dateRangeFilter ? Moment(row.original.dateRangeFilter).format('MM-DD-YYYY'):"N/A"}
             </div>          
           ),
        },
        {
          accessorFn: (data) => `${data.publisherId?data.publisherId:''}`,
          id:'Publisher Id',
          header: 'Publisher Id',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.publisherId ? row.original.publisherId : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.AID?data.AID.trim():''}`,
          id:'AID',
          header: 'AID',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.Description?data.Description.trim():''}`,
          id:'Description',
          header: 'Description',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.Description ? row.original.Description.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data.productName?data.productName.trim():''}`,
          id:'Product Name',
          header: 'Product Name',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original.productName ? row.original.productName.trim() : 'N/A'),
        },
        {
          accessorFn: (data) => `${data['Signup Nbr']?data['Signup Nbr']:''}`,
          id:'Signup Nbr',
          header: 'Signup Nbr',
          size: 50,
          Cell: ({ renderedCellValue, row }) => (row.original['Signup Nbr'] ? row.original['Signup Nbr'] : 'N/A'),
        }
        
      ]
    );
    const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: `Sign Up By Day Report_${currentDate}.csv`,
      headers: ['Enrollement Date', 'Publisher Id', 'AID', 'Description', 'Product Name', 'Signup Nbr'],
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handleExportData = () => {
      if(localStorage.getItem("admin") != null) {
        navigate('/login');
        return false;
      }
      const exportedData = data.map((row) => {
        const rowData = {};
        columns.forEach((column) => {
          rowData[column.header] = column.accessorFn(row);
        });
        return rowData;
      });
    
      csvExporter.generateCsv(exportedData);
    };
        return (
            <>
                <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                filterFns={{
                  customSearchFilterFn: (row, id, filterValue) =>
                  row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableRowSelection={false}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection }} 
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                    backgroundColor:"#F6FAFF",
                    },
                }} 
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                    <Button
                      sx={{ bgColor: "#2A68B3" }}
                      //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                      onClick={handleExportData}
                      variant="contained"
                      className='export'
                    >
                      CSV
                    </Button>
                  </Box>
                )}
                
                />
            </>
        );
    }
    
    
    export default SignUpByDay;