import React, { useMemo, useState, useEffect } from 'react';
import { Grid,Box,Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import Moment from 'moment';
import AuthUser from '../../../Components/Auth/AuthUser';
import CircularProgress from '@mui/material/CircularProgress';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Typography } from '@mui/joy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
function SuspendedAffiliate() {
  //dialog content end
  const dispatch = useDispatch();
  const { http, user } = AuthUser();
  const [suspendeddata, setData] = useState([]);
 
  const [loader, setLoader] = useState('');
  const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});


 const tablePreferencesArray = { 

    }
  const companyId = "";
  let des = [];
  const fetchSuspendedReport = async page => {
    setLoader(<CircularProgress />)
    const apiUrl = '/suspendedMemberList';
    const args = {
      companyId: companyId
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      setData(res.data);
      
    }
  }
  useEffect(() => {
    fetchSuspendedReport();
  }, [])


  const handleData = (data) => {
    des = data;
  }
  useEffect(() => {
  }, [rowSelection]);
   ////////////////////////
   useEffect(() => {
    if(Object.values(columnVisibility).length>0){
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);

  useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])

  ////////////////////////

  const tablePreferences = async() => {   
    const apiUrl = '/fetchTablePreferences/affiliatesuspendedmember';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
  }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
        const apiUrl = '/saveTablePreferences';
        const args = {
            columnArray: columnVisibility,
            userId: user.id,
            tableId: 'affiliatesuspendedmember'
        }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : 'N/A'}`,
        id: 'Member Id',
        header: 'Member Id',
        size: 50,
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        id: 'Name',
        header: 'Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
        sortingFn: (rowA, rowB) => {
          const emailA = rowA.original.email.toLowerCase();
          const emailB = rowB.original.email.toLowerCase();
          return emailA.localeCompare(emailB);
        },
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.phoneNumber ? data.phoneNumber : ''}`,
        id: 'Phone Number',
        header: 'Phone Number',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.phoneNumber  ? row.original.phoneNumber: 'N/A'),
      },
      {
        accessorFn: (data) => `${data.publisher_id ? data.publisher_id : ''}`,
        id: 'PID',
        header: 'PID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.publisher_id ? row.original.publisher_id : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.aid ? data.aid.trim() : ''}`,
        id: 'AID',
        header: 'AID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.date_range_filter?data.date_range_filter:''}`,
        id: 'Subscription Date',
        header: 'Subscription Date',
        size: 50,
        Cell:({renderedCellValue,row}) => (
          <div>
              {row.original.date_range_filter ? Moment(row.original.date_range_filter).format('MM-DD-YYYY'): "N/A"}
           </div>          
         ),
      },

    ]
  );
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Suspended-Members.csv',
    headers: ['Member Id','Name','Email','PID','AID','Subscription Date' ],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (suspendeddata) => {
    const exportedData = suspendeddata.map((row) => {
      return {
        member_id: `${row.original.member_id ? row.original.member_id : 'N/A'}`,
       name:`${row.original.name ? row.original.name : 'N/A'}`,
       email:`${row.original.email ? row.original.email : 'N/A'}`,
       pid:`${row.original.publisher_id ? row.original.publisher_id : 'N/A'}`,
       aid:`${row.original.aid ? row.original.aid : 'N/A'}`,
       subscription_date:`${Moment(row.original.date_range_filter).format('MM-DD-YYYY')}`,
      };
    });
    // csvExporter.generateCsv(exportedData);
     // Convert data to CSV format
  const csvContent = convertToCSV(exportedData);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted

      // Wrap the value with double quotes if it contains a comma
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`;
      }

      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
  };

  function ContentLayout() {
    return (
      <>
        <Grid container item lg={12} md={12} xs={12} sm={12} mt={2} mb={2}>
          <Typography color="primary" level="h4"> Suspended Members</Typography>
        </Grid>
       
        <MaterialReactTable
          columns={columns}
          data={suspendeddata}
          enableColumnFilterModes
          filterFns={{
            customSearchFilterFn: (row, id, filterValue) =>
            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
           }} 
          globalFilterFn="customSearchFilterFn" //exact serach filter functionality
          enableColumnFilters={config.DatatableColumnFilter}
          onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
          state={{ columnVisibility, rowSelection }}
          onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          positionToolbarAlertBanner="bottom"
          enableDensityToggle={false}
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              backgroundColor: "#F6FAFF",
            },
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <Button
                sx={{bgColor:"#2A68B3"}}
                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportData(table.getPrePaginationRowModel().rows)
									}
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>
            </Box>
          )}
        />
      </>
    );
  }
  return (
    <div>
      <DefaultLayout content={<ContentLayout />} />
    </div>
  );
}


export default SuspendedAffiliate;