
import React, { useState, useEffect, } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import ViewSoftwareBankForm from '../../../Components/BankDetail/ViewSoftwareBankForm';
import Moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';
let htmlData ='';

function BankDetailsForm(props) {
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const { name } = useParams();
    const [value, setValue] = useState();
    const navigate = useNavigate();
    const [bank_details, setBankDetails] = useState([]);  
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [software, setSoftware] = useState('');
    const [softwareId, setSoftwareId] = useState('');
    const [operation, setOperation] = useState("Update");
    const [btntext, setBtntext] = useState("Update");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [currentDate, setCurrentDate] = useState("");
    const [softwareUserName, setSoftwareUserName] = useState('User');
    //set max date
    const myDate = new Date();
    myDate.setDate(myDate.getDate());
    let cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY');
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    //report issue functions and variable start
   
    
    const [mailBody,setMailBody] = useState();
    //report issue functions and variable end
    //history table variable start
    const [filterhistory, setFilterHistory] = useState([]);
    //history table variable end
    //modal variable and function start
    const [firstlable, setFirstLable] = useState(false);

    //Call Function if load this page
    useEffect(() => {
        setCurrentDate(cDate);
        fetchCompanyBankDetails();
        fetchSoftwareDetails();
    }, [])

    const fetchCompanyBankDetails = async() => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.software_name != name) {
                navigate('/login');
            }
        }
        handleFreezStart();
        const apiUrl = '/fetch-software-bank-details/' + name;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
           handleFreezStop();
            setSoftware(res.softwareName);
            if (res.softwareBankDetails != undefined) {
                setBankDetails(res.softwareBankDetails);
                setUpdatedAttributes(res.softwareBankDetails.updated_attributes);
                

                setValue(bank_details.last_ach_updated);
                setFilterHistory(Object.values(res.updatedAllAttributes));
                if (res.softwareBankDetails.report_issue == 1) {
                    if(user.roles_id < 3) {
                        setBtntext("Update And Resolve")
                    }else{
                        setBtntext("Update")
                    }  
                }else{
                    setBtntext("Update")
                }

                if (res.softwareBankDetails.bank_name == null || res.softwareBankDetails.bank_name == undefined) {
                    setFirstLable(true);
                }
                setOperation("Update");
            } else {
                setOperation("create");
                setBtntext("Submit")
            }
        } 
    };

    const fetchSoftwareDetails = async() => {
      
        const apiUrl = '/fetch-software-details/' + name;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if(res.success == true) {
                setSoftwareId(res.software.id);
                setSoftwareUserName(res.software.first_name ? res.software.first_name : 'User');
                htmlData = `
                <p>Dear ${ res.software.first_name ? res.software.first_name : "User" },</p>
                <p>The bank details you provided could not be verified with your bank. Please login to your affiliate account and update your account information in bank details.</p>
                <p>If you have any questions or if there is anything we can do to help you, please feel free to contact us:</p>
                <p>info@myfreescorenow.com</p>
                <p888-548-2008</p>
                <p>Thank You!</p>`;
                setMailBody(htmlData);
            }
        } 
    };

    function ContentLayout() {
    return (
        <>
            <ViewSoftwareBankForm bankDetails={bank_details} updatedAttributes={updatedAttributes} filterhistory={filterhistory} fetchCompanyBankDetails={fetchCompanyBankDetails} btntext={btntext} htmlData={htmlData} currentDate={currentDate} software={software} softwareId={softwareId} operation={operation}/>  
        </>
    );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default BankDetailsForm;