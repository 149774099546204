
import React, { useState, useRef } from "react";
import {Grid, Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement9({ highlightButton }) {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h6">
                            8.Acceptable Uses. Referrer represents and warrants that all content displayed on Referrer’s website(s) is solely owned by Referrer or that Referrer’s use thereof is authorized by the appropriate third Party; does not infringe upon any third Party rights (including, but not limited to intellectual property rights); is not defamatory, libelous, unlawful or otherwise objectionable. Referrer shall not provide, promote, distribute, place or otherwise publish, as a provider of Performance Marketing on behalf of MFSN, any content, or website that includes content, which is libelous, defamatory, obscene, pornographic, abusive, fraudulent or otherwise, violates any law. Since MFSN may not review all information provided by Referrer to the public, Referrer shall remain solely responsible for Referrer’s content and website(s). As a Referrer, Referrer may not artificially inflate traffic counts to MFSN site(s) using any device, program, robot or other means, including, but not limited to, JavaScript pop-up windows and/or redirects. Referrer shall not click on Referrer’s own banners and/or links.<br /><br />
                                
                            9. MFSN’s Privacy Policy and Terms & Conditions. Referrer acknowledges that MFSN’s Privacy Policy as well as its Terms & Conditions are agreements solely between the Visitor and MFSN and Referrer acknowledges that it shall not be deemed a third party beneficiary to any such agreement. Notwithstanding, Referrer agrees that it will abide by the applicable terms respecting the Visitors rights related to MFSN’s Privacy Policy and Terms & Conditions.<br/><br/>
                            10.Limited Warranty. MFSN shall operate its website in good faith and in a commercially reasonable manner. MFSN shall make all commercially reasonable efforts to maintain an operational website during normal business hours. However, certain technical difficulties may, from time to time, result in temporary service interruptions. Referrer understands and acknowledges that system downtime may occur and agrees that MFSN is not liable for any consequences of such interruptions. MFSN shall have no liability for unauthorized access, alteration, theft or destruction of any website of Referrer or referrer customer data files or systems or programs by way of accident, third party fraud or third party devices. To the fullest extent permissible pursuant to applicable law, MFSN disclaims all warranties express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, in relation to its service, its use and the results of such use. Without limiting the foregoing, MFSN specifically disclaims any warranty relating to its service (a) that it will be uninterrupted or error-free, (b) that defects will be corrected, (c) that there are no viruses or other harmful components, (d) that the security methods employed will be sufficient, or (e) as to MFSN’s correctness, accuracy, or reliability.<br/><br/>
                            11. Limitation of Liability. MFSN shall not be liable to Referrer or any other person for: (a) any indirect, special, incidental or consequential damages arising out of the use or inability to use MFSN’s service or any information provided on the MFSN website or any other hyperlinked website, including, without limitation, damages for loss of goodwill, lost profits, business interruption, loss of programs or other data, even if MFSN has been advised of the possibility of such damages, or (b) any claim attributable to errors, omissions or other inaccuracies in the MFSN website or any hyperlinked website. Referrer shall not be liable to MFSN or any other person for: (a) any indirect, special, incidental or consequential damages arising out of the use or inability to use the service or any information provided on the MFSN website or any other hyperlinked website, including, without limitation, damages for loss of goodwill, lost profits, business interruption, loss of programs or other data, even if Referrer has been advised of the possibility of such damages, or (b) any claim attributable to errors, omissions or other inaccuracies in the MFSN website or any hyperlinked website.
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement9;